import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AddUserComponent } from 'src/app/components/add-user/add-user.component';
import { AdminPageComponent } from 'src/app/components/admin-page/admin-page.component';
import { ManageUserComponent } from 'src/app/components/manage-user/manage-user.component';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { LoginService } from 'src/app/service/login.service';
// import { SetGstComponent } from '../set-gst/set-gst.component';
import { environment } from './../../../environments/environment';
import { TimeslotComponent } from 'src/app/components/timeslot/timeslot.component';
import { SetGstComponent } from '../set-gst/set-gst.component';
//import { UserAppointmentComponent } from 'src/app/components/user-appointment/user-appointment.component';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css'],
})
export class CommonHeaderComponent implements OnInit {
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public userName: string = '';
  access: any;
  public clinicList: any = [];
  public selectedClinic = 'Clinic List';
  public showError: boolean = false;
  isAdmin: boolean = false;
  userList: any;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private adminService: AdminService,
    private appService: AppointmentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    //console.log(this.userData.userAccess)
    this.userName =
      this.userData.userAccess.first_name +
      ' ' +
      (this.userData.userAccess.middle_name !== null &&
      this.userData.userAccess.middle_name !== 'null'
        ? this.userData.userAccess.middle_name
        : '') +
      ' ' +
      (this.userData.userAccess.last_name !== null &&
      this.userData.userAccess.middle_name !== 'null'
        ? this.userData.userAccess.last_name
        : '');
    this.access = this.userData.access;
    this.isAdmin = this.userData.userAccess.is_staff;
    this.userData.tenant_details.map((x) => {
      this.clinicList.push({ id: x[0], name: x[1], subdomain_prefix: x[2] });
    });
    if (!this.clinicList.find((x) => x.name == 'Clinic List')) {
      this.clinicList = [
        { id: 0, name: 'Clinic List', subdomain_prefix: 'Clinic List' },
        ...this.clinicList,
      ];
    }
    this.clinicList.map(
      (x) => (x.url = 'http://' + x.subdomain_prefix + '.medosys.co/login')
    );
    this.loadUsers();
    console.log(this.clinicList[0].name);
    this.selectedClinic = this.clinicList[0].name;
  }
  // homeClicked(){
  //   this.router.navigate(['/appointment']);
  // }
  logout() {
    this.appService.logout().subscribe(
      (response) => {
        //console.log(response);
        let res: any = response;
        if (res.data.message == 'Successfully logout') {
          this.router.navigate(['/login']);
        }
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  loadUsers() {
    this.adminService.GetUserList().subscribe(
      (response) => {
        if (response) {
          //console.log(response)
          this.userList = response;
          this.userList.map((element) => {
            element.edited = false;
          });
          //this.userList=this.userList.filter(x=>x.tenant==this.tenantId);
          //console.log(this.userList)
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  editProfile() {
    //console.log(this.userData.userAccess);
    //this.userData.userAccess.edited=true;
    let selectedUser = this.userList.find(
      (x) => x.id == this.userData.userAccess.id
    );
    selectedUser.edited = true;
    //console.log(selectedUser);
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '70vw',
      height: '90vh',
      disableClose: true,
      data: { userData: selectedUser },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        //console.log(result.data);
        if (result.data) {
          this.userData.userAccess = result.data;
          localStorage.setItem('userDetails', JSON.stringify(this.userData));
        }
      } else if (result.event == 'Update') {
        //console.log(result.data);
        if (result.data) {
          this.userData.userAccess = result.data;
          localStorage.setItem('userDetails', JSON.stringify(this.userData));
        }
      } else if (result.event == 'Cancel') {
        //console.log("cancel");
      }
    });
    return dialogRef.afterClosed();
  }
  public getClinicDetails() {
    this.loginService.GetClinicList().subscribe(
      (response) => {
        if (response) {
          let res: any = response;
          this.clinicList = [
            { id: 0, name: 'Clinic List', subdomain_prefix: 'Clinic List' },
            ...res,
          ];
          this.clinicList.map(
            (x) =>
              (x.url = 'http://' + x.subdomain_prefix + '.medosys.co/login')
          );
          //console.log(this.clinicList)
        }
      },
      (error) => {
        //console.log(error)
        if (error.error.detail) {
          this.showError = true;
        } else if (error.error.error.message) {
          this.snackBar.open(
            JSON.stringify(error.error.error.message),
            'Close',
            { duration: 2000 }
          );
        } else {
          this.snackBar.open(
            'Error While Logging Please contact Administrator',
            'Close',
            { duration: 2000 }
          );
        }
      }
    );
  }
  onClinicChange(event) {
    console.log(event.value);
    if (event.value !== 'Clinic List') {
      window.open(
        this.clinicList.find((x) => x.subdomain_prefix === event.value).url,
        '_blank'
      );
      // this.onClinicChange({value:'Clinic List'});
    }
    event.source.value = 'Clinic List';
    // this.getClinicDetails();
    // this.userData.userAccess.id=event.value;
    // localStorage.setItem("userDetails",JSON.stringify(this.userData));
    // this.appService.changeTenant(event.value);
    // let subdomain=this.clinicList.find(x=>x.id==event.value);
    // environment.baseUrl="https://"+subdomain+".medosysapi.com/"
  }
  manageUser() {
    const dialogRef = this.dialog.open(ManageUserComponent, {
      width: '70vw',
      height: '90vh',
      disableClose: true,
      data: { userData: this.userData },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        //this.dataLoad();
      } else if (result.event == 'Update') {
        //this.dataLoad();
      } else if (result.event == 'Cancel') {
        //this.dataLoad();
      }
    });
    return dialogRef.afterClosed();
  }
  manageTenant() {
    const dialogRef = this.dialog.open(AdminPageComponent, {
      width: '70vw',
      height: '90vh',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        //this.dataLoad();
      } else if (result.event == 'Update') {
        //this.dataLoad();
      } else if (result.event == 'Cancel') {
        //this.dataLoad();
      }
    });
    return dialogRef.afterClosed();
  }
  paymentSettings() {
    const dialogRef = this.dialog.open(SetGstComponent, {
      width: '30vw',
      height: 'auto',
      disableClose: true,
      data: { userData: this.userData.userAccess },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Add') {
        //this.dataLoad();
      } else if (result.event == 'Update') {
        //this.dataLoad();
      } else if (result.event == 'Cancel') {
        //this.dataLoad();
      }
    });
    return dialogRef.afterClosed();
  }
  timeDialog() {
    const dialogRef = this.dialog.open(TimeslotComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.snackBar.open('Updated Successfully', 'Close', { duration: 2000 });
      }
    });
    return dialogRef.afterClosed();
  }
}
