import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from "rxjs";
import { tap, catchError,finalize } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentService } from './service/appointment.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    public authToken: any;
    public userData:any;
    private totalRequests = 0;
    constructor(private router: Router,private snackBar: MatSnackBar,public http: HttpClient,
        private dialogRef: MatDialog,public appService:AppointmentService) {
        this.userData=JSON.parse(localStorage.getItem('userDetails'));
        this.authToken=JSON.parse(localStorage.getItem('userDetails'))?.authToken;
    }
intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        this.totalRequests++;
        this.appService.loaderEnable(true);
        return next.handle(req).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests == 0) {
                  this.appService.loaderEnable(false);
                }
              }),
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if(evt.status==200 && evt?.body?.data?.message=="Successfully Updated Your Password"){
                        this.dialogRef.closeAll();
                        this.router.navigate(['/login']);
                    }    
                }
            })
            ,
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    try {
                        if(err.status==400){
                            this.snackBar.open(JSON.stringify(err.error), 'Close', {duration: 4000});
                        }else if(err.status==404 && (err.error.error.message=="Signature has expired." || err.error.error.message=="Error decoding signature.")){
                            // const apiURL = environment.baseUrl + 'api/refresh_token';
                            // this.http.post(apiURL, {'token':this.authToken}).subscribe((res:any)=>{
                            //     console.log(res);
                            //     this.userData.authToken=res.token;
                            //     localStorage.setItem('userDetails',JSON.stringify(this.userData))
                            // },
                            // (error)=>{
                            //     this.snackBar.open(JSON.stringify(error), 'Close', {duration: 2000});
                            // })
                            this.snackBar.open(err.error.error.message, 'Close', {duration: 2000});
                            this.dialogRef.closeAll();
                            this.router.navigate(['/login']);
                        }  else if(err.status==404){
                            this.snackBar.open(JSON.stringify(err.error.error.message), 'Close', {duration: 4000});
                        } 
                    } catch(e) {
                        this.snackBar.open(JSON.stringify(e), 'Close', {duration: 2000});
                    }
                }
                return of(err);
            }));
    
      }
      
}
