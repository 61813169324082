import { AfterViewInit,Component, ViewChild,OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { CommonModalDialogService } from 'src/app/service/common-modal-dialog.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { AppointmentService } from 'src/app/service/appointment.service';
import { SetFeesComponent } from 'src/app/shared_components/set-fees/set-fees.component';

@Component({
  selector: 'app-doctor-management',
  templateUrl: './doctor-management.component.html',
  styleUrls: ['./doctor-management.component.css']
})
export class DoctorManagementComponent implements OnInit {
  version = VERSION;
  public displayedColumns: string[] = ['id','first_name','mcr_number','gender','phone_number','speciality','doctor_charge_ids'];
  public dataSource: MatTableDataSource<any>;
  public userList:any;
  public initialData:any=[];
  public userData=JSON.parse(localStorage.getItem('userDetails'));
  public showTable:boolean=true;
  tenantId: any=this.userData.userAccess.tenant;
  selectedData:any;
  selectedUser:any;
  selectedUserFull:any;
  @Output() backClick = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public roleArray=[];
public id:number;
  constructor(private route: ActivatedRoute,public adminService:AdminService,public appService:AppointmentService,
              private dialog: MatDialog,private snackBar: MatSnackBar,public modalService:CommonModalDialogService,) {
  }

  ngOnInit(): void {
    this.loadUsers();
    this.getRoles();
  }
  loadUsers(){
    this.adminService.GetDoctorFees().subscribe((response) => {
      if (response) {
        //console.log(response)
        this.userList=response;
        this.userList.sort((s1, s2) => {
          return s1.id - s2.id;
        });
        this.initialData=JSON.stringify(this.userList);
        this.dataSource = new MatTableDataSource(this.userList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        }
    }, (error) => {
      //console.log(error);
    });
  }
  getRoles(){
    this.appService.getRoles().subscribe((response) => {
      if (response) {
        ////console.log(response);
        let res:any = response;
        this.roleArray=res;
        }
    }, (error) => {
      //console.log(error);
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  setFee(data){
    console.log(data)
    const dialogRef = this.dialog.open(SetFeesComponent, {
      width: '30vw',
      height:'auto',
      disableClose: true,
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.event == 'Save'){
        //console.log(result.data);
        let doctor_charge_ids=result.data.doctor_charge_ids;
        this.adminService.EditDoctorFees({doctor_charge_ids},result.data.id).subscribe((response:any) => {
          if (response) {
            //console.log(response)
            this.userList.map(x=>{
              if(x.id==response.id){
                x=response;
              }
            })
            }
        }, (error) => {
          //console.log(error);
        });
      }else if(result.event == 'Cancel'){
        //console.log(result.event);
      }
    });
    return dialogRef.afterClosed(); 
  }
  closeDoctorRecord(){
    this.appService.doctorClick(true);
  }
  recordsClick(data){
    this.showTable=false;
    this.selectedData=data;
    this.selectedUser=undefined;
    this.appService.patientDetailsClick({flag:true,userData:[this.selectedData]});
  }
 
  closeClicked(event){
    this.showTable=event;
    this.appService.patientDetailsClick({flag:false,userData:null});
    this.loadUsers();
  }
}
