 <!-- <div id="med-multi-header">
<div class="drpicker">
  <mat-form-field appearance="fill">
    <mat-label><b>Date Range</b></mat-label>
    <mat-date-range-input [rangePicker]="picker1">
      <input matStartDate placeholder="Start date" #dateRangeStart [(ngModel)]="fDate">
      <input matEndDate placeholder="End date" #dateRangeEnd  [(ngModel)]="tDate" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
    </mat-date-range-input>
    <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-date-range-picker #picker1></mat-date-range-picker>
  </mat-form-field>
</div>
</div> -->
<!--<div class="card-header-right">
  <div *ngIf="!maximize" class="fullscreen" id="fullscreen" (click)="openFullscreen()"><mat-icon>fullscreen</mat-icon></div>
  <div *ngIf="maximize" class="fullscreen" (click)="fullScreenExit()"><mat-icon>fullscreen_exit</mat-icon></div>
  <div class="fullscreen" id="fullscreen" (click)="downloadChart()"><mat-icon>cloud_download</mat-icon></div>
</div>
</div>
<ngx-charts-bar-horizontal-2d
  [view]="view"
  [scheme]="colorScheme"
  [results]="multi"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  > -->
  <!-- [gradient]="gradient" -->
  <!-- [schemeType]="schemeType" -->
<!-- </ngx-charts-bar-horizontal-2d> -->
<!-- <div class="p-3" style="padding-top: 5rem !important;"> -->
  <plotly-plot [data]="graph.data" [layout]="graph.layout" [config]="graph.config"></plotly-plot>      
<!-- </div> -->