<div class="closeIcon" (click)="closeDialog()">X</div>
<div class="header">{{tenantData.edited?'Edit Tenant - '+tenantData.name:'Add Tenant'}}</div>
<mat-tab-group [selectedIndex]="selectedIndex" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)">
<mat-tab label="General Details">
<table class="tenantTable">
  <tr>
    <td rowspan="2">
        <div class="imageURL">
        <label class="hoverable" for="fileInput">
          <img [src]="url ? url : 'https://www.w3schools.com/howto/img_avatar.png'"> 
          <div class="hover-text">Choose file</div>
          <div class="background"></div>
        </label>
        <br/>
        <input id="fileInput" type='file' (change)="onSelectFile($event)">
        </div>
    </td>
    <td>
      <label ><span class="material-icons">
        person
        </span><b>Clinic</b></label>
      <br>
      <mat-form-field appearance="outline" [style.width.px]=200>
        <input matInput placeholder="Tenant / Hospital" [(ngModel)]="tenantData.name">
      </mat-form-field>
    </td>
    <td>
      <label ><span class="material-icons">
        person
        </span><b>Tenant Name</b></label>
      <br>
      <mat-form-field appearance="outline" [style.width.px]=200>
        <input matInput placeholder="Name"
        [(ngModel)]="tenantData.subdomain_prefix" (mouseenter) ="adminMouseEnter()"  (mouseleave) ="adminMouseLeave()"/> 
      </mat-form-field>
      <br>
      <div *ngIf="showWarning && tenantData.edited" class="warning-alert">
        <div class="warning"><mat-icon style="color:black;top:5px;">warning</mat-icon><span style="margin-left:10px">Warning</span></div>
        <div class="tooltip-text">{{toolTipText}}</div>
      </div>
    </td>
    <td>
      <label><span class="material-icons">
        location_city
        </span><b>Branch</b></label>
        <br>
        <mat-form-field appearance="outline" [style.width.px]=200>
          
          <input matInput placeholder="Address 1" [(ngModel)]="tenantData.address1">
        </mat-form-field>
    </td>
  </tr>
  <tr>
    <td>
      <label><span class="material-icons">
        location_city
        </span><b>Address</b></label>
        <br>
      <mat-form-field appearance="outline" [style.width.px]=200>
          
        <input matInput placeholder="Address 2" [(ngModel)]="tenantData.address2">
      </mat-form-field>
    </td>
    <td>
      <span class="material-icons">location_city</span>
      <label><b>Country</b></label>
      <br>
      <mat-form-field appearance="outline" [style.width.px]=200>
        <mat-select [(ngModel)]="tenantData.country" (selectionChange)="onCountryChange($event)">
          <mat-option *ngFor="let country of countryList" [value]="country">
            {{country}}
          </mat-option>
        </mat-select>
          <!-- <mat-select [(ngModel)]="tenantData.country">
            <mat-option  value="India">India</mat-option>
            <mat-option  value="Sinagapore">Sinagapore</mat-option>
            <mat-option  value="USA">USA</mat-option>
            <mat-option  value="Australia">Australia</mat-option>
          
          </mat-select> -->
      </mat-form-field>
    </td>
    <td>
      <label ><span class="material-icons">
        apartment
        </span><b>State</b></label><br>
        <mat-form-field appearance="outline" [style.width.px]=200>
            <mat-select [(ngModel)]="tenantData.state">
              <mat-option *ngFor="let state of stateList" [value]="state">
                {{state}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </td>
  </tr>
  <tr>
    <td></td>
    <td>
      <label><span class="material-icons">
        location_city
        </span><b>City</b></label><br>
        <mat-form-field appearance="outline" [style.width.px]=200>
            <mat-select [(ngModel)]="tenantData.city">
              <mat-option *ngFor="let city of cities" [value]="city.value">
                {{city.viewValue}}
              </mat-option>
            
            </mat-select>
        </mat-form-field>
    </td>
    <td>
      <span class="material-icons">phone</span>
        <label class="ph"><b>Country Code</b></label>
        <br>
        <mat-form-field class="example-full-width" [style.width.px]=200 appearance="outline">
          <input type="text"
          [disabled]="!tenantData.is_active" 
                 placeholder="Country"
                 aria-label="Number"
                 matInput
                 [(ngModel)]="tenantData.phone_number_prefix"
                 [formControl]="myControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete [disabled]="!tenantData.is_active" #auto="matAutocomplete" (optionSelected)='getPosts($event.option.value)'>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.dial_code">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
    </td>
    <td>
      <span class="material-icons">phone</span>
      <label class="ph"><b>Phone</b></label>
      <br>
      <mat-form-field appearance="outline" [style.width.px]=200>
        <input matInput placeholder="Number" [(ngModel)]="tenantData.phone_number">
      </mat-form-field>
    </td>
    
  </tr>
  <tr>
    <td></td>
    <td>
      <label><span class="material-icons">
        code
        </span><b>Zipcode</b></label><br>
        <mat-form-field appearance="outline" [style.width.px]=200>
          <input matInput placeholder="zipcode"  [(ngModel)]="tenantData.zipcode">
        </mat-form-field>
    </td>
    <td>
      <label ><span class="material-icons">
        location_city
        </span><b>Time Zone</b></label>
        <br>
        <mat-form-field appearance="outline" [style.width.px]=200>
          <mat-select [(ngModel)]="tenantData.time_zone">
            <mat-option value="IST" selected>IST</mat-option>
            <mat-option *ngFor="let timeZone of timeZoneList" [value]="timeZone">
              {{timeZone}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </td>
    <td>
      <mat-checkbox class="example-margin"
      [(ngModel)]="tenantData.active" [checked]="tenantData.active">Active
      </mat-checkbox>
    </td>
  </tr>
  <tr>
    <td>
      <label style="padding-left:25%"><b>Seal Image</b></label><br>
        <div class="imageURL">
        <label class="hoverable" for="fileInput1">
          <img [src]="sealUrl ? sealUrl : 'https://thumbs.dreamstime.com/z/grunge-textured-hospital-stamp-seal-ribbon-rosette-watermark-texture-designed-round-small-crowns-blue-vector-rubber-136161493.jpg'"> 
          <div class="hover-text">Choose file</div>
          <div class="background"></div>
        </label>
        <br/>
        <input id="fileInput1" type='file' (change)="onSelectSealFile($event)">
        </div>
    </td>
    <td></td>
    <td colspan="2">
      <button class="btn1" mat-stroked-button color='default' (click)="closeDialog()">Cancel</button>&nbsp;
      <button class="btn" mat-stroked-button color='primary' (click)="saveDialog()">Save</button>
    </td>
  </tr>
</table>
</mat-tab>
<mat-tab label="Work time & Holiday">
  <div class="p-3 d-flex justify-content-between">
<h2 class="heading"><b>Work time & Holiday</b></h2>
<button class="btn background" (click)="saveTime()">Save</button>
</div>
<div class="p-3" style="padding-top: 0px;">
<h3><b>Work time</b></h3>
</div>
<div class="container">
<div class="row">
    <div class="col-sm-4">
      <h3><b>Morning</b></h3>
      <div class="d-flex justify-content-between">
      <div class="col-sm-6" style="padding-left: 0px;">
      <label for="start_time">Start Time</label>
      <input matInput type='time' class="form-control" id="starttime" value="morning" [(ngModel)]="morning"/>
    </div>
    <div class="col-sm align-self-end pb-2">to</div>
      <div class="col-sm-6" style="padding-left: 0px;">
      <label for="end_time">End Time</label>
      <input type='time' class="form-control" id="endtime" [(ngModel)]="slotData.endMor"/>
    </div>
  </div>
    </div>
    <div class="col-sm-4">
      <h3><b>Afternoon</b></h3>
      <div class="d-flex justify-content-between">
        <div class="col-sm-6" style="padding-left: 0px;">
        <label for="start_time">Start Time</label>
        <input type='time' class="form-control" id="starttime" [(ngModel)]="slotData.startAft"/>
      </div>
      <div class="col-sm align-self-end pb-2">to</div>
        <div class="col-sm-6" style="padding-left: 0px;">
        <label for="end_time">End Time</label>
        <input type='time' class="form-control" id="endtime" [(ngModel)]="slotData.endAft"/>
      </div>
    </div>
    </div>
    <div class="col-sm-4">
      <h3><b>Evening</b></h3>
      <div class="d-flex justify-content-between">
        <div class="col-sm-6" style="padding-left: 0px;">
        <label>Start Time</label>
        <input type='time' class="form-control" id="starttime" [(ngModel)]="slotData.startEve"/>
      </div>
      <div class="col-sm align-self-end pb-2">to</div>
        <div class="col-sm-6" style="padding-left: 0px;">
        <label>End Time</label>
        <input type='time' class="form-control" id="endtime" [(ngModel)]="slotData.endEve"/>
      </div>
    </div>
    </div>
  
</div>
</div>
<div class="p-3">
  <h3><b>Interval</b></h3>
   <div class="row">
    <div class="col-sm-4">
      <h4 class="p-0" style="padding-left: 42px;"><b>Time</b></h4>
      <div class="col-sm-2 p-0">
        <mat-form-field appearance="outline">
          <input matInput placeholder="mins" [(ngModel)]="slotData.interval" required>
        </mat-form-field>
    </div>
    </div>
  </div>
 
</div>
<div class="p-3">
  <h3><b>Holiday Management</b></h3>
  <div class="container m-0">
    <div class="row">
      <div class="col-sm-6">
        <h3><b>Saturday</b></h3>
        <div class="d-flex justify-content-between">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" value="option1" checked="true">
            <label class="form-check-label" for="exampleRadios1">Holiday</label>
          </div>
          <div class="form-check">
            <input class="form-check-input"  type="radio" name="exampleRadios" value="option2">
            <label class="form-check-label" for="exampleRadios2">Working</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="option3">
            <label class="form-check-label" for="exampleRadios3">Alternate Working Day</label>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h3><b>Sunday</b></h3>
        <div class="d-flex justify-content-between">
          <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" value="option" checked="true">
    <label class="form-check-label" for="exampleRadios1">Holiday</label>
    </div>
    <div class="form-check">
    <input  class="form-check-input" type="radio" name="exampleRadios" value="option2">
    <label class="form-check-label" for="exampleRadios2">
      Working
    </label>
    </div>
    <div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" value="option3">
    <label class="form-check-label" for="exampleRadios3">
     Alternate Working Day
    </label>
    </div>
  </div>
      </div>
   
  </div>
  </div>
</div>
<div class="p-3">
  <h3><b>Public Holidays</b></h3>
  </div>


</mat-tab>
</mat-tab-group>
