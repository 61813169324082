import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import{AdminService} from '../../service/admin.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService } from 'src/app/service/appointment.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
interface Gender {
  value: string;
  viewValue: string;
}
interface City {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-tenant',
  templateUrl: './add-tenant.component.html',
  styleUrls: ['./add-tenant.component.css']
})
export class AddTenantComponent implements OnInit {
  cities: City[] = [
    {value: 'chennai', viewValue: 'Chennai'},
    {value: 'coimbatore', viewValue: 'Coimbatore'},
    {value: 'trichy', viewValue: 'Trichy'},
    {value: 'salem', viewValue: 'Salem'},
    {value: 'madurai', viewValue: 'Madurai'},
    {value: 'thanjavur', viewValue: 'Thanjavur'},
    {value: 'kanchipuram', viewValue: 'Kanchipuram'},
    {value: 'erode', viewValue: 'Erode'}
  ];
  tenantData:any;
  url:any=null;
  sealUrl:any=null;
  file:any=null;
  sealFile:any=null;
  telephoneCodes:any=[];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  countryCode:any;
  countryList:any=[];
  stateList:any=[];
  timeZoneList:any=[];
  showWarning:boolean=false;
  selectedIndex: number = 0;
  toolTipText:any="By changing the TENANT NAME, the application configuration will be changed, and the application will not be accessible.  Please discuss this with your local administrator or Medosys team"
  slotData={
    startMor:"",endMor:"",startAft:"",endAft:"",startEve:"",endEve:"",interval:""
  }
  timeSlotData:any;
  morning:any;
  constructor( public adminService:AdminService, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef:MatDialogRef<AddTenantComponent>,private snackBar: MatSnackBar,public appService:AppointmentService) { 
      
    }

  ngOnInit(): void {
    this.tenantData=this.data.tenantData;
    this.url=this.tenantData.logo_url;
    this.sealUrl=this.tenantData.clinic_stamp_url;
    this.tenantData.speciality_ids=[1];
    console.log(this.tenantData);
    this.onLoadTelephoneCodes();
    this.onLoadTimeZone();
    this.onLoadCountries();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.closeDialog();
      }
  });
  if(this.tenantData?.edited){
    this.onLoadStates(this.tenantData.country)
  }
  this.getTimeslot()
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.telephoneCodes.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  onLoadTelephoneCodes(){
    this.appService.getDataFromURL('../../assets/json/telephoneCodes.json').subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        this.telephoneCodes=res;
        }
    }, (error) => {
      console.log(error);
    });
  }
  onLoadTimeZone(){
    this.appService.getTimeZone().subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        this.timeZoneList=res;
        }
    }, (error) => {
      console.log(error);
    });
  }
  onLoadCountries(){
    this.adminService.getCountryList().subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        this.countryList=res.data;
        }
    }, (error) => {
      console.log(error);
    });
  }
  onLoadStates(country){
    this.adminService.getStateList(country).subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        this.stateList=res.data;
        }
    }, (error) => {
      console.log(error);
    });
  }
  onCountryChange(event){
    this.onLoadStates(event.value)
  }
  getPosts(countryCode){
    this.countryCode=countryCode;
    this.tenantData.phone_number_prefix=this.countryCode;
  }
  closeDialog(){
      this.dialogRef.close({event:'Cancel'});
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.file=event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  }
  onSelectSealFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.sealFile=event.target.files[0];
      var reader1 = new FileReader();
      reader1.readAsDataURL(event.target.files[0]); // read file as data url
      reader1.onload = (event) => { // called once readAsDataURL is completed
        this.sealUrl = event.target.result;
      }
    }
  }
  saveDialog(){
    let formData = new FormData();
    if(this.tenantData.phone_number_prefix!=='undefined' && this.tenantData.phone_number_prefix!=='null'){
      Object.keys(this.tenantData).forEach((key)=>{
        if(key!=='logo_url' && key!=='clinic_stamp_url'){
          formData.append(key,this.tenantData[key]);
        }
      });
    if(this.tenantData.edited){
      if(this.file!==null){
        formData.append('logo_url',this.file,this.file.name);
      }
      if(this.sealFile!==null){
        formData.append('clinic_stamp_url',this.sealFile,this.sealFile.name);
      }
      this.adminService.editTenentList(formData,this.tenantData.id).subscribe((response) => {
        if (response) {
          this.dialogRef.close({event:'Update',data:response});
          }
      }, (error) => {
        this.snackBar.open(error, 'Close', {duration: 2000});
      });
    }else{
      if(this.file!==null){
        formData.append('logo_url',this.file,this.file.name);
      }
      if(this.sealFile!==null){
        formData.append('clinic_stamp_url',this.sealFile,this.sealFile.name);
      }
      this.adminService.addTenant(formData).subscribe((response) => {
        if (response) {
          this.dialogRef.close({event:'Add',data:response});
          }
      }, (error) => {
        this.snackBar.open(error, 'Close', {duration: 2000});
      });
    }
  } else {
    this.snackBar.open("Please enter the country code", 'Close', {duration: 2000});
  }
  }
  adminMouseEnter(){
      this.showWarning=true;
    }
    adminMouseLeave(){
      this.showWarning=false;
    }
    onTabChanged(event){
      console.log(event.index)
      if(event.index==0){}
    }
    saveTime(){
      console.log(this.slotData)
      let data=
      {
        "morning_opening_time": this.slotData.startMor,
        "afternoon_opening_time": this.slotData.startAft,
        "evening_opening_time": this.slotData.startEve,
        "morning_closing_time": this.slotData.endMor,
        "afternoon_closing_time": this.slotData.endAft,
        "evening_closing_time": this.slotData.endEve,
        "time_interval": this.slotData.interval,
        "is_saturday_holiday": true,
        "is_sunday_holiday": true,
        "tenant": 1
      }
      console.log(data)
      let id=1
      if(this.timeSlotData.length>0){
        this.appService.updateTimeslot(data,id).subscribe((response) => {
          if (response) {
            let res:any = response;
          console.log(res)}
        })
      }else{
      this.appService.generateTimeslot(data).subscribe((response) => {
        if (response) {
          //console.log(response);
          let res:any = response;
          console.log(res)
          // this.timeZoneList=res;
          }
      })
    }
    }
    time24To12(a:any) {
      //below date doesn't matter.
      return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
          timeZone: "UTC",
          hour12: !0,
          hour: "numeric",
          minute: "numeric"
      });
  }
  getTimeslot(){
    let id=1
    this.appService.getTimeslot(id).subscribe((response:any) => {
      if (response) {
      console.log(response)
      this.timeSlotData=response
      this.morning=this.timeSlotData.morning_opening_time
      console.log(this.morning)
    }
  })
}
}
function getDay(arg0: string): any {
  throw new Error('Function not implemented.');
}


