import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { CommonModalDialogService } from 'src/app/service/common-modal-dialog.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { AppointmentService } from 'src/app/service/appointment.service';
import { SetFeesComponent } from 'src/app/shared_components/set-fees/set-fees.component';
import { RefundResponseComponent } from 'src/app/shared_components/refund-response/refund-response.component';

@Component({
  selector: 'app-refund-requests',
  templateUrl: './refund-requests.component.html',
  styleUrls: ['./refund-requests.component.css'],
})
export class RefundRequestsComponent implements OnInit {
  version = VERSION;
  public displayedColumns: string[] = [
    'sno',
    'appointment_id',
    'nric/passport_number',
    'patient_name',
    'request_type',
    'message',
    'request_Date',
    'action',
  ];
  public displayedColumns2: string[] = [
    'sno',
    'appointment_id',
    'nric/passport_number',
    'patient_name',
    'request_type',
    'message',
    'operator_name',
    'approval_date',
    'reply',
    'action',
  ];
  public dataSource: MatTableDataSource<any>;
  public dataSource2: MatTableDataSource<any>;
  public refundList: any;
  public initialData: any = [];
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public showTable: boolean = true;
  label_Not_processed = 'Not Processed';
  label_processed = 'Processed';
  tenantId: any = this.userData.userAccess.tenant;
  selectedData: any;
  selectedUser: any;
  selectedUserFull: any;
  @Output() backClick = new EventEmitter<any>();
  currentDateTime: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public id: number;
  allProvider: any;
  operator: any;
  constructor(
    private route: ActivatedRoute,
    public adminService: AdminService,
    public appService: AppointmentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public modalService: CommonModalDialogService
  ) {}

  ngOnInit(): void {
    console.log(this.userData);
    this.currentDateTime = new Date().toISOString();
    console.log(this.currentDateTime);
    // var d = new Date();
    // let month =(d.getMonth() + 1);
    // let day = d.getDate();
    // let year = d.getFullYear();
    // this.dateTime=month+"-"+day+"-"+year;
    //   console.log(this.dateTime)
    this.loadUsers();
    this.getProviders();
  }
  loadUsers() {
    this.adminService.GetRefundDetails().subscribe(
      (response) => {
        if (response) {
          console.log(response);
          this.refundList = response;
          const processedList = this.refundList
            .filter((refund) => refund.doctor_description !== null)
            .map((refund) => {
              const refundData = {
                ...refund,
                patientMessageshowMore: false,
              };
              return refundData;
            });
          const unprocessedList = this.refundList
            .filter((refund) => refund.doctor_description === null)
            .map((refund) => {
              const refundData = {
                ...refund,
                docMessageshowMore: false,
                patientMessageshowMore: false,
              };
              return refundData;
            });

          this.refundList = this.refundList.filter(
            (x) => x.refund_status == null
          );
          this.initialData = JSON.stringify(this.refundList);
          this.dataSource = new MatTableDataSource(unprocessedList);
          this.dataSource2 = new MatTableDataSource(processedList);
          this.dataSource.paginator = this.paginator;
          this.dataSource2.paginator = this.paginator2;
          this.dataSource.sort = this.sort;
          this.dataSource2.sort = this.sort;
          this.label_Not_processed = `Un-Processed (${unprocessedList.length})`;
          this.label_processed = `Processed (${processedList.length})`;
        }
      },
      (error) => {}
    );
  }
  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource2.paginator = this.paginator2;
  //   this.dataSource.sort = this.sort;
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.dataSource2.filter = filterValue.trim().toLowerCase();

    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
  }
  trimString(text, length) {
    return text.trim().length > length
      ? text.substring(0, length).trim() + '...'
      : text.trim();
  }
  setFee(data) {
    const dialogRef = this.dialog.open(SetFeesComponent, {
      width: '30vw',
      height: '40vh',
      disableClose: true,
      data: { data: data },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == 'Save') {
        this.adminService.EditDoctorFees(result.data, 2).subscribe(
          (response: any) => {
            if (response) {
              this.refundList.map((x) => {
                if (x.id == response.id) {
                  x = response;
                }
              });
            }
          },
          (error) => {}
        );
      } else if (result.event == 'Cancel') {
      }
    });
    return dialogRef.afterClosed();
  }
  closeRefund() {
    this.appService.refundClick(true);
  }
  acceptRefund(data) {
    const approvedData = {
      doctor_description: 'OK',
      status: 'AP',
      refund_total_amount: data.refund_total_amount,
    };

    this.adminService.EditRefundDetails(approvedData, data.id).subscribe(
      (response: any) => {
        if (response) {
          this.refundList.map((x) => {
            if (x.id == response.id) {
              x = response;
            }
          });
          this.loadUsers();
        }
      },
      (error) => {}
    );
  }
  rejectRefund(data: any, type: string) {
    data.doctor_description = '';
    const dialogRef = this.dialog.open(RefundResponseComponent, {
      width: '40vw',
      height: 'auto',
      disableClose: true,
      data: { data: data, headerText: type },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result.event == 'Save') {
        let params = result.data;
        let appoinmentDetail: any = {
          doctor_description: params.doctor_description,
        };
        if (type === 'accept') {
          appoinmentDetail.status = 'AP';
          appoinmentDetail.refund_total_amount = data.refund_total_amount;
          appoinmentDetail.refund_or_reschedule_approval_date =
            this.currentDateTime;
        } else {
          appoinmentDetail.status = 'RJ';
          appoinmentDetail.refund_or_reschedule_rejection_date =
            this.currentDateTime;
        }
        console.log(appoinmentDetail);
        this.adminService
          .EditRefundDetails(appoinmentDetail, result.data.id)
          .subscribe(
            (response: any) => {
              if (response) {
                console.log(response);
                this.refundList.map((x) => {
                  if (x.id == response.id) {
                    x = response;
                  }
                });
                this.loadUsers();
              }
            },
            (error) => {}
          );
      } else if (result.event == 'Cancel') {
      }
    });

    return dialogRef.afterClosed();
  }
  onTabChanged(event) {}

  getProviders() {
    this.adminService.GetDoctorFees().subscribe((response: any) => {
      if (response) {
        console.log(response);
        this.allProvider = response;
      }
    });
  }
  getProviderName(id) {
    debugger;
    console.log(id);
    let a = this.allProvider.filter((obj) => obj.id === id);
    console.log(a);
    console.log(a[0].first_name);
    this.operator = a[0].first_name;
    return this.operator;
  }
}
