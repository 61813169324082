import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.css']
})
export class TimeslotComponent {
  slotData={
    startMor:"",endMor:"",startAft:"",endAft:"",startEve:"",endEve:"",interval:""
  }
  timeSlotData:any;
  morning:any;
  isSaturdayHoliday:boolean = null;
  isSundayHoliday:boolean = null;
  holiday:any={};
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  public model = [
    // new Date('7/15/1966'),
    // new Date('3/23/1968'),
    // new Date('7/4/1992'),
    // new Date('1/25/1994'),
    // new Date('6/17/1998')
  ];
  updateId:any;
  formDate:any;
  tenantId:any;
  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;
  option1 = [
    {  id: 'Holiday', value: false, label: 'Holiday'},
    {   id: 'Working',value: false, label: 'Working'},
   
  ];  
  option2 = [
    { id: 'Holiday',value: false, label: 'Holiday' },
    { id: 'Working',value: false, label: 'Working' },
    
  ];  
  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = event.value;
      const index = this._findDate(date);
      if (index === -1) {
        this.model.push(date);
      } else {
        this.model.splice(index, 1)
      }
      this.resetModel = new Date(0);
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
    }
  }

  public remove(date: Date): void {
    
    console.log(date)
    const index=this.model.findIndex((a)=>a == date)
    this.model.splice(index, 1)
    console.log(this.model)
    let remov=this.holiday[0].holiday_dates.filter((a)=>a == date)
    console.log(remov)
    if(!!remov){
    this.updateId=this.holiday[0].id
    this.saveHoliday(null)
    }
  }

  private _findDate(date: Date): number {
    return this.model.map((m) => +m).indexOf(+date);
  }


constructor(public appService:AppointmentService,public dialogRef:MatDialogRef<TimeslotComponent>,public datepipe:DatePipe){}

ngOnInit():void{
 let userData = JSON.parse(localStorage.getItem('userDetails'));
 this.tenantId=userData.tenant_details[0]
 this.getTimeslot();
 this.getHoliday();
}

  saveTime(){
    console.log(this.slotData)
    let id=this.tenantId[0]
    let data=
    {
      "morning_opening_time": this.timeSlotData.morning_opening_time,
      "afternoon_opening_time": this.timeSlotData.afternoon_opening_time,
      "evening_opening_time": this.timeSlotData.evening_opening_time,
      "morning_closing_time": this.timeSlotData.morning_closing_time,
      "afternoon_closing_time": this.timeSlotData.afternoon_closing_time,
      "evening_closing_time": this.timeSlotData.evening_closing_time,
      "time_interval": this.timeSlotData.time_interval,
      "is_saturday_holiday": this.isSaturdayHoliday,
      "is_sunday_holiday": this.isSundayHoliday,
      "tenant": id
    }
    console.log(data)
    if(!!this.timeSlotData){
      this.appService.updateTimeslot(data,this.timeSlotData.id).subscribe((response) => {
        if (response) {
          let res:any = response;
        console.log(res)}
      })
      this.dialogRef.close(true);
    }
    else{
    this.appService.generateTimeslot(data).subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        console.log(res)
        this.timeSlotData=response
        // this.timeZoneList=res;
        }
    })
  }
  
}
  time24To12(a:any) {
    //below date doesn't matter.
    return (new Date("1955-11-05T" + a + "Z")).toLocaleTimeString("bestfit", {
        timeZone: "UTC",
        hour12: !0,
        hour: "numeric",
        minute: "numeric"
    });
}
getTimeslot(){
  let id=this.tenantId[0]
  this.appService.getTimeslot(id).subscribe((response:any) => {
    if (response) {
    console.log(response[0])
    this.timeSlotData=response[0];
    this.morning=this.timeSlotData.morning_opening_time;
    if(this.timeSlotData.is_saturday_holiday == undefined || this.timeSlotData.is_saturday_holiday == null){
      this.isSaturdayHoliday = true;
    }
    else{
      this.isSaturdayHoliday = this.timeSlotData.is_saturday_holiday;
    }
    if(!this.timeSlotData || this.timeSlotData.is_sunday_holiday == undefined || this.timeSlotData.is_sunday_holiday == null){
      this.isSundayHoliday = true;
    }
    else{
      this.isSundayHoliday = this.timeSlotData.is_sunday_holiday;
    }
    console.log("isSaturdayHoliday" + this.isSaturdayHoliday)
  }
})
}
closeDialog(){
  this.dialogRef.close({event:'Cancel'});
}
onOptionChange(item: string) {
//  console.log(event); 
//  this.isSaturdayHoliday=event
if(item == "Holiday"){
  this.isSaturdayHoliday = true;
}
else{
  this.isSaturdayHoliday = false;
}

 console.log(this.isSaturdayHoliday);
}
onOptionChange1(item: string) {
  if(item == "Holiday"){
    this.isSundayHoliday = true;
  }
  else{
    this.isSundayHoliday = false;
  }
 }

 saveHoliday(event){
  debugger
 
    if(this.holiday.length>0){
      if(!!this.updateId){
        if(event!==null){
        var d = new Date(event);
        let month =(d.getMonth() + 1);
        let day = d.getDate();
        let year = d.getFullYear();
        console.log(month,day,)
      this.formDate=month+"-"+day+"-"+year;
        console.log(this.formDate)
        console.log(this.model)
        let a=this.model.map(b=>this.datepipe.transform(b,'MM-dd-YYYY'))
        console.log(a)
        this.model=a
        this.model.push(this.formDate)
        console.log(this.model)
       }else{
       console.log(this.model)
       let a=this.model.map(b=>this.datepipe.transform(b,'MM-dd-YYYY'))
       console.log(a)
       this.model=a
      }       
        let holiday={
          "holiday_dates": this.model,
          "tenant": 1
        }
        console.log(holiday)
            this.appService.updateHoliday(holiday,this.updateId).subscribe((response) => {
        if (response) {
          console.log(response)
        }
      })
    }
    }else{
      var d = new Date(event);
      let month =(d.getMonth() + 1);
      let day = d.getDate();
      let year = d.getFullYear();
     let date=month+"-"+day+"-"+year;
      console.log(date)
      console.log(this.model)
      this.model.push(date)
      console.log(this.model)
      let holiday={
        "holiday_dates": this.model,
        "tenant": 1
      }
    this.appService.saveHoliday(holiday).subscribe((response) => {
    if (response) {
      console.log(response)
    }
  })
  }
 }
 getHoliday(){
  let id=this.tenantId[0]
  console.log(id)
  this.appService.getHolidays(id).subscribe((response) => {
    if (response) {
      console.log(response)
      this.holiday=response
      console.log(this.holiday)
      this.updateId=this.holiday[0].id
    if(this.holiday[0].holiday_dates!==null){
     this.model=this.holiday[0].holiday_dates
     let sor= this.model.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
     this.model=sor
    }
      }
  })
 }
 
  
}
