<!-- <div class="row container-fluid appointment-cotainer">
  <div class="col-12">
      <app-common-header></app-common-header>
  </div>
</div>
<div class="row container-fluid appointment-cotainer">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
    <app-left-panel></app-left-panel>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
         -->
<div class="patientContainer">
  <div *ngIf="isPatientRecTable">
    <div class="closeIcon" (click)="closePatientRecord()">X</div>
    <div class="row container-fluid appointment-cotainer">
      <div class="col-12" style="margin-top: 1vh">
        <div class="headContainer">
          <label
            style="
              width: 40%;
              float: left;
              font-size: 1.2em;
              padding: 30px 20px 17px 20px;
              color: white;
              font-weight: 500;
            "
            >Patient Records ({{
              userList && userList.length ? userList.length : 0
            }})</label
          >
          <!-- <div style="background: #ECECEC; padding:10px;padding-bottom:11px;width:10%;float:left;">
                <button mat-raised-button color="primary" (click)="addUser()">Add</button></div> -->
          <div style="width: 60%; float: right">
            <mat-form-field style="margin-right: 5%; width: 40%; float: right">
              <input
                matInput
                (keyup)="applyFilter($event)"
                style="
                  color: grey;
                  border-radius: 25px;
                  background: white;
                  padding-block: 10px;
                "
                #input
              />
              <mat-placeholder class="placeholder">Search</mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort class="userTable">
            <!-- Table Columns -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Si.Num</th>
              <td mat-cell *matCellDef="let i = index">
                {{
                  this.paginator?.pageIndex == 0
                    ? i + 1
                    : 1 +
                      i +
                      this.paginator?.pageIndex * this.paginator?.pageSize
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="mrn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                NRIC/Passport
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="patientrec-nric-passport"
                matTooltip="{{ row.mrn | nricMask }}"
                matTooltipClass="mrn-tooltip"
                style="text-transform: uppercase"
              >
                <span *ngIf="!!row.mrn && row.mrn !== 'string'">{{
                  row.mrn | nricMask
                }}</span>
                <span
                  *ngIf="
                    !!row.passport_number && row.passport_number !== 'string'
                  "
                  >{{ row.passport_number }}</span
                >

                <!-- {{ row.mrn | nricMask }} -->
              </td>
            </ng-container>
            <ng-container matColumnDef="first_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let row" class="patientrec-name">
                {{ row.first_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
              <td mat-cell *matCellDef="let row">
                <span>{{ row.gender == "F" ? "Female" : "Male" }}</span>
                <!-- <span *ngIf="row.edited"><input class="editInput" matInput [(ngModel)]="row.gender" value="{{row.gender}}"></span> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone Numer
              </th>
              <td mat-cell *matCellDef="let row">
                <span>{{ row.phone_number }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="D.O.B">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>D.O.B</th>
              <td mat-cell *matCellDef="let row">
                <span>{{ row.date_of_birth }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="record">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Record</th>
              <!-- <td mat-cell *matCellDef="let row">
                            <span *ngFor="let role of row.role_id">
                                <span class="roleStyle">{{roleArray[role-1]}}</span>
                            </span>
                          
                        </td> -->
              <td mat-cell *matCellDef="let row">
                <span>
                  <span class="roleStyle" (click)="recordsClick(row)"
                    >View Record</span
                  >
                </span>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="admin">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
                      <td mat-cell *matCellDef="let row"> 
                        <span>{{row.is_staff?"Yes":"No"}}</span>
                      </td>
                    </ng-container> -->

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
              <td mat-cell *matCellDef="let row">
                <span
                  ><mat-icon
                    class="plus-icon"
                    style="font-size: 1.5em; cursor: pointer; color: green"
                    (click)="editUser(row)"
                    >edit</mat-icon
                  ></span
                >
                <span *ngIf="row.is_active"
                  ><mat-icon
                    (click)="deleteUser(row)"
                    class="enableDel"
                    title="Delete User"
                    >delete</mat-icon
                  ></span
                >
                <span *ngIf="!row.is_active"
                  ><mat-icon class="disableDel" title="User is In-active"
                    >delete</mat-icon
                  ></span
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="viewActivity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Activity
              </th>
              <td mat-cell *matCellDef="let row">
                <span
                  style="color: #0984a8; cursor: pointer"
                  (click)="viewActivity(row)"
                  >View activity</span
                >
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">
                No data matching for "{{ input.value }}"
              </td>
            </tr>
          </table>

          <mat-paginator
            pageSize="10"
            [pageSizeOptions]="[10, 25, 100]"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="isViewActivityTable">
      <div class="closeIcon" (click)="closePatientRecord()">X</div>
      <div class="row container-fluid appointment-cotainer">
        <div class="col-12" style="margin-top: 1vh">
          <div class="headContainer">
            <label
              style="
                width: 40%;
                float: left;
                font-size: 1.2em;
                padding: 30px 20px 17px 20px;
                color: white;
                font-weight: 500;
              "
              >Activity's</label
            >

            <div class="mat-elevation-z8">
              <table
                mat-table
                matSort
                class="userTable"
                [dataSource]="patientHistory"
              >
                <!-- Table Columns -->
                <ng-container matColumnDef="id" style="width: 100px">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    S.No
                  </th>
                  <td mat-cell *matCellDef="let i = index">
                    <span>
                      {{
                        this.paginator?.pageIndex == 0
                          ? i + 1
                          : 1 +
                            i +
                            this.paginator?.pageIndex * this.paginator?.pageSize
                      }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="date" style="width: 300px">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Date
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    class="patient-history-date"
                  >
                    <span>
                      {{ row.history_date | date : "yyyy-MM-dd" }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="operator">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 300px"
                  >
                    Operator
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    class="patient-history-operator"
                  >
                    <div class="patient-history-first-name">
                      {{ row.history_user.first_name }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 300px"
                  >
                    Description
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    class="patient-history-description"
                  >
                    <div class="patient-history-history_change_reason">
                      {{ row.history_change_reason || "-" }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="event_type" style="width: 100px">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Event Type
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    class="patient-history-event_type"
                  >
                    <span *ngIf="row.history_type === '+'">Create</span>
                    <span *ngIf="row.history_type === '~'">Update</span>
                    <span *ngIf="row.history_type === '-'">Delete</span>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="patientHistoryColumns"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: patientHistoryColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">No data</td>
                </tr>
              </table>

              <mat-paginator
                pageSize="10"
                [pageSizeOptions]="[10, 25, 100]"
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isPatientAppTable">
      <app-patient-appointment-list
        [MRNData]="selectedUser"
        [patientRec]="selectedData"
        (closeClick)="closeClicked($event)"
      ></app-patient-appointment-list>
    </div>
  </div>
</div>
