<!-- <div class="row container-fluid appointment-cotainer">
  <div class="col-12">
      <app-common-header></app-common-header>
  </div>
</div>
<div class="row container-fluid appointment-cotainer">
    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
    <app-left-panel></app-left-panel>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12">
         -->
<div>
  <button mat-raised-button color="primary" (click)="goBack()" style="margin-left:20px">Back</button>
  <!-- <div style="float:right;" (click)="goBack()"><mat-icon style="cursor:pointer;margin-right:20px;font-weight:bold;font-size:2em;color:red"><b>close</b></mat-icon></div> -->
  <div class="closeIcon" (click)="goBack()">X</div>
</div>         
<div class="row container-fluid appointment-cotainer">
<div class="col-12" style="margin-top:5vh">
    <!-- <label style="background: #ECECEC; width:40%;float:left;font-size:1.2em;padding:20px 20px 17px 20px;color:black;font-weight: 500;">User Details</label>
    <div style="background: #ECECEC; padding:10px;padding-bottom:11px;width:10%;float:left;">
      <button mat-raised-button color="primary" (click)="addUser()">Add</button></div>
    <div style="width:50%;float:right;background:#ECECEC;">
    <mat-form-field style="color:black;width:50%;float:right">
        <mat-label style="color:black;font-size:1em;font-weight: 500;">Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input >
    </mat-form-field>
    </div> -->
    <div class="headContainer">
      <label style="width:40%;float:left;font-size:1.2em;padding:30px 20px 17px 20px;color:black;font-weight: 500;">User Details ({{tenantId.name}})</label>
      <div style="padding:10px;padding-bottom:11px;width:10%;float:left;">
        <button mat-raised-button color="primary" (click)="addUser()">Add</button></div>
      <div style="width:50%;float:right">
      <mat-form-field style="margin-right:5%;width:40%;float:right">
          <input matInput (keyup)="applyFilter($event)"
          style="color:grey;border-radius:25px;background:white;padding-block:10px;padding-left:20px;" #input1>
          <mat-placeholder class="placeholder">Search</mat-placeholder>
      </mat-form-field>
      </div></div>
      
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort class="userTable">
          <!-- Table Columns -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Si.Num </th>
            <td mat-cell *matCellDef="let i = index">
            {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
            </td>
          </ng-container>
           <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> 
              <span>{{row.first_name}}</span> 
            </td>
            
          </ng-container>
          <ng-container matColumnDef="phone_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Numer </th>
            <td mat-cell *matCellDef="let row"> 
              <span>{{row.phone_number}}</span> 
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let row"> 
              <span>{{row.email}}</span>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="is_active">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
             <td mat-cell *matCellDef="let row"> 
              <span>{{row.is_active?"Active":"In-active"}}</span>
            </td>
          </ng-container>
    
          <ng-container matColumnDef="is_staff">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
            <td mat-cell *matCellDef="let row"> 
              <span>{{row.is_staff?"Yes":"No"}}</span>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
            <td mat-cell *matCellDef="let row">
                <span><mat-icon class="plus-icon" style="font-size:1.5em;cursor:pointer;color:green" (click)="editUser(row)">edit</mat-icon></span>
                <span *ngIf="row.is_active"><mat-icon (click)="deleteUser(row)" class="enableDel" title="Delete User">delete</mat-icon></span>
                <span *ngIf="!row.is_active"><mat-icon class="disableDel" title="User is In-active">delete</mat-icon></span>
            </td>
          </ng-container>
               
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No data matching</td>
          </tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>
    </div>
 <!--</div> -->