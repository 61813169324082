import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from 'src/app/service/appointment.service';
import {HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit {
  container:any;
  ac1="";
  ac2="";
  ac3="";
  ac4="";
  ac5="";
  ac6="";
  accessCode:any;
  percentDone: number;
  uploadSuccess: boolean;
  patientId:any;
  tenantId:any;
  appointmentId:any;
  accessCodeSection:boolean=false;
  questionnareSection:boolean=true;
  selectedSymptomArray:any;
  selectedSymptom:any="";
  selectedSymptomObject:any;
  qaArray:any=[];
  addedSymptom:any="";
  addSymptomFlag:boolean=false;
  spinner:boolean=false;
  selectedAppointment:any;
  questionnaireArray:any =[];

  constructor(private route: ActivatedRoute,public appService:AppointmentService,
    private snackBar: MatSnackBar, private http: HttpClient) { }

  ngOnInit(): void {
    this.appService.questionnaireDataFlag.subscribe(data =>this.selectedAppointment = data);
    // this.route.queryParams.subscribe(params => {
    //   this.patientId = params['id'];
    //   this.tenantId = params['tenant'];
    // });
    this.patientId = this.selectedAppointment.patient.id;
    this.tenantId = this.selectedAppointment.patient.tenant;
    this.continueProfile();
  }
  keytab(event){
    let element;
    console.log(event);
    if(event.key=="Backspace"){
      element= event.srcElement.previousSibling;
    } else {
      element= event.srcElement.nextElementSibling;
    }
     // get the sibling element

    if(element == null)  // check if its null
        return;
    else
        element.focus();   // focus if not null
}
submitCode(){
  if(this.ac1!="" && this.ac2!="" && this.ac3!="" && this.ac4!="" && this.ac5!="" ){
    this.accessCode = parseInt(this.ac1+this.ac2+this.ac3+this.ac4+this.ac5+this.ac6);
    let params={
      patient_id:this.patientId,
      otp:this.accessCode,
      tenant_id:parseInt(this.tenantId)
    }
    this.appService.accessCodeSubmit(JSON.stringify(params)).subscribe((response) => {
      if (response) {
        console.log(response)
        let data:any=response;
        // if(data.questionnaire_wizard.length>0){
        //   this.questionnareSection=true;
        //   this.accessCodeSection=false;
        //   this.appointmentId=data.appointment.id;
        // } else {
        //   this.snackBar.open("No Questionnaire Available", 'Close', {duration: 2000});
        // }
        this.questionnareSection=true;
          this.accessCodeSection=false;
          this.appointmentId=data.appointment.id;
      }
    }, (error) => {
      console.log(error);
      this.snackBar.open(error.error.error.message, 'Close', {duration: 2000});
    });
  } else {
    this.snackBar.open("Please Enter valid Access Code", 'Close', {duration: 2000});
  }
}
addSymptomClick(){
  this.addSymptomFlag=true;
}
closeQuestionnaire(){
  this.appService.questionnaireClick(true);
}
saveSymptom(){
  if(this.addedSymptom!==""){
    let complaintParams={
      "complaint_name": this.addedSymptom,
      "is_active": true
    }
    this.appService.saveSymptom(complaintParams).subscribe((response) => {
      if (response) {
        console.log(response)
        let data:any=response;
        this.snackBar.open("Symptom added successfully", 'Close', {duration: 2000});
        this.addSymptomFlag=false;
      }
    }, (error) => {
      console.log(error);
      this.snackBar.open(error, 'Close', {duration: 2000});
    });
  } else{
    this.snackBar.open("Symptom should not be empty", 'Close', {duration: 2000});
  }
  }

cancelAdd(){
  this.addSymptomFlag=false;
  this.addedSymptom="";
}
continueProfile(){
  this.questionnaireArray=[];
    this.appService.getSymptomList().subscribe((response) => {
      if (response) {
        console.log(response)
        let data:any=response;
        console.log(data);
        if(data.length>0){
          data.map((element)=>{
            this.questionnaireArray.push({
              symptom:element.complaint_name,
              complaintId:element.id,
              selected:false,
              questions:[]
            })
          })
        } else {
          this.snackBar.open("Error Occur while get symptom list, please try again from start", 'Close', {duration: 2000});
        }
      }
    }, (error) => {
      console.log(error);
      this.snackBar.open(error, 'Close', {duration: 2000});
    });
  }
  getJSONData(url){
    
  }
continueQuestinnaire(stepper){
  stepper.next();
}
prevQuestions(stepper){
  stepper.previous();
}
continueQuestions(stepper){
  this.qaArray=[];
  this.selectedSymptomArray.map((x:any)=>{
    this.qaArray.push({question:x.question,answer:x.control=='dropdown'?(x.answer+' '+x.period):x.control=='YesInput'?(x.answer +', '+x.yesInput):x.answer});
  })
  stepper.next();
}
skipQuestions(stepper){
  stepper.selectedIndex = 3;
  this.continueReview(stepper);
}
continueReview(stepper){ 
  let formData = new FormData();
  let questionnaire_data = this.qaArray.reduce((a, v) => ({ ...a, [v.question]: v.answer}), {})
  let submitData = {
    "appointment_id": this.selectedAppointment.id,
    "hpi": "string",
    "patient_id": this.patientId,
    "otp": this.accessCode,
    "tenant_id": this.tenantId,
    "summary": "string",
    "complaint_id": [
      this.selectedSymptomObject.complaintId
    ],
    "questionnaire_data": questionnaire_data,
    "demographic": "string",
    "medical_history": "string",
    "ros_data": "string",
    "is_new_patient": "string"
  }
console.log(submitData);
this.spinner=true;
this.appService.submitQuestionnaire(submitData).subscribe((response) => {
  if (response) {
    this.spinner=false;
    console.log(response)
    let data:any=response;
    stepper.next();
    //this.snackBar.open("No Questionnaire Available", 'Close', {duration: 2000});
  }
}, (error) => {
  console.log(error);
  this.spinner=false;
  this.snackBar.open(error, 'Close', {duration: 2000});
});
}
backToQuestion(stepper){
  stepper.previous();
}
symptomClick(qArray){
  this.questionnaireArray.map(x=>x.selected=false);
  this.appService.getDataFromURL('../../assets/json/complaint_'+qArray.complaintId+'.json').subscribe((response) => {
    if (response) {
      //console.log(response);
      let res:any = response;
      this.questionnaireArray.find(x=>x.complaintId==qArray.complaintId).selected=true;
      this.questionnaireArray.find(x=>x.complaintId==qArray.complaintId).questions=res;
      this.selectedSymptomArray=res;
      }
  }, (error) => {
    console.log(error);
  });
  this.selectedSymptom=qArray.symptom;
  this.selectedSymptomObject=this.questionnaireArray.find(x=>x.complaintId==qArray.complaintId);
}

upload(file: File){
  //pick from one of the 4 styles of file uploads below
  this.uploadAndProgressSingle(file);
}

uploadAndProgressSingle(file: File){ 
  var formData = new FormData();
  formData.append('medical_history_url',file[0],file[0].name);
  formData.append('appointment_id',this.selectedAppointment.id);
  formData.append('tenant',this.tenantId);
  this.appService.uploadMedicalFiles(formData).subscribe((response:any) => {
      this.appService.questionnaireClick(true);
       if (response.id) {
        this.snackBar.open("File uploaded successfully", 'Close', {duration: 2000});
        this.uploadSuccess = true;
      } else {
        this.snackBar.open("File upload failed", 'Close', {duration: 2000});
      }
  });
}
}
