import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit , OnDestroy{
  public isAdmin:boolean;
  public isNotes:boolean;
  public isPatient:boolean;
  public isquestionnaire:boolean;
  public isReport:boolean;
  public isDashboard:boolean;
  public isDoctorMgmt:boolean;
  public isRefund:boolean;
  selectedReport: string = 'Patient Appointment';
  private unsubscriber: Subject<void> = new Subject<void>();
  
  constructor(private loginServive:LoginService,private appService:AppointmentService) { }

  ngOnInit(): void {
    //console.log(this.loginServive.userDetails);
    this.isAdmin=this.loginServive.userDetails.is_staff;
    this.appService.notesListFlag.subscribe(data => this.isNotes = !data);
    this.appService.patientListFlag.subscribe(data => this.isPatient = !data);
    this.appService.questionnaireFlag.subscribe(data => this.isquestionnaire = !data);
    this.appService.reportFlag.subscribe(data => {
      this.isReport = !data.flag;
      this.selectedReport=data.selectedreport;
    });
    this.appService.dashboardFlag.subscribe(data => {this.isDashboard = !data});
    this.appService.doctorListFlag.subscribe(data => this.isDoctorMgmt = !data);
    this.appService.refundFlag.subscribe(data => this.isRefund = !data);
    history.pushState(null, '');
  
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
       
      });
  }


  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
