<!-- <div class="row container-fluid appointment-cotainer">
    <div class="col-12">
        <app-common-header></app-common-header>
    </div>
</div> -->

<div class="row container-fluid appointment-cotainer">
    <div class="col-12">
        <div class="closeIcon" (click)="closeQuestionnaire()">x</div>
        <!-- <div *ngIf="accessCodeSection" class="Header row">
        <div class="col-5">
            <div class="stethescope-logo">
                <img src="../../../assets/images/stethoscope.svg" alt="Medosys.ai" width="350" height="250">
            </div>
        </div>
        <div class="col-7 ">
            <div style="font-size: 25px;position: relative;top: 5vh"><b>Enter your access code</b></div>
            <div class="content">Please enter the access code which has been<br> send to your registered mail id and mobile numer<br> at the time of appointment creation.If you don't have <br>an access code please ask the front desk to generate one for you.</div>
            <div  style="margin-top: 2vw;">
                <div class="accessCode" id="accessCode">
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac1"/>
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac2"/>
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac3"/>
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac4"/>
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac5"/>
                    <input type="text" (keyup)="keytab($event)" maxlength="1" [(ngModel)]="ac6"/>
                </div>
            </div>
            <mat-divider></mat-divider>
            <button  (click)="submitCode()" mat-stroked-button style="width: 120px;margin-left: 5vw;background: linear-gradient(#0984A8, #1FAE79);color: white;font-size: 18px;margin-top: 3vw;">Start</button>  
            
        </div>
        </div> -->
        <div id="questSec" class="quesHeader">
                <!-- <div class="closeIcon">x</div> -->
                <mat-horizontal-stepper #stepper style="margin-top:10vh;padding-inline: 50px;">
                    <!-- <mat-step label="Profile" state="phone">
                        <div class="content">
                            <div style="width:50vh;height:200px;border-radius:50%;margin-left:20vw"> <div style="display:inline-block"><img src="../../../assets/images/avator.JPG" alt="userName"></div>
                                <div class="cameraIcon"><mat-icon>photo_camera</mat-icon></div></div>
                            <div class="h1-label">Add a profile photo</div>
                            <div class="finalText">Your Profile photo helps doctors and medical staff <br> identity who your photo is used for identification <br> purposes only</div> 
                            <div style="margin-top:2vh;text-align: center;"><button mat-stroked-button style="background: linear-gradient(#0984A8, #1FAE79);font-size: 1.1em;color: white;margin-top: 10px;" class="btn ml-4" (click)="continueProfile(stepper)">Continue</button></div>
                        </div>
                    </mat-step> -->
                    <mat-step label="Questionnaire" state="chat">
                        <div class="content" style="position: relative;line-height: 2.0;margin-left: 3vw;">
                            <div style="color:black;font-size: 1.5em; font-weight: 500;">What brings you in today?</div>
                            <div style="color:darkgray;font-size: 1.1em">Select one that may apply</div>
                            <div style="margin-top: 20px;margin-bottom: 15px;">
                                <div style="display:inline-block" *ngFor="let qArray of questionnaireArray">
                                    <button [ngClass]="qArray.selected?'btn-selected':'btn'" (click)="symptomClick(qArray)">{{qArray.symptom}}</button>
                                </div>
                            </div>
                            <!-- <div class="row">
                            <div class="col-4">
                            <div style="color:darkgray;margin-top: 20px;font-size: 1.1em">Don't see your listed?</div>
                            <button (click)="addSymptomClick()" mat-stroked-button style="background:linear-gradient(#0984A8, #1FAE79);font-size: 1.1em;color: white;margin-top: 10px;">Add
                                Symptoms</button>
                            </div>
                            <div class="col-8">
                                <div *ngIf="addSymptomFlag">
                                <div style="display:inline-block;padding-block:30px">
                                <mat-form-field style="width:90%" appearance="fill">
                                    <mat-label>Add Symptom here</mat-label>
                                    <input matInput placeholder="Ex. Headache" [(ngModel)]="addedSymptom">
                                  </mat-form-field>
                                </div>
                                  <div style="display:inline-block">
                                    <span><button mat-stroked-button (click)="saveSymptom()">Save</button></span>
                                    <span style="padding-left:10px"><button mat-stroked-button (click)="cancelAdd()">Cancel</button></span>
                                </div>
                            </div>
                            </div> -->
                            <button *ngIf="selectedSymptom!==''" mat-stroked-button style="margin-top:0px;margin-left:30%" class="submit mt-5" (click)="continueQuestinnaire(stepper)">Next</button> 
                            <button *ngIf="selectedSymptom==''" mat-stroked-button style="margin-top:0px;margin-left:30%;opacity:0.3;cursor:not-allowed;" title="Please select any symptoms" class="submit mt-5" (click)="continueQuestinnaire(stepper)">Next</button> 
                        
                    </div>
                    </mat-step>
                    <mat-step label="Questions" state="phone">
                        <div class="content" style="position: relative;line-height: 1.0;margin-left: 3vw;margin-top:-30px">
                            <div style="line-height: 2.0;color:grey;font-size: 1.5em; font-weight: 500;margin-left: 2vw;">{{selectedSymptom}}</div>
                            <ol><li *ngFor="let qList of selectedSymptomArray" style="padding-top:20px">
                            <div style="color:black;font-size: 1.3em; font-weight: 500;">
                                {{qList.question}}
                            </div>
                            <!-- <div style="color:darkgray;font-size: 1em;padding-top:5px">{{qList.like}}</div> -->
                            <div class="answerBlock" *ngIf="qList.control=='radio'">
                                <mat-radio-group class="example-radio-group" [(ngModel)]="qList.answer">
                                    <mat-radio-button class="example-radio-button" value="Yes">Yes</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" value="No" style="margin-left:20px">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="answerBlock" *ngIf="qList.control=='dropdown'">
                                <div class="time-picker-component">
                                    <mat-form-field class="example-half-width" appearance="fill">
                                        <input matInput [placeholder]="qList.like" [value]="qList.answer" [(ngModel)]="qList.answer">
                                    </mat-form-field>
                                    <mat-form-field class="example-half-width">
                                        <mat-select  appearance="fill" [(ngModel)]="qList.period">
                                          <mat-option value="Days">Days</mat-option>
                                          <mat-option value="Weeks">Weeks</mat-option>
                                          <mat-option value="Months">Months</mat-option>
                                     </mat-select>
                                   </mat-form-field>
                                </div>
                            </div>
                            <div class="answerBlock" *ngIf="qList.control=='YesInput'">
                            <mat-radio-group class="example-radio-group" [(ngModel)]="qList.answer">
                            <mat-radio-button class="example-radio-button" value="Yes">Yes</mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="No" style="margin-left:20px">No</mat-radio-button>
                            </mat-radio-group>
                                <mat-form-field *ngIf="qList.answer=='Yes'" class="example-full-width" appearance="fill" style="margin-left:20px">
                                    <input matInput [placeholder]="qList.like" [value]="qList.yesInput" [(ngModel)]="qList.yesInput">
                                  </mat-form-field>
                            </div>
                            <div class="answerBlock" *ngIf="qList.control=='input'">
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <input matInput [placeholder]="qList.like" [value]="qList.answer" [(ngModel)]="qList.answer">
                                  </mat-form-field>
                            </div>
                        </li></ol>
                        <table><tr>
                            <td><button mat-stroked-button class="submit" (click)="prevQuestions(stepper)">Back</button></td>
                            <td><button mat-stroked-button class="submit" (click)="continueQuestions(stepper)">Next</button></td>
                            <td><button mat-stroked-button class="submit" (click)="skipQuestions(stepper)">Skip</button></td>
                        </tr></table>
                        </div>
                    </mat-step>
                    <mat-step label="Review" state="phone">
                        <div *ngIf="!spinner" class="content" style="position: relative;line-height: 1.0;margin-left: 3vw;margin-top:-30px">
                            <div style="line-height: 2.0;color:grey;font-size: 1.5em; font-weight: 500;margin-left: 2vw;">{{selectedSymptom}}</div>
                            <ol><li *ngFor="let qList of qaArray" style="padding-top:20px">
                            <div style="color:black;font-size: 1.3em; font-weight: 500;">
                                {{qList.question}}
                            </div>
                            <div style="color:darkgray;font-size: 1.2em;padding-top:15px">{{qList.answer}}</div>
                        </li></ol>
                        
                        <div class="row">
                            <div class="col-2 col-offset-2">
                                <button mat-stroked-button class="submit" (click)="backToQuestion(stepper)">Start Over</button>
                            </div>
                            <div class="col-2">
                                <button mat-stroked-button class="submit" (click)="continueReview(stepper)">Submit</button>
                            </div>
                        </div>  
                    </div>
                    <div class="dark-overlay" *ngIf="spinner">
                        <mat-spinner diameter="80" style="margin-top:25%;margin-left:50%"></mat-spinner>
                      </div>
                    </mat-step>
                    <mat-step label="Confirmation" state="chat">
                        <div class="all-done">
                        <div class="label1">All Done</div>
                        <div class="label2">Please bring this tablet back to the front desk</div>
                        <div  id="history">
                            <label class="label2" style="color:orangered">You can upload any documents/files related to this symptom for doctor reference</label>
                            <div class="upload">
                            <form id="upload_form" enctype="multipart/form-data" method="post">
                              <input type="file" name="file1" id="file1" (change)="upload($event.target.files)">
                            </form>
                            <br />
                        
                            <ng-container *ngIf="uploadSuccess">
                              Upload Successful
                            </ng-container> 
                            </div>
                        </div>
                        <div class="doneButton">
                        <button mat-stroked-button class="submit" (click)="closeQuestionnaire()">Done</button>
                    </div>
                </div>
                    </mat-step>
                    <!-- Icon overrides. -->
        <ng-template matStepperIcon="phone">
            <mat-icon>perm_identity</mat-icon>
          </ng-template>
          <ng-template matStepperIcon="chat">
            <mat-icon>event_note</mat-icon>
          </ng-template>
                </mat-horizontal-stepper>
        </div>
    </div>
</div>


