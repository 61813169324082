import { Component, ViewChild } from '@angular/core';
import { DyteMeeting } from '@dytesdk/angular-ui-kit';
import DyteClient from '@dytesdk/web-core';
import { AppointmentService } from './service/appointment.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'MyProject';
  spinner: boolean = false;
  constructor(public appService: AppointmentService) {}
  ngOnInit(): void {
    this.appService.loaderFlag.subscribe((message) => (this.spinner = message));
  }
}
