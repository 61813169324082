import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  public header: any;
  public authToken: any;
  private messageSource = new BehaviorSubject<boolean>(true);
  currentMessage = this.messageSource.asObservable();

  private tenantPass = new BehaviorSubject<any>(1);
  currentTenant = this.tenantPass.asObservable();

  private docList = new BehaviorSubject<any>([]);
  currentDoctorList = this.docList.asObservable();

  private notesList = new BehaviorSubject<boolean>(true);
  notesListFlag = this.notesList.asObservable();

  private patientList = new BehaviorSubject<boolean>(true);
  patientListFlag = this.patientList.asObservable();

  private doctorList = new BehaviorSubject<boolean>(true);
  doctorListFlag = this.doctorList.asObservable();

  private popup = new BehaviorSubject<boolean>(false);
  popupFlag = this.popup.asObservable();

  private patientData = new BehaviorSubject<any>({
    flag: false,
    userData: null,
  });
  patientFlag = this.patientData.asObservable();

  private questionnaire = new BehaviorSubject<boolean>(true);
  questionnaireFlag = this.questionnaire.asObservable();

  private report = new BehaviorSubject<any>({
    flag: true,
    selectedReport: 'Patient Appointment',
  });
  reportFlag = this.report.asObservable();

  private refund = new BehaviorSubject<boolean>(true);
  refundFlag = this.refund.asObservable();

  private dashboard = new BehaviorSubject<boolean>(true);
  dashboardFlag = this.dashboard.asObservable();

  private loader = new BehaviorSubject<any>(0);
  loaderFlag = this.loader.asObservable();

  private questionnaireData = new BehaviorSubject<any>([]);
  questionnaireDataFlag = this.questionnaireData.asObservable();

  private videoData = new BehaviorSubject<any>('default data');
  video = this.videoData.asObservable();

  private selectedAppmt = new BehaviorSubject<any>('default data');
  private notesFlag = new BehaviorSubject<any>(true);

  currentAppmt = this.selectedAppmt.asObservable();
  currentNotesFlag = this.notesFlag.asObservable();

  private appLength = new BehaviorSubject<any>(0);
  appointmentLength = this.appLength.asObservable();

  constructor(public http: HttpClient) {
    let LSData = JSON.parse(localStorage.getItem('userDetails'));
    this.authToken = LSData?.authToken;
    this.header = new HttpHeaders({
      Authorization: 'Bearer ' + this.authToken,
      accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }
  getHeader() {
    let LSData = JSON.parse(localStorage.getItem('userDetails'));
    this.authToken = LSData?.authToken;

    this.header = new HttpHeaders({
      Authorization: 'Bearer ' + this.authToken,
      accept: 'application/json',
      'Content-Type': 'application/json',
    });
    this.header.append('Access-Control-Allow-Headers', '*');
    // this.header.append('Access-Control-Allow-Methods', 'GET');
    this.header.append('Access-Control-Allow-Origin', '*');
  }
  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }
  changeTenant(Id: any) {
    this.tenantPass.next(Id);
  }
  getDoctorList(message: any) {
    this.docList.next(message);
  }
  notesClick(message: boolean) {
    this.notesList.next(message);
  }
  patientClick(message: boolean) {
    this.patientList.next(message);
  }
  doctorClick(message: boolean) {
    this.doctorList.next(message);
  }
  popupClick(message: boolean) {
    this.popup.next(message);
  }
  patientDetailsClick(message: any) {
    this.patientData.next(message);
  }
  questionnaireClick(message: boolean) {
    this.questionnaire.next(message);
  }
  reportClick(message: any) {
    this.report.next(message);
  }
  refundClick(message: boolean) {
    this.refund.next(message);
  }
  dashboardClick(message: boolean) {
    this.dashboard.next(message);
  }

  questionnaireSelect(selected: any) {
    this.questionnaireData.next(selected);
  }
  changeAppmt(data: any) {
    this.selectedAppmt.next(data);
  }
  videoCall(data: any) {
    this.videoData.next(data);
  }
  changeAppCount(data: any) {
    this.appLength.next(data);
  }
  doneNotes(flag: any) {
    this.notesFlag.next(flag);
  }
  loaderEnable(message: any) {
    this.loader.next(message);
  }
  public logout() {
    this.getHeader();
    //let header=new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json'})
    const apiURL = environment.baseUrl + 'api/logout';
    return this.http.post(apiURL, '', { headers: this.header });
  }
  public searchMRN(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patients/?' + params;
    return this.http.get(apiURL, { headers: this.header });
  }
  public createMRN(params: any) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  public editMRN(params: any, id) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/' + id;
    return this.http.patch(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  public getPatients() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public createTime(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/appointment_timing/?tenant=' +
      id;
    return this.http.get(apiURL, { headers: this.header });
  }
  public getProviders() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_provider/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public filter_Providers(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/filter_provider_by_datetime/?date_time=' +
      params;
    //const opts = { params: new HttpParams(params),headers : this.header };
    return this.http.get(apiURL, { headers: this.header });
  }
  public getProviderbookedTime(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/get_provider_booked_times/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public createAppointment(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patient_appointment/';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  public editAppointment(params: any, id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patient_appointment/' + id;
    return this.http.patch(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  // getAppointmentList(params:any){
  //   this.getHeader();
  //   let apiURL = environment.baseUrl + 'api/v1/appointments/patient_appointment/?appointment_date='+params.appointment_start_date
  //   return this.http.get(apiURL,{headers : this.header});
  // }
  getAppointmentList(params: any) {
    this.getHeader();
    let newParams = new HttpParams();
    for (const property in params) {
      newParams = newParams.set(`${property}`, `${params[property]}`);
      console.log(newParams);
    }
    //const apiURL = environment.baseUrl + 'api/v1/appointments/patient_appointment/?appointment_start_date='+params.appointment_start_date+'&appointment_end_date='+params.appointment_end_date
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patient_appointment/';
    return this.http.get(apiURL, { params: newParams, headers: this.header });
    //return this.http.get(apiURL,{headers : this.header});
  }
  getPaymentList(params: any) {
    console.log(params);
    this.getHeader();
    let newParams = new HttpParams();
    console.log(newParams);
    for (const property in params) {
      newParams = newParams.set(`${property}`, `${params[property]}`);
    }
    //const apiURL = environment.baseUrl + 'api/v1/appointments/patient_appointment/?appointment_start_date='+params.appointment_start_date+'&appointment_end_date='+params.appointment_end_date
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/payment_dashboard_data/';
    return this.http.get(apiURL, { params: newParams, headers: this.header });
    //return this.http.get(apiURL,{headers : this.header});
  }
  getVideoAuth(params: any) {
    this.getHeader();
    let newParams = new HttpParams();
    for (const property in params) {
      newParams = newParams.set(`${property}`, `${params[property]}`);
    }
    const apiURL =
      environment.baseUrl + 'api/v1/medosys_engine/start_video_call/';
    return this.http.get(apiURL, { params: newParams, headers: this.header });
  }
  getTranscriptAuth(params: any) {
    const apiURL = 'https://api.symbl.ai/oauth2/token:generate';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    });
  }
  stopRecording(params) {
    this.getHeader();
    let newParams = new HttpParams();
    for (const property in params) {
      newParams = newParams.set(`${property}`, `${params[property]}`);
    }
    const apiURL =
      environment.baseUrl + 'api/v1/medosys_engine/stop_recording_from_web/';
    return this.http.get(apiURL, { params: newParams, headers: this.header });
  }
  updatePatient(params: any, id) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/' + id;
    // return this.http.patch(apiURL,params,{headers : this.header});
    return this.http.patch(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  getAppointment(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patient_appointment/' + params;
    // let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    return this.http.get(apiURL, { headers: this.header });
    // return this.http.get(apiURL,{headers : header});
  }
  public saveRecord(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/medosys_engine/note_transcribe_audio_file_and_medical_data/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getMedicalInfo(params: any) {
    const apiURL =
      environment.baseUrl + 'api/v1/medosys_engine/get_medical_information/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getTranscrptedData(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/medosys_engine/get_background_status/?task_id=' +
      params;
    return this.http.get(apiURL, { headers: this.header });
  }
  public getTranscrptedDataURL(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/medosys_engine/doctor_patient_conversation/?appointment_id=' +
      params;
    return this.http.get(apiURL, { headers: this.header });
  }
  public getHistoryFile(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/questionnaire/medical_history/?appointment_id=' +
      params;
    return this.http.get(apiURL, { headers: this.header });
  }
  public getDataFromURL(params: any) {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', '*');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    const apiURL = params;
    return this.http.get(apiURL, { headers: headers });
  }
  public accessCodeSubmit(params: any) {
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/verify_access_code';
    let header = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return this.http.post(apiURL, params, { headers: header });
  }
  public submitQuestionnaire(params: any) {
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/questionnaire_submit';
    let header = new HttpHeaders({
      Authorization: 'Bearer ' + this.authToken,
      accept: 'application/json',
      'Content-Type': 'application/json',
    });
    //let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'multipart/form-data'})
    //return this.http.post(apiURL,params,{headers : new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/x-www-form-urlencoded'})});
    return this.http.post(apiURL, params, { headers: header });
  }
  public saveSymptom(params: any) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/complaint';
    //let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    return this.http.post(apiURL, params, { headers: this.header });
  }
  // public savePrescription(params: FormData) {
  //   this.getHeader();
  //   // Set x-nullable header to true
  //  // const headers = new HttpHeaders().set('x-nullable', 'true');
  //   const apiURL = environment.baseUrl + 'api/v1/questionnaire/prescription/';
  //   return this.http.post(apiURL, params, {
  //     headers: new HttpHeaders({
  //       Authorization: 'Bearer ' + this.authToken,
  //       accept: 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'x-nullable': 'true',
  //     }),
  //   });
  // }
  public savePrescriptionComplete(params: any) {
    console.log(params);
    let header = new HttpHeaders({
      Authorization: 'Bearer ' + this.authToken,
      accept: 'application/json',
    });
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/prescription/';
    // const apiURL = environment.baseUrl + 'api/v1/user/add_multitenant_users/';
    return this.http.post(apiURL, params, { headers: header });
  }
  public savePrescription(params: any) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/prescription/';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  public saveMedicalCert(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/medical_certificate/';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authToken,
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  public saveMedicalCertComplete(params: any) {
    let header = new HttpHeaders({ Authorization: 'Bearer ' + this.authToken });
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/medical_certificate/';
    return this.http.post(apiURL, params, { headers: header });
  }
  public getPrescription() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/prescription/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public getMedicalCert() {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/medical_certificate/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public getRoles() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/roles/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public getSpecialities() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/specialities/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public getTimeZone() {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/get_all_timezones/';
    return this.http.get(apiURL, { headers: this.header });
  }
  public changePwd(paramBody) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/change_password';
    return this.http.patch(apiURL, paramBody, { headers: this.header });
  }
  public getSymptomList() {
    this.getHeader();
    //let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    const apiURL = environment.baseUrl + 'api/v1/questionnaire/complaint';
    return this.http.get(apiURL, { headers: this.header });
  }
  public uploadMedicalFiles(params: any) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/questionnaire/medical_history/';
    return this.http.post(apiURL, params, {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + this.authToken }),
    });
  }
  public getROSdata(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/questionnaire/review_Of_system/?appointment_id=' +
      id;
    return this.http.get(apiURL, { headers: this.header });
  }
  public getAssessmentData(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/assessment/?appointment_id=' +
      id;
    return this.http.get(apiURL, { headers: this.header });
  }
  public patchAssessmentData(params, id) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/assessment/' + id;
    return this.http.patch(apiURL, params, { headers: this.header });
  }
  public manualAddAssessmentData(params) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/assessment';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public savePayment(params) {
    this.getHeader();
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/process_payment_for_web/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getPayment(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/appointment_payment_details/' +
      id;
    return this.http.get(apiURL, { headers: this.header });
  }
  public updateMCDPData(params, id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/medosys_engine/doctor_patient_conversation/' +
      id +
      '/';
    return this.http.patch(apiURL, params, { headers: this.header });
  }
  public getAppointmentStatus(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/appointment_stats';
    // const apiURL = 'https://mte.medosysapi.com/appointment_stats';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getPieChartStatus(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/appointment_status';
    // const apiURL = 'https://mte.medosysapi.com/appointment_status';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getScheduledAppointment(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/scheduled_appointment_gender_comparison';
    // const apiURL =
    //   'https://mte.medosysapi.com/scheduled_appointment_gender_comparison';
    return this.http.post(apiURL, params, { headers: this.header });
  }

  public topCauses(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL = environment.baseUrl + 'api/v1/appointments/top_causes';
    // const apiURL = 'https://mte.medosysapi.com/top_causes';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public patientAgeCategory(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/patient_age_category';
    // const apiURL = 'https://mte.medosysapi.com/patient_age_category';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public paymentStatus(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL = environment.baseUrl + 'api/v1/appointments/payment_stats';
    // const apiURL = 'https://mte.medosysapi.com/payment_stats';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public revenueStatus(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL = environment.baseUrl + 'api/v1/appointments/revenue_stats';
    // const apiURL = 'https://mte.medosysapi.com/revenue_stats';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getDaywiseAppointment(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/day_wise_appointent_count';
    // const apiURL = 'https://mte.medosysapi.com/day_wise_appointent_count';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public generateTimeslot(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'v1/appointments/web_appointment_timeslot_configurations/';
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_timeslot_configurations/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getTimeslot(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_timeslot_configurations/?tenant='+ id;
      
    return this.http.get(apiURL, { headers: this.header });
  }
  public updateTimeslot(params, id) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'v1/appointments/web_appointment_timeslot_configurations/';
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_timeslot_configurations/' +
      id +
      '/';
    return this.http.patch(apiURL, params, { headers: this.header });
  }
  public gaugeChart(params): Observable<any> {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    const apiURL =
      environment.baseUrl + 'api/v1/appointments/appointment_stats_gauge';
    // const apiURL =
    //   'https://admin.medosysapi.com/api/v1/appointments/appointment_stats_gauge';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public saveDoctordetail(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_details/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public updateDoctordetail(params, id) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL =
      environment.baseUrl + 'api/v1/user/doctor_details/' + id + '/';
    return this.http.patch(apiURL, params, { headers: this.header });
  }
  public getDoctordetailById(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl + 'api/v1/user/doctor_details/?user_id=' + id;
    return this.http.get(apiURL, { headers: this.header });
    // console.log(data)
  }
  public getDoctordetail() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_details/';
    return this.http.get(apiURL, { headers: this.header });
    // console.log(data)
  }
  public saveHoliday(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_slot_holidays/';
    return this.http.post(apiURL, params, { headers: this.header });
  }
  public getHolidays(id) {
    this.getHeader();
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_slot_holidays/?tenant='+ id;
    return this.http.get(apiURL, { headers: this.header });
    // console.log(data)
  }
  public saveUserMRN(params) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL = environment.baseUrl + 'api/v1/user/web_patient_signup';
    return this.http.post(apiURL, params, { headers: this.header });
  }

  public updateHoliday(params, id) {
    this.getHeader();
    console.log(this.header);
    console.log(params);
    // const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_payment_details/';
    const apiURL =
      environment.baseUrl +
      'api/v1/appointments/web_appointment_slot_holidays/' +
      id +
      '/';
    return this.http.patch(apiURL, params, { headers: this.header });
  }
}
