import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/service/appointment.service';
import { AppointmentPaymentComponent } from 'src/app/shared_components/appointment-payment/appointment-payment.component';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { AppointmentDetailComponent } from '../appointment-detail/appointment-detail.component'

@Component({

  selector: 'app-appointment-landing',
  templateUrl: './appointment-landing.component.html',
  styleUrls: ['./appointment-landing.component.css']
})
export class AppointmentLandingComponent implements OnInit {
  public appointmentData:any=[];
  public appData:any=[];
  public textDate:string="";
  public pickedDate:string="";
  public pickedDateMM:string="";
  public todayString:string="";
  public day:string="";
  public month:string="";
  public dayNum:number;
  public year:number;
  public selectedDoctorList=[]
  public weekArray:any=[];
  public seenAppmnt:any=[];
  public upcAppmnt:any=[];
  public missedAppmt:any=[];
  public checkedInAppmnt: any = [];
  public completedAppmnt: any = [];
  public totalAppmnt: any = [];
  public totalUnpaidAppointments: any = [];
  public selectedAppointment:any;
  public selectedQuestionnaire:any;
  public monthArray=[ "Jan", "Feb", "Mar", "Apr", "May", "June",
                      "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
  public dayArray=["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  public loggedinUser=JSON.parse(localStorage.getItem('userDetails')).userAccess.id
  public access=JSON.parse(localStorage.getItem('userDetails')).access;
  fromDate:any;
  toDate:any;
  fDate:any;
  tDate:any;
  initFromDate:any;
  initToDate:any;
  today:Date=new Date();
  currentTime=this.today.getHours()+'.'+this.today.getMinutes();
  isSort:boolean=false;
  @Output() editApmt = new EventEmitter<any>();

  constructor(public appService:AppointmentService,private snackBar: MatSnackBar,
              private router: Router,private dialog: MatDialog) {}

  ngOnInit(): void {
    var d = new Date();
    this.day=this.dayArray[d.getDay()];
    this.dayNum=d.getDate();
    this.year = d.getFullYear();
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    let prevMonth =(d.getMonth() + 1);
    let prevDay = d.getDate()-1;
    let prevYear = d.getFullYear();
    this.toDate=prevMonth+"-"+new Date().getDate()+"-"+prevYear;
    this.initToDate=prevMonth+"-"+prevDay+"-"+prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev =(dPrev.getMonth() + 1);
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    this.fromDate=monthPrev+"-"+dayPrev+"-"+yearPrev;
    this.initFromDate=monthPrev+"-"+dayPrev+"-"+yearPrev;
    this.pickedDateMM=month+"-"+day+"-"+year;
    this.pickedDate=year+"-"+month+"-"+day;
    this.todayString=this.pickedDate;
    this.textDate=(this.monthArray[month-1]+" "+day+" "+year);
    this.month=this.monthArray[month-1];
   
    this.getThisWeek();
    this.loadAppointmentList();
    this.loadPreiviousAppointmentList();
    this.appService.currentDoctorList.subscribe(data => {
      this.selectedDoctorList = data;
      this.loadAppointmentList();
    });
  }
  viewAppointment(app){
    if(app.appointment_status=="SE"||app.appointment_status=="CI"){
      this.router.navigate(['/notes'], { queryParams: { id: app.id } });
    }
  }
  getThisWeek() {
    this.weekArray=[];
    var today = new Date();
    const temp = {
      d: today.getDate(),
      m: today.getMonth(),
      y: today.getFullYear(),
    }
    const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate()
  
    Array.from({length: 7}, _ => {
      if (temp.d > numDaysInMonth){
        temp.m +=1;
        temp.d = 1;
        // not needed, Date(2020, 12, 1) == Date(2021, 0, 1)
        /*if (temp.m >= 12){
          temp.m = 0
          temp.y +=1
        }*/
      }      
  
      const newDate = new Date(temp.y, temp.m, temp.d++); //.toUTCString()
  
      this.weekArray.push({
        day: newDate.toLocaleDateString('en-US', {weekday: 'short'}),
        num: newDate.getDate(),
        date: newDate.getMonth()+1 +"/"+newDate.getDate() + "/" + newDate.getFullYear(),
        selected: false,
      });
    });
    this.weekArray[0].selected=true;
  }
  dayChange(day){
    this.weekArray.map(x=>x.selected=false);
    this.weekArray.find(x=>x.num==day.num).selected=true;
    this.dateChange(day.date);
  }
  // compareTime(x){
  //   return (parseFloat(x.split(':')[0]+'.'+x.split(':')[1]) < parseFloat(new Date().getHours()+'.'+new Date().getMinutes()))
  // }

  loadAppointmentList(){
    this.seenAppmnt=[];
    this.upcAppmnt=[];
    this.missedAppmt=[];
    this.checkedInAppmnt = [];
    this.totalAppmnt = [];
    this.totalUnpaidAppointments = [];
    let appParams={
        appointment_date:this.pickedDateMM
      }
    this.appService.getAppointmentList(appParams).subscribe((response) => {
      if (response) {
        let appData:any=response;
        if(appData.length>0){
          this.appointmentData=appData;
          this.appointmentData.map(x=>{
            // console.log(new Date(x.appointment_date)>new Date);
            x.selected=false;
            x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[2]);
            // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
            // if(x.appointment_status=='UP'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
            // }
            // if(x.appointment_status=='CI'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
            // }
          })
          this.seenAppmnt = [];
          this.upcAppmnt = [];
          this.missedAppmt = [];
          this.checkedInAppmnt = [];
          this.totalAppmnt = [];
          this.totalUnpaidAppointments = [];

          if (this.selectedDoctorList.length) {
            this.selectedDoctorList?.forEach(doc => {
              this.appointmentData?.forEach(app => {
                if (app?.doctor_id == doc?.id && app.appointment_status == 'SE') {
                  this.seenAppmnt.push(app)
                }
                if (app?.doctor_id == doc?.id && app.appointment_status == 'UP') {
                  this.upcAppmnt.push(app)
                }
                if (app?.doctor_id == doc?.id && app.appointment_status == 'MI') {
                  this.missedAppmt.push(app)
                }
                if (app?.doctor_id == doc?.id && app.appointment_status == 'CI') {
                  this.checkedInAppmnt.push(app)
                }
                if (app?.doctor_id == doc?.id && app.appointment_status !== 'CA') {
                  this.totalAppmnt.push(app)
                }
                if (app?.doctor_id == doc?.id && app.payment_status == null) {
                  this.totalUnpaidAppointments.push(app)
                }
              })
            })
          } else {
            this.appointmentData = this.appointmentData.length > 0 ? this.appointmentData: []
            this.appointmentData = this.appointmentData?.filter(app => app.doctor_id === this.loggedinUser)
            this.seenAppmnt = this.appointmentData.filter(x => x.appointment_status == 'SE');
            this.upcAppmnt = this.appointmentData.filter(x => x.appointment_status == 'UP');
            this.missedAppmt = this.appointmentData.filter(x => x.appointment_status == 'MI');
            this.checkedInAppmnt = this.appointmentData.filter(x => x.appointment_status == 'CI');
            this.totalAppmnt = this.appointmentData.filter(x => x.appointment_status !== 'CA');
            this.totalUnpaidAppointments = this.appointmentData.filter(x => x.payment_status === null);
            this.appointmentData = this.totalAppmnt;
          }

        
          if(this.selectedDoctorList.length>0){
            let doctorsAppmnt=[];
            this.selectedDoctorList.map(x=>{
              let innerAppmt=[];
              innerAppmt=this.appointmentData.filter(item=>item.doctor_id == x.id);
              if(innerAppmt.length>0){
                innerAppmt.map(x=>{
                  doctorsAppmnt.push(x)
                });
              }
            })
            this.appointmentData=doctorsAppmnt;
          }
          else {
            this.appointmentData = [...this.checkedInAppmnt, ...this.upcAppmnt]
          }
        } else {
          this.appointmentData = [];
          
        }
      }
    }, (error) => {
      console.log(error)
        this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }

  loadPreiviousAppointmentList(){
    let appParams={
      appointment_start_date:this.fromDate,
      appointment_end_date:this.toDate
    }
    this.fDate=new Date(this.fromDate);
    this.tDate=new Date(this.toDate);
    this.appService.getAppointmentList(appParams).subscribe((response: any) => {
      if (response) {
        let appData: any = response
        if(appData.length>0){
          appData=appData.sort((a,b) => new Date(b.appointment_date).getTime() - new Date(a.appointment_date).getTime());
          // appData.sort((a:any, b:any) => new Date(b.appointment_date) - new Date(a.appointment_date));
          this.appData = appData.filter(app => {
            return app.appointment_status === "MI" || app.appointment_status === "SE"
          });
          this.appData.map(x=>{
            x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[2]);
            // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
            // if(x.appointment_status=='UP'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
            // }
            // if(x.appointment_status=='CI'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
            // }
          })
          this.appService.changeAppCount(this.appointmentData.length)
          // this.appointmentData.map(x=>{
          //   x.selected=false;
          //   x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[0]);
          // })
          // this.completedData=this.appointmentData.filter(x=>(x.appointment_status== "SE"));
          // this.upcomingData=this.appointmentData.filter(x=>x.appointment_status== "UP");
          // this.checkedinData=this.appointmentData.filter(x=>x.appointment_status== "CI");
          //this.appointmentData[0].selected=true;
          //this.selectedAppointment=this.appointmentData.find(x=>x.selected);
          // console.log(this.completedData);
          // console.log(this.upcomingData);
          // console.log(this.checkedinData);
        } else {
          this.appData=[];
        }
      }
    }, (error) => {
      console.log(error)
        //this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }
  dateRangeChange(dateRangeStart: HTMLInputElement,  dateRangeEnd: HTMLInputElement) {
    var fromdate= new Date(dateRangeStart.value);
    this.fromDate=(fromdate.getMonth()+1)+"-"+fromdate.getDate()+"-"+fromdate.getFullYear();
    var todate=new Date(dateRangeEnd.value);
    this.toDate=(todate.getMonth()+1)+"-"+todate.getDate()+"-"+todate.getFullYear();
    if(this.fromDate!=="NaN-NaN-NaN" && this.toDate!=="NaN-NaN-NaN")
    this.loadPreiviousAppointmentList();
  }
  clearDate(event){
    this.fromDate=this.initFromDate;
    this.toDate=this.initToDate;
    this.loadPreiviousAppointmentList();
  }
  dateChange(date){
    this.selectedAppointment=false;
    if(this.weekArray.find(x=>x.date==date)){
      if(!this.weekArray.find(x=>x.date==date).selected){
        this.weekArray.map(x=>x.selected=false);
        this.weekArray.find(x=>x.date==date).selected=true;
      }
    }else{
      this.weekArray.map(x=>x.selected=false);
    }
      this.dateFormat(date);
      this.loadAppointmentList();
  }
  dateFormat(date){
    var d = new Date(date);
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    this.pickedDateMM=month+"-"+day+"-"+year;
    this.pickedDate=year+"-"+month+"-"+day;
    this.textDate=(this.monthArray[month-1]+" "+day+" "+year);
    this.month=this.monthArray[month-1];
    this.day=this.dayArray[d.getDay()];
    this.dayNum=d.getDate();
    this.year = d.getFullYear();
  }
  toggle(ref){
  }
  editAppointment(app:any){
    this.editApmt.emit(app);
  }
  deleteAppointment(data:any){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: 'Are you sure to delete this appointment?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var appointment_date=encodeURIComponent("appointment_date");
    var appointment_date_value=encodeURIComponent(data.appointment_date);
    var doctor_id=encodeURIComponent("doctor_id");
    var doctor_id_value=encodeURIComponent(data.doctor_id);
    var patient_id=encodeURIComponent("patient_id");
    var patient_id_value=encodeURIComponent(data.patient_id);
    var questionnaire_url=encodeURIComponent("questionnaire_url");
    var questionnaire_url_value=encodeURIComponent(data.questionnaire_url);
    var booking_date=encodeURIComponent("booking_date");
    var booking_date_value=encodeURIComponent(data.booking_date);
    var appointment_status=encodeURIComponent("appointment_status");
    var appointment_status_value=encodeURIComponent("CA");
    var questionnaire_status=encodeURIComponent("questionnaire_status");
    var questionnaire_status_value=encodeURIComponent(data.questionnaire_status);
    var notes_status=encodeURIComponent("notes_status");
    var notes_status_value=encodeURIComponent(data.notes_status);
    
    var id=encodeURIComponent("id");
    var id_value=encodeURIComponent(data.id);
    var saveData:any=[];

      saveData.push(appointment_date+"="+appointment_date_value);
      saveData.push(doctor_id +"="+ doctor_id_value)
        saveData.push(patient_id +"="+ patient_id_value)
          saveData.push(questionnaire_url +"="+ questionnaire_url_value)
            saveData.push(booking_date +"="+ booking_date_value)
              saveData.push(appointment_status +"="+ appointment_status_value)
                saveData.push(questionnaire_status +"="+ questionnaire_status_value)
                  saveData.push(notes_status +"="+ notes_status_value)
                  saveData.push(id +"="+ id_value)
    saveData=saveData.join("&");
        this.appService.editAppointment(saveData,data.id).subscribe((response) => {
          if (response) {
            this.snackBar.open('Appointment is Deleted successfully', 'Close', {duration: 2000});
            this.loadAppointmentList();
            }
        }, (error) => {
          console.log(error)
          if(error.status=404){
            this.snackBar.open(error.statusText, 'Close', {duration: 2000});
          }
        });
      }
    });
  }
  createNewAppointment(){
    this.appService.changeMessage(false);
  }
  selectAppointment(appointment){
    const dialogRef = this.dialog.open(AppointmentDetailComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { userData: appointment }
    });
    dialogRef.afterClosed().subscribe(result => {
      // if(result.event == 'Add'){
      //   console.log(result.data);
      //   if(result.data){
      //     this.userData.userAccess=result.data;
      //     localStorage.setItem('userDetails',JSON.stringify(this.userData));
      //   }
      // }else if(result.event == 'Update'){
      //   console.log(result.data);
      //   if(result.data){
      //     this.userData.userAccess=result.data;
      //     localStorage.setItem('userDetails',JSON.stringify(this.userData));
      //   }
      // } else if(result.event == 'Cancel'){
      //   console.log("cancel");
      // }
    });
    this.selectedAppointment=appointment;
    this.appointmentData.map(x=>{
      x.selected=false;
    });
    this.appointmentData.find(x=>x.id==appointment.id).selected=true;
    return dialogRef.afterClosed();
  }
  questionnaireClick(){
    //window.open(this.selectedAppointment.questionnaire_url, '_blank');
    //this.router.navigate(['/questionnaire']);
      this.appService.questionnaireClick(false);
      this.appService.questionnaireSelect(this.selectedAppointment);
      
  }
  public notesClick(){
    this.router.navigate(['/notes'], { queryParams: { id: this.selectedAppointment.id } });
    //this.appService.changeAppmt(this.selectedAppointment);
  }
  selectPayment(appointment){
    const dialogRef = this.dialog.open(AppointmentPaymentComponent, {
      width: '50vw',
      height:'60vh',
      disableClose: true,
      data: { userData: appointment }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Save') {
        this.loadAppointmentList();
        this.loadPreiviousAppointmentList()
        this.snackBar.open('Appointment payment Done', 'Close', {duration: 2000});
      } else if(result.event == 'Cancel'){
        this.snackBar.open('Appointment payment Cancel', 'Close', {duration: 2000});
      } else if(result.event == 'Close'){
        console.log("closed")
      }
    });
    return dialogRef.afterClosed();
  }
  sortAppointment(){
    this.isSort=!this.isSort;
    if(!this.isSort){
      this.appData=this.appData.sort((a,b) => Date.parse(b.appointment_date) - Date.parse(a.appointment_date));
    } else {
      this.appData=this.appData.sort((a,b) => Date.parse(a.appointment_date) - Date.parse(b.appointment_date));
    }
    
  }
}
