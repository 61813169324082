<div class="patientContainer refund-status-container">
  <div *ngIf="showTable">
    <div class="closeIcon" (click)="closeRefund()">X</div>
    <div class="row container-fluid appointment-cotainer">
      <div class="col-12" style="margin-top: 1vh">
        <div class="headContainer">
          <label
            style="
              width: 40%;
              float: left;
              font-size: 1.2em;
              padding: 30px 20px 17px 20px;
              color: #fff;
              font-weight: 600;
            "
            >Requests info</label
          >
          <div style="width: 60%; float: right">
            <mat-form-field style="margin-right: 5%; width: 40%; float: right">
              <input
                matInput
                (keyup)="applyFilter($event)"
                style="
                  color: grey;
                  border-radius: 25px;
                  background: white;
                  padding-block: 10px;
                "
                #input
              />
              <mat-placeholder class="placeholder">Search</mat-placeholder>
            </mat-form-field>
          </div>
        </div>

        <mat-tab-group
          mat-align-tabs="start"
          (selectedTabChange)="onTabChanged($event)"
          class="mat-elevation-z8 procesTab"
          style="display: block !important"
        >
          <mat-tab [label]="label_Not_processed" style="margin-left: 40px">
            <div class="mat-elevation-z8">
              <table
                mat-table
                [dataSource]="dataSource"
                matSort
                class="userTable"
              >
                <!-- Table Columns -->
                <ng-container matColumnDef="sno">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 100px"
                  >
                    S. No
                  </th>
                  <td mat-cell *matCellDef="let i = index">
                    {{
                      this.paginator.pageIndex == 0
                        ? i + 1
                        : 1 +
                          i +
                          this.paginator.pageIndex * this.paginator.pageSize
                    }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="appointment_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Appointment Id
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="text-transform: uppercase"
                  >
                    {{ row.appointment_id }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="nric/passport_number">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    NRIC/Passport number
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="text-transform: uppercase"
                  >
                    {{ row.nric_passport_number | nricMask }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="patient_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 150px"
                  >
                    Patient name
                  </th>
                  <td
                    class="refund-reschedule-patient_name"
                    mat-cell
                    *matCellDef="let row"
                    style=""
                  >
                    {{ row.patient_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="request_type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Request Type
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <span>{{row.doctor.first_name}}</span> -->
                    <span *ngIf="row.types_of_cancellation === 'RF'"
                      >Refund</span
                    >
                    <span *ngIf="row.types_of_cancellation === 'RS'"
                      >Reschedule</span
                    >
                  </td>
                </ng-container>
                <ng-container matColumnDef="message">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 250px"
                  >
                    Request Message
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="
                      width: 250px !important;
                      max-width: 250px;
                      word-wrap: break-word;
                    "
                  >
                    <span *ngIf="!row.patientMessageshowMore">
                      {{ trimString(row.patient_description.trim(), 20) }}</span
                    >
                    <span
                      *ngIf="row.patientMessageshowMore"
                      style="max-width: 280px"
                    >
                      {{ row.patient_description }}
                    </span>
                    <div
                      style="color: #0984a8"
                      *ngIf="row.patient_description.length > 20"
                      (click)="
                        row.patientMessageshowMore = !row.patientMessageshowMore
                      "
                    >
                      Show
                      <span>{{
                        row.patientMessageshowMore ? "less" : "More"
                      }}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="request_Date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Requested Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <span>{{row.doctor.first_name}}</span> -->
                    <span>{{
                      row.refund_or_reschedule_request_date
                        | date : "dd/MM/yyyy"
                    }}</span>
                    <!-- <span *ngIf="row.types_of_cancellation === 'RS'">Reschedule</span> -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 200px"
                  >
                    Action
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span class="accept" (click)="rejectRefund(row, 'accept')"
                      >Accept</span
                    >
                    <span class="reject" (click)="rejectRefund(row, 'reject')"
                      >Reject</span
                    >
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">
                    No data matching for "{{ input.value }}"
                  </td>
                </tr>
              </table>

              <mat-paginator
                #paginator
                pageSize="10"
                [pageSizeOptions]="[10, 25, 100]"
              ></mat-paginator>
            </div>
          </mat-tab>
          <mat-tab [label]="label_processed" style="margin-left: 40px">
            <div class="mat-elevation-z8">
              <table
                mat-table
                [dataSource]="dataSource2"
                matSort
                class="userTable"
              >
                <!-- Table Columns -->
                <ng-container matColumnDef="sno">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 100px"
                  >
                    S. No
                  </th>
                  <td mat-cell *matCellDef="let i = index">
                    {{
                      this.paginator2.pageIndex == 0
                        ? i + 1
                        : 1 +
                          i +
                          this.paginator2.pageIndex * this.paginator2.pageSize
                    }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="appointment_id">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 125px"
                  >
                    Appointment Id
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="text-transform: uppercase"
                  >
                    {{ row.appointment_id }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="nric/passport_number">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 185px"
                  >
                    NRIC/Passport number
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="text-transform: uppercase"
                  >
                    {{ row.nric_passport_number | nricMask }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="patient_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 150px"
                  >
                    Patient name
                  </th>
                  <td
                    class="refund-reschedule-patient_name"
                    mat-cell
                    *matCellDef="let row"
                    style=""
                  >
                    {{ row.patient_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="request_type">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 150px"
                  >
                    Request Type
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <span>{{row.doctor.first_name}}</span> -->
                    <span *ngIf="row.types_of_cancellation === 'RF'"
                      >Refund</span
                    >
                    <span *ngIf="row.types_of_cancellation === 'RS'"
                      >Reschedule</span
                    >
                  </td>
                </ng-container>
                <ng-container matColumnDef="operator_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 180px"
                  >
                    Operator
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <span>{{row.doctor.first_name}}</span> -->
                    <span *ngIf="!!row.refund_or_reschedule_approval_user">{{
                      getProviderName(row.refund_or_reschedule_approval_user)
                    }}</span>
                    <span *ngIf="!!row.refund_or_reschedule_rejection_user">{{
                      getProviderName(row.refund_or_reschedule_rejection_user)
                    }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="approval_date">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 180px"
                  >
                    Approval / Rejected Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <span>{{row.doctor.first_name}}</span> -->
                    <span *ngIf="!!row.refund_or_reschedule_approval_date">{{
                      row.refund_or_reschedule_approval_date
                        | date : "dd/MM/yyyy"
                    }}</span>
                    <span *ngIf="!!row.refund_or_reschedule_rejection_date">{{
                      row.refund_or_reschedule_rejection_date
                        | date : "dd/MM/yyyy"
                    }}</span>
                  </td>
                </ng-container>
                <ng-container
                  matColumnDef="message"
                  style="width: 200px !important; max-width: 200px"
                >
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 200px !important; max-width: 200px"
                  >
                    Request Message
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="
                      width: 200px !important;
                      max-width: 200px;
                      word-wrap: break-word;
                    "
                  >
                    <span *ngIf="!row.patientMessageshowMore">{{
                      trimString(row.patient_description.trim(), 15)
                    }}</span>
                    <span *ngIf="row.patientMessageshowMore"
                      >{{ row.patient_description }}
                    </span>
                    <div
                      style="color: #0984a8"
                      *ngIf="row.patient_description.length > 15"
                      (click)="
                        row.patientMessageshowMore = !row.patientMessageshowMore
                      "
                    >
                      Show
                      <span>{{
                        row.patientMessageshowMore ? "less" : "More"
                      }}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container
                  matColumnDef="reply"
                  style="width: 250px !important; max-width: 250px"
                >
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 250px !important; max-width: 250px"
                  >
                    Review Notes
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    style="
                      width: 250px !important;
                      max-width: 250px;
                      word-wrap: break-word;
                    "
                  >
                    <div class="refund-reschedule-doctor-dec">
                      <span *ngIf="!row.docMessageshowMore">
                        {{
                          trimString(row.doctor_description.trim(), 15)
                        }}</span
                      >
                      <span *ngIf="row.docMessageshowMore">
                        {{ row.doctor_description }}
                      </span>
                      <div
                        style="color: #0984a8"
                        *ngIf="row.doctor_description.length > 15"
                        (click)="
                          row.docMessageshowMore = !row.docMessageshowMore
                        "
                      >
                        Show
                        <span>{{
                          row.docMessageshowMore ? "less" : "more"
                        }}</span>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    style="width: 150px"
                  >
                    Action
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.status === 'AP'" style="color: #0984a8"
                      >Approved</span
                    >
                    <span *ngIf="row.status === 'RJ'" style="color: red"
                      >Rejected</span
                    >
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns2"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">
                    No data matching for "{{ input.value }}"
                  </td>
                </tr>
              </table>

              <mat-paginator
                #paginator2
                pageSize="10"
                [pageSizeOptions]="[10, 25, 100]"
              ></mat-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
