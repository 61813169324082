<div
  class="container-fluid bg-light"
  style="max-height: 93vh; overflow-y: scroll; overflow-x: hidden"
>
  <div
    class="row"
    id="parent"
    cdkDropList
    (cdkDropListDropped)="onDrop($event)"
  >
    <div
      *ngFor="let key of chartList"
      [id]="key.chartType"
      [ngClass]="
        key.chartType == 'med-speed1'
          ? 'col-lg-12 col-md-12 col-12 px-1'
          : 'col-lg-6 col-md-6 col-6 px-1'
      "
      cdkDrag
      (cdkDragStarted)="onDragStarted($event, key.chartType)"
    >
      <div class="card">
        <div class="card-header p-0 d-flex justify-content-between">
          <div class="align-self-center" style="padding-left: 11px">
            <h1>
              <b>{{ key.chartName }}</b>
            </h1>
          </div>

          <div [id]="key.chartType" style="padding-right: 16px">
            <div *ngIf="key.chartType == 'med-speed1'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker9">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate"
                    (dateChange)="dateRangeChange(fDate, tDate, 'dateRange9')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker9"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker9></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-speed'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker1">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate1"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate1"
                    (dateChange)="dateRangeChange(fDate1, tDate1, 'dateRange1')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker1></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-bar'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker2">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate2"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate2"
                    (dateChange)="dateRangeChange(fDate2, tDate2, 'dateRange2')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker2></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-line'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker3">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate3"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate3"
                    (dateChange)="dateRangeChange(fDate3, tDate3, 'dateRange3')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker3></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-multi'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker4">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate4"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate4"
                    (dateChange)="dateRangeChange(fDate4, tDate4, 'dateRange4')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker4"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker4></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-pie'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker5">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate5"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate5"
                    (dateChange)="dateRangeChange(fDate5, tDate5, 'dateRange5')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker5"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker5></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-vertical'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker6">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate6"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate6"
                    (dateChange)="dateRangeChange(fDate6, tDate6, 'dateRange6')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker6"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker6></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-pie1'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker7">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate7"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate7"
                    (dateChange)="dateRangeChange(fDate7, tDate7, 'dateRange7')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker7"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker7></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div *ngIf="key.chartType == 'med-vertical1'" class="drpicker">
              <mat-form-field class="pb-0" appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker8">
                  <input
                    matStartDate
                    placeholder="Start date"
                    #dateRangeStart
                    [(ngModel)]="fDate8"
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    #dateRangeEnd
                    [(ngModel)]="tDate8"
                    (dateChange)="dateRangeChange(fDate8, tDate8, 'dateRange8')"
                  />
                </mat-date-range-input>
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker8"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker8></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div
          id="element-to-hide"
          data-html2canvas-ignore="true"
          class="fullscreen"
          [id]="key.fullScreen"
          (click)="openFullscreen(key.fullScreen)"
        >
          <mat-icon style="float: right">fullscreen</mat-icon>
        </div>
        <div *ngIf="key.chartType == 'med-speed1'">
          <div *ngIf="!dateRange9">
            <div>
              <app-gauge-chart></app-gauge-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange9">
            <div>
              <app-gauge-chart [dateRange]="dateRange9"></app-gauge-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-speed'">
          <div *ngIf="!dateRange1">
            <div>
              <app-speed-chart></app-speed-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange1">
            <div>
              <app-speed-chart [dateRange]="dateRange1"></app-speed-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-bar'">
          <div *ngIf="!dateRange2">
            <div>
              <app-bar-chart></app-bar-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange2">
            <div>
              <app-bar-chart [dateRange]="dateRange2"></app-bar-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-line'">
          <div *ngIf="!dateRange3">
            <div>
              <app-line-chart></app-line-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange3">
            <div>
              <app-line-chart [dateRange]="dateRange3"></app-line-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-multi'">
          <div *ngIf="!dateRange4">
            <div>
              <app-multi-chart></app-multi-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange4">
            <div>
              <app-multi-chart [dateRange]="dateRange4"></app-multi-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-pie'">
          <div *ngIf="!dateRange5">
            <div>
              <app-pie-chart></app-pie-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange5">
            <div>
              <app-pie-chart [dateRange]="dateRange5"></app-pie-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-vertical'">
          <div *ngIf="!dateRange6">
            <div>
              <app-multi-vertical></app-multi-vertical>
            </div>
          </div>
          <div *ngIf="!!dateRange6">
            <div>
              <app-multi-vertical [dateRange]="dateRange6"></app-multi-vertical>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-pie1'">
          <div *ngIf="!dateRange7">
            <div>
              <app-payment-chart></app-payment-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange7">
            <div>
              <app-payment-chart [dateRange]="dateRange7"></app-payment-chart>
            </div>
          </div>
        </div>
        <div *ngIf="key.chartType == 'med-vertical1'">
          <div *ngIf="!dateRange8">
            <div>
              <app-revenue-chart></app-revenue-chart>
            </div>
          </div>
          <div *ngIf="!!dateRange8">
            <div>
              <app-revenue-chart [dateRange]="dateRange8"></app-revenue-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
