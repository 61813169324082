import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-set-gst',
  templateUrl: './set-gst.component.html',
  styleUrls: ['./set-gst.component.css']
})
export class SetGstComponent {
  public userdata:any;
  public tenantData:any;
  public selectedUser:any;
  public selectedTenant:any;
  isAdmin:boolean=false;
  constructor(private loginServive:LoginService,public dialogRef:MatDialogRef<SetGstComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  public adminService:AdminService) { }
  ngOnInit(): void {
    this.isAdmin=this.loginServive.userDetails.is_staff;
    this.selectedUser=this.data.userData;
    console.log(this.selectedUser);
    this.loadTenants();
  }
  loadTenants(){
    this.adminService.GetTenantList().subscribe((response) => {
      if (response) {
        this.tenantData=response;
        this.loadUsers();
        }
    }, (error) => {
      console.log(error);
    });
  }
  loadUsers(){
    this.adminService.GetUserList().subscribe((response) => {
      if (response) {
        this.userdata=response;
        this.selectTenant();
        }
    }, (error) => {
      console.log(error);
    });
  }
  selectTenant(){
    let id=0;
    id=this.userdata.find(x=>x.id==this.selectedUser.id).tenant;
    this.selectedTenant=this.tenantData.find(x=>x.id==id);
    console.log(this.selectedTenant);
  }
  saveDialog(){
    let doctor_charges={};
    let formData = new FormData();
    if(this.selectedTenant.tax_percentage!=='undefined' && this.selectedTenant.currency_code!==''){
      Object.keys(this.selectedTenant).forEach((key)=>{
        if(key!=='logo_url' && key!=='clinic_stamp_url' && key!=='questionnaire_wizard'){
          formData.append(key,this.selectedTenant[key]);
        }  
      });
      this.adminService.editTenentList(formData,this.selectedTenant.id).subscribe((response) => {
        if (response) {
          console.log(response)
          }
      }, (error) => {
        console.log(error)
      });
    this.dialogRef.close({event:'Save',data:this.selectTenant});
    } else {
      alert("Tax % and GST fields should not be empty");
    }
  }
  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }

}
