let domain = window.location.hostname
let subdomain = domain.split(".")
let api_url = "."+subdomain[1]
let url = "https://"+subdomain[0]+".medosysapi.com/"

export const environment = {
	  production: true,
	    useAuthentication: true,
	      baseUrl: url,
	        logoutUIUrl: '',
		  devUrl: '',
		    cookieDomain: '',
		      domainUrl: '',
		        sharePointUrl: '',
			  reportUrl: '',
			    insightKey: '',
			      pmBaseUrl: ''
};
