
<mat-dialog-content>
<div *ngIf="selectedProvider===0">
  <label id="example-radio-group-label" style="padding-left:30px"><b>Pick your doctor before check the time</b></label>
  <button class="btn" mat-stroked-button color='primary' (click)="closeDialog()">OK</button>
</div>
<div *ngIf="selectedProvider!==0">
<label id="example-radio-group-label" style="padding-left:30px"><b>Pick the speciality</b></label>
<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="selectedSpeciality">
  <ul style="padding-inline:20px;">
    <li *ngFor="let speciality of speciality"><mat-radio-button class="example-radio-button" [value]="speciality">
    <span style="text-transform:capitalize">{{speciality}}</span>
    </mat-radio-button></li>
  </ul>
</mat-radio-group>

<button class="btn1" mat-stroked-button color='default' (click)="closeDialog()">Cancel</button>&nbsp;
<button class="btn" mat-stroked-button color='primary' (click)="saveDialog()">OK</button>
</div>
</mat-dialog-content>