import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddTenantComponent } from '../components/add-tenant/add-tenant.component';
import { AddUserComponent } from '../components/add-user/add-user.component';

@Injectable({
  providedIn: 'root'
})
export class CommonModalDialogService {
  tenantData: any;
  userData:any;
  AddTenantInput:any;
  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<AddTenantComponent>) { }
  openDialog(): Observable<any> {
    const dialogRef = this.dialog.open(AddTenantComponent, {
      width: '80vw',
      height:'80vh',
      disableClose: true,
      data: { tenantData: this.tenantData }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.tenantData=result.data;
      }else if(result.event == 'Update'){
        this.tenantData(result.data);
      } else if(result.event == 'Cancel'){
        alert("cancel is clicked");
      }
    });
    return dialogRef.afterClosed();
  }
  openUserDialog(): Observable<any> {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '80vw',
      height:'80vh',
      disableClose: true,
      data: { userData: this.userData }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.userData(result.data);
      }else if(result.event == 'Update'){
        this.userData(result.data);
      } else if(result.event == 'Cancel'){
        alert("User cancel is clicked");
      }
    });
    return dialogRef.afterClosed();
  }
}
