<div class="patientContainer">
            <div *ngIf="showTable">
                   <div class="closeIcon" (click)="closeDoctorRecord()">X</div>        
                    <div class="row container-fluid appointment-cotainer">
                    <div class="col-12" style="margin-top:1vh">
                      <div class="headContainer">
                        <label style="width:40%;float:left;font-size:1.2em;padding:30px 20px 17px 20px;color:white;font-weight: 500;">Doctor Management ({{userList && userList.length ? userList.length:0}})</label>
                       <div style="width:60%;float:right">
                        <mat-form-field style="margin-right:5%;width:40%;float:right">
                            <input matInput (keyup)="applyFilter($event)"
                            style="color:grey;border-radius:25px;background:white;padding-block:10px;" #input>
                            <mat-placeholder class="placeholder">Search</mat-placeholder>
                        </mat-form-field>
                        </div></div>
                          
                          <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource" matSort class="userTable">
                              <!-- Table Columns -->
                              <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Si.Num </th>
                                <td mat-cell *matCellDef="let i = index">
                                {{this.paginator?.pageIndex == 0 ? i + 1 : 1 + i + this.paginator?.pageIndex * this.paginator?.pageSize}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="first_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let row"> 
                                  <span>{{row.first_name}}</span> 
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="mcr_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> MCR Number </th>
                                <td mat-cell *matCellDef="let row" style="text-transform: uppercase;"> {{(row.mcr_number!=='null' && row.mcr_number!==null)?row.mcr_number:'NO MCR Added'}} </td>
                              </ng-container>
                              <ng-container matColumnDef="gender">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                                  <td mat-cell *matCellDef="let row"> 
                                    <span>{{row.gender=="F"?"Female":"Male"}}</span> 
                                    <!-- <span *ngIf="row.edited"><input class="editInput" matInput [(ngModel)]="row.gender" value="{{row.gender}}"></span> -->
                                  </td>
                                </ng-container>
                              <ng-container matColumnDef="phone_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
                                <td mat-cell *matCellDef="let row"> 
                                  <span>{{row.phone_number}}</span> 
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="speciality">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Speciality</th>
                                <td mat-cell *matCellDef="let row"> 
                                  <span *ngFor="let role of row.speciality_name">
                                    <span class="roleStyle">{{role}}</span>
                                </span>
                                </td>
                              </ng-container>
          
                              <!-- <ng-container matColumnDef="record">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Govt Grant</th>
                                 <td mat-cell *matCellDef="let row">
                                  <mat-form-field appearance="outline" [style.width.px]=70>
                                      <mat-select [(ngModel)]="row.govtGrant">
                                        <mat-option value="Yes">Yes</mat-option>
                                        <mat-option value="No">No</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  </td>
                                  
                                </ng-container> -->
                        
                              <!-- <ng-container matColumnDef="admin">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
                                <td mat-cell *matCellDef="let row"> 
                                  <span>{{row.is_staff?"Yes":"No"}}</span>
                                </td>
                              </ng-container> -->
                              
                              <ng-container matColumnDef="doctor_charge_ids">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manage Fees</th>
                                <td mat-cell *matCellDef="let row" (click)="setFee(row)">
                                   <span class="set-fee">{{row.doctor_charge_ids==null?'Set Fee':'Change Fee'}}</span>
                                </td>
                              </ng-container>
                                   
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          
                              <!-- Row shown when there is no matching data. -->
                              <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="5">No data matching for "{{input.value}}"</td>
                              </tr>
                            </table>
                          
                            <mat-paginator pageSize=10 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                          </div>
                        </div>
                        </div>
                      </div></div>
