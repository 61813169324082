import { AfterViewInit,Component, ViewChild,OnInit } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { LoginService } from '../../service/login.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';

import {ConfirmationDialogComponent} from './../../shared_components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './../../shared_components/alert-dialog/alert-dialog.component';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import{AddUserComponent} from '../add-user/add-user.component'
import { CommonModalDialogService } from '../../service/common-modal-dialog.service'
import { AddTenantComponent } from '../add-tenant/add-tenant.component';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  version = VERSION;
  public displayedColumns: string[] = ['rollNum','city', 'access','name','state','zipCode','country','action','users'];
  public dataSource: MatTableDataSource<any>;
  public users:any;
  public userList:any;
  isTenant:boolean=true;
  selectedTenant:any;
  public initialData:any=[];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public adminService:AdminService,public loginService:LoginService,public modalService:CommonModalDialogService,
    public dialogRef1:MatDialogRef<AdminPageComponent>,private router: Router,private dialog: MatDialog,public dialogRef: MatDialogRef<AddTenantComponent>,private snackBar: MatSnackBar) {
    
  }

  ngOnInit(): void {
    //const inputParams={email: this.loginService.email,password: this.loginService.password}
    this.dataLoad();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  dataLoad(){
    this.adminService.GetTenantList().subscribe((response) => {
      if (response) {
        //console.log(JSON.stringify(response));
        this.users=response;
        this.users.sort((s1, s2) => {
          return s1.id - s2.id;
        });
        this.users.map(x=>x.edited=false);
        this.initialData=JSON.stringify(this.users);
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        }
    }, (error) => {
      //console.log(error);
    });
  }
  closeDialog(){
    this.dialogRef1.close({event:'Cancel'});
}
  addTenant(){
    let tenantData={
        // intake_questions: {
        //   id:this.users.length+1,
        //   created_date:new Date(),
        //   modified_date:new Date()
        // },
        name: "",
        subdomain_prefix: "",
        active: true,
        time_zone: "",
        phone_number: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        is_api_customer: false,
        speciality_ids: [
          1
        ],
        questionnaire_wizard: [
          this.users.length>7?7:this.users.length
        ],
        edited:false
    }
    const dialogRef = this.dialog.open(AddTenantComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { tenantData: tenantData }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.dataLoad();
      }else if(result.event == 'Update'){
        this.dataLoad();
      } else if(result.event == 'Cancel'){
        this.dataLoad();
      }
    });
    return dialogRef.afterClosed();    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  editTenant(data){
    this.users.find(x=>x.id==data.id).edited=true;
    this.users.find(x=>x.id==data.id).questionnaire_wizard=[1];
    let tenantData=this.users.find(x=>x.id==data.id);
    //console.log(tenantData);
    const dialogRef = this.dialog.open(AddTenantComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { tenantData: tenantData }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.dataLoad();
      }else if(result.event == 'Update'){
        this.dataLoad();
      } else if(result.event == 'Cancel'){
        this.dataLoad();
        //console.log("cancel is clicked");
      }
    });
    return dialogRef.afterClosed();
  }
  // saveTenant(data){
  //   this.users.find(x=>x.id==data.id).edited=false;
  //   this.users.find(x=>x.id==data.id).speciality_ids= [1];
  //   this.adminService.editTenentList(this.users.find(x=>x.id==data.id),data.id).subscribe((response) => {
  //     if (response) {
  //       //console.log(response)
  //       this.snackBar.open('Tenant is Edited successfully', 'Close', {duration: 2000});
  //       }
  //   }, (error) => {
  //     this.snackBar.open(error, 'Close', {duration: 2000});
  //   });
  //   var saveRow = this.users.find(x=>x.id==data.id);
  //   if (saveRow) {
  //     saveRow.name = data.name;
  //     saveRow.subdomain_prefix = data.subdomain_prefix,
  //     saveRow.city = data.city;
  //     saveRow.state = data.state,
  //     saveRow.zipcode = data.zipcode;
  //     saveRow.country = data.country
  //   } 
    
  // }
  // cancelSaveTenant(data){
  //   var InitData = JSON.parse(this.initialData);
  //   this.users.find(x=>x.id==data.id).name=InitData.find(x=>x.id==data.id).name;
  //   this.users.find(x=>x.id==data.id).subdomain_prefix=InitData.find(x=>x.id==data.id).subdomain_prefix;
  //   this.users.find(x=>x.id==data.id).city=InitData.find(x=>x.id==data.id).city;
  //   this.users.find(x=>x.id==data.id).state=InitData.find(x=>x.id==data.id).state;
  //   this.users.find(x=>x.id==data.id).zipcode=InitData.find(x=>x.id==data.id).zipcode;
  //   this.users.find(x=>x.id==data.id).country=InitData.find(x=>x.id==data.id).country;
  //   this.users.find(x=>x.id==data.id).edited=false;
  // }
  deleteTenant(data){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
   

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.users.find(x=>x.id==data.id).active=false;
        this.adminService.editTenentList(this.users.find(x=>x.id==data.id),data.id).subscribe((response) => {
          if (response) {
            this.dataLoad();
            this.snackBar.open('Tenant is Deleted successfully', 'Close', {duration: 2000});
            }
        }, (error) => {
          this.snackBar.open("Error while delete", 'Close', {duration: 2000});
        });
      }
    });
  }
  showUserList(data){
    this.selectedTenant=data;
    this.isTenant=false;
    this.adminService.GetSpecificTenantUserList(data.id).subscribe((response) => {
      if (response) {
        //console.log(response)
        this.userList=response;
        this.userList.map(element=>{
          element.edited=false;
        });
        // this.userList=this.userList.filter(x=>x.tenant==data.id)
        if(this.userList.length>0){
          //this.router.navigate(['/userList'], { queryParams: { category: data.id } });
          this.selectedTenant=data;
          this.initialData=JSON.stringify(this.userList);
          this.dataSource = new MatTableDataSource(this.userList);
        }else{
          this.snackBar.open("No User found", 'Close', {duration: 2000});
        }
        
        }
    }, (error) => {
      this.snackBar.open(JSON.stringify(error.error.detail), 'Close', {duration: 2000});
    });
  }
  backClicked(event){
    this.isTenant=event;
    this.dataLoad();
  }
}
