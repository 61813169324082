import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/service/appointment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { timeStamp } from 'console';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDetailComponent } from '../appointment-detail/appointment-detail.component';

@Component({
  selector: 'app-patient-appointment-list',
  templateUrl: './patient-appointment-list.component.html',
  styleUrls: ['./patient-appointment-list.component.css']
})
export class PatientAppointmentListComponent implements OnInit {
  @Input() patientRec: any;
  @Input() MRNData: any;
  @Output() closeClick = new EventEmitter<any>();
  appointmentList:any=[];
  originalAppList:any=[];
  appList:boolean=true;
  validMRN:boolean=false;
  public countryCode:any;
  public telephoneCodes: any = [];
  public access=JSON.parse(localStorage.getItem('userDetails')).access;
  @Output() editApmt = new EventEmitter<any>();
  
  year = new Date().getFullYear();
  genders = [
    {value: 'M', viewValue: 'Male'},
    {value: 'F', viewValue: 'Female'},
  ];
  mStatus = [
    {value: 'S', viewValue: 'Single'},
    {value: 'M', viewValue: 'Married'}
  ];
  bGroup = [
    {value: 'AP', viewValue: 'A+'},
    {value: 'AN', viewValue: 'A-'},
    {value: 'BP', viewValue: 'B+'},
    {value: 'BN', viewValue: 'B-'},
    {value: 'OP', viewValue: 'O+'},
    {value: 'ON', viewValue: 'O-'}
  ];
  IDType = [
    {value: '1', viewValue: 'NRIC/FIN'},
    {value: '2', viewValue: 'PASSPORT'},
  ];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  dob:any;
  searchParam:string="";
  constructor(private appService:AppointmentService,private router: Router,private snackBar: MatSnackBar,private dialog: MatDialog) { }


  ngOnInit(): void {
    this.onLoadTelephoneCodes();
    if(this.patientRec)
    this.getPatientAppointmentList(this.patientRec.id);
    this.dob=new Date(this.MRNData?.date_of_birth);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }
  ngOnChanges(){
    if(this.MRNData){
      this.MRNData.idType='1'
      this.appList=false;
    }
  }
  editAppointment(app:any){
    this.editApmt.emit(app);
  }
  mrnValidation() {
    if (this.MRNData.idType == '1' && new RegExp(/^[SFTG]\d{7}[A-Z]$/).test(this.MRNData.mrn)) {
      this.validMRN = false;
    } else if (this.MRNData.idType !== '1' && new RegExp(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/).test(this.MRNData.mrn)){
      this.validMRN= false;
    } else {
      this.validMRN=true;
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.telephoneCodes.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  dateFormat(date){
    var d = new Date(date);
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    this.MRNData.date_of_birth=month+"-"+day+"-"+year;
    this.dob=new Date(this.MRNData.date_of_birth);
  }
  onLoadTelephoneCodes(){
    this.appService.getDataFromURL('../../assets/json/telephoneCodes.json').subscribe((response) => {
      if (response) {
        //console.log(response);
        let res:any = response;
        this.telephoneCodes=res;
        }
    }, (error) => {
      console.log(error);
    });
  }
  getPosts(countryCode){
    this.countryCode=countryCode;
  }
getPatientAppointmentList(id){
    let appParams={
      patient_id:id
    }
    this.appService.getAppointmentList(appParams).subscribe((response: any) => {
      if (response) {
        let appData:any=response;
        if(appData.length>0){
          this.appointmentList=appData;
          this.appointmentList.map(x=>{
            x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[2]);
            // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
            // if(x.appointment_status=='UP'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
            // }
            // if(x.appointment_status=='CI'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
            // }
          })
          this.appointmentList = this.appointmentList.sort((a,b) => new Date(b.appointment_date).getTime() - new Date(a.appointment_date).getTime());
          this.originalAppList = [...this.appointmentList]
          console.log("APPPPPP =====> ", this.originalAppList)
        } else {
          this.appointmentList=[];
        }
      }
    }, (error) => {
      console.log(error)
        //this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }
  closeAppmtList(){
    this.closeClick.emit(true);
    this.validMRN=null;
  }
  selectAppointment(appointment){
    const dialogRef = this.dialog.open(AppointmentDetailComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { userData: appointment }
    });
  }
  mask(word) {
    if (word.length <=4) {
      return word
    } else {
      var masked = word.substring(0, word.length - 4).replace(/[a-z\d]/gi,"*") + 
      word.substring(word.length - 4, word.length)
      return masked;
    }
  }
  searchUser(value){
    if(value!==""){
      this.appointmentList=this.originalAppList.filter(x=>x.doctor.first_name.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.appointmentList=this.originalAppList;
    }
  }
  
  updateMRN(){
    var createData:any=[];
    if(this.MRNData.date_of_birth.split("-")[0].length==4){
      this.MRNData.date_of_birth=this.MRNData?.date_of_birth.split("-")[1]+'-'+
      this.MRNData.date_of_birth.split("-")[2]+'-'+
      this.MRNData.date_of_birth.split("-")[0];
    } else if(this.MRNData.date_of_birth.split("-")[0].length==2){
      this.MRNData.date_of_birth=this.MRNData?.date_of_birth.split("-")[0]+'-'+
      this.MRNData.date_of_birth.split("-")[1]+'-'+
      this.MRNData.date_of_birth.split("-")[2];
    }
    
    this.MRNData.avator_url=this.MRNData.avator_url==null?'':this.MRNData.avator_url;
for (const [key, value] of Object.entries(this.MRNData)) {
  if(`${key}` !== 'avator_url' && `${value}` !== null && `${value}` !== 'null'){
  createData.push(encodeURIComponent(`${key}`) +"="+ encodeURIComponent(`${value}`));
  }
}
createData = createData.join("&");
      this.appService.editMRN(createData,this.MRNData.id).subscribe((response) => {
      if (response) {
        let res:any=response;
        if(res.mrn)
        this.MRNData=res;
       this.snackBar.open("Details updated successfully", 'Close', {duration: 2000});
        this.closeAppmtList();
      }
    }, (error) => {
      console.log(error)
        this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }
}
