import { AfterViewInit,Component, ViewChild,OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { CommonModalDialogService } from 'src/app/service/common-modal-dialog.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { ColDef, GridReadyEvent,GridApi, ColumnApi } from 'ag-grid-community';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit,AfterViewInit {
  public appointmentData:any=[];
  public appData:any=[];
  public year:number;
  fromDate:any;
  toDate:any;
  fDate:any;
  tDate:any;
  defaultFromDate:any;
  defaultToDate:any;
  exportString:string="export"
  version = VERSION;
  public dataSource: MatTableDataSource<any>;
  public userList:any;
  public initialData:any=[];
  public userData=JSON.parse(localStorage.getItem('userDetails'));
  public showTable:boolean=true;
  public tableData:any=[];
  tenantId: any=this.userData.userAccess.tenant;
  selectedData:any;
  selectedUser:any;
  selectedUserFull:any;
  patientAppmt:any=[];
  doctorAppmt:any=[];
  @Input() selectedReport: any;
  @Output() backClick = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
public id:number;
public gridApi: GridApi<any>;
gridColumnApi: ColumnApi;
filterParams:any ={
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
  constructor(private route: ActivatedRoute,public adminService:AdminService,public appService:AppointmentService,
              private dialog: MatDialog,private snackBar: MatSnackBar,public modalService:CommonModalDialogService,
              private http: HttpClient) {
  }
  public columnDefs: ColDef[] = [];
  // public selectionList=['Patient Appointment','Appointment','Disease Specific','Payment Report','Doctor Report']
  public defaultColDef: ColDef = {
    flex: 1,
    filter: true,
    sortable: true,
    floatingFilter: true
  };
  public rowData:any;
  onGridReady(event) {
    this.loadPreiviousAppointmentList();
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnInit(): void {
    var d = new Date();
    this.year=d.getFullYear();
    let prevMonth =(d.getMonth() + 1);
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    this.toDate=prevMonth+"-"+prevDay+"-"+prevYear;
    this.defaultToDate=this.toDate;
    // var dPrev = new Date(d.setMonth(d.getMonth() - 1));
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev =(dPrev.getMonth() + 1);
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    this.fromDate=monthPrev+"-"+dayPrev+"-"+yearPrev;
    this.defaultFromDate=this.fromDate;
    // this.reportChange(this.selectedReport)
  }
  ngOnChanges(){
    this.reportChange(this.selectedReport)
  }
  ngAfterViewInit() {
  }
  reportChange(report){
    this.toDate=this.defaultToDate;
    this.fromDate=this.defaultFromDate;
    this.loadPreiviousAppointmentList();
    if(report=='Patient Appointment'){
      this.columnDefs=[
        { field: 'si_no',headerName:'Serial No'},
        { field: 'first_name',headerName:'Patient Name' },
        { field: 'age', filter: 'agNumberColumnFilter',headerName:'Age'},
        { field: 'gender_str',headerName:'Gender'},
        { field: 'complaint_name',headerName:'Disease'},
        {
          field: 'appointment_date',
          filter: 'agDateColumnFilter',
          filterParams: this.filterParams,headerName:'Appmnt Date'
        },
        { field: 'date_of_birth',headerName:'D.O.B'}
      ];
    } else if(report=='Appointment'){
      this.columnDefs=[
        { field: 'si_no',headerName:'Serial No'},
        { field: 'appmt_id',headerName:'Appmnt Id'},
        { field: 'appointment_date',filter: 'agDateColumnFilter',filterParams: this.filterParams,headerName:'Appmnt Date' },
        { field: 'doctor.first_name',headerName:'Doctor Name'},
        { field: 'complaint_name',headerName:'Disease'}
      ];
    }
    else if(report=='Disease Specific'){
      this.columnDefs=[
        { field: 'si_no',headerName:'Serial No'},
        { field: 'complaint_name',headerName:'Disease'},
        { field: 'appmt_id',headerName:'Appointment Id'}
      ];
    }
    else if(report=='Payment Report'){
      this.columnDefs=[
        { field: 'si_no',headerName:'Serial No'},
        { field: 'appmt_id',headerName:'Appointment Id' }
      ];
    }
    else if(report=='Doctor Report'){
      this.columnDefs=[
        { field: 'si_no',headerName:'Serial No'},
        { field: 'doctor_id',headerName:'Doctor Id'},
        { field: 'doctor.first_name',headerName:'Doctor Name'},
        { field: 'doctor.last_name',headerName:'Last Name'},
        { field: 'gender_str',headerName:'Gender'},
        { field: 'doctor_degree',headerName:'Qualification'},
        { field: 'appmt_id',headerName:'Appmnt Id'}
      ];
    }
  }
  closeReport(){
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
  }
  onBtExport(format) {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'medosys_excel'
    };
    if(format=="csv"){
      this.gridApi.exportDataAsCsv(params);
    } else if(format=="excel"){
      this.gridApi.exportDataAsExcel(params);
      this.gridApi.exportDataAsExcel({
        columnKeys: this.generateColumnsForExcel()
      });
    }
  }
  generateColumnsForExcel(): string[] {
    const keys = this.gridColumnApi
      .getAllDisplayedColumns()
      .map((column) => column.getColId());
    return keys;
  }
  loadPreiviousAppointmentList(){
    this.fDate=new Date(this.fromDate);
    this.tDate=new Date(this.toDate);
    let appParams={
      appointment_start_date:this.fromDate,
      appointment_end_date:this.toDate
    }
    if(this.fromDate && this.toDate){
    this.appService.getAppointmentList(appParams).subscribe((response) => {
      if (response) {
        this.appData=[];
        this.patientAppmt=[];
        console.log(response)
        let appData:any=response;
        if(appData.length>0){
          this.appData=appData;
          this.appData.map(x=>{
            x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[2]);
            // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
            // if(x.appointment_status=='UP'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
            // }
            // if(x.appointment_status=='CI'){
            //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
            // }
          });
          if(this.appData?.length>0){
            this.appData.map((x,idx)=>{
              this.patientAppmt.push({
                ...x,...x.patient,
                si_no:idx+1,
                gender_str:x.patient.gender=='M'?'Male':'Female',
                appmt_id:x.id,
                complaint_name:(x.questionnaire_info[0]?.complaint_id[0]?.complaint_name)?(x.questionnaire_info[0]?.complaint_id[0]?.complaint_name):'Not filled'
            })
            })
          }
          this.tableData=this.patientAppmt;
          console.log(this.tableData)
          this.initialData=JSON.stringify(this.tableData);
        // this.dataSource = new MatTableDataSource(this.appData);
        this.dataSource = new MatTableDataSource<any>(this.tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
          console.log(this.appointmentData);
        } else {
          this.appointmentData=[];
        }
      }
    }, (error) => {
      console.log(error)
    });
  }
  }

  dateRangeChange(dateRangeStart: HTMLInputElement,  dateRangeEnd: HTMLInputElement) {
    var fromdate= new Date(dateRangeStart.value);
    this.fromDate=(fromdate.getMonth()+1)+"-"+fromdate.getDate()+"-"+fromdate.getFullYear();
    var todate=new Date(dateRangeEnd.value);
    this.toDate=(todate.getMonth()+1)+"-"+todate.getDate()+"-"+todate.getFullYear();
    if(this.fromDate!=="NaN-NaN-NaN" && this.toDate!=="NaN-NaN-NaN")
    this.loadPreiviousAppointmentList();
  }
}


