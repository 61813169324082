<div class="appontmentDetails">
    <div class="detailHeader">
        <div style="width:10%;float:left;padding-block:5px"><img [src]="selectedAppointment.patient.avator_url ? selectedAppointment.patient.avator_url : '../../../assets/images/avator.JPG'" alt="userName" style="width:100px;height:100px;border-radius:50%;"/></div>
        <div class="detailList text-left">
            <li style="font-weight:500;font-size:1.2em">{{selectedAppointment.patient.first_name}} {{selectedAppointment.patient.last_name!=='undefined'?selectedAppointment.patient.last_name:''}}</li>
            <li style="padding-top:3px;font-size:0.9em"> MIN : {{selectedAppointment.patient.mrn | nricMask}} </li>
            <li style="padding-top:3px;font-size:0.9em"> GENDER: {{selectedAppointment.patient?(selectedAppointment.patient.gender=='M'?'Male':'Female'):''}}</li>
            <li style="padding-top:3px;font-size:0.9em"> AGE : {{selectedAppointment.age}} years Old</li>
            <li style="padding-top:3px;font-size:0.9em"> DOB : {{selectedAppointment.patient.date_of_birth}}</li>
            <!-- <li style="padding-top:3px;font-size:0.9em">Sanfransisco</li> -->
        </div>
        <div class="viewFull">
            <div style="padding-top:15px;font-size:0.9em;cursor:pointer"><div class="closeIcon" (click)="closeDialog()">X</div></div>
        <div>
            <!-- <div> -->
            <div *ngIf="selectedAppointment.appointment_status=='SE' && selectedAppointment.doctor_id==loggedinUser" class="checkin" (click)="notesClick()">View Record</div>
            <div *ngIf="selectedAppointment.questionnaire_status=='CO' && selectedAppointment.appointment_status!=='SE' && selectedAppointment.doctor_id==loggedinUser" class="checkin" (click)="notesClick()">Start Consultation</div>
            <div *ngIf="selectedAppointment.questionnaire_status=='ST' && access?.Record_option.write" class="checkin" (click)="questionnaireClick()">Check In</div>
        </div>
        </div>
    </div>
    <div class="detailContent card">
        <div *ngIf="selectedAppointment.questionnaire_status=='ST'" class="noAppointment">Questionnaire is not filled for this appointment</div>    
        <div *ngIf="selectedAppointment.questionnaire_status=='CO'">
            <ol *ngIf="qFilled" style="margin-top:2vh">
                <li *ngFor="let qa of selectedQuestionnaire" style="padding-top:10px;">
                    <span style="font-size:1.1em;font-weight:500">{{qa.question}} : </span>
                    <span style="color:red;font-size:1.2em;font-weight:500">{{qa.answer}}</span>
                </li>
            </ol>
            <div *ngIf="!qFilled" class="noAppointment">Questionnaire part is skipped from patient</div>    
        </div>
    </div>
</div>