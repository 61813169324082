import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.css']
})
export class NotesListComponent implements OnInit {
  public appointmentData:any=[];
  public upcomingData:any=[];
  public completedData:any=[];
  public checkedinData:any=[];
  fromDate:any;
  toDate:any;
  constructor(public appService:AppointmentService,private router: Router) { }

  ngOnInit(): void {
    var d = new Date();
    let prevMonth =(d.getMonth() + 1);
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    this.toDate=prevMonth+"-"+prevDay+"-"+prevYear;
    d = new Date(d.setMonth(d.getMonth() - 1));
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    this.fromDate=month+"-"+day+"-"+year;
    this.loadAppointmentList();
  }
  closeNotes(){
    this.appService.notesClick(true);
  }
  dateRangeChange(dateRangeStart: HTMLInputElement,  dateRangeEnd: HTMLInputElement) {
    var fromdate= new Date(dateRangeStart.value);
    this.fromDate=(fromdate.getMonth()+1)+"-"+fromdate.getDate()+"-"+fromdate.getFullYear();
    var todate=new Date(dateRangeEnd.value);
    this.toDate=(todate.getMonth()+1)+"-"+todate.getDate()+"-"+todate.getFullYear();
    this.loadAppointmentList();
  }

  loadAppointmentList(){
    let appParams={
      appointment_start_date:this.fromDate,
      appointment_end_date:this.toDate
    }
    this.appService.getAppointmentList(appParams).subscribe((response) => {
      if (response) {
        console.log(response)
        let appData:any=response;
        if(appData.length>0){
          this.appointmentData=appData;
          this.appService.changeAppCount(this.appointmentData.length)
          // this.appointmentData.map(x=>{
          //   x.selected=false;
          //   x.age=this.year-parseInt(x.patient.date_of_birth.split("-")[0]);
          // })
          this.completedData=this.appointmentData.filter(x=>(x.appointment_status== "SE"));
          this.upcomingData=this.appointmentData.filter(x=>x.appointment_status== "UP");
          this.checkedinData=this.appointmentData.filter(x=>x.appointment_status== "CI");
          //this.appointmentData[0].selected=true;
          //this.selectedAppointment=this.appointmentData.find(x=>x.selected);
          console.log(this.appointmentData);
          console.log(this.completedData);
          console.log(this.upcomingData);
          console.log(this.checkedinData);
        } else {
          this.appointmentData=[];
        }
      }
    }, (error) => {
      console.log(error)
        //this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }
  selectNotes(app){
    console.log(app)
    if(app.appointment_status=="SE"||app.appointment_status=="CI"){
      this.router.navigate(['/notes'], { queryParams: { id: app.id } });
    }
  }
}
