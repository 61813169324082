<div class="totalContainer">
    <div class="row appointment-cotainer" style="margin-right:0px;margin-left:0px;max-height: 100vh;position: fixed;width: 100vw;">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12" style="margin-right:0px;margin-left:0px;padding:0px">
            <app-left-panel></app-left-panel>
        </div>
        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12" style="height:94vh;padding:0px;margin-right:0px;margin-left:0px;">
        <app-common-header></app-common-header>
        <app-user-appointment *ngIf="isDashboard"></app-user-appointment>
        <app-notes-list *ngIf="isNotes && !isPatient"></app-notes-list>
        <app-patient-records *ngIf="isPatient"></app-patient-records>
        <app-questionnaire *ngIf="isquestionnaire" ></app-questionnaire>
        <app-report *ngIf="isReport" [selectedReport]="selectedReport"></app-report>
        <app-refund-requests *ngIf="isRefund"></app-refund-requests>
        <app-doctor-management *ngIf="isDoctorMgmt"></app-doctor-management>
        <app-dashboard *ngIf="!isRefund && !isDoctorMgmt && !isNotes && !isPatient && !isquestionnaire && !isReport && !isDashboard"></app-dashboard>
        </div>
    </div>
    </div>
    <!-- <div class="row container-fluid appointment-cotainer"  *ngIf="isAdmin">
        <div class="col-12">
            <app-admin-page></app-admin-page>
        </div>
    </div> -->
    
    