<app-appointment-landing *ngIf="showLanding" (editApmt)="editAppointment($event)"></app-appointment-landing>
<div *ngIf="!showLanding" class="newAppoint">
    <div class="closeIcon" (click)="closeAppointment()">X</div>
    <div class="appContainer">
      <mat-horizontal-stepper #stepper>
        <mat-step label="Patient" state="phone">
            <div *ngIf="newAppointment" class="patientDetails">
                <div *ngIf="tilePage" class="row align-items-center tileSection">
                  <div class="col-sm-6">
                    <div class="tile purple" (click)="newUserClick()">
                      <h3 class="title">New User</h3>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="tile red" (click)="existingUserClick()">
                      <h3 class="title">Existing User</h3>
                    </div>
              </div></div>
              <div *ngIf="existingUser" class="searchDiv">
              <div class="headerCont">
                    <h1><strong>New Appointment</strong></h1>
                    <p>Search by either <strong>Phone number</strong> or <strong> Mail Id</strong></p>
                </div>
                <br>
                <div>
                  <mat-label class="example-container"><span class="material-icons">
                      phone
                  </span>Phone</mat-label>
                <br>
                  <mat-form-field class="example-full-width" [style.width.px]=120 appearance="outline">
                    <input type="text"
                           placeholder="Country"
                           aria-label="Number"
                           matInput
                           [(ngModel)]="MRNData.phone_number_prefix"
                           [formControl]="myControl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getPosts($event.option.value)'>
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.dial_code">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>&nbsp;
                <mat-form-field class="example-container"  appearance="outline" [style.width.px]=230>
                  <span></span>
                  <span><input matInput placeholder="Phone Number" [(ngModel)]="MRNData.phone_number" ></span>
                </mat-form-field>
              </div>
                
                <div style="text-align: left;padding-left: 30%;"><h2>Or</h2></div>
              
                <div>
                  <mat-label class="example-container"><span class="material-icons">
                      mail
                  </span>Email</mat-label>
                <br>
                <mat-form-field class="example-container" appearance="outline" [style.width.px]=350>
                  <input matInput placeholder="Email" type="email" [(ngModel)]="MRNData.email">
                </mat-form-field>
              </div>
          <br>
          <div class="content">
            <button  class="btn ml-4" style="margin-right: 40%;" (click)="onSearch()">Search</button>
          </div> 
          </div>
              
          </div>
          <div *ngIf="patientNotFound">
            <div class="content">
                <h1><strong>{{newUser?'New User':headerText}}</strong></h1>
                <p *ngIf="headerText=='Patient Not Found'">Please add this patient to medosys</p>
            </div>
            <br>
            <div class="patientDetails">
            <table style="width:100%">
            <tr>
              <td>
            <mat-label class="example-container"><span class="material-icons">
              person
              </span>First Name</mat-label>
            <br>
            <mat-form-field class="example-container" appearance="outline">
              <input matInput [(ngModel)]="MRNData.first_name" [disabled]="edited || headerText !=='Patient Not Found'">
            </mat-form-field>
            <td>
              <mat-label class="example-container"><span class="material-icons">
                person
                </span>Second Name</mat-label>
              <br>
            <mat-form-field  class="example-container"appearance="outline">
              <input matInput [(ngModel)]="MRNData.middle_name" [disabled]="edited || headerText !=='Patient Not Found'">
            </mat-form-field>
            </td>
            <td>
              <mat-label class="example-container"><span class="material-icons">
                person
                </span>Last Name</mat-label>
              <br>
            <mat-form-field  class="example-container"appearance="outline">
              <input matInput [(ngModel)]="MRNData.last_name" [disabled]="edited || headerText !=='Patient Not Found'">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
    <mat-label class="example-container"><span class="material-icons">
      open_in_browser
      </span>NRIC / FIN / PASSPORT</mat-label>
      <br>
      <mat-form-field class="example-container" appearance="outline">
        
        <mat-select [(ngModel)]="MRNData.idType" (click)="getType(MRNData.idType)"[disabled]="edited || headerText !=='Patient Not Found'">
          <mat-option *ngFor="let id of IDType" [value]="id.value">
            {{id.viewValue}}
          </mat-option>
        
        </mat-select>
      </mat-form-field>
      </td>
      <td>
      <br>
      <mat-form-field *ngIf="headerText =='Patient Not Found'" class="example-container"  appearance="outline">
        <input matInput [placeholder]="MRNData.idType=='1'?'Enter medical record No':'Enter the passport No'" 
        [(ngModel)]="MRNData.mrn" [disabled]="edited || headerText !=='Patient Not Found'" (change)="mrnValidation()" style="text-transform: uppercase;">
      </mat-form-field>
      <mat-form-field *ngIf="edited || headerText !=='Patient Not Found'" class="example-container"  appearance="outline">
        <input matInput placeholder="Enter the identification number"  (change)="mrnValidation()"
        [value]="mask(MRNData.mrn)" [disabled]="edited || headerText !=='Patient Not Found'" style="text-transform: uppercase;">
      </mat-form-field><br>
     <label *ngIf="validMRN=='true' && MRNData.mrn !==null" class="error">* Please Enter valid {{MRNData.idType=='1'?'NRIC':'Passport'}} number</label>
      </td>
      <td><mat-label class="example-container"><span class="material-icons">
        calendar_today
        </span>DOB</mat-label><br>
        <mat-form-field appearance="outline" [style.width.px]=200>
          <input  matInput #ref [matDatepicker]="picker" [value]="dob" (dateChange)="MRNDateFormat(ref.value)" [disabled]="edited || headerText !=='Patient Not Found'">
          <mat-datepicker-toggle matSuffix [for]="picker" style="color:#0984A8;font-size:1em"></mat-datepicker-toggle>
          <mat-datepicker #ref #picker></mat-datepicker>
        </mat-form-field>
      </td>
      </tr>
            <tr>
              
              <td><mat-label class="example-container"><span class="material-icons">
                female
              </span>Gender</mat-label>
              <br>
              <mat-form-field class="example-container" appearance="outline">
                <mat-select [(ngModel)]="MRNData.gender" [disabled]="edited || headerText !=='Patient Not Found'">
                  <mat-option *ngFor="let gender of genders" [value]="gender.value">
                    {{gender.viewValue}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
              </td>
              <td>
                <mat-label class="lbl2"><span class="material-icons">
                  phone
                  </span>Phone</mat-label><br>
                  <mat-form-field class="example-full-width" appearance="outline" [(ngModel)]="MRNData.phone_number" [style.width.px]=80>
                    <input type="text"
                           placeholder="Country"
                           aria-label="Number"
                           matInput
                           [(ngModel)]="MRNData.phone_number_prefix"
                           [formControl]="myControl"
                           [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getPosts($event.option.value)'>
                      <mat-option  [disabled]="edited || headerText !=='Patient Not Found'" *ngFor="let option of filteredOptions | async" [value]="option.dial_code">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field class="example-container"  appearance="outline" [style.width.px]=133>
                    <span><input matInput placeholder="Phone Number" [disabled]="edited || headerText !=='Patient Not Found'" [(ngModel)]="MRNData.phone_number" ></span>
                  </mat-form-field>
              </td>
              <td>
                <mat-label class="example-container"><span class="material-icons">
                  mail
                  </span>Email</mat-label>
                <br>
                <mat-form-field class="example-container" appearance="outline">
                  <input matInput placeholder="Enter your email id" type="email" [disabled]="edited || headerText !=='Patient Not Found'" [(ngModel)]="MRNData.email">
                </mat-form-field>
                <div class="d-inline p-3">
                <button class="btn content" *ngIf="isgenerate==false" (click)="generateEmail()">Generate Email</button>
              </div>
              </td>
            </tr>
                <tr>
                  <td>
                    <label>Marital Status</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                    <mat-select [(ngModel)]="MRNData.marital_status" [disabled]="edited || headerText !=='Patient Not Found'">
                      <mat-option *ngFor="let mStatus of mStatus" [value]="mStatus.value">
                        {{mStatus.viewValue}}
                      </mat-option>
                    </mat-select>
                    </mat-form-field>
                  </td>
                  <td>
                    <label style="margin-left:15px">Children</label>
                    <br>
                    <div class="radioButtonPad">
                    <mat-radio-group  aria-label="Select an option" [(ngModel)]="MRNData.children" [disabled]="edited || headerText !=='Patient Not Found'">
                      <mat-radio-button value="Y">Yes</mat-radio-button>
                      <mat-radio-button style="padding-left:15px" value="N">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  </td>
                  <td>
                    <label>Blood Group</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                    <mat-select [(ngModel)]="MRNData.blood_group" [disabled]="edited || headerText !=='Patient Not Found'">
                      <mat-option *ngFor="let bGroup of bGroup" [value]="bGroup.value">
                        {{bGroup.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>Weight (kg)</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                      <input matInput placeholder="Weight" [(ngModel)]="MRNData.weight" [disabled]="edited || headerText !=='Patient Not Found'">
                    </mat-form-field>
                  </td>
                  <td>
                    <label>Height (m)</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                      <input matInput placeholder="Height" [(ngModel)]="MRNData.height" [disabled]="edited || headerText !=='Patient Not Found'">
                    </mat-form-field>
                  </td>
                  <td>
                    <label>BMI (kg/m2)</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                      <input matInput placeholder="BMI" [(ngModel)]="MRNData.bmi" [disabled]="edited || headerText !=='Patient Not Found'">
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>BF Percentage %</label>
                    <br>
                    <mat-form-field class="example-container" appearance="outline">
                      <input matInput placeholder="BF %" [(ngModel)]="MRNData.bf_percentage" [disabled]="edited || headerText !=='Patient Not Found'">
                    </mat-form-field>
                  </td>
                  <td>
                    <label style="margin-left:15px">Smoker</label>
                    <br>
                    <div class="radioButtonPad">
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="MRNData.smoker" [disabled]="edited || headerText !=='Patient Not Found'">
                      <mat-radio-button value="Y">Yes</mat-radio-button>
                      <mat-radio-button style="padding-left:15px" value="N">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  </td>
                  <td>
                    <label style="margin-left:15px">Allergies</label>
                    <br>
                    <div class="radioButtonPad">
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="MRNData.allergies" [disabled]="edited || headerText !=='Patient Not Found'">
                      <mat-radio-button value="1">Yes</mat-radio-button>
                      <mat-radio-button style="padding-left:15px" value="2">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  </td>
                </tr>
                <tr>
              </table>
            </div>
            <div class="content">
                <button class="btn mt-4 ml-4 content" (click)="createMRN(stepper)">{{edited?"Next":"Create"}}</button>
            </div>
        </div>
        </mat-step>

        <mat-step label="Availability" state="chat">
            <div class="content">
                <div class="container-fluid">
                  <div class="row">
                    <!-- <div class="col-1"></div> -->
                    <div class="col-12 p-2 bg-light">
                      <ul class="nav nav-tabs nav-fill" id="ex1" role="tablist">
                        <li class="align-self-center px-4" style="font-weight: bold;">
                          <div class="row flex-column">
                           <div><b>Select Day</b></div>
                           <mat-form-field style="font-size:2em;width:0px;float: left;vertical-align: top;margin-top:-36px;height: 65px;">
                            <input  matInput #ref [matDatepicker]="picker" [min]="today" placeholder="Date" [(ngModel)]="pickedDate" (dateChange)="dateChange(ref.value)">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #ref (opened)="toggle(ref)" (closed)="toggle(ref)" #picker></mat-datepicker>
                          </mat-form-field>
                          </div>
                        </li>
                        <div class="nav-item font-weight-bold pt-4 currDate">{{selectedDate}}</div>
                        <div class="triangle-right"></div>
                        <li class="nav-item" role="presentation" *ngFor="let i of timeSlot; let a = index">
                          <a
                            class="nav-link"
                            [ngClass]="i.active?'active':''"
                            [id]="a+1"
                            data-bs-toggle="tab"
                            (click)="getDate(i.value)"
                            role="tab"
                            aria-controls="ex2-tabs-1"
                            aria-selected="true"
                            >{{i.date}}  {{i.day}}</a
                          >
                        </li>
                        <li class="align-self-center">
                          <mat-select [(ngModel)]="selectedProvider" style="padding-block:5px;background: linear-gradient(#0984A8, #1FAE79);color: white!important;font-weight: bold;"  (selectionChange)="onProviderChange($event)">
                            <mat-option  *ngFor="let select of providers" [value]="select.id"><b><span *ngIf="select.first_name!=='All-Doctors'">Dr. </span><span class="fname">{{select.first_name}}</span></b><div *ngIf="select.first_name!=='All-Doctors'" [ngClass]="select.online?'online':'offline'"></div></mat-option>
                     </mat-select>
                        </li>
                      </ul>
                    </div>
                    <div class="col-sm-6">
                      <div class="bg-grey"></div>
                    </div>
                    <!-- <div class="col-5"><label><h1><strong>{{textDate}}</strong></h1></label></div> -->
                    <!-- <div class="col-3"></div> -->
                    <!-- <div class="col-2" style="float:right">
                    <mat-select [(ngModel)]="selectedProvider" style="padding-block:5px;background: linear-gradient(#0984A8, #1FAE79);color: white!important;font-weight: bold;"  (selectionChange)="onProviderChange($event)">
                       <mat-option  *ngFor="let select of providers" [value]="select.id"><b><span *ngIf="select.first_name!=='All-Doctors'">Dr. </span><span class="fname">{{select.first_name}}</span></b><div *ngIf="select.first_name!=='All-Doctors'" [ngClass]="select.online?'online':'offline'"></div></mat-option>
                </mat-select></div> -->
            </div>
                </div>
            <div class="container-fluid selectTime">
              <div class="row mb-3">
                  <div class="col-sm-12"><h3 class="text-left mb-0"><b>Morning</b></h3></div>
                  
                  <div *ngFor="let time of morningTime;let i = index" style="padding-block:3vh;margin-right: 15px;">
                      <!-- <div class="chkBox"><label>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                    <input type="checkbox" *ngIf="!time.disable" [checked]="time.checked" (change)="checkBoxChange(time,'AM')"/>
                    <input type="checkbox" *ngIf="time.disable" disabled [checked]="time.checked"/>
                    <span></span>
                </label></div> -->
                      <div class="timeBox" [ngClass]="{'selected': morningSelectedElementId === i,'disableCursor': time.disable ==true}">
                          <!-- <div class="material-icons" style="font-size:1.8em;display:inline-block;">perm_identity</div> -->
                          <div style="vertical-align: middle;display:inline-block" (click)="onChangeMorValue(time.time,i)" *ngIf="checkAfter12(time.time) < 12">{{time.time +' AM'}}</div>
                          <div style="vertical-align: middle;display:inline-block" (click)="onChangeMorValue(time.time,i)" *ngIf="checkAfter12(time.time) >= 12">{{time.time +' PM'}}</div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-12"><h3 class="text-left mb-0"><b>Aternoon</b></h3></div><br><br>&nbsp;&nbsp;
                  <div *ngFor="let time of anoonTime;let i = index" style="padding-block:3vh;margin-right:15px;">
                      <!-- <div class="chkBox"><label>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                    <input type="checkbox" *ngIf="!time.disable" [checked]="time.checked" (change)="checkBoxChange(time,'PM')"/>
                    <input type="checkbox" *ngIf="time.disable" disabled [checked]="time.checked"/>
                    <span></span>
                </label></div> -->
                      <!-- <div class="rowspan-4"> -->
                          <div class="timeBox" [ngClass]="{'selected': noonSelectedElementId === i ,'disableCursor': time.disable ==true}">
                              <!-- <div class="material-icons" style="font-size:1.8em;display:inline-block;">perm_identity</div> -->
                              <div style="vertical-align: middle;display:inline-block" (click)="onChangeNoonValue(time.time,i)">{{time.time+' PM'}}</div>
                          </div>
                      <!-- </div> -->
                  </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-12"><h3 class="text-left mb-0"><b>Evening</b></h3></div><br> &nbsp;
                  <div *ngFor="let time of eveningTime; let i = index" style="padding-block:3vh;;margin-right: 15px;">
                      <!-- <div class="chkBox"><label>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                        <input type="checkbox" *ngIf="!time.disable" [checked]="time.checked" (change)="checkBoxChange(time,'PM')"/>
                    <input type="checkbox" *ngIf="time.disable" disabled [checked]="time.checked"/>
                    <span></span>
                </label></div> -->
                      <!-- <div class="time"> -->
                          <div class="timeBox" [ngClass]="{'selected': eveningSelectedElementId === i, 'disableCursor': time.disable ==true}">
                              <!-- <div class="material-icons" style="font-size:1.8em;display:inline-block;">perm_identity</div> -->
                              <div style="vertical-align: middle;display:inline-block" (click)="onChangeEveValue(time.time,i)">{{time.time+' PM'}}</div>
                          </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
          <div class="content mt-4 ml-4">
            <button class="btn1" matStepperPrevious>Back</button>
            <button class="btn ml-4" (click)="confirmAppointment(stepper)">Next</button>
          </div>
        </mat-step>
        <mat-step label="Confirmation">
        <div class="content" style="margin: 0;">
          <h1 style="font-weight: 500;">Success</h1>
          <div style="display: flex; align-items: center;justify-content: center;flex-direction: column;">
            <div >
              <img style="width: 200px;height: 200px;border-radius: 50%;" 
                [src]="appointmentData?.patient?.avator_url ? appointmentData?.patient?.avator_url : '../../../assets/images/avator.JPG'" alt="userName">
            </div>
            <h3 class="h1-label" style="margin-top: 5px;">{{appointmentData?appointmentData.patient.first_name:""}}</h3>
            <h3>{{appointmentData?appointmentData.doctor.first_name:""}} is schedule to see <b>{{appointmentData?appointmentData.patient.first_name:""}}</b> on
              <b>{{appointmentData?.appointment_date.split(" ")[0]||""}} at {{appointmentData?.appointment_time||""}} 
              </b>
            </h3>
            <div sty class="finalText">An email confirmation has been sent to <a>{{appointmentData?appointmentData.patient.email:""}}</a></div> 
            <button style="margin-top: 10px;" class="btn ml-4" (click)="doneAppointment()">Done</button>
          </div>
        </div>
        </mat-step>
      
        <!-- Icon overrides. -->
        <ng-template matStepperIcon="phone">
          <mat-icon>perm_identity</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="chat">
          <mat-icon>event_note</mat-icon>
        </ng-template>
      </mat-horizontal-stepper>
    </div>
    </div>
