<div class="appLanding" *ngIf="access?.Appointment_landing.read">
  <div class="appOverview row">
    <div class="col-12 head">Appointments Overview</div>
  </div>
  <div class="row px-5 my-3">
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber">{{ totalAppmnt.length }}</div>
        <div class="dText">Today's total appointments</div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber">{{ seenAppmnt.length }}</div>
        <div class="dText">Today's completed appointments</div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber">{{ upcAppmnt.length }}</div>
        <div class="dText">Today's upcoming appointments</div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber">{{ checkedInAppmnt.length }}</div>
        <div class="dText">Today's checked-in appointments</div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber">{{ missedAppmt.length }}</div>
        <div class="dText">Today's missed appointments</div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard">
        <div class="dNumber" style="color: #ffa726">
          {{ totalUnpaidAppointments.length }}
        </div>
        <div class="dText" style="color: #ffa726">
          Total Unpaid appointments
        </div>
      </div>
    </div>
    <div class="col-xl col-lg col-md-2 col-sm-6 col-xs-6 col-6">
      <div class="detailsCard newAppmnt" (click)="createNewAppointment()">
        <img
          src="../../../assets/images/appointments.png"
          alt="New Appointment"
          style="width: 50px; height: 50px"
        />
        <div class="dText">Create new appointment</div>
      </div>
    </div>
  </div>
  <div class="row appOverview">
    <div class="col-12 head">Appointments</div>
    <div class="daysList">
      <ul>
        <li
          *ngFor="let day of weekArray"
          [ngClass]="day.selected ? 'daySelect' : 'daynonSelect'"
          (click)="dayChange(day)"
        >
          {{ day.day }}
        </li>
      </ul>
    </div>
  </div>
  <div class="firstDiv">
    <div class="appointmentList">
      <div class="dateCont">
        <div class="date">
          <div class="day">
            <label class="dayNum">{{ dayNum }}</label>
          </div>
          <div class="month">
            <div>
              <label>{{ month }}</label>
            </div>
            <div>
              <label>{{ year }}</label>
            </div>
          </div>
          <div class="dayText">
            <label>{{ day }}</label>
          </div>
        </div>
        <div class="calendar">
          <div class="todayLabel" *ngIf="pickedDate == todayString">Today</div>
          <mat-form-field
            style="
              display: inline-block;
              font-size: 2em;
              width: 0px;
              float: left;
              vertical-align: top;
              margin-top: -10px;
            "
          >
            <input
              matInput
              #ref
              [matDatepicker]="picker"
              placeholder="Date"
              [(ngModel)]="pickedDate"
              (dateChange)="dateChange(ref.value)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              style="color: #0984a8; font-size: 2em"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #ref
              (opened)="toggle(ref)"
              (closed)="toggle(ref)"
              #picker
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="appCardCont" *ngIf="access?.Appointment_list.read">
        <div
          *ngFor="let appmt of appointmentData"
          class="card"
          [ngStyle]="{
            'border-left':
              appmt.appointment_status == 'UP'
                ? '5px solid rgb(247, 142, 43)'
                : appmt.appointment_status == 'CI'
                ? '5px solid #2292FF'
                : appmt.appointment_status == 'SE'
                ? '5px solid green'
                : appmt.appointment_status == 'MI'
                ? '5px solid red'
                : '5px solid red'
          }"
        >
          <table style="width: 100%">
            <tr>
              <td style="width: 25%">
                <img
                  [src]="
                    appmt.patient.avator_url
                      ? appmt.patient.avator_url
                      : '../../../assets/images/avator.JPG'
                  "
                  alt="userName"
                  style="width: 60px; height: 50px; border-radius: 50%"
                />
              </td>
              <td
                *ngIf="appmt.patient.gender == 'F'"
                class="name"
                style="width: 55%"
              >
                <label
                  style="
                    font-weight: bold;
                    font-size: 1.2em;
                    padding-left: 10px;
                  "
                  >Mrs. {{ appmt.patient.first_name }}
                  {{ appmt.patient.last_name }}</label
                >
              </td>
              <td
                *ngIf="appmt.patient.gender == 'M'"
                class="name"
                style="width: 55%"
              >
                <label
                  style="
                    font-weight: bold;
                    font-size: 1.2em;
                    padding-left: 10px;
                  "
                  >Mr. {{ appmt.patient.first_name }}
                  {{ appmt.patient.last_name }}</label
                >
              </td>
              <td style="width: 20%">
                <mat-icon
                  style="color: black; top: 0px"
                  *ngIf="appmt.appointment_from == 'W'"
                  class="systemIcon ml-2"
                  title="Created from Web"
                  >computer</mat-icon
                >
                <mat-icon
                  style="color: black; top: 0px"
                  *ngIf="appmt.appointment_from == 'M'"
                  class="systemIcon ml-2"
                  title="Created from Mobile-App"
                  >mobile_screen_share</mat-icon
                >
                <mat-icon
                  style="top: 0px"
                  *ngIf="
                    access?.Edit_access_of_appointment.write &&
                    appmt.appointment_status !== 'SE'
                  "
                  (click)="editAppointment(appmt)"
                  class="editAppIcon ml-2"
                  >edit</mat-icon
                >
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div style="width: 100%; padding-top: 5px">
                  <div style="width: 50%; display: flex; float: left">
                    <mat-icon style="font-size: 1.5em">person_add</mat-icon>
                    <label
                      class="appointment-dr-name pl-2"
                      style="
                        color: black;
                        font-size: 0.9em;
                        font-weight: 500;
                        padding-top: 7px;
                      "
                      >Dr. {{ appmt.doctor.first_name }}</label
                    >
                  </div>
                  <div
                    class="pt-1"
                    style="
                      width: 50%;
                      display: inline-block;
                      float: right;
                      text-align: right;
                    "
                  >
                    <span
                      ><mat-icon style="font-size: 1.2em">today</mat-icon></span
                    ><label
                      class="pl-2"
                      style="color: black; font-size: 0.9em; font-weight: 500"
                      >{{
                        appmt.appointment_date.split(" ")[0]
                          | date : "mediumDate"
                      }}
                      &nbsp; {{ appmt.appointment_time }}</label
                    >
                  </div>
                </div>
              </td>
              <!-- <td><mat-icon style="font-size:1.2em;">access_alarm</mat-icon><label  style="color:black;font-size:0.9em;font-weight: 500;margin-top:15px;"></label></td> -->
            </tr>
            <tr>
              <td colspan="2" style="padding-bottom: 10px">
                <button
                  style="float: left"
                  [ngStyle]="{
                    background:
                      appmt.payment_status !== 'SU' &&
                      appmt.payment_status !== 'IN'
                        ? 'rgb(248, 103, 103)'
                        : ''
                  }"
                  class="viewBtn"
                  (click)="selectPayment(appmt)"
                >
                  {{
                    appmt.payment_status == "IN"
                      ? "Payment: Paid"
                      : appmt.payment_status == "SU"
                      ? "Payment: Paid"
                      : "Payment: Unpaid"
                  }}
                </button>
              </td>
              <td
                *ngIf="appmt.appointment_status != 'SE'"
                style="padding-left: 40px; padding-bottom: 10px"
              >
                <button class="viewBtn" (click)="selectAppointment(appmt)">
                  Action
                </button>
              </td>
              <td
                *ngIf="appmt.appointment_status == 'SE'"
                style="padding-left: 40px; padding-bottom: 10px"
              >
                <button class="viewBtn" (click)="selectAppointment(appmt)">
                  View
                </button>
              </td>
            </tr>
            <tr style="border-top: 1px solid black; padding-top: 15px">
              <td *ngIf="appmt.appointment_status == 'UP'">
                <label
                  style="
                    color: #0984a8;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid #0984a8;
                    border-radius: 5px;
                  "
                  >Upcoming</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'CI'" class="checked-in">
                <label
                  style="
                    border: 2px solid rgb(10, 10, 94);
                    color: rgb(10, 10, 94);
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border-radius: 5px;
                  "
                  >Checked-In</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'SE'">
                <label
                  style="
                    color: green;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid green;
                    border-radius: 5px;
                  "
                  >Completed</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'CA'">
                <label
                  style="
                    color: red;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid red;
                    border-radius: 5px;
                  "
                  >Cancel</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'MI'">
                <label
                  style="
                    color: red;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid red;
                    border-radius: 5px;
                  "
                  >Missed</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == null"></td>
              <td colspan="2">
                <b>Payment Status :</b>
                {{
                  appmt.payment_status == "IN"
                    ? "Paid"
                    : appmt.payment_status == "SU"
                    ? "Paid"
                    : "Unpaid"
                }}, <b>Appointment Status :</b>
                {{
                  appmt.appointment_status == "UP"
                    ? "Appointment is booked"
                    : appmt.appointment_status == "CI"
                    ? "Questionnaire is completed / skipped, Appointment is confirmed"
                    : appmt.appointment_status == "SE"
                    ? "Doctor consultation is completed, and notes are saved"
                    : appmt.appointment_status == "CA"
                    ? "Cancelled"
                    : appmt.appointment_status == "MI"
                    ? "Patient missed the appointment / appointment not yet completed"
                    : "Not Started"
                }}
              </td>
            </tr>
          </table>
        </div>
        <div
          *ngIf="appointmentData.length == 0"
          style="
            text-align: center;
            padding-inline: 20%;
            padding-block: 10%;
            color: red;
            font-size: 1.2em;
            font-weight: 500;
          "
        >
          No appointments available for this doctor
        </div>
      </div>
    </div>
  </div>
  <div class="secondDiv">
    <div class="row">
      <div class="col-6 head">Previous Appointments</div>
      <div class="col-6" style="text-align: right">
        <mat-icon
          [ngClass]="isSort ? 'noSort' : 'sort'"
          (click)="sortAppointment()"
          >sort</mat-icon
        >
        <mat-form-field appearance="fill" class="drpicker">
          <mat-label><b>Date Range</b></mat-label>
          <mat-date-range-input [rangePicker]="picker1">
            <input
              matStartDate
              placeholder="Start date"
              #dateRangeStart
              [(ngModel)]="fDate"
            />
            <input
              matEndDate
              placeholder="End date"
              #dateRangeEnd
              [(ngModel)]="tDate"
              (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
            />
          </mat-date-range-input>
          <mat-icon matDatepickerToggleIcon (click)="clearDate($event)"
            >clear</mat-icon
          >
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
          <!-- <mat-datepicker-toggle matSuffix (click)="clearDate(dateRangeStart, dateRangeEnd)">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle> -->
        </mat-form-field>
      </div>
    </div>
    <div class="appCardCont" *ngIf="access?.Appointment_list.read">
      <div
        *ngFor="let appmt of appData"
        class="card"
        [ngStyle]="{
          'border-left':
            appmt.appointment_status == 'UP'
              ? '5px solid #0984A8'
              : appmt.appointment_status == 'CI'
              ? '5px solid rgb(14, 14, 92)'
              : appmt.appointment_status == 'SE'
              ? '5px solid green'
              : appmt.appointment_status == 'MI'
              ? '5px solid red'
              : '5px solid red'
        }"
      >
        <table style="width: 100%">
          <tr>
            <td style="width: 25%">
              <img
                [src]="
                  appmt.patient.avator_url
                    ? appmt.patient.avator_url
                    : '../../../assets/images/avator.JPG'
                "
                alt="userName"
                style="width: 60px; height: 50px; border-radius: 50%"
              />
            </td>
            <td
              *ngIf="appmt.patient.gender == 'F'"
              class="name"
              style="width: 55%"
            >
              <label style="font-weight: bold; font-size: 1.2em"
                >Mrs. {{ appmt.patient.first_name }}
                {{ appmt.patient.last_name }}</label
              >
            </td>
            <td
              *ngIf="appmt.patient.gender == 'M'"
              class="name"
              style="width: 55%"
            >
              <label style="font-weight: bold; font-size: 1.2em"
                >Mr. {{ appmt.patient.first_name }}
                {{ appmt.patient.last_name }}</label
              >
            </td>

            <td style="width: 20%">
              <mat-icon
                style="color: black; top: 0px"
                *ngIf="appmt.appointment_from == 'W'"
                class="systemIcon ml-2"
                title="Created from Web"
                >computer</mat-icon
              >
              <mat-icon
                style="color: black; top: 0px"
                *ngIf="appmt.appointment_from == 'M'"
                class="systemIcon ml-2"
                title="Created from Mobile-App"
                >mobile_screen_share</mat-icon
              >
              <mat-icon
                style="top: 0px"
                *ngIf="
                  access?.Edit_access_of_appointment.write &&
                  appmt.appointment_status !== 'SE'
                "
                (click)="editAppointment(appmt)"
                class="editAppIcon ml-2"
                >edit</mat-icon
              >
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div style="width: 100%; padding-top: 5px">
                <div style="width: 50%; display: flex; float: left">
                  <mat-icon style="font-size: 1.5em">person_add</mat-icon>
                  <label
                    class="appointment-dr-name pl-2"
                    style="
                      color: black;
                      font-size: 0.9em;
                      font-weight: 500;
                      padding-top: 7px;
                    "
                    >Dr. {{ appmt.doctor.first_name }}</label
                  >
                </div>
                <div
                  class="pt-1"
                  style="
                    width: 50%;
                    display: inline-block;
                    float: right;
                    text-align: right;
                  "
                >
                  <span
                    ><mat-icon style="font-size: 1.2em">today</mat-icon></span
                  ><label
                    class="pl-2"
                    style="color: black; font-size: 0.9em; font-weight: 500"
                    >{{
                      appmt.appointment_date.split(" ")[0] | date : "mediumDate"
                    }}
                    &nbsp; {{ appmt.appointment_time }}</label
                  >
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding-bottom: 10px">
              <button
                style="float: left"
                [ngStyle]="{
                  background:
                    appmt.payment_status !== 'SU' &&
                    appmt.payment_status !== 'IN'
                      ? 'rgb(248, 103, 103)'
                      : ''
                }"
                class="viewBtn"
                (click)="selectPayment(appmt)"
              >
                {{
                  appmt.payment_status == "IN"
                    ? "Payment: Paid"
                    : appmt.payment_status == "SU"
                    ? "Payment: Paid"
                    : "Payment: Unpaid"
                }}
              </button>
            </td>
            <td
              *ngIf="appmt.appointment_status != 'SE'"
              style="padding-left: 40px; padding-bottom: 10px"
            >
              <button class="viewBtn" (click)="selectAppointment(appmt)">
                Action
              </button>
            </td>
            <td
              *ngIf="appmt.appointment_status == 'SE'"
              style="padding-left: 40px; padding-bottom: 10px"
            >
              <button class="viewBtn" (click)="selectAppointment(appmt)">
                View
              </button>
            </td>
          </tr>
          <tr style="border-top: 1px solid black; padding-top: 15px">
            <td *ngIf="appmt.appointment_status == 'UP'">
              <label
                style="
                  color: #0984a8;
                  font-size: 0.9em;
                  font-weight: 500;
                  padding: 4px;
                  margin-top: 25px;
                  border: 2px solid #0984a8;
                  border-radius: 5px;
                "
                >Upcoming</label
              >
            </td>
            <td *ngIf="appmt.appointment_status == 'CI'" class="checked-in">
              <label
                style="
                  border: 2px solid rgb(10, 10, 94);
                  color: rgb(10, 10, 94);
                  font-size: 0.9em;
                  font-weight: 500;
                  padding: 4px;
                  margin-top: 25px;
                  border-radius: 5px;
                "
                >Checked-In</label
              >
            </td>
            <td *ngIf="appmt.appointment_status == 'SE'">
              <label
                style="
                  color: green;
                  font-size: 0.9em;
                  font-weight: 500;
                  padding: 4px;
                  margin-top: 25px;
                  border: 2px solid green;
                  border-radius: 5px;
                "
                >Completed</label
              >
            </td>
            <td *ngIf="appmt.appointment_status == 'CA'">
              <label
                style="
                  color: red;
                  font-size: 0.9em;
                  font-weight: 500;
                  padding: 4px;
                  margin-top: 25px;
                  border: 2px solid red;
                  border-radius: 5px;
                "
                >Cancel</label
              >
            </td>
            <td *ngIf="appmt.appointment_status == 'MI'">
              <label
                style="
                  color: red;
                  font-size: 0.9em;
                  font-weight: 500;
                  padding: 4px;
                  margin-top: 25px;
                  border: 2px solid red;
                  border-radius: 5px;
                "
                >Missed</label
              >
            </td>
            <td *ngIf="appmt.appointment_status == null"></td>
            <td colspan="2">
              <b>Payment Status :</b>
              {{
                appmt.payment_status == "IN"
                  ? "Paid"
                  : appmt.payment_status == "SU"
                  ? "Paid"
                  : "Unpaid"
              }}, <b>Appointment Status :</b>
              {{
                appmt.appointment_status == "UP"
                  ? "Appointment is booked"
                  : appmt.appointment_status == "CI"
                  ? "Questionnaire is completed / skipped, Appointment is confirmed"
                  : appmt.appointment_status == "SE"
                  ? "Doctor consultation is completed, and notes are saved"
                  : appmt.appointment_status == "CA"
                  ? "Cancelled"
                  : appmt.appointment_status == "MI"
                  ? "Patient missed the appointment / appointment not yet completed"
                  : "Not Started"
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
