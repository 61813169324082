<div class="container-fluid med-login">
<div class="row">
<div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 med-login-left"> 
    <div class="h1-label">medosys</div>
    <div class="h2-label">Medosys enables Doctors to focus on their patients and <br> take care of them with emotion and empathy</div>
    <div class="h3-label">Power of Voice - Medosys AI Solution</div>
    <div class="login-img"><img src="../../../assets/images/login_Image.svg" alt="Medosys.ai"></div>
</div>
<div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 med-login-right">
    <div class="login-side">
        <div class="login-logo">
            <img src="../../../assets/images/logo.JPG" alt="Medosys.ai">
        </div>
        <div class="login-label">medosys</div>
        <div style="margin-top:6vh">
            <span style="padding:0px;margin:0px 0px 0px 10px;"><mat-icon style="font-size:0.75em;color:white;height:0px;width:0px">email</mat-icon>
            <span class="loginInput-label">Email</span>
            </span>
            <input type="email" placeholder="enter your email here" [(ngModel)]="userMail" class="login-email"/>
            <label class="errorMsg" *ngIf="showError"> * Please enter the valid credentials</label>
        </div>
        <div style="margin-top:3vh">
            <span style="padding:0px;margin:0px 0px 0px 10px;"><mat-icon style="font-size:0.75em;color:white;height:0px;width:0px">lock</mat-icon>
            <span  class="loginInput-label">Password</span>
            </span>
            <input [type]="showpwd?'text':'password'" placeholder="enter your password" [(ngModel)]="password" class="login-password"/>
                <mat-icon *ngIf="!showpwd" style="vertical-align:middle;font-size:1.4em;cursor:pointer;color:grey;margin-left: -30px;margin-top:5px;" (click)="showPassword()">visibility</mat-icon>
                <mat-icon *ngIf="showpwd" style="vertical-align:middle;font-size:1.4em;cursor:pointer;color:grey;margin-left: -30px;margin-top:5px;" (click)="showPassword()">visibility_off</mat-icon>
            <label class="errorMsg" *ngIf="showError"> * Please enter the valid credentials</label>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div style="display: inline-block;width:30%;float:left;text-align: center;"><input style="font-size:0.9em;width:15px;height:15px;margin-top:10px;" (click)="onRememberMe()" [checked]="rememberMe" type="checkbox"/></div>
                    <div style="display: inline-block;width:70%;float:left;margin-top:8px"><label style="font-size:0.75em;" class="loginInput-label1">Remember Me</label></div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"><label style="margin-top:8px;font-size:0.75em;text-align: right;cursor:pointer" class="loginInput-label1" (click)="forgetPassword()"><a>forgot password?</a></label></div>
            </div>
        </div>
        <div style="margin-top:3vh">
            <button type="submit" (click)="login()" class="login-submit">Login</button>
        </div>
    </div>
</div>
</div>
</div>