<div style="width:98%;margin-inline: 1%;" *ngIf="isTenant">
  <div class="closeIcon" (click)="closeDialog()">X</div>
  <div style="margin-top:5vh;float:left;width:100%;">
    <div class="headContainer">
      <label style="width:40%;float:left;font-size:1.2em;padding:30px 20px 17px 20px;color:black;font-weight: 500;">Manage Tenants</label>
      <div style="padding:10px;padding-bottom:11px;width:10%;float:left;">
        <button mat-raised-button color="primary" (click)="addTenant()">Add</button></div>
      <div style="width:50%;float:right">
      <mat-form-field style="margin-right:5%;width:40%;float:right">
          <input matInput (keyup)="applyFilter($event)"
          style="color:grey;border-radius:25px;background:white;padding-block:10px;padding-left:20px;" #input1>
          <mat-placeholder class="placeholder">Search</mat-placeholder>
      </mat-form-field>
      </div></div>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="tenantTable">
      <!-- Table Columns -->
      <ng-container matColumnDef="rollNum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Si.Num </th>
        <td mat-cell *matCellDef="let i = index">
        {{this.paginator?.pageIndex == 0 ? i + 1 : 1 + i + this.paginator?.pageIndex * this.paginator?.pageSize}}
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container> -->
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Clinic Name </th>
        <td mat-cell *matCellDef="let row">
          <span style="text-transform: capitalize;">{{row.name}}</span></td>
      </ng-container>
       
      <ng-container matColumnDef="access">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch / City </th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.city}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant / Hospital </th>
        <td mat-cell *matCellDef="let row"> 
          <span>{{row.subdomain_prefix}}</span></td>
        
      </ng-container>

      <ng-container matColumnDef="zipCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip Code </th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.zipcode}}</span></td>
      </ng-container>
      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.country}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row">
          <span>{{row.active?"Active":"In-active"}}</span></td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
        <td mat-cell *matCellDef="let row">
            <span><mat-icon class="plus-icon" style="font-size:1.5em;cursor:pointer;color:green" (click)="editTenant(row)">edit</mat-icon></span>
            <span *ngIf="row.active"><mat-icon (click)="deleteTenant(row)" class="enableDel" title="Delete Tenant">delete</mat-icon></span>
            <span *ngIf="!row.active"><mat-icon class="disableDel" title="Tenant is In-active">delete</mat-icon></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="users">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User List </th>
        <td mat-cell *matCellDef="let row">
            <span><mat-icon style="font-size:1.5em;cursor:pointer;color:blue" (click)="showUserList(row)">supervisor_account</mat-icon></span>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">No data matching for "{{input.value}}"</td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div></div>
<app-user-list *ngIf="!isTenant" [tenantId]="selectedTenant" (backClick)="backClicked($event)"></app-user-list>
<!-- </div></div> -->
