import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-appointment-payment',
  templateUrl: './appointment-payment.component.html',
  styleUrls: ['./appointment-payment.component.css']
})
export class AppointmentPaymentComponent {
  consultationFee:number;
  governmentGrant:number;
  tax:number;
  taxPercent:number=0;
  selectedAppointmet:any;
  govtGrantFlag:string='normal';
  paymentMode:string='cash'
  speciality:any=[];
  totalFees:number=0;
  govtGrantAmount:number=0;
  pickedDateMM:string="";
  constructor(public adminService:AdminService,public appService:AppointmentService,
    public dialogRef:MatDialogRef<AppointmentPaymentComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    this.selectedAppointmet=this.data.userData;
    var d = new Date();
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    let hour=d.getHours();
    let mins=d.getMinutes();
    this.pickedDateMM=month+"-"+day+"-"+year+" "+hour+":"+mins;
    if(this.selectedAppointmet.payment_id==null){
      // this.getTenantDetails(this.selectedAppointmet.patient.tenant);
      this.getFeesdata(this.selectedAppointmet.doctor.id);
    } else {
      this.getFeesdata(this.selectedAppointmet.doctor.id);
      this.appService.getPayment(this.selectedAppointmet.payment_id).subscribe((response:any) => {
        if (response) {
          let res=response;
          this.govtGrantAmount=res.goverment_grand_amount;
          this.tax=res.tax_amount;
          this.totalFees=res.total_amount;
          this.consultationFee=res.doctor_charge;
          this.paymentMode=res.payment_from;
          this.govtGrantFlag=res.goverment_grand=='Y'?'grant':'normal';
          }
      }, (error) => {
        console.log(error)
      });
    }
  }
  // getTenantDetails(id){
  //   this.adminService.GetTenantData(id).subscribe((response:any) => {
  //     if (response) {
  //       let res=response;
  //       this.taxPercent=parseInt(res.tax_percentage);
  //       }
  //   }, (error) => {
  //     console.log(error)
  //   });
  // }
  getFeesdata(id){
    this.adminService.GetSpecificDoctor(id).subscribe((response:any) => {
      if (response) {
        let res=response;
        this.taxPercent=parseInt(res?.doctor_charge_ids?.tenant.tax_percentage);
        if(res?.doctor_charge_ids?.length==0||res?.doctor_charge_ids==null){
          this.consultationFee=0;
        } else {
          if(this.selectedAppointmet.specialities==null ||this.selectedAppointmet.specialities=="" ){
            this.consultationFee=0;
          } else {
            this.consultationFee=res.doctor_charge_ids.doctor_charges[this.selectedAppointmet.specialities];
          }
        }
        this.consultationFeeChange();
        }
    }, (error) => {
      console.log(error)
    });
  }
  consultationFeeChange(){
    this.tax=(this.consultationFee-this.govtGrantAmount)*(this.taxPercent/100);
    this.totalFees=(this.consultationFee-this.govtGrantAmount)+this.tax;
  }
  govtGrantAmountChange(event){
    this.tax=(this.consultationFee-this.govtGrantAmount)*(this.taxPercent/100);
    this.totalFees=(this.consultationFee-this.govtGrantAmount)+this.tax;
  }
  govtGrantChange(event){
    if(event=='normal'){
      this.govtGrantAmount=0;
    }
    this.tax=(this.consultationFee-this.govtGrantAmount)*(this.taxPercent/100);
    this.totalFees=(this.consultationFee-this.govtGrantAmount)+this.tax;
  }
 cancel(){
  this.dialogRef.close({event:'Cancel'});
 }
 close(){
  this.dialogRef.close({event:'Close'});
 }
 confirm(){
  let params={
    "appointment_id": this.selectedAppointmet.id,
    "doctor_id": this.selectedAppointmet.doctor.id,
    "doctor_charge": Math.round(this.consultationFee),
    "payment_from": this.paymentMode,
    "goverment_grand_amount": Math.round(this.govtGrantAmount),
    "goverment_grand": this.govtGrantFlag=='normal'?'N':'Y',
    "total_amount": Math.round(this.totalFees),
    "tax_amount": Math.round(this.tax),
    "payment_date":this.pickedDateMM
  }
  // "payment_intent_response": "string",
  //   "ephemeral_key_response": "string",
  //   "customer_response": "string",
  //   "payment_by": this.paymentMode,
  //   "payment_from": this.selectedAppointmet.patient.first_name,
  //   "goverment_grand": this.govtGrantFlag=='normal'?'N':'Y',
  //   "goverment_grand_amount": Math.round(this.govtGrantAmount),
  //   "doctor_charge": Math.round(this.consultationFee),
  //   "tax_amount": ,
  //   "total_amount": Math.round(this.totalFees),
  //   "tenant": this.selectedAppointmet.doctor.tenant
  this.appService.savePayment(params).subscribe((response:any) => {
    if (response) {
      let res=response;
      }
  }, (error) => {
    console.log(error)
  });
  this.dialogRef.close({event:'Save'});
 }
}
