<div class="row">
    <div class="col-sm-6 d-flex flex-column">
        <label>Degree :</label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Degree" [(ngModel)]="QualDegree.degree" required>
        </mat-form-field>
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <label>Degree completed year :</label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Degree completed year" [(ngModel)]="QualDegree.degree_completed_year"  required>
        </mat-form-field>
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <label>Degree completed College :</label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Degree completed college" [(ngModel)]="QualDegree.college" required>
        </mat-form-field>
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <label>Degree completed University :</label>
        <mat-form-field appearance="outline" >
  
          <input matInput placeholder="Degree completed University" [(ngModel)]="QualDegree.university" required>
        </mat-form-field>
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <label>Degree completed Country :</label>
        <mat-form-field appearance="outline" >
  
          <input matInput placeholder="Degree completed Country" [(ngModel)]="QualDegree.degree_completed_country" required>
        </mat-form-field>
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <label>FMGE status (If Degree competed in abroad) :</label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="FMGE status (If Degree competed in abroad)" [(ngModel)]="QualDegree.fmge_status">
        </mat-form-field> 
      </div>
      <div class="col-sm-12 d-flex flex-column" *ngIf="!!isShow">
        <label>About experience :</label>
        <mat-form-field appearance="outline">
          <textarea matInput name="aboutText" cols="35" rows="4" placeholder="Enter your experience here..."  [(ngModel)]="about"></textarea>
        </mat-form-field>
      </div>
  <div class="col-sm-12 text-right">
    <button class="btn btn-danger" mat-stroked-button (click)="closeDialog()">Cancel</button>&nbsp;
    <button class="btn btn-primary" mat-stroked-button (click)="saveDetail(null)">Save</button>
  </div>
</div>