<div>
    <div><h3><b>Set Currency and Tax % for {{selectedTenant?.name}}</b></h3></div>
    <div class="row">
        <div class="col-6 pt-4" style="font-weight:500;font-size:1em;color:rgb(46, 134, 134)">Currency:</div>
        <div class="col-6">
            <mat-form-field appearance="outline" [style.width.px]=100>
            <input matInput placeholder="Doctor Charge" type="text" [disabled]="!isAdmin" [(ngModel)]="selectedTenant.currency_code">
          </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6 pt-4" style="font-weight:500;font-size:1em;color:rgb(46, 134, 134)">Tax percentage:</div>
        <div class="col-6">
            <mat-form-field appearance="outline" [style.width.px]=100>
            <input matInput placeholder="Doctor Charge" oninput="this.value = Math.abs(this.value)" min="0" type="number" [disabled]="!isAdmin" [(ngModel)]="selectedTenant.tax_percentage">
          </mat-form-field>
        </div>
    </div>
    <div class="row pt-4">
    <div *ngIf="isAdmin" class="col-8" style="text-align:center;margin-left:10%">
        <button class="btn1" mat-stroked-button color='default' (click)="closeDialog()">Cancel</button>&nbsp;
        <button class="btn" mat-stroked-button color='primary' (click)="saveDialog()">OK</button>
    </div>
    <div *ngIf="!isAdmin" class="col-8" style="text-align:center;margin-left:10%">
        <button class="btn1" mat-stroked-button color='default' (click)="closeDialog()">Close</button>
    </div>
    </div>
</div>
