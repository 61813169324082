import {
  Component,
  NgZone,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';
import { AppointmentService } from '../../service/appointment.service';
import '../../../assets/js/annyang.min.js';
declare var annyang: any;
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, async } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import domtoimage from 'dom-to-image';
import jspdf from 'jspdf'; // tslint:disable-line no-unused-variable
declare var $: any;
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FullScreenComponent } from 'src/app/shared_components/full-screen/full-screen.component';
import { promise } from 'protractor';
import { AdminService } from 'src/app/service/admin.service';
import DyteClient from '@dytesdk/web-core';
import {
  activateTranscriptions,
  deactivateTranscriptions,
  addTranscriptionsListerner,
  removeTranscriptionsListener,
} from '@dytesdk/symbl-transcription';
import { DyteMeeting } from '@dytesdk/angular-ui-kit';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesComponent implements OnInit {
  formData: any = {
    // initialize with default values or empty strings
    // formData properties correspond to your form fields
    tenant: 0,
    appointment_id: 0,
    prescription_certificate_url: null,
    medicine_details: '',
    advice: '',
  };
  // title = 'micRecorder';
  record;
  recording = false;
  url;
  audioShow: boolean = false;
  audioURL;
  hisoryFileURL: any = null;
  error;
  videoEnable: boolean = false;
  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  voiceData: any = [];
  selectedAppointment: any;
  intervalId: any;
  audioAvail: boolean = true;
  selectable = true;
  removable = true;
  // readonly separatorKeysCodes = [ENTER, COMMA] as const;
  // addOnBlur:boolean=true;
  imgTestCtrl = new FormControl();
  cardiacCtrl = new FormControl();
  respCtrl = new FormControl();
  diagCtrl = new FormControl();
  transcriptLoader: boolean = false;
  filteredImgTest: Observable<string[]>;
  filteredCardiac: Observable<string[]>;
  filteredResp: Observable<string[]>;
  filteredDiag: Observable<string[]>;
  audioLoader: boolean = false;
  scriptLoader: boolean = false;
  scriptLoaderValue = 0;
  notesData: any = [];
  summary: string = '';
  tabIndex: number = 0;
  innerTabIndex: number = 0;
  transcriptArray: any = [];
  peerId: string = '';
  vidAuthToken: any;
  imagingTests: string[] = [];
  cardiac: string[] = [];
  resp: string[] = [];
  diag: string[] = [];
  prescriptionData: any = {
    advice: '',
    medicine_details: [
      {
        med: '',
        time: { mor: false, an: false, night: false },
        food: '',
      },
    ],
  };
  public appointmentId: number;
  public isEditable: boolean = true;
  public selectedMcdpDataIndex: number;
  public transcrptionData: any;
  public mcdpData: any = [
    {
      mcdp_category: 'Animals / Plants',
      animals_plants: [],
    },
    {
      birds_insects: [],
      mcdp_category: 'Birds / Insects',
    },
    {
      mcdp_category: 'Blood Types and Cells',
      blood_types_cells: [],
    },
    {
      disease_name: [],
      mcdp_category: 'Disease Name',
    },
    {
      mcdp_category: 'Symptoms and Signs',
      symptoms_signs: [],
    },
    {
      mcdp_category: 'Medical Instruments',
      medical_instruments: [],
    },
    {
      mcdp_category: 'Procedures / Tests',
      procedures_tests: [],
    },
    {
      mcdp_category: 'Microorganism',
      microorganism: [],
    },
    {
      mcdp_category: 'Drugs and Medicines',
      drugs_medicines: [],
    },
    {
      mcdp_category: 'Chemical Component',
      chemical_components: [],
    },
    {
      mcdp_category: 'Highly Sensitive',
      highly_sensitive: [],
    },
    {
      mcdp_category: 'Institution & Organisation',
      institution_organization: [],
    },
    {
      mcdp_category: 'General Medical Terms',
      general_medical_terms: [],
    },
    {
      human_organ: [],
      mcdp_category: 'Human Organ',
    },
    // {
    //     "ethnicity": [],
    //     "mcdp_category": "Ethnicity"
    // }
  ];
  public mcdpCateogories = {
    'Symptoms and Signs': 'symptoms_signs',
    'Animals / Plants': 'animals_plants',
    'Birds / Insects': 'birds_insects',
    'Blood Types and Cells': 'blood_types_cells',
    'Disease Name': 'disease_name',
    'Medical Instruments': 'medical_instruments',
    'Procedures / Tests': 'procedures_tests',
    Microorganism: 'microorganism',
    'Chemical Component': 'chemical_components',
    'Highly Sensitive': 'highly_sensitive',
    'Institution & Organisation': 'institution_organization',
    'General Medical Terms': 'general_medical_terms',
    'Human Organ': 'human_organ',
    Ethnicity: 'ethnicity',
  };
  medicalCertData: any = {
    days: '',
    from_date: '',
    to_date: '',
    mcr_number: '',
  };
  tenantData: any;
  allSymptoms: string[] = [
    'temperature',
    'blood tested',
    'voice is out of tune',
    'fever',
    'headache',
    'shivering',
    'malaria',
    'Appears Age',
    'Fatigue Appearing',
    'Normal Rhytham',
    'Grade mumr',
    'diagnosis1',
    'diagnosis2',
    'Not enough',
    'Not Wheezing',
    'Periacular Structure',
    'Conjuctive health without lesions',
  ];
  @ViewChild('imgTestInput') imgTestInput: ElementRef<HTMLInputElement>;
  @ViewChild('cardiacInput') cardiacInput: ElementRef<HTMLInputElement>;
  @ViewChild('respInput') respInput: ElementRef<HTMLInputElement>;
  @ViewChild('diagInput') diagInput: ElementRef<HTMLInputElement>;
  @ViewChild('originalElement', { static: true }) originalElement: ElementRef;
  file: any;
  title = 'MyProject';
  dyteMeeting: DyteClient;
  @ViewChild('mic') micButton: DyteMeeting;
  recording_id: any;
  noteData: any;
  addOnBlur = true;
  pdfLoader: boolean = false;
  chips: string[] = [];
  hideSeal: boolean = true;
  hideSign: boolean = true;
  loading: boolean = false;
  enableStop: boolean = true;
  isShow: boolean = true;
  isRefreshing: boolean = false;
  stopLoader: boolean = false;
  formPdf: any;
  selectedFile: File;
  fileUrl: any;
  printType: any;
  certify: boolean = false;
  pdfblb: any;
  isDisplay: boolean = false;
  addChip(event: MatChipInputEvent, id: string, ind: any): void {
    const input = event.input;
    const value = event.value;
    console.log(id);

    // Add chip
    if ((value || '').trim()) {
      console.log(this.mcdpData[ind][id]);
      this.mcdpData[ind][id].push(value.trim());
    }

    // Reset input value
    if (input) {
      input.value = '';
    }
  }

  removeChip(chip: string, id: string, ind: any): void {
    const index = this.mcdpData[ind][id].indexOf(chip);

    if (index >= 0) {
      this.mcdpData[ind][id].splice(index, 1);
    }
  }

  // @ViewChild('#myElement', { static: true }) myElement: HTMLButtonElement;
  //  ngAfterViewInit() {
  //

  //   let params={
  //     title:'Doctor Meeting with '+this.selectedAppointment.patient.first_name,
  //     doctor_name:this.selectedAppointment.doctor.first_name,
  //     appointment_id:this.selectedAppointment.id,
  //     phone_number:this.selectedAppointment.patient.phone_number,
  //     preset_name:'Medosys_audio'
  //   }
  //   this.appService.getVideoAuth(params).subscribe((response) => {
  //     if (response) {
  //       console.log(response)
  //       const res:any=response;
  //       this.vidAuthToken=res.data.participant_response.data.token;

  //       this.recordAudio();
  //       }
  //   }, (error) => {

  //   });
  // }
  async recordAudio() {
    let transcriptToken;
    const meeting = await DyteClient.init({
      authToken: this.vidAuthToken,
    });

    meeting.joinRoom();
    this.dyteMeeting = meeting;
    if (this.micButton) this.micButton.meeting = meeting;
    // this.dyteMeeting = meeting;
    // const elements = this.elRef.nativeElement.getElementsByClassName('dyte-el');
    // console.log(elements.meeting);

    // for (let i = 0; i < elements.length; i++) {
    //   elements[i].meeting = meeting;
    // }
    // if (this.meetingComponent) this.meetingComponent.meeting = meeting;
    let transcriptParams = {
      type: 'application',
      appId: '57615579366a334a5649794d654c48323353766764694d36706775646b686653',
      appSecret:
        '667766325243305a7037396d34707445417278354536485465634f35796f734366416e4c4e743546394f313961764c4c454e514a526d4e57554c6c355a636c42',
    };
    debugger;
    if (this.enableStop == false) {
      this.appService.getTranscriptAuth(transcriptParams).subscribe(
        (response) => {
          if (response) {
            console.log(response);
            const res: any = response;

            transcriptToken = res.accessToken;

            activateTranscriptions({
              meeting: meeting, // From DyteClient.init
              symblAccessToken: transcriptToken,
            });
            addTranscriptionsListerner({
              meeting: meeting,
              noOfTranscriptionsToCache: 200,
              transcriptionsCallback: (allFormattedTranscriptions) => {
                //console.log(allFormattedTranscriptions);
                if (allFormattedTranscriptions?.length > 0) {
                  console.log(allFormattedTranscriptions);
                  // this.transcriptPass.emit(allFormattedTranscriptions);
                }
              },
            });
          }
        },
        (error) => {
          //console.log(error);
        }
      );
    }
    meeting.self.on('roomLeft', () => {
      //console.log("meeting ends")
      this.onMicStop();
    });
    this.enableStop = false;
  }

  onMicToggle() {
    console.log('hi');
    this.enableStop = false;
    console.log(this.dyteMeeting);

    let params = {
      title:
        'Doctor Meeting with ' + this.selectedAppointment.patient.first_name,
      doctor_name: this.selectedAppointment.doctor.first_name,
      appointment_id: this.selectedAppointment.id,
      phone_number: this.selectedAppointment.patient.phone_number,
      preset_name: 'Medosys_audio',
    };
    this.appService.getVideoAuth(params).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          const res: any = response;
          this.vidAuthToken = res.data.participant_response.data.token;
          this.recording_id = res.data.video_recording_id;
          this.recordAudio();
        }
      },
      (error) => {
        //console.log(error);
      }
    );
    // this.dyteMeeting.self.disableAudio();
  }
  onMicStop() {
    debugger;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Do you want to stop recording?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.enableStop = true;
        let params = {
          recording_id: this.recording_id,
        };
        this.appService.stopRecording(params).subscribe(
          (response) => {
            if (response) {
              //console.log(response)
              const res: any = response;
            }
          },
          (error) => {
            //console.log(error);
          }
        );
        this.pageRefresh(true);
      }
    });
  }

  constructor(
    public appService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FullScreenComponent>,
    public adminService: AdminService,
    private ngZone: NgZone,
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private elRef: ElementRef,
    public datepipe: DatePipe
  ) {
    this.filteredImgTest = this.imgTestCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allSymptoms.slice()
      )
    );

    this.filteredCardiac = this.cardiacCtrl.valueChanges.pipe(
      startWith(null),
      map((cardiac: string | null) =>
        cardiac ? this._filter(cardiac) : this.allSymptoms.slice()
      )
    );

    this.filteredResp = this.respCtrl.valueChanges.pipe(
      startWith(null),
      map((resp: string | null) =>
        resp ? this._filter(resp) : this.allSymptoms.slice()
      )
    );

    this.filteredDiag = this.diagCtrl.valueChanges.pipe(
      startWith(null),
      map((diag: string | null) =>
        diag ? this._filter(diag) : this.allSymptoms.slice()
      )
    );
  }
  pageRefresh(data) {
    debugger;
    console.log(data);
    if (!!data) {
      // this.loading = true;
      setTimeout((pageRefresh) => {
        this.transcriptArray = [];
        this.loading = false;
        debugger;
        this.appService.getAppointment(this.appointmentId).subscribe(
          (response) => {
            if (response) {
              console.log(response);
              let data: any = response;
              this.selectedAppointment = data;
              this.stopLoader = true;
              this.getTenantData(this.selectedAppointment.patient.tenant);
              this.getTranscriptedDataURL(this.appointmentId);
              this.getHistoryFile(this.appointmentId);
            }
          },
          (error) => {
            //
          }
        );
        // window.location.reload();
      }, 20000);
    }
  }
  moveToNextTab() {
    this.innerTabIndex = 1;
    setTimeout(() => {
      this.printMcdpCer();
    }, 2000);
  }
  ngOnInit(): void {
    debugger;
    // this.moveToNextTab();
    console.log(this.isEditable);
    let id: any;
    this.route.queryParams.subscribe((params) => {
      id = params['id'];
    });
    this.appointmentId = id;
    this.appService.getAppointment(id).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          let data: any = response;
          this.selectedAppointment = data;
          this.getTenantData(this.selectedAppointment.patient.tenant);
          this.getTranscriptedDataURL(id);
          this.getHistoryFile(id);
          this.getPrescriptionAndCertificate();
        }
      },
      (error) => {
        //
      }
    );
    //this.appService.video.subscribe(data => this.processRecording = data);
    // this.appService.currentAppmt.subscribe(data => this.selectedAppointment = data);
    // //
  }
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
  addImgTest(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.imagingTests.push(value);
    }

    // Clear the input value
    event.input.value = '';

    this.imgTestCtrl.setValue(null);
  }
  addCardiac(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.cardiac.push(value);
    }
    event.input.value = '';
    this.cardiacCtrl.setValue(null);
  }
  addResp(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.resp.push(value);
    }
    event.input.value = '';
    this.respCtrl.setValue(null);
  }
  addDiag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.diag.push(value);
    }
    event.input.value = '';
    this.diagCtrl.setValue(null);
  }

  remov(item: string): void {
    const index = this.imagingTests.indexOf(item);

    if (index >= 0) {
      this.imagingTests.splice(index, 1);
    }
  }
  removeCardiac(item: string): void {
    const index = this.cardiac.indexOf(item);

    if (index >= 0) {
      this.cardiac.splice(index, 1);
    }
  }
  removeResp(item: string): void {
    const index = this.resp.indexOf(item);

    if (index >= 0) {
      this.resp.splice(index, 1);
    }
  }
  removeDiag(item: string): void {
    const index = this.diag.indexOf(item);

    if (index >= 0) {
      this.diag.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.imagingTests.push(event.option.viewValue);
    this.imgTestInput.nativeElement.value = '';
    this.imgTestCtrl.setValue(null);
  }
  selectedCardiac(event: MatAutocompleteSelectedEvent): void {
    this.cardiac.push(event.option.viewValue);
    this.cardiacInput.nativeElement.value = '';
    this.cardiacCtrl.setValue(null);
  }
  selectedResp(event: MatAutocompleteSelectedEvent): void {
    this.resp.push(event.option.viewValue);
    this.respInput.nativeElement.value = '';
    this.respCtrl.setValue(null);
  }
  selectedDiag(event: MatAutocompleteSelectedEvent): void {
    this.diag.push(event.option.viewValue);
    this.diagInput.nativeElement.value = '';
    this.diagCtrl.setValue(null);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSymptoms.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }
  addPageClass() {
    this.prescriptionData.medicine_details.map((x) => {
      if (this.prescriptionData.medicine_details.length <= 10) {
        x.class = 'firstPage';
      } else if (
        this.prescriptionData.medicine_details.length > 10 &&
        this.prescriptionData.medicine_details.length < 21
      ) {
        x.class = 'secondPage';
      } else if (this.prescriptionData.medicine_details.length > 20) {
        x.class = 'thirdPage';
      }
    });
  }
  addMedicine() {
    this.prescriptionData.medicine_details.push({
      med: '',
      time: { mor: false, an: false, night: false },
      food: '',
      days: '',
    });
    // this.addPageClass();
  }
  deleteMedicine(id) {
    this.prescriptionData.medicine_details.splice(id, 1);
    // this.addPageClass();
  }
  presTimeChange(time, id) {
    if (this.prescriptionData.medicine_details[id].time[time]) {
      this.prescriptionData.medicine_details[id].time[time] = false;
    } else {
      this.prescriptionData.medicine_details[id].time[time] = true;
    }
  }
  // ----------------Record as wav file section-------------------------------------------//
  successCallback(stream) {
    var options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 50000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */

  blopReceived(file) {
    //alert("enter")
    this.file = file;
    this.videoEnable = true;
    this.uploadFile(file, this.selectedAppointment.id);
  }
  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    //
    //

    var d = new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let secs = d.getSeconds();
    let pickedDate =
      year + '_' + month + '_' + day + '_' + hours + '_' + minutes + '_' + secs;
    let filename =
      this.selectedAppointment.id.toString() + '_' + pickedDate + '.wav';
    this.file = new File([blob], filename, { type: 'audio/wav' });
    this.uploadFile(this.file, this.selectedAppointment.id);
  }
  /**
   * Process Error.
   */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }
  timeSpend = (startTime, stopTime) => {
    let difference = stopTime - startTime;
    let tempTime = moment.duration(difference);
    let result = 2;
    let minutes = tempTime.minutes();
    if (minutes > 0) {
      result = minutes;
    }
    return [result, 'new', 'More than'].join(',');
  };
  // ------------------Voice recording section -------------------------------------------//

  initializeVoiceRecognitionCallback(): void {
    annyang.addCallback('error', (err) => {
      if (err.error === 'network') {
        this.voiceText = 'Internet is require';
        annyang.abort();
        this.ngZone.run(() => (this.voiceActiveSectionSuccess = true));
      } else if (this.voiceText === undefined) {
        this.ngZone.run(() => (this.voiceActiveSectionError = true));
        annyang.abort();
      }
    });

    annyang.addCallback('soundstart', (res) => {
      this.ngZone.run(() => (this.voiceActiveSectionListening = true));
    });

    annyang.addCallback('end', () => {
      if (this.voiceText === undefined) {
        this.ngZone.run(() => (this.voiceActiveSectionError = true));
        annyang.abort();
      }
    });

    annyang.addCallback('result', (userSaid) => {
      this.ngZone.run(() => (this.voiceActiveSectionError = false));

      let queryText: any = userSaid[0];

      //annyang.abort();
      var today = new Date();
      var date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      this.voiceData.push({ voiceNote: queryText, date: date, time: time });
      this.voiceData = this.voiceData.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return (
          index ===
          this.voiceData.findIndex((obj) => {
            return JSON.stringify(obj) === _thing;
          })
        );
      });
      //
      this.ngZone.run(() => (this.voiceActiveSectionListening = true));
      this.ngZone.run(() => (this.voiceActiveSectionSuccess = false));
    });
  }

  startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceText = undefined;

    if (annyang) {
      let commands = {
        'demo-annyang': () => {},
      };

      annyang.addCommands(commands);

      this.initializeVoiceRecognitionCallback();

      annyang.start({ autoRestart: false });
    }
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceActiveSectionListening = false;
    this.voiceText = undefined;

    annyang.abort();
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
    let id: any;
    this.route.queryParams.subscribe((params) => {
      id = params['id'];
    });
    this.appointmentId = id;
    this.appService.getAppointment(id).subscribe(
      (response) => {
        if (response) {
          //
          let data: any = response;
          this.selectedAppointment = data;
          this.getTenantData(this.selectedAppointment.patient.tenant);
          this.getTranscriptedDataURL(id);
          this.getHistoryFile(id);
        }
      },
      (error) => {
        //
      }
    );
  }

  SelectAll() {
    //
  }
  uploadFile(file, id) {
    this.audioLoader = true;
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: 'AKIA5LEOLHXEGSJNLXBP',
      secretAccessKey: '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',
      region: 'ap-southeast-1',
    });
    const params = {
      Bucket: 'medosys-dev-audio-file',
      Key: file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, (err, data) => {
      if (err) {
        //
      } else {
        this.audioLoader = false;
        //
        this.saveData();
      }
      return true;
    });

    //   function (err, data) {
    //     if (err) {
    //       this.audioLoader=false;
    //         //
    //         return false;
    //     }
    //       this.audioLoader=false;
    //       //
    //       this.saveData();
    //       return true;
    // });
    //for upload progress
    // bucket.upload(params).on('httpUploadProgress', function (evt) {
    //           //
    //       }).send(function (err, data) {
    //           if (err) {
    //               //
    //               return false;
    //           }
    //           //
    //           return true;
    //       });
  }
  onTabChanged(event) {
    debugger;
    this.tabIndex = event.index;
    if (event.index == 0) {
      this.appService.getAssessmentData(this.selectedAppointment.id).subscribe(
        (response) => {
          if (response) {
            console.log(response);
            this.notesData = [];
            //
            let res: any = response;
            this.notesData = res[0];
            //
            if (res[0].aws_diagnosis && res[0].aws_diagnosis.length > 0) {
              this.diag = [];
              res[0].aws_diagnosis.map((x) => {
                this.diag.push(x);
              });
            }
            if (
              res[0].workup?.aws_imaging_tests_labs &&
              res[0].workup?.aws_imaging_tests_labs.length > 0
            ) {
              this.imagingTests = [];
              res[0].workup?.aws_imaging_tests_labs.map((x) => {
                this.imagingTests.push(x);
              });
            }
            if (res[0].aws_symptoms && res[0]?.aws_symptoms.length > 0) {
              this.cardiac = [];
              res[0].aws_symptoms.map((x) => {
                this.cardiac.push(x);
              });
            }
            // if(res[0].treatment_plan.aws_medications && res[0].treatment_plan.aws_medications.length>0){
            //   this.cardiac=[];
            //   res[0].treatment_plan.aws_medications.map(x=>{
            //     this.cardiac.push(x);
            //   })
            // }
            if (
              res[0].treatment_plan.aws_procedures &&
              res[0].treatment_plan.aws_procedures.length > 0
            ) {
              this.resp = [];
              res[0].treatment_plan.aws_procedures.map((x) => {
                this.resp.push(x);
              });
            }
            this.summary = res[0].summary == 'string' ? '' : res[0].summary;
          }
        },
        (error) => {
          //
        }
      );
    } else if (event.index == 1) {
      //
    } else if (event.index == 2) {
      this.onTabPresChanged({ index: 0 });
    }
  }
  saveData() {
    this.appService.doneNotes(true);
    //this.router.navigate(['/appointment']);
    let params = {
      audio_file_name: this.file.name,
      speaker_count: 2,
      appointment_id: this.selectedAppointment.id,
      time_spend: '01:03',
      patient_spending_percentage: 'More than',
    };
    // var formBody:any = [];
    // for (var property in params) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(params[property]);
    //   formBody.push(encodedKey + "=" + encodedValue);
    // }
    // formBody = formBody.join("&");
    this.appService.saveRecord(params).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          let taskId = res.data.task_id;
          this.intervalId = setInterval(
            this.getTranscriptedData.bind(this),
            10000,
            taskId
          );
          //this.getTranscriptedData(taskId);
        }
      },
      (error) => {
        //
      }
    );
  }
  getPrescriptionAndCertificate() {
    this.appService.getPrescription().subscribe(
      (response) => {
        if (response) {
          //
          console.log(response);
          let res: any = response;
          let filteredRes = res.filter(
            (x) => x.appointment_id == this.selectedAppointment.id
          );
          console.log(filteredRes);
          if (filteredRes.length > 0) {
            this.prescriptionData = filteredRes[filteredRes.length - 1];
            // this.prescriptionData.medicine_details =
            //   this.prescriptionData.medicine_details;

            let med = decodeURIComponent(
              this.prescriptionData.medicine_details
            );
            this.prescriptionData.medicine_details = JSON.parse(med);

            console.log(this.prescriptionData);
            if (
              !!this.prescriptionData.prescription_certificate_url &&
              this.prescriptionData.prescription_certificate_url !== null
            ) {
              this.isDisplay = true;
            }
            //
          }
        }
      },
      (error) => {
        this.snackBar.open(error, 'Close', { duration: 2000 });
      }
    );
    this.appService.getMedicalCert().subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          //
          let filteredRes = res.filter(
            (x) => x.appointment_id == this.selectedAppointment.id
          );
          if (filteredRes.length > 0) {
            this.medicalCertData = filteredRes[filteredRes.length - 1];
          }
          if (
            !!this.medicalCertData.medical_certificate_url &&
            this.medicalCertData.medical_certificate_url !== null
          ) {
            this.isDisplay = true;
          }
        }
      },
      (error) => {
        this.snackBar.open(error, 'Close', { duration: 2000 });
      }
    );
  }
  onTabPresChanged(event) {
    console.log(this.prescriptionData, this.medicalCertData);
    this.innerTabIndex = event.index;
    if (event.index == 0) {
      this.appService.getPrescription().subscribe(
        (response) => {
          if (response) {
            //
            console.log(response);
            let res: any = response;
            let filteredRes = res.filter(
              (x) => x.appointment_id == this.selectedAppointment.id
            );
            console.log(filteredRes);
            if (filteredRes.length > 0) {
              this.prescriptionData = filteredRes[filteredRes.length - 1];
              // this.prescriptionData.medicine_details =
              //   this.prescriptionData.medicine_details;

              let med = decodeURIComponent(
                this.prescriptionData.medicine_details
              );
              this.prescriptionData.medicine_details = JSON.parse(med);

              console.log(this.prescriptionData);
              if (
                !!this.prescriptionData.prescription_certificate_url &&
                this.prescriptionData.prescription_certificate_url !== null
              ) {
                this.isDisplay = true;
              }
              //
            }
          }
        },
        (error) => {
          this.snackBar.open(error, 'Close', { duration: 2000 });
        }
      );
    } else if (event.index == 1) {
      this.appService.getMedicalCert().subscribe(
        (response) => {
          if (response) {
            //
            let res: any = response;
            //
            let filteredRes = res.filter(
              (x) => x.appointment_id == this.selectedAppointment.id
            );
            if (filteredRes.length > 0) {
              this.medicalCertData = filteredRes[filteredRes.length - 1];
            }
            if (
              !!this.medicalCertData.medical_certificate_url &&
              this.medicalCertData.medical_certificate_url !== null
            ) {
              this.isDisplay = true;
            }
          }
        },
        (error) => {
          this.snackBar.open(error, 'Close', { duration: 2000 });
        }
      );
    }
  }
  cancelNotes() {
    this.appService.doneNotes(true);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message:
          'Are you sure you want to leave this page without save changes?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.router.navigate(['/appointment']);
      }
    });
  }

  saveNotes() {
    debugger;
    this.isEditable = true;
    console.log(this.prescriptionData);
    console.log(this.medicalCertData);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Do you want to save the changes?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log(this.mcdpData);
        this.transcrptionData.mcdp_medical_data = {};
        if (this.mcdpData) {
          this.transcrptionData.mcdp_medical_data.push(this.mcdpData);
          this.appService
            .updateMCDPData(this.transcrptionData, this.transcrptionData.id)
            .subscribe(
              (response: any) => {},
              (error) => {
                this.transcriptLoader = false;
              }
            );
        }
        if (this.prescriptionData.medicine_details[0].med !== '') {
          this.savePres();
        }
      }
    });
  }

  savePres() {
    let medicine_details = encodeURIComponent('medicine_details');
    let medicine_details_value = encodeURIComponent(
      JSON.stringify(this.prescriptionData.medicine_details)
    );
    let advice = encodeURIComponent('advice');
    let advice_value = encodeURIComponent(this.prescriptionData.advice);
    let tenant = encodeURIComponent('tenant');
    let tenant_value = encodeURIComponent(
      this.selectedAppointment.patient.tenant
    );
    let appointment_id = encodeURIComponent('appointment_id');
    let appointment_id_value = encodeURIComponent(this.selectedAppointment.id);
    let saveData: any = [];

    saveData.push(medicine_details + '=' + medicine_details_value);
    saveData.push(advice + '=' + advice_value);
    saveData.push(tenant + '=' + tenant_value);
    saveData.push(appointment_id + '=' + appointment_id_value);
    saveData = saveData.join('&');
    // saveData.append(prescription_certificate_url, file, 'custom.pdf');
    //saveData = saveData.join('&');
    // for (const key in saveData) {
    //   if (key !== 'prescription_certificate_url') {
    //     formData.append(`${key}`, `${saveData[key]}`);
    //   }
    // }
    debugger;
    console.log(saveData);

    this.appService.savePrescription(saveData).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          if (res.id) {
            this.snackBar.open(
              'Prescription has been upoaded successfully',
              'Close',
              { duration: 2000 }
            );
            // this.appService.doneNotes(true);
            // this.router.navigate(['/appointment']);
          }
        }
      },
      (error) => {
        // this.appService.doneNotes(true);
        // this.router.navigate(['/appointment']);
        this.snackBar.open(error, 'Close', { duration: 2000 });
      }
    );
    //this.uploadpdf(file,filename);
    if (!!this.medicalCertData.from_date && !!this.medicalCertData.to_date) {
      this.saveCertify();
    }
  }

  // alert("2")
  // this.appService.doneNotes(true);
  // this.router.navigate(['/appointment']);
  // this.snackBar.open("Medical certificate saved successfully", 'Close', {duration: 2000});
  saveCertify() {
    let from_date = encodeURIComponent('from_date');
    let from_date_value = encodeURIComponent(
      this.datepipe.transform(this.medicalCertData.from_date, 'MM-dd-yyyy')
    );
    let to_date = encodeURIComponent('to_date');
    let to_date_value = encodeURIComponent(
      this.datepipe.transform(this.medicalCertData.to_date, 'MM-dd-yyyy')
    );
    let days = encodeURIComponent('days');
    let days_value = encodeURIComponent(this.medicalCertData.days);
    let mcr_number = encodeURIComponent('mcr_number');
    let mcr_number_value = encodeURIComponent(this.medicalCertData.mcr_number);
    let tenant = encodeURIComponent('tenant');
    let tenant_value = encodeURIComponent(
      this.selectedAppointment.patient.tenant
    );
    let appointment_id = encodeURIComponent('appointment_id');
    let appointment_id_value = encodeURIComponent(this.selectedAppointment.id);
    let medSaveData: any = [];

    medSaveData.push(from_date + '=' + from_date_value);
    medSaveData.push(to_date + '=' + to_date_value);
    medSaveData.push(mcr_number + '=' + mcr_number_value);
    medSaveData.push(days + '=' + days_value);
    medSaveData.push(tenant + '=' + tenant_value);
    medSaveData.push(appointment_id + '=' + appointment_id_value);
    medSaveData = medSaveData.join('&');
    console.log(medSaveData);

    this.appService.saveMedicalCert(medSaveData).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          if (res.id) {
            this.snackBar.open(
              'Medical Certificated has been upoaded successfully',
              'Close',
              { duration: 2000 }
            );
            // this.appService.doneNotes(true);
            // this.router.navigate(['/appointment']);
          }
        }
      },
      (error) => {
        // this.appService.doneNotes(true);
        //     this.router.navigate(['/appointment']);
        this.snackBar.open(error, 'Close', { duration: 2000 });
      }
    );
    // } else {
    //   this.snackBar.open('Enter the mandatory data to proceed', 'Close', {
    //     duration: 2000,
    //   });
    // }
  }

  uploadpdf(file, filename) {
    const contentType = 'pdf';
    const bucket = new S3({
      accessKeyId: 'AKIA5LEOLHXEHTEOM5IL',
      secretAccessKey: '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',
      region: 'ap-southeast-1',
    });
    const params = {
      Bucket: 'medosys-prescription',
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, (err, data) => {
      if (err) {
        //
      } else {
        //
      }
      return true;
    });
  }
  getTranscriptedData(taskId) {
    if ((this.stopLoader = true)) {
      this.scriptLoader = false;
    } else {
      this.scriptLoader = true;
    }
    this.appService.getTranscrptedData(taskId).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          if (res.state === 'SUCCESS') {
            this.scriptLoader = false;
            this.snackBar.open(
              'Your Record has been transcripted successfully',
              'Close',
              { duration: 2000 }
            );
            this.getTranscriptedDataURL(this.selectedAppointment.id);
            clearInterval(this.intervalId);
          } else if (res.data !== null) {
            this.scriptLoaderValue = res.data.current;
          }
        }
      },
      (error) => {
        //
        this.scriptLoader = false;
      }
    );
  }
  handleEditMCDPDCategory(mcdpCategory, mcdpKey) {
    this.isEditable = true;
    const dialogRef = this.dialog.open(FullScreenComponent, {
      width: '500px',
      height: '500px',
      disableClose: true,
      data: {
        mcdpData: this.mcdpData.filter(
          (mcdp, index) => mcdp.mcdp_category === mcdpCategory.mcdp_category
        )[0],
        modal: 'mcdpDailog',
        mcdpCateogories: this.mcdpCateogories,
        selectedMcdpDataIndex: mcdpKey,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mcdpData[this.selectedMcdpDataIndex] = result.mcdpData;
      }
    });
    return dialogRef.afterClosed();
  }

  closeEdit() {
    this.isEditable = false;
  }
  getTranscriptedDataURL(id) {
    debugger;
    if ((this.stopLoader = true)) {
      this.transcriptLoader = false;
    } else {
      this.transcriptLoader = true;
    }
    this.appService.getTranscrptedDataURL(id).subscribe(
      (response: any) => {
        if (response) {
          console.log(response);
          this.transcriptLoader = false;
          this.mcdpData = response[0]?.mcdp_medical_data || this.mcdpData;
          console.log(this.mcdpData);
          this.transcrptionData = response[0];
          let res: any = response;
          if (res.length > 0) {
            this.audioAvail = true;
            this.audioShow = true;
            let jsonFileURL = res[0].s3_transcripted_file_url;
            this.audioURL = res[0].s3_audio_file_url;
            this.getDataFromURL(jsonFileURL);
          } else {
            this.audioAvail = false;
          }
        }
      },
      (error) => {
        this.transcriptLoader = false;
      }
    );
  }
  getHistoryFile(id) {
    this.appService.getHistoryFile(id).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          this.hisoryFileURL = res[0]?.medical_history_url;
        }
      },
      (error) => {
        //
      }
    );
  }
  getTenantData(id) {
    this.adminService.GetTenantData(id).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          this.tenantData = res;
        }
      },
      (error) => {
        //
      }
    );
  }
  transform() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.hisoryFileURL);
  }
  getDataFromURL(url) {
    this.appService.getDataFromURL(url).subscribe(
      (response) => {
        if (response) {
          //
          let res: any = response;
          console.log(res);
          this.loadConversation(res);
        }
      },
      (error) => {
        //
      }
    );
  }
  loadConversation = (result) => {
    const transcript = result;
    this.onTabChanged({ index: 0 });
    //const conversation = this.state.conversation
    //if(transcript && transcript.results && transcript.results.speaker_labels && _.isEmpty(conversation)){
    let data = [];
    let segments = transcript.results.speaker_labels?.segments;
    let items = transcript.results.items;
    let speaker_count = transcript.results.speaker_labels.speakers;
    let conversation = [];
    segments.map((segment, i) => {
      let msg = [];
      segment.items.map((item, i) => {
        let start_time = item.start_time;
        let end_time = item.end_time;
        let speaker_label = item.speaker_label;
        let result: any = _.find(items, {
          start_time: start_time,
          end_time: end_time,
        });
        let content = result.alternatives[0].content;
        msg.push({
          start_time: start_time,
          end_time: end_time,
          content: content,
        });
        data.push({
          speaker_label: speaker_label,
          start_time: start_time,
          end_time: end_time,
          content: content,
        });
      });
      conversation.push({
        speaker_label: segment.speaker_label,
        content: msg,
        start_time: segment.start_time,
        end_time: segment.end_time,
        time_format: this.formatTime(segment.start_time),
      });
    });

    let records = [];
    let spk_lbl = conversation[0].speaker_label;
    let speakers_data = { speaker_label: spk_lbl, content: [], label: spk_lbl };
    let speakero_flag = true;
    conversation.map((record, i) => {
      if (speakers_data.speaker_label === record.speaker_label) {
        speakers_data.content.push(record);
        if (conversation.length === 1) {
          records.push(speakers_data);
        }
        speakero_flag = true;
      } else {
        speakero_flag = false;
        records.push(speakers_data);
        speakers_data = {
          speaker_label: record.speaker_label,
          content: [],
          label: record.speaker_label,
        };
        speakers_data.content.push(record);
        if (i === conversation.length - 1) {
          records.push(speakers_data);
        }
      }
      if (
        speakero_flag &&
        conversation.length !== 1 &&
        i === conversation.length - 1
      ) {
        records.push(speakers_data);
      }
    });

    let finalRecords = [];
    records.map((x) => {
      let contentArray = [];
      x.content.map((item) => {
        item.content.map((item) => {
          contentArray.push(item.content);
        });
      });
      finalRecords.push({
        spk_label: x.speaker_label,
        voiceNote: contentArray.join(' '),
        time: '',
      });
    });
    //
    this.voiceData = finalRecords;
    //   let speaker_details = this.props.speaker_details
    //   if(!speaker_details){
    //     speaker_details =  this.state.options
    //   }
    //   if(speaker_details && speaker_details.length > 0){
    //     records.map((record, i) => {
    //       let speaker = _.find(speaker_details, (option) => option.spk_lbl === record.speaker_label)
    //       if(speaker){
    //         _.assign(record, {speaker_label: speaker.name})
    //       }
    //     })
    //   }
    //   this.setState({ context: data, conversation: records})

    // this.setState({transcriptLoading: false })
    // }else{
    //   if(transcript && transcript.results){
    //     let transcript_flag =  _.isEmpty(transcript.results.items)
    //     this.setState({ isTranscriptEmpty: transcript_flag})
    //   }
    // }
  };
  formatTime = (time) => {
    let given_time = time.split('.');
    let minutes = given_time[0];
    let seconds = given_time[1];
    let format = [];
    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }
    format.push(minutes);
    if (seconds.length === 1) {
      seconds = '0' + seconds;
    }
    format.push(seconds);
    return format.join(':');
  };
  fromDateChange(event) {
    console.log(event);
    console.log(new Date(event));
    let fromDate = new Date(event);
    let date = event.split('-');
    console.log(date);
    this.medicalCertData.from_date = date[1] + '-' + date[2] + '-' + date[0];
    let a = this.medicalCertData.days * 24 * 60 * 60 * 1000;
    const endDate = new Date(
      fromDate.getTime() + (this.medicalCertData.days - 1) * 24 * 60 * 60 * 1000
    );
    console.log(endDate);
    this.medicalCertData.to_date = endDate;
  }
  toDateChange(event) {
    //
    let date = event.split('-');
    this.medicalCertData.to_date = date[1] + '-' + date[2] + '-' + date[0];
  }
  onSwitchChange(event) {
    this.videoEnable = !this.videoEnable;
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    var reader1 = new FileReader();
    reader1.readAsDataURL(event.target.files[0]); // read file as data url
    reader1.onload = (event) => {
      // called once readAsDataURL is completed
      this.fileUrl = event.target.result;
      console.log(this.fileUrl, this.selectedFile.name);
    };
  }
  async printDocument(data) {
    this.printType = data;
    let input: HTMLElement;

    var del = document.querySelectorAll<HTMLElement>('.medDel');
    var medInput = document.querySelectorAll<HTMLElement>('.medLeft input');
    var medNumber = document.querySelectorAll<HTMLElement>('.medLast input');
    if (this.tabIndex == 2 && this.innerTabIndex == 0) {
      // document.getElementById('med-pre-header').style.display = 'block';
      // document.getElementById('med-pre-header').style.height = '112px';
      document.getElementById('fullscreen').style.display = 'none';
      document.getElementById('med-pre').style.padding = '20px';
      document.getElementById('lowerDetail').style.display = 'none';
      document.getElementById('txAreaInp').classList.add('d-none');
      document.getElementById('txAreaPar').classList.remove('d-none');
      document.getElementById('removeBtn').classList.add('d-none');

      del.forEach((x) => {
        x.style.display = 'none';
      });
      medInput.forEach((x) => {
        x.style.border = 'none';
      });
      medNumber.forEach((x) => {
        x.style.border = 'none';
      });
      input = document.getElementById('med-pre');
    } else if (this.tabIndex == 2 && this.innerTabIndex == 1) {
      // document.getElementById('med-cert-header').style.display = 'block';
      document.getElementById('med-cert-header').style.height = '112px';
      document.getElementById('med-cer').style.padding = '20px';
      document.getElementById('removeCerBtn').classList.add('d-none');
      // document.getElementById("med-cer").style.border="2px solid black"
      input = document.getElementById('med-cer');
    }
    console.log(input);
    this.downloadPDF(input);
    setTimeout(() => {
      document.getElementById('txAreaPar').classList.add('d-none');
      document.getElementById('txAreaInp').classList.remove('d-none');
      document.getElementById('removeBtn').classList.remove('d-none');
      document.getElementById('removeCerBtn').classList.remove('d-none');
    }, 10000);
  }
  downloadPDF(inputs: any) {
    const input = inputs;
    const headerContent = document.getElementById('header-content');
    console.log(headerContent);

    html2canvas(headerContent, {
      scale: 2,
    }).then((headerCanvas) => {
      console.log(headerCanvas);

      const headerImageData = headerCanvas.toDataURL('image/jpeg'); // Convert header canvas to image data
      console.log(headerImageData);
      // Proceed to convert main content to PDF

      html2canvas(input, {
        scale: 2,
        logging: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        var pdf: any = new jspdf('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const maxContentHeight = pdfHeight - 30; // Adjust this value based on your needs
        const headerHeight = 30; // Adjust the header height as needed
        const footerHeight = 15; // Adjust the footer height as needed
        const contentHeight = pdfHeight - headerHeight - footerHeight; // Available content height after considering header and footer

        let currentY = headerHeight; // Start content below the header
        let currentPage = 1;

        // Function to add header to each page
        const addHeader = () => {
          pdf.addImage(headerImageData, 'JPEG', 0, 0, pdfWidth, headerHeight); // Add header image
        };

        // Function to add footer to each page
        const addFooter = () => {
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
          pdf.text(`Page ${currentPage}`, pdfWidth / 2, pdfHeight - 10, {
            align: 'center',
          }); // Adjust the footer text and position as needed
        };

        // Split the content into chunks
        const chunks = this.splitContentIntoChunks(canvas, maxContentHeight);

        // Add header to the first page
        addHeader();

        // Add each chunk to a separate page
        chunks.forEach((chunk, index) => {
          const ratio = Math.min(
            pdfWidth / imgWidth,
            maxContentHeight / chunk.height
          );

          // Check if adding the current chunk will exceed the content height
          if (currentY + chunk.height * ratio > maxContentHeight) {
            pdf.addPage();
            currentPage++;
            currentY = headerHeight;
            addHeader(); // Add header to the new page
          }

          pdf.addImage(
            chunk.imgData,
            'PNG',
            0,
            currentY,
            pdfWidth,
            chunk.height * ratio
          );
          currentY += chunk.height * ratio;

          // Add footer to each page
          addFooter();
        });

        pdf.save('downloaded.pdf');

        // const pdfBlob = pdf.output('blob');
        //var pdfBlob = new Blob([pdf], { type: 'mime/type'});2w
        // Encode data URI component
        // var encodedPdfContent = encodeURIComponent(pdf);
        var pdfBlob = new Blob([pdf], {
          type: 'application/pdf',
        });
        console.log(pdfBlob);
        if (this.certify == true && this.printType !== 'print') {
          this.completeCertificate(pdfBlob);
        }
        if (this.printType !== 'print' && this.certify == false) {
          this.completeMcdp(pdfBlob);
        }
      });
    });
  }

  splitContentIntoChunks(canvas, maxContentHeight) {
    const chunks = [];
    let startY = 0;

    while (startY < canvas.height) {
      const chunkHeight = Math.min(maxContentHeight, canvas.height - startY);
      const chunkCanvas = document.createElement('canvas');
      chunkCanvas.width = canvas.width;
      chunkCanvas.height = chunkHeight;
      const chunkContext = chunkCanvas.getContext('2d');
      chunkContext.drawImage(
        canvas,
        0,
        startY,
        canvas.width,
        chunkHeight,
        0,
        0,
        canvas.width,
        chunkHeight
      );
      chunks.push({
        imgData: chunkCanvas.toDataURL('image/png'),
        height: chunkHeight,
      });
      startY += chunkHeight;
    }

    return chunks;
  }

  // if (this.tabIndex == 2 && this.innerTabIndex == 0) {
  //   document.getElementById('med-pre-header').style.display = 'none';
  //   document.getElementById('fullscreen').style.display = 'block';
  //   document.getElementById('med-pre').style.padding = '0px';
  //   del.forEach((x) => {
  //     x.style.display = 'inline-block';
  //   });
  //   medInput.forEach((x) => {
  //     x.style.borderBottom = '1px solid black';
  //   });
  //   medNumber.forEach((x) => {
  //     x.style.borderBottom = '1px solid black';
  //   });
  // } else if (this.tabIndex == 2 && this.innerTabIndex == 1) {
  //   document.getElementById('med-cert-header').style.display = 'none';
  //   document.getElementById('med-cer').style.padding = '0px';
  // }

  onDelete(disease, catKey, disKey) {
    this.mcdpData[catKey][disease.key] = this.mcdpData[catKey][
      disease.key
    ].filter((dis, index) => index != disKey);
  }
  addDisease(event, mcdp, item) {
    const diseaseName = event.target.value;
    if (diseaseName.trim() !== '') {
      this.mcdpData
        .filter((dis, index) => dis.mcdp_category == mcdp.mcdp_category)[0]
        [item.key].push(diseaseName);
    }
  }
  onChangeValue(value, i) {
    this.mcdpData[this.mcdpCateogories[this.mcdpData.mcdp_category]][i] = value;
  }
  doneAllNotes(selectData) {
    debugger;
    this.appService.doneNotes(true);
    let showMessage;
    if (selectData == 'done') {
      showMessage = 'Do you want to save changes?';
    } else {
      this.isShow = false;
      showMessage =
        'You cannot edit this page once click "Yes".Do you want to continue?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: showMessage,
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (selectData == 'done') {
          console.log(this.transcrptionData);
          this.transcrptionData.mcdp_medical_data = this.mcdpData;
          this.appService
            .updateMCDPData(
              this.transcrptionData,
              this.transcrptionData.appointment_id
            )
            .subscribe(
              (response: any) => {},
              (error) => {
                this.transcriptLoader = false;
              }
            );
          console.log(this.tabIndex);
          this.savePres();

          this.router.navigate(['/appointment']);
        } else {
          if ((this.innerTabIndex = 0)) {
            this.printMcdpDocument(null);
          } else {
            this.innerTabIndex = 0;
            setTimeout(() => {
              this.printMcdpDocument(null);
            }, 1000);
          }
        }
      } else {
        this.isShow = true;
      }
    });
  }
  async printMcdpDocument(data) {
    this.loading = true;
    this.printType = data;
    debugger;
    if (!!this.prescriptionData) {
      let input: HTMLElement;

      var del = document.querySelectorAll<HTMLElement>('.medDel');
      var medInput = document.querySelectorAll<HTMLElement>('.medLeft input');
      var medNumber = document.querySelectorAll<HTMLElement>('.medLast input');

      // document.getElementById('med-pre-header').style.display = 'block';
      // document.getElementById('med-pre-header').style.height = '112px';
      document.getElementById('fullscreen').style.display = 'none';
      // document.getElementById('med-pre').style.padding = '20px';
      var lowerDetl = document.getElementById('lowerDetail');
      if (lowerDetl != null) {
        lowerDetl.style.display = 'none';
      }

      document.getElementById('txAreaInp').classList.add('d-none');
      document.getElementById('txAreaPar').classList.remove('d-none');
      document.getElementById('removeBtn').classList.add('d-none');

      del.forEach((x) => {
        x.style.display = 'none';
      });
      medInput.forEach((x) => {
        x.style.border = 'none';
      });
      medNumber.forEach((x) => {
        x.style.border = 'none';
      });
      input = document.getElementById('med-pre');
      this.downloadPDF(input);
    }
    debugger;

    setTimeout(() => {
      document.getElementById('txAreaPar').classList.add('d-none');
      document.getElementById('txAreaInp').classList.remove('d-none');
      document.getElementById('removeBtn').classList.remove('d-none');
      // document.getElementById('removeCerBtn').classList.remove('d-none');
    }, 10000);
  }
  printMcdpCer() {
    debugger;
    // this.moveToNextTab();
    let input: HTMLElement;
    // document.getElementById('med-cert-header').style.display = 'block';
    document.getElementById('med-cert-header').style.height = '112px';
    document.getElementById('med-cer').style.padding = '20px';
    document.getElementById('removeCerBtn').classList.add('d-none');
    // document.getElementById("med-cer").style.border="2px solid black"
    input = document.getElementById('med-cer');
    this.certify = true;
    this.downloadPDF(input);
  }
  completeMcdp(file) {
    debugger;
    this.isEditable = true;
    console.log;
    if (!!this.mcdpData) {
      this.transcrptionData.mcdp_medical_data = this.mcdpData;
      this.appService
        .updateMCDPData(this.transcrptionData, this.transcrptionData.id)
        .subscribe(
          (response: any) => {},
          (error) => {
            this.transcriptLoader = false;
          }
        );
    }

    if (this.prescriptionData.medicine_details[0].med !== '') {
      let medicine_details = encodeURIComponent('medicine_details');
      let medicine_details_value = encodeURIComponent(
        JSON.stringify(this.prescriptionData.medicine_details)
      );
      let advice = encodeURIComponent('advice');
      let advice_value = this.prescriptionData.advice;
      let tenant = encodeURIComponent('tenant');
      let tenant_value = encodeURIComponent(
        this.selectedAppointment.patient.tenant
      );
      let appointment_id = encodeURIComponent('appointment_id');
      let appointment_id_value = encodeURIComponent(
        this.selectedAppointment.id
      );
      let prescription_certificate_url = encodeURIComponent(
        'prescription_certificate_url'
      );
      console.log(this.selectedFile);
      let formData = new FormData();
      // if (this.selectedFile !== null) {
      //   formData.append(
      //     'prescription_certificate_url',
      //     this.selectedFile.name
      //   );
      // }

      // var saveData: FormData = new FormData();
      //pdfData.append('prescription_certificate_url', file, 'custom.pdf');
      let saveData = new FormData();

      saveData.set('medicine_details', medicine_details_value);
      saveData.set('advice', advice_value);
      saveData.set('tenant', tenant_value.toString());
      saveData.set('appointment_id', appointment_id_value.toString());
      saveData.append('prescription_certificate_url', file);
      //saveData = saveData.join('&');
      console.log(saveData);
      // saveData.append(prescription_certificate_url, file, 'custom.pdf');
      //saveData = saveData.join('&');
      // for (const key in saveData) {
      //   if (key !== 'prescription_certificate_url') {
      //     formData.append(`${key}`, `${saveData[key]}`);
      //   }
      // }
      debugger;
      console.log(saveData);

      this.appService.savePrescriptionComplete(saveData).subscribe(
        (response) => {
          if (response) {
            //
            let res: any = response;
            if (res.id) {
              this.snackBar.open(
                'Prescription has been upoaded successfully',
                'Close',
                { duration: 2000 }
              );
              // this.appService.doneNotes(true);
              // this.router.navigate(['/appointment']);
            }
          }
        },
        (error) => {
          // this.appService.doneNotes(true);
          // this.router.navigate(['/appointment']);
          this.snackBar.open(error, 'Close', { duration: 2000 });
        }
      );
      // this.uploadpdf(file,filename);
    }
    this.moveToNextTab();
  }

  // alert("2")
  // this.appService.doneNotes(true);
  // this.router.navigate(['/appointment']);
  // this.snackBar.open("Medical certificate saved successfully", 'Close', {duration: 2000});
  completeCertificate(file) {
    let from_date = encodeURIComponent('from_date');
    let from_date_value = encodeURIComponent(
      this.datepipe.transform(this.medicalCertData.from_date, 'MM-dd-yyyy')
    );
    let to_date = encodeURIComponent('to_date');
    let to_date_value = encodeURIComponent(
      this.datepipe.transform(this.medicalCertData.to_date, 'MM-dd-yyyy')
    );
    let days = encodeURIComponent('days');
    let days_value = encodeURIComponent(this.medicalCertData.days);
    let mcr_number = encodeURIComponent('mcr_number');
    let mcr_number_value = encodeURIComponent(this.medicalCertData.mcr_number);
    let tenant = encodeURIComponent('tenant');
    let tenant_value = encodeURIComponent(
      this.selectedAppointment.patient.tenant
    );
    let appointment_id = encodeURIComponent('appointment_id');
    let appointment_id_value = encodeURIComponent(this.selectedAppointment.id);
    // let medSaveData: any = [];

    // medSaveData.push(from_date + '=' + from_date_value);
    // medSaveData.push(to_date + '=' + to_date_value);
    // medSaveData.push(mcr_number + '=' + mcr_number_value);
    // medSaveData.push(days + '=' + days_value);
    // medSaveData.push(tenant + '=' + tenant_value);
    // medSaveData.push(appointment_id + '=' + appointment_id_value);
    // medSaveData = medSaveData.join('&');
    // console.log(medSaveData);

    let medSaveData = new FormData();

    medSaveData.set('from_date', from_date_value.toString());
    medSaveData.set('to_date', to_date_value.toString());
    medSaveData.set('days', days_value.toString());
    medSaveData.set('tenant', tenant_value.toString());
    medSaveData.set('appointment_id', appointment_id_value.toString());
    medSaveData.append('medical_certificate_url', file);
    //saveData = saveData.join('&');
    console.log(medSaveData);

    if (!!medSaveData) {
      this.appService.saveMedicalCertComplete(medSaveData).subscribe(
        (response) => {
          if (response) {
            //
            let res: any = response;
            if (res.id) {
              this.snackBar.open(
                'Medical Certificated has been upoaded successfully',
                'Close',
                { duration: 2000 }
              );
              // this.appService.doneNotes(true);
              // this.router.navigate(['/appointment']);
            }
          }
        },
        (error) => {
          // this.appService.doneNotes(true);
          //     this.router.navigate(['/appointment']);
          this.snackBar.open(error, 'Close', { duration: 2000 });
        }
      );
      // } else {
      //   this.snackBar.open('Enter the mandatory data to proceed', 'Close', {
      //     duration: 2000,
      //   });
      // }
    }
    this.isDisplay = true;
    this.loading = false;
  }

  moveDashboard() {
    this.router.navigate(['/appointment']);
  }
  openFullscreen(type: string) {
    const dialogRef = this.dialog.open(FullScreenComponent, {
      width: '70vw',
      height: '90vh',
      disableClose: true,
      data: {
        selectedAppointment: this.selectedAppointment,
        prescriptionData: this.prescriptionData,
        tenantData: this.tenantData,
        medicalCertData: this.medicalCertData,
        screen: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.prescriptionData = result.prescriptionData;
      }
    });
    return dialogRef.afterClosed();
  }

  transcriptReceive(array: any) {
    console.log(array);
    this.transcriptArray = array;
    this.peerId = this.transcriptArray[0].peerId;
  }

  updateNote() {
    this.isEditable = !this.isEditable;
  }
  getmcdpData(event) {
    console.log(event.target.value);
  }
  removeSeal() {
    debugger;
    this.hideSeal = false;
  }
  removeSign() {
    debugger;
    this.hideSign = false;
  }
}
