         <div class="patientContainer">
            <div class="row" style="margin-left:0px;margin-right:0px;">
                <div class="col-3 ml-4 mt-3" style="text-align:left;">
                    <mat-form-field appearance="fill">
                        <mat-label><b>Date Range</b></mat-label>
                        <mat-date-range-input [rangePicker]="picker1">
                          <input matStartDate placeholder="Start date" #dateRangeStart [(ngModel)]="fDate">
                          <input matEndDate placeholder="End date" #dateRangeEnd [(ngModel)]="tDate" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker1></mat-date-range-picker>
                      </mat-form-field>
                </div>

                <div class="col-5 mt-4" style="font-size: 1.5em;text-align: center;">
                  <label><b>{{selectedReport}}</b></label>
                </div>
                <!-- <div class="col-2">
                <mat-form-field appearance="outline" [style.width.px]=200>
                    <mat-select [(ngModel)]="selectedReport" (selectionChange)="reportChange($event.value)">
                      <mat-option *ngFor="let report of selectionList" [value]="report">
                        {{report}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
              </div> -->
              <div class="col-2 mt-3 ml-3">
                <mat-form-field appearance="outline" [style.width.px]=200>
                  <mat-select [(ngModel)]="exportString" (selectionChange)="onBtExport($event.value)">
                    <mat-option value="export">Export</mat-option>
                    <mat-option value="excel">Excel</mat-option>
                    <mat-option value="csv">CSV</mat-option>
                  </mat-select>
              </mat-form-field>
                <!-- <button (click)="onBtExport()" style="margin-bottom: 5px;margin-left: 50px;font-weight: bold;">Export to Excel</button> -->
              </div>
               <div class="col-1 mt-4 ml-4">
                <div class="closeIcon" (click)="closeReport()">X</div>
              </div>
            </div>
              <ag-grid-angular
                      style="height: 100%;padding-inline:2%"
                      class="ag-theme-alpine"
                      [columnDefs]="columnDefs"
                      [defaultColDef]="defaultColDef"
                      [rowData]="tableData"
                      [paginationAutoPageSize]="true"
                      [pagination]="true"
                      (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>