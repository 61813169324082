import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nricMask'
})
export class NricMaskPipe implements PipeTransform {
  public userData=JSON.parse(localStorage.getItem('userDetails'));
  transform(value: string) {
    if(this.userData.userAccess.is_staff){
      return value;
    } else {
      return ('' + value).slice(0, -4)
      .replace(/./g, '#')
      + ('' + value).slice(-4);
    }
  }

}
