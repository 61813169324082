<div class="closeIcon" (click)="closeDialog()">X</div>
<div class="header">
  {{ userData.edited ? "Edit User - " + userData.first_name : "Add User" }}
</div>
<mat-tab-group
  [selectedIndex]="selectedIndex"
  mat-align-tabs="start"
  (selectedTabChange)="onTabChanged($event)"
>
  <mat-tab label="General Details">
    <div class="user-container">
      <table>
        <tr>
          <td rowspan="2">
            <div class="imageURL">
              <label class="hoverable" for="fileInput">
                <img
                  [src]="
                    url
                      ? url
                      : userData?.gender == 'F'
                      ? '../../../assets/images/female_avatar.webp'
                      : 'https://www.w3schools.com/howto/img_avatar.png'
                  "
                />
                <div class="hover-text">Choose file</div>
                <label style="padding-left: 25%; margin-top: 10%"
                  ><b> Profile Picture </b></label
                ><br />
                <div class="background"></div>
              </label>
              <br />
              <input
                id="fileInput"
                type="file"
                (change)="onSelectFile($event)"
              />
            </div>
          </td>
          <td>
            <label
              ><span class="material-icons"> person </span
              ><b>First Name</b></label
            >
            <br />
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!userData.is_active"
                style="text-transform: capitalize"
                [(ngModel)]="userData.first_name"
                (ngModelChange)="searchUser($event)"
                (mouseleave)="leave($event)"
              />
            </mat-form-field>
            <br />
            <table *ngIf="showSearch" id="customers">
              <tr>
                <th>No:</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Govt Id</th>
                <th>Add</th>
              </tr>
              <tr *ngFor="let search of searchData">
                <td>{{ search.id }}</td>
                <td>
                  {{ search.first_name }} {{ search.middle_name }}
                  {{ search.last_name }}
                </td>
                <td>{{ MaskCharacter(search.phone_number, "#", 4) }}</td>
                <td>{{ MaskCharacter(search.govt_id, "#", 4) }}</td>
                <td><button (click)="cloneUser(search)">Add</button></td>
              </tr>
            </table>
          </td>
          <td>
            <label
              ><span class="material-icons"> person </span
              ><b>Middle Name</b></label
            >
            <br />
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!userData.is_active"
                style="text-transform: capitalize"
                [(ngModel)]="userData.middle_name"
              />
            </mat-form-field>
          </td>
          <td>
            <label
              ><span class="material-icons"> person </span
              ><b>Last Name</b></label
            >
            <br />
            <mat-form-field appearance="outline" [style.width.px]="235">
              <input
                matInput
                [disabled]="!userData.is_active"
                style="text-transform: capitalize"
                [(ngModel)]="userData.last_name"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <label><b>Govt Id</b></label
            ><br />
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!userData.is_active"
                placeholder="Govt Id"
                style="text-transform: capitalize"
                [(ngModel)]="userData.govt_id"
              />
            </mat-form-field>
          </td>
          <td>
            <span class="material-icons">female</span>
            <label><b>Gender</b></label>
            <br />
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="!userData.is_active"
                [(ngModel)]="userData.gender"
              >
                <mat-option
                  *ngFor="let gender of genders"
                  [value]="gender.value"
                >
                  {{ gender.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <label><b>Date of birth</b></label
            ><br />
            <mat-form-field appearance="outline">
              <input
                matInput
                #ref
                [matDatepicker]="picker"
                [value]="dob"
                (dateChange)="dateFormat(ref.value)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                style="color: #0984a8; font-size: 1em"
              ></mat-datepicker-toggle>
              <mat-datepicker #ref #picker></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span class="material-icons">phone</span>
            <label class="ph"><b>Country Code</b></label>
            <br />
            <mat-form-field class="example-full-width" appearance="outline">
              <input
                type="text"
                placeholder="Country"
                aria-label="Number"
                matInput
                [(ngModel)]="userData.phone_number_prefix"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                [disabled]="!userData.is_active"
                #auto="matAutocomplete"
                (optionSelected)="getPosts($event.option.value)"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option.dial_code"
                >
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
          <td>
            <span class="material-icons">phone</span>
            <label class="ph"><b>Phone</b></label>
            <br />
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!userData.is_active"
                placeholder="Number"
                [(ngModel)]="userData.phone_number"
              />
            </mat-form-field>
          </td>
          <td>
            <label
              ><span class="material-icons"> mail </span><b>Email</b></label
            >
            <br />
            <mat-form-field appearance="outline" [style.width.px]="235">
              <input
                matInput
                [disabled]="!userData.is_active"
                placeholder="Enter your email"
                [(ngModel)]="userData.email"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <br />
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="userData.is_active"
              [checked]="userData.is_active"
              >Active
            </mat-checkbox>
          </td>
          <td>
            <br />
            <mat-checkbox
              [disabled]="!userData.is_active || !loggedinUser.is_staff"
              class="example-margin"
              [(ngModel)]="userData.is_staff"
              [checked]="userData.is_staff"
              (mouseenter)="adminMouseEnter()"
              (mouseleave)="adminMouseLeave()"
            >
              Admin
            </mat-checkbox>
            <br />
            <div *ngIf="showWarning && userData.edited" class="warning-alert">
              <div class="warning">
                <mat-icon style="color: black; top: 5px">warning</mat-icon
                ><span style="margin-left: 10px">Warning</span>
              </div>
              <div class="tooltip-text">
                By Adding ADMIN access, a User will have access to all pages
                without any restriction. By Removing ADMIN access, a User will
                loss the access to perform key administrative tasks
              </div>
            </div>
          </td>
          <td>
            <br />
            <mat-checkbox
              *ngIf="showCheckbox"
              class="example-margin"
              [disabled]="!userData.is_active"
              [checked]="password"
              (change)="changePwd()"
              >Change password
            </mat-checkbox>
            <div *ngIf="password" class="password-popup">
              <div class="closeIconPwd" (click)="closePassword()">X</div>
              <div>
                <label><b>Old Password</b></label
                ><br />
                <input
                  [type]="showpwd ? 'text' : 'password'"
                  placeholder="Old Password"
                  [(ngModel)]="oldPassword"
                  class="login-password"
                />
                <mat-icon
                  *ngIf="!showpwd"
                  style="
                    vertical-align: top;
                    font-size: 1.4em;
                    cursor: pointer;
                    color: grey;
                    margin-left: -30px;
                    margin-top: 10px;
                  "
                  (click)="showPassword()"
                  >visibility</mat-icon
                >
                <mat-icon
                  *ngIf="showpwd"
                  style="
                    vertical-align: top;
                    font-size: 1.4em;
                    cursor: pointer;
                    color: grey;
                    margin-left: -30px;
                    margin-top: 10px;
                  "
                  (click)="showPassword()"
                  >visibility_off</mat-icon
                >
              </div>
              <br />
              <div>
                <label><b>New Password</b></label
                ><br />
                <input
                  [type]="showpwd1 ? 'text' : 'password'"
                  placeholder="New Password"
                  [(ngModel)]="newPassword"
                  class="login-password"
                  (ngModelChange)="StrengthChecker($event)"
                />
                <mat-icon
                  *ngIf="!showpwd1"
                  style="
                    vertical-align: top;
                    font-size: 1.4em;
                    cursor: pointer;
                    color: grey;
                    margin-left: -30px;
                    margin-top: 10px;
                  "
                  (click)="showPassword1()"
                  >visibility</mat-icon
                >
                <mat-icon
                  *ngIf="showpwd1"
                  style="
                    vertical-align: top;
                    font-size: 1.4em;
                    cursor: pointer;
                    color: grey;
                    margin-left: -30px;
                    margin-top: 10px;
                  "
                  (click)="showPassword1()"
                  >visibility_off</mat-icon
                >
              </div>
              <div style="padding-top: 20px; text-align: center">
                <button
                  mat-stroked-button
                  color="default"
                  (click)="closePassword()"
                >
                  Cancel</button
                >&nbsp;
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="savePassword()"
                >
                  Save
                </button>
              </div>
              <div
                *ngIf="tooltipText !== ''"
                class="passwordStatus"
                [ngStyle]="{
                  marginTop: '10px',
                  background:
                    tooltipText == 'Strong Password'
                      ? 'green'
                      : tooltipText == 'Medium Password'
                      ? 'orange'
                      : 'red'
                }"
              >
                {{ tooltipText }}
              </div>
            </div>
          </td>
        </tr>
      </table>
      <div style="padding-left: 15%">
        <button
          style="left: 280px"
          class="btn1"
          mat-stroked-button
          color="default"
          (click)="closeDialog()"
        >
          Cancel</button
        >&nbsp;
        <button
          style="left: 280px"
          class="btn"
          mat-stroked-button
          color="primary"
          (click)="nextDialog()"
        >
          Save
        </button>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Role Details">
    <div class="example-container">
      <table>
        <tr>
          <td>
            <label
              ><span class="material-icons"> person </span><b>Role</b></label
            ><br />
            <mat-form-field appearance="outline">
              <mat-select
                (selectionChange)="onRoleChange($event)"
                [disabled]="!userData.is_active"
                [(ngModel)]="userData.roles"
                multiple
              >
                <ng-container *ngFor="let role of roles">
                  <mat-option *ngIf="role.id !== 1" [value]="role.id">
                    {{ role.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
          <td *ngIf="userData.roles.includes(2)">
            <div class="SignImageURL">
              <label><b>Signature Image</b></label
              ><br />
              <label class="hoverable" for="fileInput1">
                <img
                  [src]="
                    fileUrl
                      ? fileUrl
                      : 'https://upload.wikimedia.org/wikipedia/commons/0/0c/Doctor_Tshimanga%27s_Signature.jpg'
                  "
                />
                <div class="hover-text">Choose file</div>
                <div class="background"></div>
              </label>
              <br />
              <input
                id="fileInput1"
                type="file"
                (change)="onSelectSignatureFile($event)"
              />
            </div>
          </td>
        </tr>
        <tr *ngIf="userData.roles.includes(2)">
          <td>
            <label
              ><span class="material-icons"> thumb_up_alt </span
              ><b>Speciality</b></label
            ><br />
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="!userData.is_active"
                (selectionChange)="onSpecialityChange($event)"
                [(ngModel)]="userData.specialities"
                multiple
              >
                <mat-option
                  *ngFor="let specialty of specialityArray"
                  [value]="specialty.id"
                >
                  {{ specialty.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <label><b>MCR Number</b></label>
            <br />
            <mat-form-field appearance="outline">
              <input
                matInput
                [disabled]="!userData.is_active"
                placeholder="MCR Number"
                [(ngModel)]="userData.mcr_number"
                (ngModelChange)="searchUser($event)"
                style="text-transform: capitalize"
              />
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <!-- <td *ngIf="userData.roles.includes(1)">
              <label><b>About :</b></label>
              <br>
          <mat-form-field appearance="outline">
            <textarea matInput name="aboutText" cols="35" rows="4" placeholder="About you" [(ngModel)]="userData.about" required></textarea>
          </mat-form-field>
            </td> -->
          <td>
            <!-- <div style="display: flex;align-items: center;" *ngIf="userData.roles.includes(1)">
                <mat-checkbox class="example-margin" 
                [(ngModel)]="isAcknowlegedDoctorFee" [checked]="isAcknowlegedDoctorFee">Active
                </mat-checkbox>
                <p style="margin-bottom: 5px;">Please set the Doctor Fees in Doctor Management > Manage Fees > Change Fee.</p>
              </div> -->
            <div style="margin-top: 20px">
              <button
                class="btn1"
                mat-stroked-button
                color="default"
                (click)="closeDialog()"
              >
                Cancel</button
              >&nbsp;
              <button
                class="btn"
                mat-stroked-button
                color="primary"
                (click)="saveDialog()"
              >
                Save
              </button>
            </div>
          </td>
        </tr>
      </table>
      <br /><br />
    </div>
  </mat-tab>
  <mat-tab label="About">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 pt-3 text-right" *ngIf="!isShow || !!isShow">
          <button
            mat-raised-button
            color="primary"
            class="btn"
            (click)="addDetails()"
          >
            Add
          </button>
        </div>
        <div
          *ngIf="
            (!isShow && !doctorEducation) ||
            (userData.edited == true && doctorEducation.length == 0)
          "
        >
          <!-- <div *ngIf="!doctorEducation[0]"> -->
          <h3 class="text-not-found">Add your Educational Details</h3>
          <!-- </div> -->
        </div>
        <div *ngIf="!!isShow">
          <div *ngIf="!!doctorEducation[0]">
            <div
              class="col-sm-12 m-auto p-3"
              *ngFor="let data of academicData; let i = index"
            >
              <!-- <h3 *ngIf="i!==0"><b>Degree{{i}}</b></h3> -->
              <div
                class="card card-body shadow bg-light"
                style="border-left: 7px"
              >
                <div class="col-sm-12 d-flex justify-content-between">
                  <h3 *ngIf="i >= 0">
                    <b>Degree-{{ i + 1 }}</b>
                  </h3>
                  <span
                    ><mat-icon
                      class="plus-icon"
                      style="font-size: 1.5em; cursor: pointer; color: green"
                      (click)="editDetail(data.id)"
                      >edit</mat-icon
                    ></span
                  >
                </div>
                <div class="row container">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-3">
                        <h3>Degree</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-8">
                        <h3>{{ data.degree }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-4">
                        <h3>Completed Year</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-7">
                        <h3>{{ data.degree_completed_year }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-3">
                        <h3>University</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-8">
                        <h3>{{ data.university }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-4">
                        <h3>College</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-7">
                        <h3>{{ data.college }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-3">
                        <h3>Country</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-8">
                        <h3>{{ data.degree_completed_country }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-4">
                        <h3>FMGE status (If Degree competed in abroad)</h3>
                      </div>
                      <div>
                        <h3>:</h3>
                      </div>
                      <div class="col-sm-7">
                        <h3>{{ data.fmge_status }}</h3>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>About Experience</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>B.E (EEE)zdfsdgfsdgsdgfdghfdhbdghgfhgfhjbfdhyhtghtrytrh</h3>
                    </div>
                  </div>
                </div>  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
        <div *ngIf="!!isShow">
          <div *ngIf="!!doctorEducation[0]">
            <div class="col-sm-12 pt-3 m-auto">
              <div class="mat-text">
                <h3 class="mr-4 align-self-center"><b>About</b></h3>
                <div class="d-flex">
                  <mat-form-field appearance="outline">
                    <textarea
                      matInput
                      name="aboutText"
                      cols="700"
                      rows="1"
                      placeholder="About you"
                      [(ngModel)]="doctorEducation[0].about"
                      [disabled]="editBio"
                      required
                    ></textarea>
                  </mat-form-field>
                  <div
                    class="col-sm pl-0 pt-2 text-right"
                    *ngIf="editBio == true"
                  >
                    <span
                      ><mat-icon
                        class="plus-icon"
                        style="font-size: 1.5em; cursor: pointer; color: green"
                        matTooltip="edit"
                        (click)="updateBio()"
                        >edit</mat-icon
                      ></span
                    >
                  </div>
                  <div
                    class="col-sm pl-0 pt-2 text-right"
                    *ngIf="editBio == false"
                  >
                    <span
                      ><mat-icon
                        class="plus-icon"
                        style="
                          font-size: 1.5em;
                          cursor: pointer;
                          color: skyblue;
                        "
                        matTooltip="save"
                        (click)="updateDoctorDetail(doctorEducation[0].about)"
                        >save</mat-icon
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card card-body shadow bg-light" style="border-left: 7px;">
                <div class="col-sm-12 text-right">
              <span><mat-icon class="plus-icon" style="font-size:1.5em;cursor:pointer;color:green"  (click)="editDetail()">edit</mat-icon></span>
            </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>Degree</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>B.E (EEE)</h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>University</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>M.K University</h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>College</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>M.M college</h3>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>FMGE status (If Degree competed in abroad)</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>B.E (EEE)</h3>
                    </div>
                  </div>
                </div> -->
        <!-- <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-5">
                      <h3>About Experience</h3>
                    </div>
                    <div class="col-sm-1">
                      <h3>:</h3>
                    </div>
                    <div class="col-sm-6">
                      <h3>B.E (EEE)zdfsdgfsdgsdgfdghfdhbdghgfhgfhjbfdhyhtghtrytrh</h3>
                    </div>
                  </div>
                </div> -->
        <!-- </div>
              </div> -->

        <!-- <div class="col-sm-6"> -->

        <!-- </div> -->
        <!-- <div class="col-sm-6">
              <div class="card card-body shadow bg-light" style="border-left: 5px solid red;">
                <div class="d-flex justify-content-between">
                  <label>Degree:</label>
                  <h6>B.E (EEE)</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <label>University:</label>
                  <h6>B.E (EEE)</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <label>Degree:</label>
                  <h6>B.E (EEE)</h6>
                </div>
                <div class="d-flex justify-content-between">
                  <label>Degree:</label>
                  <h6>B.E (EEE)</h6>
                </div>
              </div>
            </div> -->
        <!-- </div>  -->
        <!-- </div>
        </div> -->
        <div *ngIf="!!isShow">
          <div *ngIf="!!showDetails">
            <div style="width: 500px">
              <div style="display: flex; flex-direction: column">
                <label>Degree :</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Degree"
                    [(ngModel)]="doctorDetails.degree"
                    required
                  />
                </mat-form-field>
              </div>
              <div style="display: flex; flex-direction: column">
                <label>Degree completed year :</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Degree completed year"
                    [(ngModel)]="doctorDetails.degree_completed_year"
                    required
                  />
                </mat-form-field>
              </div>
              <div style="display: flex; flex-direction: column">
                <label>Degree completed University :</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Degree completed University"
                    [(ngModel)]="doctorDetails.university"
                    required
                  />
                </mat-form-field>
              </div>
              <div style="display: flex; flex-direction: column">
                <label>Degree completed Country :</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Degree completed Country"
                    [(ngModel)]="doctorDetails.degree_completed_country"
                    required
                  />
                </mat-form-field>
              </div>
              <div style="display: flex; flex-direction: column">
                <label>FMGE status (If Degree competed in abroad) :</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="FMGE status (If Degree competed in abroad)"
                    [(ngModel)]="doctorDetails.fmge_status"
                  />
                </mat-form-field>
              </div>
              <div style="display: flex; flex-direction: column">
                <label>About experience :</label>
                <mat-form-field appearance="outline">
                  <textarea
                    matInput
                    name="aboutText"
                    cols="35"
                    rows="4"
                    placeholder="About"
                    [(ngModel)]="doctorDetails.about"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <div>
              <button
                class="btn1"
                mat-stroked-button
                color="default"
                (click)="closeDialog()"
              >
                Cancel</button
              >&nbsp;
              <button
                class="btn"
                mat-stroked-button
                color="primary"
                (click)="saveDoctor()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
