<div class="left-panel-container">
    <div *ngIf="!patientData.flag">
    <div class="medHeader">
        <div style="display:inline-block;float:left;width:30%"><img src="../../../assets/images/logo.JPG" style="width:3vw;height: 6vh;"/></div>
        <div style="display:inline-block;float:left;width:70%;text-align: left;">
            <div class="leftPanelHeader">medosys</div>
            <div class="leftPanelHeader2">{{subdomain[0]}}</div>
        </div>
    </div>
    <div style="padding:1vh 10px 3vh 10px;border-bottom: 1px solid #d3d4d4;">
        <div class="notes-btn-container" (click)="newAppointment()"><button mat-raised-button class="newApp-btn">
            <mat-icon class="plus-icon">dashboard</mat-icon>Dashboard</button></div>

        <div *ngIf="access?.New_Appointment_button.read" class="newApp-btn-container" (click)="dashboardClick()"><button  mat-raised-button class="newApp-btn">
            <mat-icon class="plus-icon">date_range</mat-icon>Appointments</button></div>
            
        <div class="appmt-btn-container"><button (click)="doctorMgmtClick()"  mat-raised-button class="newApp-btn">
            <mat-icon class="plus-icon">speaker_notes</mat-icon>Doctor Management</button></div>

        <div class="appmt-btn-container"><button (click)="patientRecordClick()" mat-raised-button class="newApp-btn">
            <mat-icon class="plus-icon">supervised_user_circle</mat-icon>Patient Records</button></div>

        <div class="appmt-btn-container"><button (click)="requestRefundClick()" mat-raised-button class="newApp-btn">
            <mat-icon class="plus-icon">attach_money</mat-icon>Refund/Reschedule Requests</button></div>

        <div class="appmt-btn-container" [matMenuTriggerFor]="reports"><button mat-raised-button class="newApp-btn"
            >
            <mat-icon class="plus-icon">list_alt</mat-icon>Reports</button></div>
    </div>
    <mat-menu #reports="matMenu" yPosition="above" xPosition="before" class="myMenu" backdropClass="sg-vertical-sub-menu">
        <button mat-menu-item (click)="reportClick('Patient Appointment')">Patient Appointment</button>
        <button mat-menu-item (click)="reportClick('Appointment')">Appointment</button>
        <button mat-menu-item (click)="reportClick('Disease Specific')">Disease Specific</button>
        <button mat-menu-item (click)="reportClick('Payment Report')">Payment Report</button>
        <button mat-menu-item (click)="reportClick('Doctor Report')">Doctor Report</button>
      </mat-menu>
    <div>
        <div *ngIf="access?.Other_doctors.read" class="doctorList">
            <label style="margin-left:25px;color: black;font-size: 1.2em;font-weight: 500;">Doctor Status</label>
            <div class="leftDoc">
            <div class="name-btn">
                <input type="search" placeholder="Search by name" (keyup)="searchDoctor($event)"/>
                <!-- <div class="name-tick">
                    <mat-icon>check</mat-icon>
                </div>
                <div class="name-btn-name" style="text-transform: capitalize;">{{userName}}</div> -->
            </div>
            <div class="list-container">
                <!-- <div class="listHeader">Other Doctors</div> -->
                    <ul class="docList">
                        <li *ngFor="let select of providers">
                            <mat-checkbox *ngIf="select.is_active" class="listCheckBox" [(ngModel)]="select.checked" (change)="doctorSelect()">
                                <img [src]="select.avator_url ? select.avator_url : '../../../assets/images/avator.JPG'" style="width:30px;height:25px;border-radius:50%;"/>
                                 <span class="fname">Dr. {{select.first_name}}</span>
                                 <span [title]="select.online?'online':'offline'" [ngClass]="select.online?'online':'offline'"></span>
                            </mat-checkbox></li>
                    </ul>
            </div>
        </div>
        </div>
    </div>
    </div>
    <div *ngIf="patientData.flag">
        <div class="container">
            <div class="first-div">
                <img [src]="patientList[0].avator_url ? patientList[0].avator_url : '../../../assets/images/avator.JPG'" alt="patient image" style="height: 200px; width: 100%;border-radius: 50%;">
            </div>
            <div class="second-div">
                <h3 class="martin">{{patientList[0].first_name}} {{patientList[0].last_name}}</h3>
                <p class="number"><b>ID: {{patientList[0].mrn | nricMask}}</b></p>
                <!-- <button class="update">update</button>
                <button class="share">share</button> -->
            </div>  
            <div class="third-div">
                <h3 class="details"><b>Details</b></h3>
                <table class="table">
                    <tr>
                        <td class="starter">D.O.B</td>
                        <td class="ender" >{{patientList[0].date_of_birth}}</td>
                      </tr>
                      <tr>
                        <td class="starter">gender</td>
                        <td class="ender">{{patientList[0].gender=='M'?'Male':'Female'}}</td>
                      </tr>
                      <tr>
                        <td class="starter">marital status</td>
                        <td class="ender">{{patientList[0].marital_status=='M'?'Married':'Not-Married'}}</td>
                      </tr>
                      <tr>
                        <td class="starter">children</td>
                        <td class="ender">{{patientList[0].children=='Y'?'Yes':'No'}}</td>
                      </tr>
                      <tr>
                        <td class="starter">blood group</td>
                        <td class="ender">{{patientList[0].blood_group=='OP'?'O+ve':
                            patientList[0].blood_group=='AP'?'A+ve':
                            patientList[0].blood_group=='BP'?'B+ve':
                            patientList[0].blood_group=='ON'?'O-ve':
                            patientList[0].blood_group=='AN'?'A-ve':'B-ve'}}</td>
                      </tr>
                      <tr>
                        <td class="starter">height(m)</td>
                        <td class="ender">{{patientList[0].height}} mts</td>
                      </tr>
                      <tr>
                        <td class="starter">weight(kg)</td>
                        <td class="ender" >{{patientList[0].weight}} kgs</td>
                      </tr>
                      <tr>
                        <td class="starter">BMI(kg/m)</td>
                        <td class="ender">{{patientList[0].bmi}} kg/m2</td>
                      </tr>
                      <tr>
                        <td class="starter">Smoker</td>
                        <td class="ender" *ngIf="patientList[0].smoker == 'N'">No</td>
                        <td class="ender" *ngIf="patientList[0].smoker != 'N'">Yes</td>
                      </tr>
                      <tr>
                        <td class="starter">Allergies</td>
                        <td class="ender" *ngIf="patientList[0].allergies == '1'" >Yes</td>
                        <td class="ender" *ngIf="patientList[0].allergies != '1'" >No</td>
                      </tr>
                </table>
            </div>       
        </div>
    </div>
</div>
