<div class="container-fluid med-login">
    <div class="closeIcon" (click)="closeDialog()">X</div>
    <div class="row">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 med-login-right">
        <div class="login-side">
            <div class="login-logo">
                <img src="../../../assets/images/logo.JPG" alt="Medosys.ai">
            </div>
            <div class="login-label" style="margin-right:20px;">medosys</div>
            <div class="login-label" style="margin-right:20px;margin-top:3vh;color:rgb(9, 8, 8);font-size: 1.2em;">Forgot Password</div>
            <div style="margin-top:6vh">
                <span style="padding:0px;margin:0px 0px 0px 10px;"><mat-icon style="font-size:0.75em;color:white;height:0px;width:0px">email</mat-icon>
                <span class="loginInput-label">Enter your registered Email here</span>
                </span>
                <input type="email" style="margin-top:2vh" placeholder="enter your email here"  [(ngModel)]="userMail" class="login-email"/>
                <label class="errorMsg" *ngIf="showError"> * Please enter the valid Email Id</label>
                <label class="resultMsg" *ngIf="showResult"> {{result}}</label>
                
            </div>
            <!-- <div *ngIf="showChange">
            <div style="margin-top:2vh">
            <label class="login-label">You can Change your password here</label>    
            </div>
            <div style="margin-top:2vh">
                <span style="padding:0px;margin:0px 0px 0px 10px;"><mat-icon style="font-size:0.75em;color:white;height:0px;width:0px">lock</mat-icon>
                <span  class="loginInput-label">Old Password</span>
                </span>
                <input type="password" placeholder="enter your password" [(ngModel)]="oldPassword" class="login-password"/>
            </div>
            <div style="margin-top:2vh">
                <span style="padding:0px;margin:0px 0px 0px 10px;"><mat-icon style="font-size:0.75em;color:white;height:0px;width:0px">lock</mat-icon>
                <span  class="loginInput-label">New Password</span>
                </span>
                <input type="password" placeholder="enter your password" [(ngModel)]="newPassword" class="login-password"/>
                <label class="errorMsg" *ngIf="showchangepwdError"> * Please enter the valid credentials</label>
                <label class="resultMsg" *ngIf="showchangepwdResult"> {{pwdresult}}</label>
            </div>
            </div> -->
            
            <div style="margin-top:2vh">
                <button type="submit" class="login-submit" (click)="proceedForgetPassword()" *ngIf="!showResult">Proceed</button>
                <button type="submit" class="login-submit" (click)="closeDialog()" *ngIf="showResult">Login Now</button>
            </div>
            <!-- <div style="margin-top:2vh" *ngIf="showChange">
                <button type="submit" class="login-submit" (click)="changePassword()">Change</button>
            </div> -->
        </div>
    </div>
    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 med-login-left"> 
        <div class="h1-label">medosys</div>
        <div class="h2-label">Password reset link will be sent to your registered email id</div>
        <div class="h3-label">You can reset the password through that link</div>
        <div class="login-img"><img src="../../../assets/images/login_Image.svg" alt="Medosys.ai"></div>
    </div>
    </div>
    </div>