import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { VideoService } from '../../service/video.service';
import { CallInfoDialogComponent, DialogData } from './dialog/callinfo-dialog.component';
import * as RecordRTC from 'recordrtc';
import * as S3 from 'aws-sdk/clients/s3';
import { AppointmentService } from 'src/app/service/appointment.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-mobile-video',
  templateUrl: './mobile-video.component.html',
  styleUrls: ['./mobile-video.component.css']
})
export class MobileVideoComponent implements OnInit {
  // public isCallStarted$: Observable<boolean>;
  // private peerId: string;
  // record;
  // url;
  // callEnds:boolean;
  // @Output()
  // blobPass = new EventEmitter<string>();
  // @Input() selectedAppointment: any;
  // file:any;
  // @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  // @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;
  // @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
  // constructor(public dialog: MatDialog, private callService: VideoService,
  //   public http: HttpClient,private route: ActivatedRoute,
  //   private appService: AppointmentService) { 
  //   this.isCallStarted$ = this.callService.isCallStarted$;
  //   this.peerId = this.callService.initPeer();
  // }
 
  ngOnInit(): void {
  //   let id:any;
  //   let auth:any;
  //   this.route.queryParams.subscribe(params => {
  //     id = params['id'];
  //     auth = params['auth'];
  //   });
  //   const apiURL = environment.baseUrl + 'api/v1/appointments/patient_appointment/'+id
  //   let header = new HttpHeaders({'Authorization': 'Bearer ' + auth,'accept': 'application/json','Content-Type': 'application/json'});
  //   this.http.get(apiURL,{headers : header}).subscribe((response) => {
  //     if (response) {
  //     console.log(response)
  //     let data:any=response;
  //     this.selectedAppointment=data;
  //   }
  // }, (error) => {
  //   console.log(error);
  // });

  //   this.callService.localStream$
  //     .pipe(filter(res => !!res))
  //     .subscribe(stream => {
  //       this.localVideo.nativeElement.srcObject = stream;
  //       this.successCallback(stream);
  //     })
  //   this.callService.remoteStream$
  //     .pipe(filter(res => !!res))
  //     .subscribe(stream => {
  //       this.remoteVideo.nativeElement.srcObject = stream
  //       this.successCallback(stream);
  //     });
    }
  
  // ngOnDestroy(): void {
  //   this.callService.destroyPeer();
  // }
  // public successCallback(stream) {
  //   var options = {
  //   mimeType: "audio/wav",
  //   numberOfAudioChannels: 1,
  //   sampleRate: 50000,
  //   };
  //   //Start Actuall Recording
  //   var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
  //   this.record = new StereoAudioRecorder(stream, options);
  //   this.record.record();
  //   }
  // public showModal(joinCall: boolean): void {
  //   if(this.selectedAppointment.video_call_url!==null){
  //     let url=this.selectedAppointment.video_call_url.replace('https://','');
  //     url=url.replace('.com','');
  //     this.selectedAppointment.video_call_url=url;
  //   }
  //   let dialogData: DialogData = joinCall ? ({ peerId: this.selectedAppointment.video_call_url, joinCall: true }) : ({ peerId: this.peerId, joinCall: false });
  //   const dialogRef = this.dialog.open(CallInfoDialogComponent, {
  //     width: '250px',
  //     data: dialogData
  //   });
  //   if(!joinCall){
  //     //alert(this.peerId)
  //     let appmt_data={
  //             "appointment_date":this.selectedAppointment.appointment_date,
  //             "doctor_id":this.selectedAppointment.doctor_id,
  //             "patient_id":this.selectedAppointment.patient_id,
  //             "questionnaire_url":this.selectedAppointment.questionnaire_url,
  //             "booking_date":this.selectedAppointment.booking_date,
  //             "appointment_status":this.selectedAppointment.appointment_status,
  //             "questionnaire_status":this.selectedAppointment.questionnaire_status,
  //             "notes_status":this.selectedAppointment.notes_status,
  //             "video_call_url":"https://"+this.peerId+".com"
  //           }
  //           var inputParam = Object.keys(appmt_data).map(key => key + '=' + encodeURIComponent(appmt_data[key])).join('&');
  //           console.log(inputParam)
  //           this.appService.editAppointment(inputParam,this.selectedAppointment.id).subscribe((response) => {
  //             if (response) {
  //               console.log(response)
  //               this.selectedAppointment=response;
  //               }
  //           }, (error) => {
  //             console.log(error)
  //             if(error.status=404){
  //               console.log(error.statusText);
  //             }
  //           });
  //   }
  //   dialogRef.afterClosed()
  //     .pipe(
  //       switchMap(peerId => 
  //         joinCall ? of(this.callService.establishMediaCall(peerId)) : of(this.callService.enableCallAnswer(peerId))
  //       ),
  //     )
  //     .subscribe(_  => { });
  // }

  // public endCall() {
  //   this.callService.closeMediaCall();
  //   this.callEnds=true;
  //   // this.record.stop(this.processRecording.bind(this));
  //   //this.record.stop();
  //   //this.appService.video.subscribe(data => this.selectedAppointment = data);
  // }
  
  // //processRecording(blob) {
    
  //   // this.url = URL.createObjectURL(blob);
  //   // console.log("blob", blob);
  //   // console.log("url", this.url);

  //   // var d = new Date();
  //   // let month =(d.getMonth() + 1);
  //   // let day = d.getDate();
  //   // let year = d.getFullYear();
  //   // let hours = d.getHours();
  //   // let minutes = d.getMinutes();
  //   // let secs = d.getSeconds();
  //   // let pickedDate = year+"_"+month+"_"+day+"_"+hours+"_"+minutes+"_"+secs;
  //   // let filename = pickedDate+'.wav';
  //   // this.file = new File([blob],filename,{ type:"audio/wav" });
  //   // this.uploadFile(this.file);
  //   //}
  //   processRecording(blob) {
  //     this.url = URL.createObjectURL(blob);
  //     console.log("blob", blob);
  //     console.log("url", this.url);
  
  //     var d = new Date();
  //     let month =(d.getMonth() + 1);
  //     let day = d.getDate();
  //     let year = d.getFullYear();
  //     let hours = d.getHours();
  //     let minutes = d.getMinutes();
  //     let secs = d.getSeconds();
  //     let pickedDate = year+"_"+month+"_"+day+"_"+hours+"_"+minutes+"_"+secs;
  //     let filename = this.selectedAppointment.id.toString()+"_"+pickedDate+'.wav';
  //     this.file = new File([blob],filename,{ type:"audio/wav" });
  //     this.blobPass.emit(this.file)
  //     }
  //   uploadFile(file) {
  //     const contentType = file.type;
  //     const bucket = new S3(
  //           {
  //               accessKeyId: 'AKIA5LEOLHXEHTEOM5IL',
  //               secretAccessKey: '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',
  //               region: 'ap-southeast-1'
  //           }
  //       );
  //       const params = {
  //           Bucket: 'medosys-dev-audio-file',
  //           Key: file.name,
  //           Body: file,
  //           ACL: 'public-read',
  //           ContentType: contentType
  //       };
  //       bucket.upload(params, (err, data) => {
  //         if (err) {
  //           console.log(err, 'there was an error uploading your file');
  //         } else {
  //           console.log(data.Key+ ' uploaded successfully');    
  //         }        
  //         return true;
  //       });
  // }
}
