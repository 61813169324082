import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.css'],
})
export class GaugeChartComponent {
  fromDate: any;
  toDate: any;
  fDate: any;
  tDate: any;
  initFromDate: any;
  initToDate: any;
  _graph: any;
  chartWidth: number = 1300;
  @Input() dateRange: any;
  @Input() chartPopup: any;

  constructor(
    public appService: AppointmentService,
    public datepipe: DatePipe
  ) {}
  ngOnChanges() {
    // this.loadInitData(this.parentData);
    if (!!this.dateRange.Start_date && !!this.dateRange.End_date) {
      this.loadChart(this.dateRange);
    }
  }

  ngOnInit(): void {
    console.log(this.chartPopup);
    var d = new Date();
    let prevMonth = d.getMonth() + 1;
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    let end = prevMonth + '-' + prevDay + '-' + prevYear;
    this.initToDate = prevMonth + '-' + prevDay + '-' + prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev = dPrev.getMonth() + 1;
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    let start = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.initFromDate = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.fromDate = this.datepipe.transform(start, 'MM-dd-yyyy');
    this.toDate = this.datepipe.transform(end, 'MM-dd-yyyy');
    if (!this.dateRange) {
      this.loadChart(null);
    }
  }
  loadChart(param) {
    console.log(this.dateRange);
    debugger;
    if (!!param) {
      let appParams = {
        start_date: param.Start_date,
        end_date: param.End_date,
      };
      this.appService.gaugeChart(appParams).subscribe((response) => {
        if (response) {
          console.log(response);
          let ch = (response.layout.height = 550);
          debugger;
          if (!!this.chartPopup) {
            let res = (response.layout.width = 1300);
            console.log(res);
            let ch = (response.layout.height = 550);
          }
          this._graph = response;
        }
      });
    } else {
      let appParams = {
        start_date: this.fromDate,
        end_date: this.toDate,
      };

      this.fDate = new Date(this.fromDate);
      this.tDate = new Date(this.toDate);
      try {
        this.appService.gaugeChart(appParams).subscribe((response: any) => {
          if (response) {
            let ch = (response.layout.height = 550);
            if (!!this.chartPopup) {
              let res = (response.layout.width = this.chartWidth);
              console.log(res);
              let ch = (response.layout.height = 550);
            }

            this._graph = response;
            console.log('guage chart ------------' + this._graph);
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
