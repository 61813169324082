import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { NotesComponent } from './components/notes/notes.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { MobileVideoComponent } from './components/mobile-video/mobile-video.component';

const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'appointment', component: AppointmentComponent },
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'userList', component: UserListComponent },
  { path: 'notes', component: NotesComponent },
  { path: 'questionnaire', component: QuestionnaireComponent },
  { path: 'notesList', component: NotesListComponent },
  { path: 'mobileVideo', component: MobileVideoComponent },
  { path: '***', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
