import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService } from '../../service/appointment.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../shared_components/alert-dialog/alert-dialog.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgExtension } from '@angular/compiler-cli/src/ngtsc/shims/src/expando';
import { DatePipe } from '@angular/common';
import { Index } from 'aws-sdk/clients/resourceexplorer2';
@Component({
  selector: 'app-user-appointment',
  templateUrl: './user-appointment.component.html',
  styleUrls: ['./user-appointment.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class UserAppointmentComponent implements OnInit {
  public MRNData: any;
  newUser: boolean = true;
  public appointmentData: any;
  public patientDetails: any;
  public timeResult: any = [];
  public morningTime: any = [];
  public anoonTime: any = [];
  public eveningTime: any = [];
  public providers: any = [];
  public allProviders: any = [];
  public telephoneCodes: any = [];
  public countryCode: any;
  public newAppointment: boolean = true;
  public patientNotFound: boolean = false;
  public tilePage: boolean = true;
  public existingUser: boolean = false;
  public headerText: string = 'Patient Details';
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public selectedProvider: any;
  public selectedTime: string = '';
  public textDate: string = '';
  public pickedDate: string = '';
  public todayFormat: string = '';
  public pickedDateMM: string = '';
  public finalScreen: boolean = false;
  public timingScreen: boolean = false;
  public showLanding: boolean = true;
  public appointmentId: any;
  public edited: boolean = false;
  selectedSpeciality: string = '';
  // spinner:boolean=false;
  validMRN: string = null;
  today: Date = new Date();
  currentTime = this.today.getHours() + '.' + this.today.getMinutes();
  //popupFlag:boolean=false;
  public monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  //public currentDate
  genders = [
    { value: 'M', viewValue: 'Male' },
    { value: 'F', viewValue: 'Female' },
  ];
  mStatus = [
    { value: 'S', viewValue: 'Single' },
    { value: 'M', viewValue: 'Married' },
  ];
  bGroup = [
    { value: 'AP', viewValue: 'A+' },
    { value: 'AN', viewValue: 'A-' },
    { value: 'BP', viewValue: 'B+' },
    { value: 'BN', viewValue: 'B-' },
    { value: 'OP', viewValue: 'O+' },
    { value: 'ON', viewValue: 'O-' },
  ];
  IDType = [
    { value: '1', viewValue: 'NRIC/FIN' },
    { value: '2', viewValue: 'PASSPORT' },
  ];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  dob: any;
  timeSlot: any = [];
  tenantId: any = 1;
  morningSelectedElementId: string | null = null;
  noonSelectedElementId: string | null = null;
  eveningSelectedElementId: string | null = null;
  timeSlotData: any;
  isSaturday: any;
  isSunday: any;
  weekEnd: any;
  tenantName: any;
  isgenerate: boolean = false;
  idType: any;
  selectedDate: any;
  holiday: any = {};
  holidayDate: any;
  loginTenantId:any;
  constructor(
    public appService: AppointmentService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private renderer: Renderer2,
    private el: ElementRef,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    console.log(this.userData);
    let userData = JSON.parse(localStorage.getItem('userDetails'));
    this.loginTenantId=userData.tenant_details[0]
    // var tenant_value=encodeURIComponent(this.userData.userAccess.tenant);
    let tenant = this.userData.tenant_details.filter((a) =>
      a.some((b) => b === this.userData.userAccess.tenant)
    );
    console.log(this.userData.userAccess.tenant);
    console.log(tenant, tenant[0][2]);
    this.tenantName = tenant[0][2];
    // let days=[]
    //   const selectedDateTime = new Date();
    //   let da = selectedDateTime.toLocaleDateString('en-US', { weekday: 'long' });
    //   console.log(da)
    //  let nxt=selectedDateTime.getDate()+1;
    //  console.log(nxt)
    for (let i = 0; i <= 6; i++) {
      const selectedDateTime = new Date();
      console.log(selectedDateTime);
      const nxt = new Date();
      nxt.setDate(selectedDateTime.getDate() + i);
      // let x=new Date(nxt)
      console.log(nxt);
      let num = nxt.getUTCDate();
      console.log(num);
      let da = nxt.toLocaleDateString('en-US', { weekday: 'long' });
      let week = da.substring(0, 3);
      console.log(week);
      let slot: days = {
        day: week,
        date: num,
        value: nxt,
        active: i == 0 ? true : false,
      };
      this.timeSlot.push(slot);
      console.log(this.timeSlot);
      console.log(this.timeSlot.day);
      console.log(this.timeSlot.date);
    }
    this.userData = JSON.parse(localStorage.getItem('userDetails'));
    var d = new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    this.selectedDate = day + '-' + month + '-' + year;
    this.pickedDateMM = month + '-' + day + '-' + year;
    this.pickedDate = year + '-' + month + '-' + day;
    this.todayFormat = year + '-' + month + '-' + day;
    console.log(this.todayFormat, this.pickedDate);
    this.textDate = this.monthArray[month - 1] + ' ' + day + ' ' + year;
    //this.pickDateMM
    this.MRNData = {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      avator_url: '',
      phone_number: '',
      phone_number_prefix: '',
      email: '',
      gender: '',
      mrn: '',
      masked_mrn: '',
      tenant: this.userData.userAccess.tenant,
      search: '',
      idType: '1',
      marital_status: 'S',
      allergies: 'N',
      bf_percentage: null,
      blood_group: null,
      bmi: null,
      children: 'N',
      height: null,
      smoker: 'N',
      weight: null,
    };
    this.getTimeslot();
    this.getScheduleTimeslot();
    this.getHoliday();
    // this.onLoadTimes();
    // this.onLoadTelephoneCodes();
    // this.onGetProviders();
    // this.selectedProvider=0;
    // this.getBookedTimes();
    this.appService.currentMessage.subscribe(
      (message) => (this.showLanding = message)
    );
    this.appService.currentTenant.subscribe((tenant) =>
      this.loadAllData(tenant)
    );
    this.appService.currentNotesFlag.subscribe(
      (data) => (this.showLanding = data)
    );
    this.edited = false;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }
  loadAllData(id) {
    // this.userData.userAccess.tenant=id;
    // this.onLoadTimes();
    // this.getTimeslot()
    this.onLoadTelephoneCodes();
    this.onGetProviders();
    this.selectedProvider = 0;
    this.getBookedTimes();
  }
  mask(word) {
    if (word.length <= 4) {
      return word;
    } else {
      var masked =
        word.substring(0, word.length - 4).replace(/[a-z\d]/gi, '*') +
        word.substring(word.length - 4, word.length);
      return masked;
    }
  }
  onProviderChange(data) {
    this.selectedProvider = data.value;
    console.log(this.selectedProvider);
    this.blockPastTime();
    this.getBookedTimes();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.telephoneCodes.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  getPosts(countryCode) {
    this.countryCode = countryCode;
    this.MRNData.phone_number_prefix = countryCode;
  }
  onSearch() {
    this.isgenerate = true;
    let search: any =
      this.MRNData.mrn !== '' && this.MRNData.mrn !== undefined
        ? { mrn: this.MRNData.mrn }
        : this.MRNData.phone_number !== '' &&
          this.MRNData.phone_number !== undefined
        ? { phone_number: this.MRNData.phone_number }
        : this.MRNData.email !== '' && this.MRNData.email !== undefined
        ? { email: this.MRNData.email }
        : this.MRNData.date_of_birth !== '' &&
          this.MRNData.date_of_birth !== undefined
        ? { date_of_birth: this.MRNData.date_of_birth }
        : this.MRNData.first_name !== '' &&
          this.MRNData.first_name !== undefined
        ? { first_name: this.MRNData.first_name }
        : '';

    var formBody: any = [];
    for (var property in search) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(search[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    this.appService.searchMRN(formBody).subscribe(
      (response) => {
        if (response) {
          let allMRN: any = response;
          console.log(response);
          console.log(response[0].tenant);
          this.tenantId = response[0].tenant;
          if (allMRN.length > 0) {
            this.MRNData = allMRN[0];
            this.MRNData.masked_mrn = this.mask(this.MRNData.mrn);
            this.MRNData.idType = this.MRNData.mrn.length == 9 ? '1' : '2';
            this.dob = new Date(this.MRNData.date_of_birth);
            this.headerText = 'Patient Details';
            this.edited = true;
            this.newUser = false;
            this.isgenerate = true;
          } else {
            this.headerText = 'Patient Not Found';
            this.newUser = false;
          }
          this.patientNotFound = true;
          this.newAppointment = false;
        }
      },
      (error) => {
        //console.log(error)
        if ((error.status = 404)) {
          this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
          this.headerText = 'Patient Not Found';
          this.newUser = true;
          this.patientNotFound = true;
          this.newAppointment = false;
        }
      }
    );
  }
  mrnValidation() {
    debugger;
    console.log(this.MRNData.idType);
    if (this.MRNData.idType == '1') {
      if (
        (this.MRNData.mrn.charAt(0) == 'S' ||
          this.MRNData.mrn.charAt(0) == 's' ||
          this.MRNData.mrn.charAt(0) == 'f' ||
          this.MRNData.mrn.charAt(0) == 'T' ||
          this.MRNData.mrn.charAt(0) == 't' ||
          this.MRNData.mrn.charAt(0) == 'F' ||
          this.MRNData.mrn.charAt(0) == 'G' ||
          this.MRNData.mrn.charAt(0) == 'g') &&
        this.MRNData.mrn.charAt(this.MRNData.mrn.length - 1).match(/[a-z]/i) !==
          null &&
        this.MRNData.mrn.match(/\d+/) !== null &&
        this.MRNData.mrn.match(/\d+/) &&
        this.MRNData.mrn.match(/\d+/).toString().length == 7
      ) {
        this.validMRN = 'false';
      } else {
        //alert(this.MRNData.mrn.charAt(this.MRNData.mrn.length-1).match(/[a-z]/i)+' //// '+this.MRNData.mrn.match(/\d+/))
        this.validMRN = 'true';
        this.snackBar.open('Please Enter Valid NRIC Number', 'Close', {
          duration: 2000,
        });
      }
    } else {
      const expr = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
      if (expr.test(this.MRNData.mrn)) {
        this.validMRN = 'false';
      } else {
        this.validMRN = 'true';
        this.snackBar.open('Please Enter Valid Passport Number', 'Close', {
          duration: 2000,
        });
      }
    }
  }
  createMRN(stepper: MatStepper) {
    if (
      this.headerText !== 'Edit Appointment Details' &&
      this.headerText !== 'Patient Details'
    ) {
      if (
        this.MRNData.phone_number_prefix !== 'undefined' &&
        this.MRNData.phone_number_prefix !== 'null' &&
        this.MRNData.phone_number_prefix !== null &&
        this.MRNData.phone_number_prefix !== undefined &&
        this.MRNData.phone_number_prefix !== ''
      ) {
        var d = new Date(this.MRNData.date_of_birth);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let year = d.getFullYear();
        let dob = month + '-' + day + '-' + year;
        var createData: any = {};
        this.getBookedTimes();
        var first_name = encodeURIComponent('first_name');
        var first_name_value = encodeURIComponent(this.MRNData.first_name);
        var middle_name = encodeURIComponent('middle_name');
        var middle_name_value = encodeURIComponent(this.MRNData.middle_name);
        var last_name = encodeURIComponent('last_name');
        var last_name_value = encodeURIComponent(this.MRNData.last_name);
        var date_of_birth = encodeURIComponent('date_of_birth');
        var date_of_birth_value = encodeURIComponent(dob);
        var avator_url = encodeURIComponent('avator_url');
        var avator_url_value = encodeURIComponent('');
        var phone_number_prefix = encodeURIComponent('phone_number_prefix');
        var phone_number_prefix_value = encodeURIComponent(
          this.MRNData.phone_number_prefix
        );
        var phone_number = encodeURIComponent('phone_number');
        var phone_number_value = encodeURIComponent(this.MRNData.phone_number);
        var email = encodeURIComponent('email');
        var email_value = encodeURIComponent(this.MRNData.email);
        var gender = encodeURIComponent('gender');
        var gender_value = encodeURIComponent(this.MRNData.gender);
        var mrn = encodeURIComponent('mrn');
        var mrn_value = encodeURIComponent(this.MRNData.mrn);
        var tenant = encodeURIComponent('tenant');
        var tenant_value = encodeURIComponent(this.userData.userAccess.tenant);
        var allergies = encodeURIComponent('allergies');
        var allergies_value = encodeURIComponent(
          this.MRNData.allergies ? this.MRNData.allergies : 'N'
        );
        var bf_percentage = encodeURIComponent('bf_percentage');
        var bf_percentage_value = encodeURIComponent(
          this.MRNData.bf_percentage ? this.MRNData.bf_percentage : null
        );
        var blood_group = encodeURIComponent('blood_group');
        var blood_group_value = encodeURIComponent(
          this.MRNData.blood_group ? this.MRNData.blood_group : null
        );
        var bmi = encodeURIComponent('bmi');
        var bmi_value = encodeURIComponent(
          this.MRNData.bmi ? this.MRNData.bmi : null
        );
        var children = encodeURIComponent('children');
        var children_value = encodeURIComponent(
          this.MRNData.children ? this.MRNData.children : 'N'
        );
        var height = encodeURIComponent('height');
        var height_value = encodeURIComponent(
          this.MRNData.height ? this.MRNData.height : null
        );
        var marital_status = encodeURIComponent('marital_status');
        var marital_status_value = encodeURIComponent(
          this.MRNData.marital_status ? this.MRNData.marital_status : 'S'
        );
        var smoker = encodeURIComponent('smoker');
        var smoker_value = encodeURIComponent(
          this.MRNData.smoker ? this.MRNData.smoker : 'N'
        );
        var weight = encodeURIComponent('weight');
        var weight_value = encodeURIComponent(
          this.MRNData.weight ? this.MRNData.weight : null
        );
        var id = encodeURIComponent('id');
        var id_value = encodeURIComponent(
          this.MRNData.id ? this.MRNData.id : null
        );

        // createData.push(first_name +"="+ first_name_value);
        // createData.push(middle_name +"="+ middle_name_value);
        // createData.push(last_name +"="+ last_name_value);
        // createData.push(date_of_birth +"="+ date_of_birth_value);
        // createData.push(avator_url +"="+ avator_url_value);
        // createData.push(phone_number +"="+ phone_number_value);
        // createData.push(phone_number_prefix +"="+ phone_number_prefix_value);
        // createData.push(email +"="+ email_value);
        // createData.push(gender +"="+ gender_value);
        // createData.push(mrn +"="+ mrn_value);
        // createData.push(tenant +"="+ tenant_value);

        let nricvalue;
        let passport;
        if (this.MRNData.idType == 1) {
          nricvalue = this.MRNData.mrn;
        } else {
          passport = this.MRNData.mrn;
        }
        createData = {
          first_name: first_name_value,
          middle_name: middle_name_value,
          last_name: last_name_value,
          phone_number: phone_number_value,
          gender: gender_value,
          email: this.MRNData.email,
          degree: null,
          is_active: true,
          tenant: tenant_value,
          is_staff: true,
          role_id: [1],
          speciality_ids: [1],
          is_upgrade_mail_sent: true,
          date_of_birth: date_of_birth_value,
          mrn: !!nricvalue ? nricvalue : 'string',
          passport_number: !!passport ? passport : 'string',
          phone_number_prefix: this.MRNData.phone_number_prefix,
          is_generated_email: true,
        };

        // createData.push(id +"="+ id_value);
        // createData.push(allergies +"="+ allergies_value);
        // createData.push(bf_percentage +"="+ bf_percentage_value);
        // createData.push(blood_group +"="+ blood_group_value);
        // createData.push(bmi +"="+ bmi_value);
        // createData.push(children +"="+ children_value);
        // createData.push(height +"="+ height_value);
        // createData.push(marital_status +"="+ marital_status_value);
        // createData.push(smoker +"="+ smoker_value);
        // createData.push(weight +"="+ weight_value);

        // createData = createData.join("&");
        console.log(createData);
        console.log(this.headerText);
        debugger;
        if (this.headerText == 'Patient Not Found') {
          if (this.MRNData.mrn == '' || this.validMRN == 'true') {
            this.snackBar.open('Please Enter Valid MRN', 'Close', {
              duration: 2000,
            });
          } else {
            this.appService.saveUserMRN(createData).subscribe(
              (response) => {
                if (response) {
                  this.MRNData = response;
                  console.log(this.MRNData);
                  let a =
                    !!this.MRNData.data.mrn &&
                    this.MRNData.data.mrn !== 'string'
                      ? this.MRNData.data.mrn
                      : this.MRNData.data.passport_number;
                  console.log(a);
                  this.snackBar.open(
                    'your NRIC/FIN number ' + a + ' is created successfully',
                    'Close',
                    { duration: 2000 }
                  );
                  stepper.next();
                }
              },
              (error) => {
                console.log(error);
                this.snackBar.open(JSON.stringify(error.error), 'Close', {
                  duration: 2000,
                });
              }
            );
          }
        } else if (this.MRNData.mrn == '') {
          this.snackBar.open(
            'Please create NRIC/FIN to proceed further',
            'Close',
            { duration: 2000 }
          );
        } else if (
          this.headerText == 'Edit Appointment Details' ||
          this.headerText == 'Patient Details'
        ) {
          this.appService.editMRN(createData, this.MRNData.id).subscribe(
            (response) => {
              if (response) {
                this.MRNData = response;
                console.log(this.MRNData);
                this.snackBar.open(
                  'your NRIC/FIN number ' +
                    this.MRNData.mrn +
                    ' is edited successfully',
                  'Close',
                  { duration: 2000 }
                );
                stepper.next();
              }
            },
            (error) => {
              //console.log(error)
              this.snackBar.open(JSON.stringify(error.error), 'Close', {
                duration: 2000,
              });
            }
          );
        } else {
          stepper.next();
        }
      } else {
        this.snackBar.open(
          'Please select the phone number country code to proceed',
          'Close',
          { duration: 2000 }
        );
      }
    } else {
      stepper.next();
    }
    // this.getTimeslot()
  }
  backToSearch() {
    this.patientNotFound = false;
    this.newAppointment = true;
  }
  onGetProviders() {
    this.appService.getProviders().subscribe(
      (response) => {
        if (response) {
          this.providers = response;
          console.log(this.providers);
          this.providers = this.providers.sort(
            (a, b) => Number(b.online) - Number(a.online)
          );
          this.providers.unshift({ first_name: 'All-Doctors', id: 0 });
        }
      },
      (error) => {
        //console.log(error)
        if ((error.status = 404)) {
          this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
        }
      }
    );
  }
  onLoadTimes() {
    this.morningTime = [];
    this.anoonTime = [];
    this.eveningTime = [];
    this.appService.createTime(this.userData.userAccess.tenant).subscribe(
      (response) => {
        if (response) {
          this.timeResult = response;
          console.log(response);
          this.timeResult[0].morning.map((element) => {
            // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) > parseFloat(element.replace(':','.'))){
            this.morningTime.push({
              time: element,
              disable:
                this.todayFormat == this.pickedDate &&
                parseFloat(this.currentTime) >
                  parseFloat(element.replace(':', '.')),
              checked: false,
            });
            // console.log(this.morningTime)
            // } else {
            //   this.morningTime.push({time:element,disable:false,checked:false});
            // }
          });
          this.timeResult[0].afternoon.map((element) => {
            let time = parseFloat(element.replace(':', '.'));
            let timeForm = time.toFixed(2);
            // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) > parseFloat(timeForm)){
            this.anoonTime.push({
              time: element,
              disable:
                this.todayFormat == this.pickedDate &&
                parseFloat(this.currentTime) > parseFloat(timeForm),
              checked: false,
            });
            // } else {
            //   this.anoonTime.push({time:element,disable:false,checked:false});
            // }
          });
          this.timeResult[0].evening.map((element) => {
            let time = parseFloat(element.replace(':', '.'));
            let timeForm = time.toFixed(2);
            // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) >  parseFloat(timeForm)){
            this.eveningTime.push({
              time: element,
              disable:
                this.todayFormat == this.pickedDate &&
                parseFloat(this.currentTime) > parseFloat(timeForm),
              checked: false,
            });
            // } else {
            //   this.eveningTime.push({time:element,disable:false,checked:false});
            // }
          });
        }
      },
      (error) => {
        //console.log(error)
        if ((error.status = 404)) {
          this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
        }
      }
    );
  }
  onLoadTelephoneCodes() {
    this.appService
      .getDataFromURL('../../assets/json/telephoneCodes.json')
      .subscribe(
        (response) => {
          if (response) {
            let res: any = response;
            this.telephoneCodes = res;
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  }
  blockPastTime() {
    console.log(this.holidayDate);
    console.log(this.morningTime);
    if (
      (this.weekEnd == 'Sat' && this.isSaturday == true) ||
      (this.weekEnd == 'Sun' && this.isSunday == true)
    ) {
      this.morningTime.map((element) => {
        element.disable = true;
        element.checked = false;
      });
    } else {
      this.morningTime.map((element) => {
        element.disable =
          this.todayFormat == this.pickedDate &&
          parseFloat(this.currentTime) >
            parseFloat(element.time.replace(':', '.'));
        element.checked = false;
      });
    }
    console.log(this.morningTime);
    if (
      (this.weekEnd == 'Sat' && this.isSaturday == true) ||
      (this.weekEnd == 'Sun' && this.isSunday == true)
    ) {
      this.anoonTime.map((element) => {
        element.disable = true;
        element.checked = false;
      });
    } else {
      this.anoonTime.map((element) => {
        let time = parseFloat(element.time.replace(':', '.'));
        let timeForm = time.toFixed(2);
        console.log(time, timeForm);
        element.disable =
          this.todayFormat == this.pickedDate &&
          parseFloat(this.currentTime) > parseFloat(timeForm);
        element.checked = false;
      });
    }
    if (
      (this.weekEnd == 'Sat' && this.isSaturday == true) ||
      (this.weekEnd == 'Sun' && this.isSunday == true)
    ) {
      this.eveningTime.map((element) => {
        element.disable = true;
        element.checked = false;
      });
    } else {
      this.eveningTime.map((element) => {
        let time = parseFloat(element.time.replace(':', '.'));
        let timeForm = time.toFixed(2);
        console.log(timeForm);
        element.disable =
          this.todayFormat == this.pickedDate &&
          parseFloat(this.currentTime) > parseFloat(timeForm);
        element.checked = false;
      });
    }
    if (this.holidayDate > 0) {
      this.morningTime.map((element) => {
        element.disable = true;
        element.checked = true;
      });
      this.anoonTime.map((element) => {
        element.disable = true;
        element.checked = true;
      });
      this.eveningTime.map((element) => {
        element.disable = true;
        element.checked = true;
      });
    }
  }
  getBookedTimes() {
    console.log(this.holidayDate);
    if (this.selectedProvider == 0) {
      console.log(this.pickedDate);
      this.appService.filter_Providers(this.pickedDate).subscribe(
        (response) => {
          if (response) {
            let res: any = response;
            console.log(res);
            this.allProviders = res.data.user;
            if (this.allProviders.length > 0) {
              this.blockPastTime();
            } else {
              this.morningTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
              this.anoonTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
              this.eveningTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
            }
            if (this.holidayDate.length > 0) {
              this.morningTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
              this.anoonTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
              this.eveningTime.map((element) => {
                element.disable = true;
                element.checked = true;
              });
            }
          }
        },
        (error) => {
          //console.log(error)
          if ((error.status = 404)) {
            this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
          }
        }
      );
    } else {
      let params = {
        date: this.pickedDateMM,
        doctor_id: this.selectedProvider,
      };
      console.log(params);
      //alert(this.selectedProvider)
      this.appService.getProviderbookedTime(params).subscribe(
        (response) => {
          if (response) {
            let res: any = response;
            console.log(res);
            if (res.length > 0) {
              res.forEach((y) => {
                if (
                  this.morningTime.filter((x) => x.time == y.appointment_time)
                    .length > 0
                ) {
                  this.morningTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].disable = true;
                  this.morningTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].checked = true;
                }
                if (
                  this.anoonTime.filter((x) => x.time == y.appointment_time)
                    .length > 0
                ) {
                  this.anoonTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].disable = true;
                  this.anoonTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].checked = true;
                }
                if (
                  this.eveningTime.filter((x) => x.time == y.appointment_time)
                    .length > 0
                ) {
                  this.eveningTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].disable = true;
                  this.eveningTime.filter(
                    (x) => x.time == y.appointment_time
                  )[0].checked = true;
                }
              });
            } else {
              // this.morningTime.map(function (element){
              //   element.disable=false;
              //   element.checked=false;
              // })
              // this.anoonTime.map(function (element){
              //   element.disable=false;
              //   element.checked=false;
              // })
              // this.eveningTime.map(function (element){
              //   element.disable=false;
              //   element.checked=false;
              // })
            }
          }
        },
        (error) => {
          //console.log(error)
          if ((error.status = 404)) {
            this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
          }
        }
      );
    }
  }
  toggle(ref) {
    //console.log(this.pickedDate);
  }
  dateFormat(date) {
    var d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    this.selectedDate = day + '-' + month + '-' + year;
    this.pickedDateMM = month + '-' + day + '-' + year;
    console.log(this.pickedDateMM);
    console.log(this.selectedDate);
    this.pickedDate = year + '-' + month + '-' + day;
    this.textDate = this.monthArray[month - 1] + ' ' + day + ' ' + year;
    let select = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd');
    console.log(select);
    this.holidayDate = this.holiday[0].holiday_dates.filter((a) => a == select);
    console.log(this.holidayDate);
    this.getBookedTimes();
    // this.getTimeslot();
  }
  MRNDateFormat(date) {
    var d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    this.MRNData.date_of_birth = month + '-' + day + '-' + year;
    this.dob = new Date(this.MRNData.date_of_birth);
  }
  dateChange(date) {
    this.dateFormat(date);
    // this.onLoadTimes();
  }
  newUserClick() {
    this.MRNData = {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      avator_url: '',
      phone_number: '',
      email: '',
      gender: '',
      mrn: '',
      masked_mrn: '',
      tenant: this.userData.userAccess.tenant,
      search: '',
      idType: '1',
    };
    this.dob = '';
    this.patientNotFound = true;
    this.tilePage = false;
    this.existingUser = false;
    this.headerText = 'Patient Not Found';
    this.newUser = true;
    this.isgenerate = false;
    //this.popupFlag=true;
    this.appService.popupClick(true);
  }
  existingUserClick() {
    this.isgenerate = true;
    this.existingUser = true;
    this.tilePage = false;
    //this.popupFlag=true;
    this.appService.popupClick(true);
  }
  checkBoxChange(time, AMPM) {
    console.log(this.selectedProvider);
    if (this.selectedProvider == 0) {
      this.morningSelectedElementId = '';
      this.noonSelectedElementId = '';
      this.eveningSelectedElementId = '';
    }
    // if(this.selectedProvider==0){
    //   const dialogRef = this.dialog.open(AlertDialogComponent, {
    //     width: '70vw',
    //     height:'90vh',
    //     disableClose: true,
    //     data: { providerList: this.allProviders}
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     if(result.event == 'OK'){
    //       // alert(result.data)
    //       this.getBookedTimes();
    //       this.selectedProvider=result.data;
    //         if(AMPM=='PM'){
    //           let tim=parseFloat(time.time.replace(':','.'))
    //           let timeForm=(tim).toFixed(2).replace('.',':');
    //           //console.log(timeForm);
    //           this.selectedTime=timeForm;
    //         } else {
    //           this.selectedTime=time.time;
    //         }
    //         this.morningTime.map(element=>{
    //           if(element.time==time.time && element.disable==false){
    //             element.checked=true;
    //           } else {
    //             element.checked=false;
    //           }
    //         })
    //         this.anoonTime.map(element=>{
    //           if(element.time==time.time && element.disable==false){
    //             element.checked=true;
    //           } else {
    //             element.checked=false;
    //           }
    //         })
    //         this.eveningTime.map(element=>{
    //           if(element.time==time.time && element.disable==false){
    //             element.checked=true;
    //           } else {
    //             element.checked=false;
    //           }
    //         })
    //     }else if(result.event == 'Cancel'){
    //       time.checked=false;
    //     }
    //   });
    //   return dialogRef.afterClosed();
    // } else {
    // time.checked=!time.checked;
    // if(time.checked){
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '40vw',
      height: '30vh',
      disableClose: true,
      data: {
        providerList: this.allProviders,
        selectedProvider: this.selectedProvider,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'OK') {
        this.selectedSpeciality = result.data;

        if (AMPM == 'PM') {
          let tim = parseFloat(time.time.replace(':', '.'));
          let timeForm = tim.toFixed(2).replace('.', ':');
          this.selectedTime = timeForm;
        } else {
          this.selectedTime = time;
        }
        this.morningTime.map((element) => {
          if (element.time == time.time && element.disable == false) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
        this.anoonTime.map((element) => {
          if (element.time == time.time && element.disable == false) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
        this.eveningTime.map((element) => {
          if (element.time == time.time && element.disable == false) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      } else if (result.event == 'Cancel') {
        time.checked = false;
      }
    });
    return dialogRef.afterClosed();
    // }
  }
  // }
  confirmAppointment(stepper) {
    console.log(this.MRNData);
    if (this.headerText !== 'Edit Appointment Details') {
      this.appointmentId='';
    }
    this.morningSelectedElementId = '';
    this.noonSelectedElementId = '';
    this.eveningSelectedElementId = '';
    this.timeSlot.forEach((a: days, x: any) =>
      x == 0 ? (a.active = true) : (a.active = false)
    );
    let domain = window.location.host;
    var d = new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    let hours = d.getHours();
    let minute = d.getMinutes();
    let current_date =
      month + '-' + day + '-' + year + ' ' + hours + ':' + minute;
    let userData = JSON.parse(localStorage.getItem('userDetails'));
    var appointment_date = encodeURIComponent('appointment_date');
    console.log(this.pickedDateMM, this.selectedTime);
    var appointment_date_value = encodeURIComponent(
      this.pickedDateMM + ' ' + this.selectedTime
    );
    var doctor_id = encodeURIComponent('doctor_id');
    var doctor_id_value = encodeURIComponent(this.selectedProvider);
    var patient_id = encodeURIComponent('patient_id');
    if (!!this.MRNData.data) {
      var patient_id_value = encodeURIComponent(this.MRNData.data.id);
    } else {
      var patient_id_value = encodeURIComponent(this.MRNData.id);
    }
    console.log(this.MRNData.id);
    var questionnaire_url = encodeURIComponent('questionnaire_url');
    var questionnaire_url_value = encodeURIComponent(
      'http://admin.medosys.co/questionnaire?id=' +
        this.MRNData.id +
        '&tenant=' +
        this.MRNData.tenant
    );
    var booking_date = encodeURIComponent('booking_date');
    var booking_date_value = encodeURIComponent(current_date);
    var appointment_status = encodeURIComponent('appointment_status');
    var appointment_status_value = encodeURIComponent('UP');
    var questionnaire_status = encodeURIComponent('questionnaire_status');
    var questionnaire_status_value = encodeURIComponent('ST');
    var notes_status = encodeURIComponent('notes_status');
    var notes_status_value = encodeURIComponent('TR');
    var id = encodeURIComponent('id');
    var id_value = encodeURIComponent(this.appointmentId);
    var specialities = encodeURIComponent('specialities');
    var specialities_value = encodeURIComponent(this.selectedSpeciality);
    var appointment_from = encodeURIComponent('appointment_from');
    var appointment_from_value = encodeURIComponent('W');
    var saveData: any = [];

    saveData.push(appointment_date + '=' + appointment_date_value);
    saveData.push(doctor_id + '=' + doctor_id_value);
    saveData.push(patient_id + '=' + patient_id_value);
    saveData.push(questionnaire_url + '=' + questionnaire_url_value);
    saveData.push(booking_date + '=' + booking_date_value);
    saveData.push(appointment_status + '=' + appointment_status_value);
    saveData.push(questionnaire_status + '=' + questionnaire_status_value);
    saveData.push(notes_status + '=' + notes_status_value);
    saveData.push(specialities + '=' + specialities_value);
    saveData.push(id + '=' + id_value);
    saveData.push(appointment_from + '=' + appointment_from_value);
    saveData = saveData.join('&');
    console.log(saveData);
    debugger
    console.log(JSON.stringify(this.appointmentId))
    if (this.selectedProvider == 0 || this.selectedTime == undefined) {
      this.snackBar.open(
        'Please select one doctor and time of appointment',
        'Close',
        { duration: 2000 }
      );
    } else if (this.headerText == 'Edit Appointment Details') {
      // this.spinner=true;

      this.appService.editAppointment(saveData, this.appointmentId).subscribe(
        (response) => {
          if (response) {
            // this.spinner=false;
            this.snackBar.open(
              'Your Appointment is Successfully Edited ',
              'Close',
              { duration: 2000 }
            );
            this.appointmentData = response;
            stepper.next();
          }
        },
        (error) => {
          // this.spinner=false;
          //console.log(error)
          if ((error.status = 404)) {
            this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
            stepper.next();
          }
        }
      );
    } else {
      // this.spinner=true;
      this.appService.createAppointment(saveData).subscribe(
        (response) => {
          if (response) {
            // this.spinner=false;
            this.snackBar.open(
              'Your Appointment is Successfully created ',
              'Close',
              { duration: 2000 }
            );
            //console.log(response)
            this.appointmentData = response;
            stepper.next();
          }
        },
        (error) => {
          // this.spinner=false;
          //console.log(error)
          if ((error.status = 404)) {
            this.snackBar.open(error.statusText, 'Close', { duration: 2000 });
            stepper.next();
          }
        }
      );
    }
  }
  doneAppointment() {
    this.showLanding = !this.showLanding;
    this.edited = false;
    this.MRNData = [];
    this.newAppointment = true;
    this.patientNotFound = false;
    this.headerText = 'Patient Not Found';
    this.newUser = true;
    this.selectedProvider = 0;
    this.tilePage = true;
    this.existingUser = false;
    this.appService.popupClick(false);
    this.myControl.setValue('');
  }
  closeAppointment() {
    this.showLanding = !this.showLanding;
    this.edited = false;
    this.validMRN = null;
    this.MRNData = [];
    this.newAppointment = true;
    this.tilePage = true;
    this.existingUser = false;
    this.patientNotFound = false;
    this.headerText = 'Patient Not Found';
    this.newUser = true;
    this.selectedProvider = 0;
    this.appService.popupClick(false);
    this.myControl.setValue('');
    this.morningSelectedElementId = '';
    this.noonSelectedElementId = '';
    this.eveningSelectedElementId = '';
    this.timeSlot.forEach((a: days, x: any) =>
      x == 0 ? (a.active = true) : (a.active = false)
    );
  }
  editAppointment(appData) {
    console.log(appData);
    this.appointmentId = appData.id;
    this.isgenerate = true;
    this.edited = true;
    this.showLanding = false;
    this.newAppointment = false;
    this.patientNotFound = true;
    this.headerText = 'Edit Appointment Details';
    this.newUser = false;
    this.MRNData = appData.patient;
    this.MRNData.idType = this.MRNData.mrn.length == 9 ? '1' : '2';
    this.selectedProvider = appData.doctor_id; 
    this.dateFormat(appData.appointment_date.split(' ')[0]);
    this.selectedTime = appData.appointment_time;
    console.log(appData.id)
    this.appService.popupClick(true);
    this.getBookedTimes();
  }
  getTimeslot() {
    let id=this.loginTenantId[0]
    this.appService.getTimeslot(id).subscribe((response: any) => {
      if (response) {
        console.log(response);
        console.log(this.todayFormat, this.pickedDate);
        9; // this.morningTime=response.moring_time_slot
        // this.anoonTime=response.afternoon_time_slot
        // this.eveningTime=response.evening_time_slot
        // console.log(this.morningTime)
        response[0].moring_time_slot.map((element) => {
          // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) > parseFloat(element.replace(':','.'))){
          this.morningTime.push({
            time: element,
            disable:
              this.todayFormat == this.pickedDate &&
              parseFloat(this.currentTime) >
                parseFloat(element.replace(':', '.')),
            checked: false,
          });
          console.log(this.morningTime);
          // } else {
          //   this.morningTime.push({time:element,disable:false,checked:false});
          // }
        });
        response[0].afternoon_time_slot.map((element) => {
          let time = parseFloat(element.replace(':', '.'));
          let timeForm = time.toFixed(2);
          console.log(timeForm);
          // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) > parseFloat(timeForm)){
          this.anoonTime.push({
            time: element,
            disable:
              this.todayFormat == this.pickedDate &&
              parseFloat(this.currentTime) > parseFloat(timeForm),
            checked: false,
          });
          console.log(this.anoonTime);
          // } else {
          //   this.anoonTime.push({time:element,disable:false,checked:false});
          // }
        });
        response[0].evening_time_slot.map((element) => {
          let time = parseFloat(element.replace(':', '.'));
          let timeForm = time.toFixed(2);
          // if(this.todayFormat==this.pickedDate && parseFloat(this.currentTime) >  parseFloat(timeForm)){
          this.eveningTime.push({
            time: element,
            disable:
              this.todayFormat == this.pickedDate &&
              parseFloat(this.currentTime) > parseFloat(timeForm),
            checked: false,
          });
          console.log(this.eveningTime);
          // } else {
          //   this.eveningTime.push({time:element,disable:false,checked:false});
          // }
        });
      }
    });
  }
  getDate(value) {
    console.log(value);
    let da = value.toLocaleDateString('en-US', { weekday: 'long' });
    let week = da.substring(0, 3);
    console.log(week);
    this.weekEnd = week;
    //
    // if(this.weekEnd1=='Sat'){
    //  this.isSaturday=true;
    //     this.getTimeslot();
    //   }
    this.morningSelectedElementId = '';
    this.noonSelectedElementId = '';
    this.eveningSelectedElementId = '';
    this.timeSlot.forEach((a: days) =>
      a.value == value ? (a.active = true) : (a.active = false)
    );
    let d = value;
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    this.selectedDate = day + '-' + month + '-' + year;
    this.pickedDateMM = month + '-' + day + '-' + year;
    console.log(this.pickedDateMM);
    console.log(this.selectedDate);
    this.pickedDate = year + '-' + month + '-' + day;
    let select = this.datepipe.transform(this.pickedDateMM, 'yyyy-MM-dd');
    console.log(select);
    this.holidayDate = this.holiday[0].holiday_dates.filter((a) => a == select);
    console.log(this.holidayDate);
    // this.getTimeslot()
    this.blockPastTime();
    this.getBookedTimes();
  }

  onChangeMorValue(time, i) {
    console.log(i);
    this.morningSelectedElementId = i;
    this.noonSelectedElementId = '';
    this.eveningSelectedElementId = '';
    this.selectedTime = time;
    console.log(this.selectedTime);
    this.checkBoxChange(time, null);
  }
  onChangeNoonValue(time, i) {
    console.log(i);
    this.noonSelectedElementId = i;
    this.morningSelectedElementId = '';
    this.eveningSelectedElementId = '';
    this.selectedTime = time;
    console.log(this.selectedTime);
    this.checkBoxChange(time, null);
  }
  onChangeEveValue(time, i) {
    console.log(i);
    this.eveningSelectedElementId = i;
    this.noonSelectedElementId = '';
    this.morningSelectedElementId = '';
    this.selectedTime = time;
    console.log(this.selectedTime);
    this.checkBoxChange(time, null);
  }

  getScheduleTimeslot() {
    this.appService.getTimeslot(this.tenantId).subscribe((response: any) => {
      if (response) {
        console.log(response);
        this.timeSlotData = response;
        this.isSaturday = response.is_saturday_holiday;
        this.isSunday = response.is_sunday_holiday;
        // this.morning=this.timeSlotData.morning_opening_time
        // console.log(this.morning)
      }
    });
  }

  checkAfter12(time: any) {
    return parseFloat(time.replace(':', '.'));
  }
  generateEmail() {
    let mrn = this.MRNData.mrn;
    console.log(mrn);
    let dynamicMail = mrn.slice(-4);
    this.MRNData.email = dynamicMail + '.' + this.tenantName + '@gmail.com';
    console.log(this.MRNData.email);
  }
  getType(type) {
    console.log(type);
    this.idType = type;
  }

  getHoliday() {
    let id=this.loginTenantId[0]
    this.appService.getHolidays(id).subscribe((response) => {
      if (response) {
        console.log(response);
        this.holiday = response;
        let a = this.holiday.filter((obj) => obj.holiday_dates !== null);
        this.holiday = a.filter((ob) => ob.holiday_dates.length > 0);
        console.log(a);
        console.log(this.holiday);
        console.log(a);
        // this.updateId=a[0].id
        // console.log(b)
        // this.model=a[0].holiday_dates
      }
    });
  }
}
export interface days {
  date: number;
  day: string;
  value: Date;
  active: boolean;
}
