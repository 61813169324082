<div class="p-3 d-flex justify-content-between">
    <h2 class="heading"><b>Work time & Holiday</b></h2>
    <div>
    <button class="btn mx-1" (click)="closeDialog()">Cancel</button>
    <button class="btn mx-1" (click)="saveTime()">Save</button>
</div>
    </div>
    <!-- <div class="p-3" style="padding-top: 0px;">
    <h3><b>Work time</b></h3>
    </div> -->
    <div class="row px-3">
        <div class="col-sm-4">
          <h2 class="text-center pr-4"><b>Morning</b></h2>
          <div class="d-flex justify-content-between">
          <div class="col-sm-5" style="padding-left: 0px;">
          <label for="start_time"><b>Start Time</b></label>
          <input type='time' class="form-control" id="starttime" [(ngModel)]="timeSlotData.morning_opening_time"/>
        </div>
        <div class="col-sm align-self-end pb-2">to</div>
          <div class="col-sm-5" style="padding-left: 0px;">
          <label for="end_time"><b>End Time</b></label>
          <input type='time' class="form-control" id="endtime" [(ngModel)]="timeSlotData.morning_closing_time"/>
        </div>
      </div>
        </div>
        <div class="col-sm-4">
          <h2 class="text-center pr-4"><b>Afternoon</b></h2>
          <div class="d-flex justify-content-between">
            <div class="col-sm-5" style="padding-left: 0px;">
            <label for="start_time"><b>Start Time</b></label>
            <input type='time' class="form-control" id="starttime" [(ngModel)]="timeSlotData.afternoon_opening_time"/>
          </div>
          <div class="col-sm align-self-end pb-2">to</div>
            <div class="col-sm-5" style="padding-left: 0px;">
            <label for="end_time"><b>End Time</b></label>
            <input type='time' class="form-control" id="endtime" [(ngModel)]="timeSlotData.afternoon_closing_time"/>
          </div>
        </div>
        </div>
        <div class="col-sm-4">
          <h2 class="text-center pr-4"><b>Evening</b></h2>
          <div class="d-flex justify-content-between">
            <div class="col-sm-5" style="padding-left: 0px;">
            <label><b>Start Time</b></label>
            <input type='time' class="form-control" id="starttime" [(ngModel)]="timeSlotData.evening_opening_time"/>
          </div>
          <div class="col-sm align-self-end pb-2">to</div>
            <div class="col-sm-5" style="padding-left: 0px;">
            <label><b>End Time</b></label>
            <input type='time' class="form-control" id="endtime" [(ngModel)]="timeSlotData.evening_closing_time"/>
          </div>
        </div>
        </div>
      
    </div>
    <div class="row mx-3 pt-5">
      <div class="d-flex">
        <h3 class="p-0 mr-2 align-self-center" style="padding-left: 42px;"><b>Interval time</b></h3>
        <mat-form-field appearance="outline" class="mr-2 align-self-center" style="width:75px;">
          <input matInput placeholder="mins" [(ngModel)]="timeSlotData.time_interval" required>
        </mat-form-field> 
        <h3 class="align-self-center">mins</h3>
      </div>       
    </div>  
    <div class="p-3">
      <h3><b>Holiday Management</b></h3>
      <div class="container m-0">
        <div class="row">
          <div class="col-sm-3">
            <h3><b>Saturday</b></h3>
            <div class="d-flex justify-content-between">
              <!-- <div class="form-check" *ngFor="let option of option1;">
                <input class="form-check-input" type="radio" name="exampleRadios1" [id]="option.id"
                [value]="option.value" [(ngModel)]="option.value" (change)="onOptionChange(option.id)">
                <h3><label class="form-check-label" [for]="option.id">{{option.label}}</label></h3>
              </div> -->
              <div class="form-check">
                <input class="form-check-input" type="radio" id="satHoliday" [value]="isSaturdayHoliday" [checked]="isSaturdayHoliday" (change)="onOptionChange('Holiday')">
                <h3><label class="form-check-label" for="satHoliday"> Holiday </label></h3>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="satWorking" [value]="!isSaturdayHoliday" [checked]="!isSaturdayHoliday" (change)="onOptionChange('Working')">
                <h3><label class="form-check-label" for="satWorking"> Working </label></h3>
              </div>
              <!-- <div class="form-check">
                <input class="form-check-input"  type="radio" name="exampleRadios2" value="option2">
                <label class="form-check-label" for="exampleRadios2">Working</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios3" value="option3">
                <label class="form-check-label" for="exampleRadios3">Alternate Working Day</label>
              </div> -->
            </div>
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-3">
            <h3><b>Sunday</b></h3>
            <div class="d-flex justify-content-between">
              <!-- <div class="form-check" *ngFor="let option of option2;let i = index;" >
              <input class="form-check-input" type="radio" name="exampleRadios2" [id]="option.id"
              [value]="option.value" [(ngModel)]="timeSlotData.is_sunday_holiday" (change)="onOptionChange1($event.target.value)">
              <h3><label class="form-check-label" [for]="option.id">{{option.label}}</label></h3>
              </div> -->
              <div class="form-check">
                <input class="form-check-input" type="radio" id="sunHoliday" [value]="isSundayHoliday" [checked]="isSundayHoliday" (change)="onOptionChange1('Holiday')">
                <h3><label class="form-check-label" for="sunHoliday"> Holiday </label></h3>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="sunWorking" [value]="!isSundayHoliday" [checked]="!isSundayHoliday" (change)="onOptionChange1('Working')">
                <h3><label class="form-check-label" for="sunWorking"> Working </label></h3>
              </div>
                <!-- <div class="form-check">
                    <input  class="form-check-input" type="radio" name="exampleRadios5" value="option5">
                    <label class="form-check-label" for="exampleRadios5">Working</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios6" value="option6">
                    <label class="form-check-label" for="exampleRadios6">Alternate Working Day</label>
                </div> -->
            </div>
        </div>
       
      </div>
      </div>
    </div>
    <div class="p-3">
      <h3><b>Public Holidays</b></h3>
      <mat-form-field>
        <mat-chip-list #chipList aria-label="Choose a date" (click)="picker.open()">
          <mat-chip
            *ngFor="let value of model"
            [selectable]="false"
            [removable]="true"
            (removed)="remove(value)"
          >
            {{ value | date }}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip>
          <input
            [value]="resetModel"
            matInput
            [matDatepicker]="picker"
            placeholder="Choose a date"
            (dateChange)="saveHoliday($event.target.value)"
            [matChipInputFor]="chipList"
            hidden
          />
        </mat-chip-list>
        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          [startAt]="init"
          [dateClass]="dateClass"
        ></mat-datepicker>
      </mat-form-field>
      </div>
