<div class="row">
    <div class="container col-6">
    <h2><strong>Payment Overview</strong></h2>

    <h3><strong>User Benefits</strong></h3>
    <mat-radio-group class="radioButtonPad" [(ngModel)]="govtGrantFlag" [disabled]="selectedAppointmet.payment_id" (ngModelChange)="govtGrantChange($event)">
        <mat-radio-button value="grant">Government grant</mat-radio-button>
        <mat-radio-button value="normal" style="padding-left:10px">Normal</mat-radio-button>
    </mat-radio-group>
    <br>

    <div class="mt-4">
    <h3><strong>Select Payment Method</strong></h3>
    <mat-radio-group class="radioButtonPad" [(ngModel)]="paymentMode" [disabled]="selectedAppointmet.payment_id">
        <mat-radio-button value="cash" class="rad-button">
            <span><mat-icon>account_balance_wallet</mat-icon>Cash</span></mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="card" class="rad-button">
             <span><mat-icon>payment</mat-icon>Card</span></mat-radio-button>&nbsp; <br><br>
        <mat-radio-button value="qr_code" class="rad-button">
             <span><mat-icon>qr_code_2</mat-icon>QR Scan</span></mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <mat-radio-button value="upi" class="rad-button">
             <span><mat-icon>account_balance</mat-icon>UPI</span></mat-radio-button>&nbsp;
        
    </mat-radio-group>
</div>
</div>

<div class="col-6 sec-part" >
    <h2><strong>Payment Details</strong></h2>
    <div class="mt-4"><label>
        Doctor Consultation fee <span *ngIf="selectedAppointmet.specialities!==null">({{selectedAppointmet.specialities}})</span></label> <input type="number" oninput="this.value = Math.abs(this.value)" min="0" [disabled]="selectedAppointmet.payment_status !== null ||  selectedAppointmet.appointment_from === 'M'" [(ngModel)]="consultationFee" (ngModelChange)="consultationFeeChange()" />
    </div>
    <div class="mt-4" *ngIf="govtGrantFlag=='grant'"><label>
        Government grant</label> <input type="number" oninput="this.value = Math.abs(this.value)" min="0" [disabled]="true" [(ngModel)]="govtGrantAmount" (ngModelChange)="govtGrantAmountChange($event)">
    </div>
    <div class="mt-4"><label>
        Tax {{taxPercent}} %  </label><input min="0" oninput="this.value = Math.abs(this.value)" [disabled]="selectedAppointmet.payment_status !== null ||  selectedAppointmet.appointment_from === 'M'" type="number" [(ngModel)]="tax"/>
    </div>
    <div class="mt-4"><label>
        Total  </label><input min="0" oninput="this.value = Math.abs(this.value)" [disabled]="selectedAppointmet.payment_status !== null ||  selectedAppointmet.appointment_from === 'M'" [(ngModel)]="totalFees">

    </div>
    <br>
    <div style="margin-left: 100px;" *ngIf="selectedAppointmet.payment_status === null">
        <button class="btn1" (click)="cancel()">Cancel</button>&nbsp;
        <button class="viewBtn" (click)="confirm()">Confirm</button>
    </div>
    <div style="margin-left: 100px;" *ngIf="selectedAppointmet.payment_status !== null">
        <button class="btn1" (click)="cancel()">Close</button>
    </div>
</div></div>
