import { Component, OnInit, Input, Optional, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-multi-vertical',
  templateUrl: './multi-vertical.component.html',
  styleUrls: ['./multi-vertical.component.css'],
})
export class MultiVerticalComponent implements OnInit {
  public appointmentData: any = [];
  public appData: any = [];
  public year: number;
  fromDate: any;
  toDate: any;
  fDate: any;
  tDate: any;
  initFromDate: any;
  initToDate: any;
  public userList: any;
  public initialData: any = [];
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public tableData: any = [];
  tenantId: any = this.userData.userAccess.tenant;
  patientAppmt: any = [];
  doctorAppmt: any = [];
  multi: any[];
  view: any[] = [630, 400];
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  legendPosition: string = 'below';
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Number of patients';
  showYAxisLabel: boolean = true;
  xAxisLabel = 'Age';
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  colorScheme = {
    domain: ['#4287f5', '#f589ef'],
  };
  maximize: boolean = false;
  schemeType: string = 'linear';
  @Input() parentData: any;
  @Input() dateRange: any;
  public graph;
  chartWidth: number = 1300;
  @Input() chartPopup: any;
  constructor(
    public adminService: AdminService,
    public appService: AppointmentService,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<MultiVerticalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    public datepipe: DatePipe
  ) {}
  ngOnInit(): void {
    var d = new Date();
    this.year = d.getFullYear();
    let prevMonth = d.getMonth() + 1;
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    let end = prevMonth + '-' + prevDay + '-' + prevYear;
    this.initToDate = prevMonth + '-' + prevDay + '-' + prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev = dPrev.getMonth() + 1;
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    let start = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.initFromDate = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.fromDate = this.datepipe.transform(start, 'MM-dd-yyyy');
    this.toDate = this.datepipe.transform(end, 'MM-dd-yyyy');
    this.loadInitData(this.parentData);
    if (this.data?.openVia == 'fullScreen') {
      this.maximize = true;
      this.view = this.data.view;
      this.loadInitData(this.data.parentData);
    }

    if (!this.dateRange) {
      this.loadChart(null);
    }
  }
  ngOnChanges() {
    // this.loadInitData(this.parentData);
    if (!!this.dateRange.Start_date && !!this.dateRange.End_date) {
      this.loadChart(this.dateRange);
    }
  }
  loadPreiviousAppointmentList() {
    let appParams = {
      appointment_start_date: this.fromDate,
      appointment_end_date: this.toDate,
    };
    this.fDate = new Date(this.fromDate);
    this.tDate = new Date(this.toDate);
    this.appService.getAppointmentList(appParams).subscribe(
      (response) => {
        if (response) {
          //console.log(response)
          let appData: any = response;
          if (appData.length > 0) {
            this.appData = appData;
            this.appData.map((x) => {
              var d = new Date(x.appointment_date);
              x.age =
                this.year - parseInt(x.patient.date_of_birth.split('-')[2]);
              x.dayStr = this.days[d.getDay()];
              // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
              // if(x.appointment_status=='UP'){
              //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
              // }
              // if(x.appointment_status=='CI'){
              //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
              // }
            });
            if (this.appData?.length > 0) {
              this.appData.map((x) => {
                this.patientAppmt.push({
                  ...x,
                  ...x.patient,
                  age: x.age,
                  appmt_id: x.id,
                  complaint_name: x.questionnaire_info[0]?.complaint_id[0]
                    ?.complaint_name
                    ? x.questionnaire_info[0]?.complaint_id[0]?.complaint_name
                    : 'Not filled',
                });
              });
            }
            this.multi = [
              {
                name: '0-10',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age < 11 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age < 11 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '11-20',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 10 && x.age < 21 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 10 && x.age < 21 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '21-30',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 20 && x.age < 31 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 20 && x.age < 31 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '31-40',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 30 && x.age < 41 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 30 && x.age < 41 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '41-50',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 40 && x.age < 51 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 40 && x.age < 51 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '51-60',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 50 && x.age < 61 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 50 && x.age < 61 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
              {
                name: '60+',
                series: [
                  {
                    name: 'Male',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 60 && x.patient.gender == 'M'
                    ).length,
                  },
                  {
                    name: 'Female',
                    value: this.patientAppmt.filter(
                      (x) => x.age > 60 && x.patient.gender == 'F'
                    ).length,
                  },
                ],
              },
            ];
            // this.multi = [
            //   {"name": "0-10","series": [{"name": "Male","value": 10},{"name": "Female","value":15}]},
            //   {"name": "11-20","series": [{"name": "Male","value": 19},{"name": "Female","value": 24}]},
            //   {"name": "21-30","series": [{"name": "Male","value": 23},{"name": "Female","value": 30}]},
            //   {"name": "31-40","series": [{"name": "Male","value": 9},{"name": "Female","value": 19}]},
            //   {"name": "41-50","series": [{"name": "Male","value": 40},{"name": "Female","value": 34}]},
            //   {"name": "51-60","series": [{"name": "Male","value": 33},{"name": "Female","value": 20}]},
            //   {"name": "60+","series": [{"name": "Male","value": 32},{"name": "Female","value": 13}]}
            // ];
            //console.log(this.multi)
            //console.log(this.appData)
            this.initialData = JSON.stringify(this.tableData);
            // this.dataSource = new MatTableDataSource(this.appData);
            //console.log(this.appointmentData);
          } else {
            this.appointmentData = [];
          }
        }
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  loadInitData(appData) {
    this.fDate = new Date(this.initFromDate);
    this.tDate = new Date(this.initToDate);
    if (appData?.length > 0) {
      this.appData = appData;
      this.appData.map((x) => {
        var d = new Date(x.appointment_date);
        x.age = this.year - parseInt(x.patient.date_of_birth.split('-')[2]);
        x.dayStr = this.days[d.getDay()];
        // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
        // if(x.appointment_status=='UP'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
        // }
        // if(x.appointment_status=='CI'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
        // }
      });
      if (this.appData?.length > 0) {
        this.appData.map((x) => {
          this.patientAppmt.push({
            ...x,
            ...x.patient,
            age: x.age,
            appmt_id: x.id,
            complaint_name: x.questionnaire_info[0]?.complaint_id[0]
              ?.complaint_name
              ? x.questionnaire_info[0]?.complaint_id[0]?.complaint_name
              : 'Not filled',
          });
        });
      }
      this.multi = [
        {
          name: '0-10',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age < 11 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age < 11 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '11-20',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 10 && x.age < 21 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 10 && x.age < 21 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '21-30',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 20 && x.age < 31 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 20 && x.age < 31 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '31-40',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 30 && x.age < 41 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 30 && x.age < 41 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '41-50',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 40 && x.age < 51 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 40 && x.age < 51 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '51-60',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 50 && x.age < 61 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 50 && x.age < 61 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
        {
          name: '60+',
          series: [
            {
              name: 'Male',
              value: this.patientAppmt.filter(
                (x) => x.age > 60 && x.patient.gender == 'M'
              ).length,
            },
            {
              name: 'Female',
              value: this.patientAppmt.filter(
                (x) => x.age > 60 && x.patient.gender == 'F'
              ).length,
            },
          ],
        },
      ];
      // this.multi = [
      //   {"name": "0-10","series": [{"name": "Male","value": 10},{"name": "Female","value":15}]},
      //   {"name": "11-20","series": [{"name": "Male","value": 19},{"name": "Female","value": 24}]},
      //   {"name": "21-30","series": [{"name": "Male","value": 23},{"name": "Female","value": 30}]},
      //   {"name": "31-40","series": [{"name": "Male","value": 9},{"name": "Female","value": 19}]},
      //   {"name": "41-50","series": [{"name": "Male","value": 40},{"name": "Female","value": 34}]},
      //   {"name": "51-60","series": [{"name": "Male","value": 33},{"name": "Female","value": 20}]},
      //   {"name": "60+","series": [{"name": "Male","value": 32},{"name": "Female","value": 13}]}
      // ];
      //console.log(this.multi)
      //console.log(this.appData)
      this.initialData = JSON.stringify(this.tableData);
      // this.dataSource = new MatTableDataSource(this.appData);
      //console.log(this.appointmentData);
    } else {
      this.appointmentData = [];
    }
  }
  dateRangeChange(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    var fromdate = new Date(dateRangeStart.value);
    this.fromDate =
      fromdate.getMonth() +
      1 +
      '-' +
      fromdate.getDate() +
      '-' +
      fromdate.getFullYear();
    var todate = new Date(dateRangeEnd.value);
    this.toDate =
      todate.getMonth() +
      1 +
      '-' +
      todate.getDate() +
      '-' +
      todate.getFullYear();
    if (this.fromDate !== 'NaN-NaN-NaN' && this.toDate !== 'NaN-NaN-NaN')
      this.loadPreiviousAppointmentList();
  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  openFullscreen() {
    this.maximize = true;
    const dialogRef = this.dialog.open(MultiVerticalComponent, {
      width: '900px',
      height: '800px',
      disableClose: true,
      data: {
        openVia: 'fullScreen',
        parentData: this.parentData,
        view: [800, 500],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
      this.view = [630, 400];
      this.maximize = false;
    });
    return dialogRef.afterClosed();
  }
  fullScreenExit() {
    this.view = [630, 400];
    this.maximize = false;
    // this.dialog.closeAll();
    this.dialogRef.close();
  }
  downloadChart() {
    document.getElementById('med-vertical-header').style.display = 'none';
    html2canvas(document.getElementById('med-vertical'), {
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // var doc = new jsPDF('p', 'mm','a4');
      var doc = new jsPDF();
      var x = 1;
      var position = 0;
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.text('Medosys ' + x + ' / ' + doc.getNumberOfPages(), 150, 285);
        x++;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('medosys_vertical_chart.pdf');
    });
    document.getElementById('med-vertical-header').style.display = 'block';
  }
  clearDate(event) {
    this.loadInitData(this.parentData);
  }
  loadChart(param) {
    if (!!param) {
      let appParams = {
        start_date: param.Start_date,
        end_date: param.End_date,
      };
      this.appService
        .patientAgeCategory(appParams)
        .subscribe((response: any) => {
          if (response) {
            console.log(response);
            let ch = (response.layout.height = 550);
            let cw = (response.layout.width = 773);
            if (response.data.length == 0) {
              response.layout.width = 773;
              response.layout.height = 550;
            }
            if (!!this.chartPopup) {
              let res = (response.layout.width = this.chartWidth);
              console.log(res);
            }
          }
          this.graph = response;
        });
    } else {
      let appParams = {
        start_date: this.fromDate,
        end_date: this.toDate,
      };
      this.fDate = new Date(this.fromDate);
      this.tDate = new Date(this.toDate);
      this.appService
        .patientAgeCategory(appParams)
        .subscribe((response: any) => {
          if (response) {
            console.log(response);
            let ch = (response.layout.height = 550);
            let cw = (response.layout.width = 773);
            if (response.data.length == 0) {
              response.layout.width = 773;
              response.layout.height = 550;
            }
            if (!!this.chartPopup) {
              let res = (response.layout.width = this.chartWidth);
              console.log(res);
            }
          }
          this.graph = response;
        });
    }
  }
}
