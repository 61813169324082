import { Component, OnInit, Input, Optional, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.css'],
})
export class RevenueChartComponent implements OnInit {
  public appointmentData: any = [];
  public appData: any = [];
  public year: number;
  fromDate: any;
  toDate: any;
  fDate: any;
  tDate: any;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';
  showLabels: boolean = true;
  public userList: any;
  public initialData: any = [];
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public tableData: any = [];
  tenantArray: any = JSON.parse(localStorage.getItem('userDetails'))
    .tenant_details;
  tenantId: any = this.userData.userAccess.tenant;
  patientAppmt: any = [];
  doctorAppmt: any = [];
  multi: any = [];
  maximize: boolean = false;
  view: any[] = [350, 400];
  view1: any[] = [350, 400];
  margins: any[] = [0, 30, 0, 0];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Days';
  showYAxisLabel = true;
  padding = 40;
  yAxisLabel = 'Amount';
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  colorScheme = {
    // domain: ['#159A90', '#1DA372','#0984A8']
    domain: [
      '#FFBF00',
      '#1DA372',
      '#2292FF',
      '#FF7000',
      '#D72323',
      '#FFBF00',
      '#1DA372',
    ],
  };
  schemeType: string = 'linear';
  @Input() parentData: any;
  initFromDate: any;
  initToDate: any;
  public graph;
  @Input() dateRange: any;
  @Input() chartPopup: any;
  chartWidth: number = 1300;
  constructor(
    public adminService: AdminService,
    public appService: AppointmentService,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<RevenueChartComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    public datepipe: DatePipe
  ) {}
  ngOnInit(): void {
    var d = new Date();
    let prevMonth = d.getMonth() + 1;
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    let end = prevMonth + '-' + prevDay + '-' + prevYear;
    this.initToDate = prevMonth + '-' + prevDay + '-' + prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev = dPrev.getMonth() + 1;
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    let start = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.initFromDate = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.fromDate = this.datepipe.transform(start, 'MM-dd-yyyy');
    this.toDate = this.datepipe.transform(end, 'MM-dd-yyyy');
    // this.loadInitData(this.parentData);
    if (this.data?.openVia == 'fullScreen') {
      this.maximize = true;
      this.view = this.data.view;
      this.loadInitData(this.parentData);
    }

    if (!this.dateRange) {
      this.loadChart(null);
    }
  }
  ngOnChanges() {
    // this.loadPreiviousAppointmentList();
    // this.loadInitData(this.parentData);
    if (!!this.dateRange.Start_date && !!this.dateRange.End_date) {
      this.loadChart(this.dateRange);
    }
  }
  loadPreiviousAppointmentList() {
    let tenantParam = [];
    this.tenantArray.forEach((element) => {
      tenantParam.push(element[0]);
    });
    let appParams = {
      tenant_id__id: tenantParam,
    };
    this.fDate = new Date(this.fromDate);
    this.tDate = new Date(this.toDate);
    this.appService.getPaymentList(appParams).subscribe(
      (response) => {
        if (response) {
          this.multi = [];
          //console.log(response)
          let appData: any = response;
          if (appData.length > 0) {
            this.appData = appData;
            //console.log(appData.filter(x=>x.tenant==2));
            // this.multi = [
            //   {"name": "Mon","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Monday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Monday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Tue","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Tuesday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Tuesday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Wed","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Wednesday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Wednesday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Thu","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Thursday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Thursday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Fri","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Friday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Friday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Sat","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Saturday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Saturday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]},
            //   {"name": "Sun","series": [{"name": "Mobile","value": this.appData.filter(x=>x.payment_day=='Sunday' && x.payment_by=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)},{"name": "Web","value": this.appData.filter(x=>x.payment_day=='Sunday' && x.payment_by!=='online_stripe')?.map((y) => +y.total_amount)?.reduce((sum, current) => sum + current,0)}]}
            // ];
            var result = this.appData.reduce(function (r, a) {
              r[a.tenant] = r[a.tenant] || [];
              r[a.tenant].push(a);
              return r;
            }, Object.create(null));
            //console.log(result);
            Object.keys(result).map((key) => {
              if (key !== null && key !== 'null') {
                let tenant: string = '';
                this.tenantArray.forEach((element) => {
                  if (element[0] == key) {
                    tenant = element[1];
                  }
                });
                this.multi.push({
                  name: tenant == '' ? key : tenant,
                  value: result[key]
                    ?.map((y: any) => +y.total_amount)
                    ?.reduce((sum, current) => sum + current, 0),
                });
              }
            });
            //console.log(this.multi)
            this.initialData = JSON.stringify(this.tableData);
            // this.dataSource = new MatTableDataSource(this.appData);
            //console.log(this.appointmentData);
          } else {
            this.appointmentData = [];
          }
        }
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  loadInitData(appData) {
    this.fDate = new Date(this.initFromDate);
    this.tDate = new Date(this.initToDate);
    if (appData?.length > 0) {
      this.appData = appData;
      this.appData.map((x) => {
        var d = new Date(x.appointment_date);
        x.age = this.year - parseInt(x.patient.date_of_birth.split('-')[2]);
        x.dayStr = this.days[d.getDay()];
        // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
        // if(x.appointment_status=='UP'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
        // }
        // if(x.appointment_status=='CI'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
        // }
      });
      if (this.appData?.length > 0) {
        this.appData.map((x) => {
          this.patientAppmt.push({
            ...x,
            ...x.patient,
            appmt_id: x.id,
            complaint_name: x.questionnaire_info[0]?.complaint_id[0]
              ?.complaint_name
              ? x.questionnaire_info[0]?.complaint_id[0]?.complaint_name
              : 'Not filled',
          });
        });
      }
      this.multi = [
        {
          name: 'Mon',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Monday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Monday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Tue',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Tuesday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Tuesday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Wed',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Wednesday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Wednesday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Thu',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Thursday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Thursday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Fri',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Friday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Friday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Sat',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Saturday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Saturday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
        {
          name: 'Sun',
          series: [
            {
              name: 'Mobile',
              value: this.appData.filter(
                (x) => x.dayStr == 'Sunday' && x.appointment_from == 'M'
              ).length,
            },
            {
              name: 'Web',
              value: this.appData.filter(
                (x) => x.dayStr == 'Sunday' && x.appointment_from == 'W'
              ).length,
            },
          ],
        },
      ];
      this.initialData = JSON.stringify(this.tableData);
      // this.dataSource = new MatTableDataSource(this.appData);
      //console.log(this.appointmentData);
    } else {
      this.appointmentData = [];
    }
  }
  dateRangeChange(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    var fromdate = new Date(dateRangeStart.value);
    this.fromDate =
      fromdate.getMonth() +
      1 +
      '-' +
      fromdate.getDate() +
      '-' +
      fromdate.getFullYear();
    var todate = new Date(dateRangeEnd.value);
    this.toDate =
      todate.getMonth() +
      1 +
      '-' +
      todate.getDate() +
      '-' +
      todate.getFullYear();
    if (this.fromDate !== 'NaN-NaN-NaN' && this.toDate !== 'NaN-NaN-NaN')
      this.loadPreiviousAppointmentList();
  }
  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onSelect1(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate1(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate1(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  openFullscreen() {
    this.maximize = true;
    const dialogRef = this.dialog.open(RevenueChartComponent, {
      width: '900px',
      height: '800px',
      disableClose: true,
      data: {
        openVia: 'fullScreen',
        parentData: this.parentData,
        view: [350, 400],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
      this.view = [350, 400];
      this.maximize = false;
    });
    return dialogRef.afterClosed();
  }
  fullScreenExit() {
    this.view = [350, 400];
    this.maximize = false;
    // this.dialog.closeAll();
    this.dialogRef.close();
  }
  downloadChart() {
    document.getElementById('med-bar-header').style.display = 'none';
    html2canvas(document.getElementById('med-bar'), { useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        // var doc = new jsPDF('p', 'mm','a4');
        var doc = new jsPDF();
        var x = 1;
        var position = 0;
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.text('Medosys ' + x + ' / ' + doc.getNumberOfPages(), 150, 285);
          x++;
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save('medosys_bar_chart.pdf');
      }
    );
    document.getElementById('med-bar-header').style.display = 'block';
  }
  clearDate(event) {
    this.loadInitData(this.parentData);
  }
  loadChart(param) {
    if (!!param) {
      let appParams = {
        start_date: param.Start_date,
        end_date: param.End_date,
      };
      this.appService.revenueStatus(appParams).subscribe((response: any) => {
        if (response) {
          console.log(response);
          let ch = (response.layout.height = 550);
          let cw = (response.layout.width = 773);
          if (response.data.length == 0) {
            response.layout.width = 773;
            response.layout.height = 550;
          }
          if (!!this.chartPopup) {
            let res = (response.layout.width = this.chartWidth);
            console.log(res);
          }
        }
        this.graph = response;
      });
    } else {
      let appParams = {
        start_date: this.fromDate,
        end_date: this.toDate,
      };

      this.fDate = new Date(this.fromDate);
      this.tDate = new Date(this.toDate);
      this.appService.revenueStatus(appParams).subscribe((response: any) => {
        if (response) {
          console.log(response);
          let ch = (response.layout.height = 550);
          let cw = (response.layout.width = 773);
          if (response.data.length == 0) {
            response.layout.width = 773;
            response.layout.height = 550;
          }
          if (!!this.chartPopup) {
            let res = (response.layout.width = this.chartWidth);
            console.log(res);
          }
        }
        this.graph = response;
      });
    }
  }

  //     const config = {
  //       margin: [10,10,20,10],
  //       filename: 'Example.pdf',
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2, logging: true, dpi: 300, letterRendering: true },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'Portrait' }
  //     // pdfCallback: pdfCallback
  //   }
  //   html2pdf().from(document.getElementById("med-bar")).set(config).toPdf().get('pdf').then((pdf) => {
  //   var totalPages = pdf.internal.getNumberOfPages();

  //   for (let i = 1; i <= totalPages; i++) {
  //     pdf.setPage(i);
  //     pdf.setFontSize(9);
  //     pdf.setTextColor(70);
  //     pdf.setTextColor(70);
  //     pdf.text('Medosys Footer text', 126, pdf.internal.pageSize.getHeight() - 8);
  //     pdf.setLineCap(2);
  //     pdf.line(2,pdf.internal.pageSize.getHeight() - 20, pdf.internal.pageSize.getWidth() - 2 , pdf.internal.pageSize.getHeight() - 20);
  //     pdf.text( i + ' / ' + totalPages, pdf.internal.pageSize.getWidth() - 16, pdf.internal.pageSize.getHeight() - 8);
  //     //pdf.text('Page '+i+' of '+totalPages, pdf.internal.pageSize.getWidth() - 30, pdf.internal.pageSize.getHeight() - 4);
  //      // you can add the line separator as an image, consult the docs below to properly set the place of the image
  //     // pdf.addImage(img, 'png', 0, 0, 52, 23)
  //   }

  // }).save();
  //   }
}
