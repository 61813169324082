<div class="mcdp-modal"  *ngIf="screenType === 'mcdpDailog'; else noMcdp">
  <div style="display: flex;justify-content: space-between;">
    <h2>{{mcdpData.mcdp_category}}</h2>
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon>cancel</mat-icon>
    </div>
  </div>
  <div style="display: flex; flex-wrap: wrap;"  *ngFor="let item of mcdpData | keyvalue">
    <div  *ngIf="item.value != mcdpData.mcdp_category">
      <div *ngIf="item.value.length > 0; else noEntries">
        <div  *ngFor="let diseaseType of item.value; let i = index" >
          <div  style="display: flex;width: 100%;align-items: center;">
            <mat-form-field appearance="outline" style="width: 80%;">
              <input matInput [ngModel]="item.value[i]" (change)="onChangeValue($event.target.value, i)" type="text"/>
            </mat-form-field>
            <mat-icon (click)="deleteDiseaseOfCategory(item, i)" style="color: red;padding-bottom: 40px;padding-left: 20px;cursor: pointer;">delete</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div> 
  <div>
    <button class="disease-add" (click)="addDisease()">Add</button>
  </div>
</div>
<ng-template #noMcdp>
  <div class="fullscreen-Container" >
    <div style="padding: 10px; display: inline-block">
      <img
        src="../../../assets/images/logo.JPG"
        alt="Logo"
        width="50px"
        height="50px"
      />
    </div>
    <label
      class="mt-2 ml-2"
      style="display: inline-block; width: 70%; font-size: 1.4em"
      ><b>Medosys</b></label
    >
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon>fullscreen_exit</mat-icon>
    </div>
    <br />
    <div>
      <div *ngIf="screen !== 'medCert'">
        <h1>fsdfsdfsdf</h1>
        <div class="doctorDetails">
              <div><label>Dr. {{selectedAppointment.doctor.first_name}} {{selectedAppointment.doctor.last_name?selectedAppointment.doctor.last_name:''}}</label></div>
              <div><label>MCR No: M6992G</label></div>
              <div><label>10, Anson Road</label></div>
              <div><label>Singapore 100001</label></div>
            </div>
            <div class="patientDetails">
              <div><label>Patient Name: <span style="color:black;margin-left:20px">{{selectedAppointment.patient.first_name}} {{selectedAppointment.patient.last_name?selectedAppointment.patient.last_name:''}}</span></label></div>
              <div><label>Gender: <span style="color:black;margin-left:20px">{{selectedAppointment.patient.gender=='M'?"Male":"Female"}}</span></label></div>
              <div style="width:100%"><label style="width:100%"><div style="display:inline-block;vertical-align: top;">Advice: </div><textarea class="advice" type="text" placeholder="Type the advice ...." [(ngModel)]="prescriptionData.advice"></textarea></label></div>
            </div>
    
              <br/>
              <label class="patientDetails">Prescription</label>
              <div class="presol">
                <ol style="width:100%">
                  <li *ngFor="let data of prescriptionData.medicine_details; let id=index">
                  <div class="medRight">
                    <div class="medLeft"><input [(ngModel)]="data.med"/></div>
                    <input  type="checkbox" (change)="presTimeChange('mor',id)" [checked]="data.time.mor" id="morning" name="morning" value="morning">
                    <label for="morning"> Mor</label>
                    <input type="checkbox" (change)="presTimeChange('an',id)" [checked]="data.time.an" id="AN" name="AN" value="AN">
                    <label for="AN"> AN</label>
                    <input type="checkbox" (change)="presTimeChange('night',id)" [checked]="data.time.night" id="Night" name="Night" value="Night">
                    <label for="Night"> Night</label>
                    <div style="display: inline-block;margin-left: 30px;">
                      <mat-radio-group class="example-radio-group" [(ngModel)]="data.food">
                        <mat-radio-button class="example-radio-button" value="bf">BF</mat-radio-button>
                        <mat-radio-button class="example-radio-button" value="af" style="margin-left:20px">AF</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="medLast" style="display: inline-block;margin-left: 10px;">
                    <label><b>Days</b></label><input [(ngModel)]="data.days"/>
                  </div>
                  <div class="medDel" (click)="deleteMedicine(id)"><span><mat-icon class="delIcon">delete</mat-icon></span></div>
                  </div></li>
                </ol>
                <div class="lowerDetail" id="lowerDetail">
                  <div><label><b>Add:</b></label></div>
                  <div style="cursor:pointer" (click)="addMedicine()"><span><mat-icon style="font-size:0.9em;width:20px;height:15px;margin:5px;border-radius: 50px;color:black;background: white;">free_breakfast</mat-icon></span><label>Medicine</label></div>
                  <div><span><mat-icon style="font-size:0.9em;width:20px;height:15px;margin:5px;border-radius: 50px;color:black;background: white;">notes</mat-icon></span><label style="cursor:pointer">Diagnostic methods</label></div>
                </div>
              </div>
            </div>
      </div>
          <div style="width:100%;float:left;margin-top:20px" *ngIf="screen === 'medCert'">
          <div class="doctorDetails" style="width:35%;float:left;">
            <div><label>Dr. {{selectedAppointment.doctor.first_name}} {{selectedAppointment.doctor.last_name?selectedAppointment.doctor.last_name:''}}</label></div>
            <div><label>MCR No: M6992G</label></div>
            <div><label>10, Anson Road</label></div>
            <div><label>Singapore 100001</label></div>
            <div><label>Telephone: 69874523</label></div>
            <div><label>UEN: 202034863N</label></div>
          </div>
      <ul class="medcert">
        <li>
          Date of consultation :
          <input
            type="date"
            id="BirthDate"
            name="BirthDate"
            [ngModel]="
              selectedAppointment.patient.modified_date | date : 'yyyy-MM-dd'
            "
            (ngModelChange)="selectedAppointment.patient.modified_date = $event"
            [value]="
              selectedAppointment.patient.modified_date | date : 'yyyy-MM-dd'
            "
            #BirthDate="ngModel"
          />
          <!-- <input type="date" [value]="new Date(selectedAppointment.patient.modified_date)|date:'dd/MM/yyyy'" style="text-align:center;border:none;border-bottom: 1px solid black;margin-left:20px;"/> -->
        </li>
        <li>
          Patient Name :
          <input
            [value]="selectedAppointment.patient.first_name"
            type="text"
            class="withoutBorder"
            placeholder="Type patient's Name.."
          />
        </li>
        <li>
          NRIC/FIN/PASSPORT :
          <input
            [value]="selectedAppointment.patient.mrn"
            type="text"
            class="withoutBorder"
            placeholder="Type patient's NRIC/FIN/PASSPORT number.."
          />
        </li>
        <li>
          Medical certificate ID :
          <input
            [value]="selectedAppointment.id"
            type="text"
            class="withoutBorder"
            placeholder="Enter medical certificate ID"
          />
        </li>
      </ul>
    

      <div class="nextDiv">
        <p>
          Doctor Name:
          <input
            [value]="selectedAppointment.doctor.first_name"
            style="
              text-align: center;
              font-weight: bold;
              border: none;
              border-bottom: 1px solid black;
            "
            type="text"
            placeholder="Type Doctor's name.."
          />
          (MCR No:<input
            style="
              text-align: center;
              font-weight: bold;
              border: none;
              border-bottom: 1px solid black;
            "
            type="text"
            placeholder="Enter MCR number.."
            [(ngModel)]="medicalCertData.mcr_number"
          />)
        </p>
        <p>
          This certificate is electronically generated. No signature is required
        </p>
      </div>
      <div class="nextDiv">
        <p>This Medical certificate is not valid for absence from court.</p>
      </div>
      <div class="sealSig" style="width: 60%; float: right">
        <div>
          <img
            src="../../../assets/images/seal.jpg"
            alt="seal"
            width="180px"
            height="140px"
          />
        </div>
        <div>
          <img
            src="../../../assets/images/signature_doctor.jpg"
            alt="seal"
            width="120px"
            height="65px"
          />
        </div>
      </div>
    </div>
  </div>
</ng-template>
