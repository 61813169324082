import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.css']
})
export class AppointmentDetailComponent implements OnInit {
  selectedAppointment:any;
  selectedQuestionnaire:any=[];
  public loggedinUser=JSON.parse(localStorage.getItem('userDetails')).userAccess.id;
  public access=JSON.parse(localStorage.getItem('userDetails')).access;
  qFilled:boolean=false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef:MatDialogRef<AppointmentDetailComponent>,
  public appService:AppointmentService,private snackBar: MatSnackBar,
              private router: Router,) { }

  ngOnInit(): void {
    console.log(this.data)
    this.selectedAppointment=this.data.userData
    this.loadQuestionnaire();
  }
  loadQuestionnaire(){
    this.selectedQuestionnaire=[];
      if(this.selectedAppointment.questionnaire_status=="CO"){
        if(this.selectedAppointment.questionnaire_info.length>0){
          this.appService.getDataFromURL( this.selectedAppointment.questionnaire_info[0].questionnaire_file_url).subscribe((response) => {
            if (response) {
              console.log(response)
              let res=response;
              //this.selectedQuestionnaire=res;
             if(Object.entries(res).length>0){
              this.qFilled=true;
              Object.entries(res).forEach(([key, value]) => {
                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                this.selectedQuestionnaire.push({'question':`${key}`,'answer':`${value}`});
              });
            } else {
              this.qFilled=false;
            }
// for (var [key, value] of Object.entries(res)) {
//   console.log(`${key}: ${value}`);
//   this.selectedQuestionnaire.push({'question':`${key}`,'answer':`${value}`});
// }
console.log(this.selectedQuestionnaire);
              }
          }, (error) => {
            console.log(error)
            if(error.status=404){
              this.snackBar.open(error.statusText, 'Close', {duration: 2000});
            }
          });
      }
    }
  }
  public notesClick(){
    this.dialogRef.close();
    this.router.navigate(['/notes'], { queryParams: { id: this.selectedAppointment.id } });
    //this.appService.changeAppmt(this.selectedAppointment);
  }
  questionnaireClick(){
    //window.open(this.selectedAppointment.questionnaire_url, '_blank');
    //this.router.navigate(['/questionnaire']);
    this.dialogRef.close();
      this.appService.questionnaireClick(false);
      this.appService.notesClick(true);
      this.appService.patientClick(true);
      this.appService.changeMessage(true);
      this.appService.dashboardClick(true);
      this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
      this.appService.questionnaireSelect(this.selectedAppointment);
      
  }
  closeDialog(){
    this.dialogRef.close();
  }

}
