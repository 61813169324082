import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public email:string;
  public password:string;
  public header: any;
  public authToken: any;
  public userDetails: any;

      
  constructor(public http: HttpClient) { 
    let LSData=JSON.parse(localStorage.getItem('userDetails'));
    this.header = {
     'Content-Type': 'application/json'
    }
    if(localStorage.getItem('userDetails')){
      this.email=LSData.email;
      this.password=LSData.password;
      this.authToken=LSData.authToken;
      this.userDetails=LSData.userAccess;
    }
  }
  public GetAuthToken(paramBody: any){
    let apiURL = environment.baseUrl + 'api/auth_token';
    return this.http.post(apiURL, paramBody, {headers : this.header});
  }
  public forgetPassword(paramBody: any){
    const apiURL = environment.baseUrl + 'api/v1/user/forgot_password';
    return this.http.patch(apiURL, paramBody, {headers : this.header});
  }
  public GetClinicList(){
    const apiURL = environment.baseUrl + 'api/v1/user/login_tenant_dropdown';
    return this.http.get(apiURL, {headers : this.header});
  }
}
