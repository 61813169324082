import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { CommonModalDialogService } from 'src/app/service/common-modal-dialog.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-patient-records',
  templateUrl: './patient-records.component.html',
  styleUrls: ['./patient-records.component.css'],
})
export class PatientRecordsComponent implements OnInit {
  version = VERSION;
  public displayedColumns: string[] = [
    'id',
    'mrn',
    'first_name',
    'gender',
    'phone_number',
    'D.O.B',
    'record',
    'action',
    'viewActivity',
  ];
  public patientHistoryColumns: string[] = [
    'id',
    'date',
    'operator',
    'description',
    'event_type',
  ];
  public dataSource: MatTableDataSource<any>;
  public userList: any;
  public initialData: any = [];
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public showTable: boolean = true;
  public isPatientRecTable: boolean = true;
  public isPatientAppTable: boolean = false;
  public isViewActivityTable: boolean = false;
  public patientHistory: MatTableDataSource<any>;

  tenantId: any = this.userData.userAccess.tenant;
  selectedData: any;
  selectedUser: any;
  selectedUserFull: any;
  @Output() backClick = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) patientHistoryPage: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) patientHistoryPageSort: MatSort;
  public id: number;
  constructor(
    private route: ActivatedRoute,
    public adminService: AdminService,
    public appService: AppointmentService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public modalService: CommonModalDialogService
  ) {
    //   this.route.queryParams.subscribe(params => {
    //     this.id = params['category'];
    // });
    //this.id=this.tenantId;
  }

  ngOnInit(): void {
    this.loadUsers();
  }
  loadUserActivity(id: number) {
    this.adminService.getPatientActivity(id).subscribe(
      (response: any) => {
        if (response) {
          this.patientHistory = new MatTableDataSource(response.data);
          this.patientHistory.paginator = this.patientHistoryPage;
          this.patientHistory.sort = this.patientHistoryPageSort;
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  loadUsers() {
    this.adminService.GetPatientList().subscribe(
      (response) => {
        if (response) {
          console.log(response);
          this.userList = response;
          this.userList.sort((s1, s2) => {
            return s1.id - s2.id;
          });
          this.userList.map((element) => {
            element.edited = false;
          });
          //this.userList=this.userList.filter(x=>x.tenant==this.tenantId);
          this.initialData = JSON.stringify(this.userList);
          this.dataSource = new MatTableDataSource(this.userList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editUser(data) {
    this.userList.find((x) => x.id == data.id).edited = true;
    this.selectedUser = this.userList.find((x) => x.id == data.id);
    this.isPatientRecTable = false;
    this.isPatientAppTable = true;
    this.isViewActivityTable = false;
    // const dialogRef = this.dialog.open(AddUserComponent, {
    //   width: '70vw',
    //   height:'90vh',
    //   disableClose: true,
    //   data: { userData: selectedUser }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if(result.event == 'Add'){
    //     this.loadUsers();
    //   }else if(result.event == 'Update'){
    //     this.loadUsers();
    //   } else if(result.event == 'Cancel'){
    //     this.loadUsers();
    //   }
    // });
    // return dialogRef.afterClosed();
  }
  viewActivity(data) {
    this.isPatientRecTable = false;
    this.isPatientAppTable = false;
    this.isViewActivityTable = true;
    this.userList.find((x) => x.id == data.id).edited = true;
    this.selectedUser = this.userList.find((x) => x.id == data.id);
    this.loadUserActivity(this.selectedUser?.id);
    this.isViewActivityTable = true;
    this.appService.patientDetailsClick({
      flag: true,
      userData: [this.selectedUser],
    });
  }
  deleteUser(data) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.userList.find((x) => x.id == data.id).is_active = false;
        this.snackBar.open('User is Deleted successfully', 'Close', {
          duration: 2000,
        });
      }
    });
  }

  // addUser(){
  //   let user={
  //     id: this.userList.length+1,
  //     first_name: "",
  //     middle_name: null,
  //     last_name: null,
  //     phone_number: null,
  //     avator_url: null,
  //     gender: null,
  //     email: "",
  //     degree: null,
  //     is_active: true,
  //     tenant: this.tenantId,
  //     is_staff: false,
  //     role_id: [1],
  //     is_upgrade_mail_sent: false,
  //     edited:false
  //   }
  //   const dialogRef = this.dialog.open(AddUserComponent, {
  //     width: '70vw',
  //     height:'90vh',
  //     disableClose: true,
  //     data: { userData: user }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result.event == 'Add'){
  //       this.loadUsers();
  //     } else if(result.event == 'Update'){
  //       this.loadUsers();
  //     } else if(result.event == 'Cancel'){
  //       this.loadUsers();
  //     }
  //   });
  //   return dialogRef.afterClosed();
  // }
  closePatientRecord() {
    if (this.isViewActivityTable) {
      this.isPatientRecTable = true;
      this.isPatientAppTable = false;
      this.isViewActivityTable = false;
    } else {
      this.appService.patientClick(true);
    }
    this.loadUsers();
    this.appService.patientDetailsClick({
      flag: false,
      userData: [this.selectedUser],
    });
  }
  recordsClick(data) {
    this.isPatientRecTable = false;
    this.isPatientAppTable = true;
    this.isViewActivityTable = false;
    this.selectedData = data;
    this.selectedUser = undefined;
    this.appService.patientDetailsClick({
      flag: true,
      userData: [this.selectedData],
    });
  }
  // loadPatientList(data){
  //   this.appService.getPatients().subscribe((response) => {
  //     if (response) {
  //       //console.log(response)
  //       let appData:any=response;
  //       if(appData.length>0){
  //         this.selectedUserFull=appData.filter(x=>x.id==data.id);
  //         this.appService.patientDetailsClick({flag:true,userData:this.selectedUserFull});
  //         //console.log(this.selectedData);
  //       } else {
  //         this.selectedUserFull=[];
  //       }
  //     }
  //   }, (error) => {
  //     //console.log(error)
  //       //this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
  //   });
  // }
  closeClicked(event) {
    this.isPatientRecTable = true;
    this.isPatientAppTable = false;
    this.isViewActivityTable = false;
    this.appService.patientDetailsClick({ flag: false, userData: null });
    this.loadUsers();
  }
}
