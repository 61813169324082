<div class="notesContainer">
    <div class="closeIcon" (click)="closeNotes()">X</div>
    <div class="content headerCont">
        <div style="display:inline-block;text-align: right;"><h1><strong>Notes</strong></h1></div>
        <div style="display:inline-block;float:right">
            <mat-form-field appearance="fill">
                <mat-label><b>Date Range</b></mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="Start date" #dateRangeStart>
                  <input matEndDate placeholder="End date" #dateRangeEnd  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
    </div>
    <div class="upcoming">
        <label class="patientName ml-3">Upcoming ({{upcomingData?.length}})</label>
        <div class="appCardCont">
            <div *ngIf="upcomingData.length<1" class="noAppointment">No Upcoming Notes in the selected date</div>
            
            <div class="appCard" *ngFor="let app of upcomingData; let i = index" (click)="selectNotes(app)">
                <table [ngClass]="app.selected ?'selectedTopbar':'topBar'">
                    <tr><td><div class="indicator" style="background:rgb(243, 124, 124)"></div></td></tr>
                    <tr><td class="usrImage"><img src="../../../assets/images/avator.JPG" alt="userName" style="width:100px;height:80px;border-radius:50%;"></td></tr>
                    <tr><td class="patientName">{{app.patient.first_name}}</td></tr>
                    <tr><td class="doctorName">Dr. {{app.doctor.first_name}}</td></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="upcoming mt-4">
        <label class="patientName ml-3">Checked-In ({{checkedinData?.length}})</label>
        <div class="appCardCont">
            <div *ngIf="checkedinData.length<1" class="noAppointment">No Checked-In Notes in the selected date</div>
            
            <div class="appCard" *ngFor="let app of checkedinData; let i = index" (click)="selectNotes(app)">
                <table [ngClass]="app.selected ?'selectedTopbar':'topBar'">
                    <tr><td><div class="indicator" style="background:rgb(247, 218, 147)"></div></td></tr>
                    <tr><td class="usrImage"><img src="../../../assets/images/avator.JPG" alt="userName" style="width:100px;height:80px;border-radius:50%;"></td></tr>
                    <tr><td class="patientName">{{app.patient.first_name}}</td></tr>
                    <tr><td class="doctorName">Dr. {{app.doctor.first_name}}</td></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="upcoming mt-4">
        <label class="patientName ml-3">Completed ({{completedData?.length}})</label>
        <div class="appCardCont">
            <div *ngIf="completedData.length<1" class="noAppointment">No Completed Notes in the selected date</div>
            
            <div class="appCard" *ngFor="let app of completedData; let i = index" (click)="selectNotes(app)">
                <table [ngClass]="app.selected ?'selectedTopbar':'topBar'">
                    <tr><td><div class="indicator" style="background:rgb(142, 160, 241)"></div></td></tr>
                    <tr><td class="usrImage"><img src="../../../assets/images/avator.JPG" alt="userName" style="width:100px;height:80px;border-radius:50%;"></td></tr>
                    <tr><td class="patientName">{{app.patient.first_name}}</td></tr>
                    <tr><td class="doctorName">Dr. {{app.doctor.first_name}}</td></tr>
                </table>
            </div>
        </div>
    </div>    
</div>
