import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-set-fees',
  templateUrl: './set-fees.component.html',
  styleUrls: ['./set-fees.component.css'],
})
export class SetFeesComponent {
  public doctorData: any;
  public speciality: any = [];
  showError: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<SetFeesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.doctorData = this.data.data;
    console.log(this.doctorData);
    if (this.doctorData.doctor_charge_ids == null) {
      this.doctorData?.speciality_name?.forEach((x) => {
        this.speciality.push({ name: x, charge: 0 });
      });
    } else {
      this.doctorData?.speciality_name?.forEach((x) => {
        this.speciality.push({
          name: x,
          charge: this.doctorData.doctor_charge_ids.doctor_charges[x] || 0,
        });
      });
    }
  }
  saveDialog() {
    debugger;
    console.log(this.speciality);
    let fee = this.speciality.filter((obj) => obj.charge === 0);
    if (fee.length > 0) {
      this.showError = true;
    }
    console.log(fee);
    let doctor_charges = {};
    this.speciality.forEach((x) => {
      doctor_charges[x.name] = x.charge;
    });
    console.log(this.speciality);
    if (this.doctorData.doctor_charge_ids === null) {
      this.doctorData.doctor_charge_ids = {
        doctor_charges: doctor_charges,
        app_charge: 0,
        tenant: this.doctorData.tenant,
      };
    } else {
      this.doctorData.doctor_charge_ids.doctor_charges = doctor_charges;
      this.doctorData.doctor_charge_ids.tenant = this.doctorData.tenant;
    }
    if (fee.length == 0) {
      this.dialogRef.close({ event: 'Save', data: this.doctorData });
    }
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  checkFee(data) {
    debugger;
    if (data > 0) {
      this.showError = false;
    }
  }
}
