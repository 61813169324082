import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AdminService } from 'src/app/service/admin.service';
import { LoginService } from 'src/app/service/login.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { DoctorDetailComponent } from '../doctor-detail/doctor-detail.component';
import { SetFeesComponent } from 'src/app/shared_components/set-fees/set-fees.component';
interface Gender {
  value: string;
  viewValue: string;
}
interface City {
  value: string;
  viewValue: string;
}
interface State {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  genders: Gender[] = [
    { value: 'M', viewValue: 'Male' },
    { value: 'F', viewValue: 'Female' },
  ];
  roles: any = [];
  public isAcknowlegedDoctorFee = false;
  public userList: any;
  public searchData: any;
  showSearch: boolean = false;
  password: boolean = false;
  newPassword: string = '';
  oldPassword: string = '';
  specialityArray: any = [];
  userData: any;
  url: any = null;
  fileUrl: any = null;
  file: any = null;
  signatureFile: any = null;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  telephoneCodes: any = [];
  countryCode: any;
  showCheckbox: boolean = false;
  dob: any;
  cloneUserFlag: boolean = false;
  oldTenant: number;
  showpwd: boolean = false;
  showpwd1: boolean = false;
  Text: string = '';
  tabIndex: number = 0;
  selectedIndex: number = 0;
  showWarning: boolean = false;
  strongPassword = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  );
  mediumPassword = new RegExp(
    '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
  );
  doctorDetails: any = {};
  showDetails: boolean = false;
  doctorEducation: any;
  doctorId: any;
  academicData: any = {};
  editBio: boolean = true;
  QualDegree: Degree = {
    degree: '',
    degree_completed_year: '',
    university: '',
    degree_completed_country: '',
    fmge_status: '',
  };
  requestData: Qualify = {
    degrees: null,
    about: null,
    user_id: null,
  };
  isShow: boolean = false;
  public loggedinUser = JSON.parse(localStorage.getItem('userDetails'))
    .userAccess;
  constructor(
    public adminService: AdminService,
    public loginService: LoginService,
    private snackBar: MatSnackBar,
    public appService: AppointmentService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.cloneUserFlag = false;
    this.showCheckbox = this.data?.userData?.first_name !== '' ? true : false;
    this.userData = this.data?.userData;
    this.doctorId = this.userData.id;
    this.userData.roles = this.userData?.role_id?.map((x) => x.id);
    this.userData.specialities = this.userData?.speciality_ids?.map(
      (x) => x.id
    );
    this.url = this.userData?.avator_url;
    this.fileUrl = this.userData?.doctor_signature_url;
    this.dob = new Date(this.userData?.date_of_birth);
    console.log(this.userData);
    this.getDoctorDetails(this.userData.tenant);
    this.getRoles();
    this.getSpecialities();
    this.onLoadTelephoneCodes();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    });
    this.adminService.GetTenantUserList().subscribe(
      (response: any) => {
        if (response) {
          console.log(response);
          this.userList = [
            ...response.filter((x) => x.tenant !== this.data?.userData?.id),
          ];
          this.userList = [
            ...new Map(this.userList.map((v) => [v.email, v])).values(),
          ];
          console.log(this.userList);
          this.searchData = response;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    console.log(this.isShow);
    if (!!this.userData.first_name) {
      this.isShow = true;
      this.getDoctordetail();
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.telephoneCodes.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  async getDoctorDetails(docId) {
    this.adminService.getAboutDoctorData(docId).subscribe(
      (response: any) => {
        if (response) {
          console.log(response);
          this.doctorDetails = response;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  saveDoctor() {
    const docData = {
      degree: this.doctorDetails.degree,
      degree_completed_year: this.doctorDetails.degree_completed_year,
      university: this.doctorDetails.university,
      degree_completed_country: this.doctorDetails.degree_completed_country,
      fmge_status: this.doctorDetails.fmge_status,
      user_id: this.userData.id,
    };
    if (this.doctorDetails.degree) {
      this.adminService
        .updateAboutDoctorData(docData, this.userData.tenant)
        .subscribe(
          (response: any) => {
            if (response) {
              console.log(response);
              this.doctorDetails = response;
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.adminService.saveAboutDoctorData(docData).subscribe(
        (response: any) => {
          if (response) {
            console.log(response);
            this.doctorDetails = response;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  onLoadTelephoneCodes() {
    this.appService
      .getDataFromURL('../../assets/json/telephoneCodes.json')
      .subscribe(
        (response) => {
          if (response) {
            //console.log(response);
            let res: any = response;
            this.telephoneCodes = res;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getRoles() {
    this.appService.getRoles().subscribe(
      (response) => {
        if (response) {
          //console.log(response);
          let res: any = response;
          this.roles = res;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getSpecialities() {
    this.appService.getSpecialities().subscribe(
      (response) => {
        if (response) {
          //console.log(response);
          let res: any = response;
          this.specialityArray = res;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  searchUser(value) {
    console.log(value);
    if (!this.userData.edited) {
      if (value !== '') {
        this.showSearch = true;
        this.searchData = this.userList.filter((x) =>
          x.first_name.toLowerCase().includes(value.toLowerCase())
        );
        if (this.searchData.length == 0) {
          this.showSearch = false;
        }
      } else {
        this.showSearch = false;
      }
    }
  }
  searchLeave() {
    // this.showSearch=false;
  }
  showPassword() {
    this.showpwd = !this.showpwd;
  }
  showPassword1() {
    this.showpwd1 = !this.showpwd1;
  }
  cloneUser(user) {
    this.userData = user;
    this.userData.roles = this.userData?.role_id?.map((x) => x.id);
    this.userData.specialities = this.userData?.speciality_ids?.map(
      (x) => x.id
    );
    this.url = this.userData?.avator_url;
    this.fileUrl = this.userData?.doctor_signature_url;
    this.dob = new Date(this.userData?.date_of_birth);
    this.cloneUserFlag = true;
    this.userData.new_tenant_id = this.data.userData.tenant;
    this.userData.old_tenant_id = user.tenant;
    this.showSearch = false;
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  closePassword() {
    this.password = false;
    this.Text = '';
  }
  nextDialog() {
    this.selectedIndex = this.selectedIndex + 1;
  }
  getPosts(countryCode) {
    this.countryCode = countryCode;
    this.userData.phone_number_prefix = this.countryCode;
  }
  changePwd() {
    this.password = !this.password;
  }
  savePassword() {
    if (this.oldPassword === this.newPassword) {
      this.snackBar.open(
        'New password should not same as old password',
        'Close',
        { duration: 2000 }
      );
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message:
            'Once you change password it will reflect to all the clinic, Do you want to proceed?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.appService
            .changePwd({
              old_password: this.oldPassword,
              new_password: this.newPassword,
              email: this.userData.email,
            })
            .subscribe(
              (response: any) => {
                if (response) {
                  this.snackBar.open(JSON.stringify(response), 'Close', {
                    duration: 2000,
                  });
                  if (
                    response?.data?.message ===
                    'Successfully Updated Your Password'
                  ) {
                    this.router.navigate(['/login']);
                    this.closeDialog();
                    this.dialogRef.close({
                      event: 'changePassword',
                      data: response,
                    });
                  }
                }
              },
              (error) => {
                this.snackBar.open(error, 'Close', { duration: 2000 });
              }
            );
        }
      });
    }
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }
  onSelectSignatureFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.signatureFile = event.target.files[0];
      console.log(this.signatureFile);
      var reader1 = new FileReader();
      reader1.readAsDataURL(event.target.files[0]); // read file as data url
      reader1.onload = (event) => {
        // called once readAsDataURL is completed
        this.fileUrl = event.target.result;
        console.log(this.fileUrl, this.signatureFile.name);
      };
    }
  }
  onRoleChange(event) {
    this.userData.role_id = [];
    this.userData.role_id = this.roles.filter((item) =>
      event.value.includes(item.id)
    );
    console.log(this.userData.role_id);
  }
  onSpecialityChange(event) {
    this.userData.speciality_ids = [];
    this.userData.speciality_ids = this.specialityArray.filter((item) =>
      event.value.includes(item.id)
    );
    console.log(this.userData.speciality_ids);
  }
  onTabChanged(event) {
    console.log(event.index);
    if (event.index == 0) {
    }
  }
  saveDialog() {
    // if (this.userData.roles.includes(1)) {
    //   if (!this.isAcknowlegedDoctorFee) {
    //     return
    //   }
    // }
    this.getDoctordetail();
    let formData = new FormData();
    if (
      this.userData.phone_number_prefix !== 'undefined' &&
      this.userData.phone_number_prefix !== 'null'
    ) {
      if (this.userData.first_name !== '' && this.userData.last_name != '') {
        if (this.userData?.speciality_ids?.length) {
          this.userData.speciality_ids.map((x) => {
            x.complaint_id = [1];
          });
        }
        for (const key in this.userData) {
          if (
            `${key}` !== 'avator_url' &&
            `${key}` !== 'last_seen' &&
            key !== 'doctor_signature_url'
          ) {
            formData.append(`${key}`, `${this.userData[key]}`);
          }
        }
        if (this.userData.edited) {
          if (this.file !== null || this.signatureFile !== null) {
            if (this.file !== null) {
              formData.append('avator_url', this.file, this.file?.name);
            }
            debugger;
            if (this.signatureFile !== null) {
              formData.append(
                'doctor_signature_url',
                this.signatureFile,
                this.signatureFile?.name
              );
              const avatorUrl = formData.get('doctor_signature_url');
              console.log(avatorUrl);
            }
            console.log(formData);
            this.adminService
              .editUserForm(formData, this.userData.id)
              .subscribe(
                (response) => {
                  if (response) {
                    console.log(response);
                    if (!this.password) {
                      this.dialogRef.close({ event: 'Update', data: response });
                    }
                  }
                },
                (error) => {
                  this.snackBar.open(error, 'Close', { duration: 2000 });
                }
              );
          }
          this.secondAPICall(this.userData.id);
        } else {
          // ============
          if (this.cloneUserFlag) {
            let addCloneFormData = new FormData();
            console.log(this.userData);
            let { avator_url, doctor_signature_url, ...userData } =
              this.userData;
            this.adminService.addUser(userData).subscribe(
              (response) => {
                if (response) {
                  this.dialogRef.close({ event: 'Add', data: response });
                  let res: any = response;
                  if (this.file !== null || this.signatureFile !== null) {
                    if (this.file !== null) {
                      addCloneFormData.append(
                        'avator_url',
                        this.file,
                        this.file?.name
                      );
                    }
                    if (this.signatureFile !== null) {
                      addCloneFormData.append(
                        'doctor_signature_url',
                        this.signatureFile,
                        this.signatureFile?.name
                      );
                    }
                    this.adminService
                      .editUserForm(addCloneFormData, res.id)
                      .subscribe(
                        (response) => {
                          if (response) {
                            if (!this.password) {
                              this.dialogRef.close({
                                event: 'Update',
                                data: response,
                              });
                            }
                          }
                        },
                        (error) => {
                          this.snackBar.open(error, 'Close', {
                            duration: 2000,
                          });
                        }
                      );
                  }
                }
              },
              (error) => {
                this.snackBar.open(error, 'Close', { duration: 2000 });
              }
            );
          } else {
            let addFormData = new FormData();
            for (const key in this.userData) {
              if (
                `${key}` !== 'avator_url' &&
                `${key}` !== 'last_seen' &&
                `${key}` !== 'doctor_signature_url' &&
                `${key}` !== 'speciality_ids' &&
                `${key}` !== 'specialities' &&
                `${key}` !== 'role_id' &&
                `${key}` !== 'roles'
              ) {
                addFormData.append(`${key}`, `${this.userData[key]}`);
              }
            }
            if (this.file !== null) {
              addFormData.append('avator_url', this.file, this.file?.name);
            }
            if (this.signatureFile !== null) {
              addFormData.append(
                'doctor_signature_url',
                this.signatureFile,
                this.signatureFile?.name
              );
            }
            this.adminService.addUserForm(addFormData).subscribe(
              (response) => {
                if (response) {
                  // this.dialogRef.close({event:'Add',data:response});
                  let res: any = response;
                  this.secondAPICall(res.id);
                }
              },
              (error) => {
                this.snackBar.open(error, 'Close', { duration: 2000 });
              }
            );
          }
        }
      } else {
        this.snackBar.open('First_name and Last_Name are Mandatory', 'Close', {
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open('Please enter the country code', 'Close', {
        duration: 2000,
      });
    }
  }
  secondAPICall(id) {
    debugger;
    let { avator_url, doctor_signature_url, ...userData } = this.userData;
    console.log(userData);
    this.adminService.editUser(userData, id).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          if (!this.password) {
            this.selectedIndex = this.selectedIndex + 1;
            //  this.dialogRef.close({event:'Update',data:response});
          }
        }
      },
      (error) => {
        this.snackBar.open(error, 'Close', { duration: 2000 });
      }
    );
    // let secondFormData = new FormData;
    // for (const key in this.userData) {
    //   if(`${key}`=='speciality_ids' || `${key}`=='role_id'){
    //     secondFormData.append(`${key}`, `${this.userData[key]}`);
    //   }
    // }
    // this.adminService.editUserForm(secondFormData,id).subscribe((response) => {
    //   if (response) {
    //    if(!this.password){
    //      this.dialogRef.close({event:'Update',data:response});
    //    }
    //     }
    // }, (error) => {
    //   this.snackBar.open(error, 'Close', {duration: 2000});
    // });
  }
  MaskCharacter(str, mask, n = 1) {
    // Slice the string and replace with
    // mask then add remaining string
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
  }
  specialityChange(id) {
    this.userData.speciality_ids = [id];
  }
  dateFormat(date) {
    console.log(date);
    var d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();
    this.userData.date_of_birth = month + '-' + day + '-' + year;
    this.dob = new Date(this.userData.date_of_birth);
  }

  StrengthChecker(newPassword) {
    console.log(newPassword);
    if (this.strongPassword.test(newPassword)) {
      this.Text = 'Strong Password';
    } else if (this.mediumPassword.test(newPassword)) {
      this.Text = 'Medium Password';
    } else {
      this.Text = 'Weak Password';
    }
  }
  adminMouseEnter() {
    this.showWarning = true;
  }
  adminMouseLeave() {
    this.showWarning = false;
  }
  addDetails() {
    // this.showDetails=true;
    let degree;
    let id = this.doctorId;
    if (!!this.doctorEducation) {
      degree = this.doctorEducation;
    } else {
      degree == 'null';
    }
    console.log(degree);
    const dialogRef = this.dialog.open(DoctorDetailComponent, {
      width: '70vw',
      height: 'auto',
      disableClose: true,
      data: { doctor_id: id, degree: degree },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isShow = true;
        this.getDoctordetail();
      }
    });
  }
  closeDialog1() {
    this.dialogRef.close();
  }
  setFee() {
    const dialogRef = this.dialog.open(SetFeesComponent, {
      width: '30vw',
      height: 'auto',
      disableClose: true,
      data: {},
    });
  }
  editDetail(degreeId) {
    let id = this.doctorId;
    let degree = this.doctorEducation;
    console.log(id, degree, degreeId);
    const dialogRef = this.dialog.open(DoctorDetailComponent, {
      width: '70vw',
      height: 'auto',
      disableClose: true,
      data: { doctor_id: id, degree: degree, degree_id: degreeId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isShow = true;
        this.getDoctordetail();
      }
    });
    return dialogRef.afterClosed();
  }
  getDoctordetail() {
    console.log(this.userData.id);
    this.isShow = true;
    // this.appService.getDoctordetail().subscribe((response) => {
    //   if (response) {
    //    this.doctorEducation = response;
    //   console.log(this.doctorEducation)}
    // })
    this.appService
      .getDoctordetailById(this.userData.id)
      .subscribe((response) => {
        if (response) {
          this.doctorEducation = response;
          console.log(this.doctorEducation);
          console.log(this.doctorEducation[0].degrees);
        }
        this.academicData = this.doctorEducation[0].degrees;
        // this.academicData=this.doctorEducation.map((x:any)=>x.degrees)
        console.log(this.academicData);
      });
  }

  updateDoctorDetail(data) {
    console.log(this.doctorEducation[0].about, event);
    console.log(this.doctorEducation);
    if (!!data) {
      console.log(data);
      this.requestData.user_id = this.doctorEducation[0].user_id;
      this.requestData.about = data;
      this.requestData.degrees =
        this.doctorEducation[0].degrees.length > 0
          ? this.doctorEducation[0].degrees
          : null;
      console.log(this.requestData);
      this.appService
        .updateDoctordetail(this.requestData, this.doctorEducation[0].id)
        .subscribe((response) => {
          if (response) {
            this.doctorEducation = response;
            console.log(response);
            this.getDoctordetail();
          }
          // this.snackBar.open("Doctor Bio updated successfully", 'Close', {duration: 2000});
        });
      this.editBio = true;
    }
  }
  updateBio() {
    this.editBio = false;
  }
  leave(event) {
    console.log(event);
    this.showSearch = false;
  }
}

interface Degree {
  degree: string;
  degree_completed_year: string;
  university: string;
  degree_completed_country: string;
  fmge_status: string;
}
interface Qualify {
  degrees: Degree[] | null;
  about: string | null;
  user_id: number | null;
}
