import { AfterViewInit,Component, ViewChild,OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';
import { CommonModalDialogService } from 'src/app/service/common-modal-dialog.service';
import { AddUserComponent } from '../add-user/add-user.component';
import { AppointmentService } from 'src/app/service/appointment.service';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  version = VERSION;
  public displayedColumns: string[] = ['id', 'first_name','phone_number','email','is_active','is_staff','action'];
  public dataSource: MatTableDataSource<any>;
  public userList:any;
  public initialData:any=[];
  public userData=JSON.parse(localStorage.getItem('userDetails'));
  tenantId: any=this.userData.userAccess.tenant;
  @Output() backClick = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public roleArray=[]
public id:number;
  constructor(private route: ActivatedRoute,public adminService:AdminService,public appService:AppointmentService,public dialogRef:MatDialogRef<ManageUserComponent>,
              private dialog: MatDialog,private snackBar: MatSnackBar,public modalService:CommonModalDialogService,) { 
  //   this.route.queryParams.subscribe(params => {
  //     this.id = params['category'];
  // });
  //this.id=this.tenantId;
  }

  ngOnInit(): void {
    this.loadUsers();
    this.getRoles();
  }
  loadUsers(){
    this.adminService.GetUserList().subscribe((response) => {
      if (response) {
        //console.log(response)
        this.userList=response;
        this.userList.sort((s1, s2) => {
          return s1.id - s2.id;
        });
        this.userList.map(element=>{
          element.edited=false;
        });
        //this.userList=this.userList.filter(x=>x.tenant==this.tenantId);
        //console.log(this.userList)
        this.initialData=JSON.stringify(this.userList);
        this.dataSource = new MatTableDataSource(this.userList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        }
    }, (error) => {
      //console.log(error);
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  editUser(data){
    this.userList.find(x=>x.id==data.id).edited=true;
    //this.userList.find(x=>x.id==data.id).role_id=[1]
    let selectedUser=this.userList.find(x=>x.id==data.id);
    //console.log(selectedUser);
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { userData: selectedUser }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.loadUsers();
      }else if(result.event == 'Update'){
        this.loadUsers();
      } else if(result.event == 'Cancel'){
        this.loadUsers();
      } else if(result.event == 'changePassword'){
        this.dialogRef.close();
      }
    });
    return dialogRef.afterClosed();
  }
  getRoles(){
    this.appService.getRoles().subscribe((response) => {
      if (response) {
        ////console.log(response);
        let res:any = response;
        this.roleArray=res;
        }
    }, (error) => {
      //console.log(error);
    });
  }
  deleteUser(data){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: 'User will be DEACTIVATED, Do you want to proceed?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let userData=this.userList.find(x=>x.id==data.id);
        if(userData?.speciality_ids?.length){
          userData.speciality_ids.map(x=>{
            x.complaint_id=[1];
          })
        }
        userData.is_active=false;
        let { avator_url, doctor_signature_url, ...userList } = userData;
        this.adminService.editUser(userList,data.id).subscribe((response) => {
          if (response) {
            this.snackBar.open('User is Deleted successfully', 'Close', {duration: 2000});
            }
        }, (error) => {
          this.snackBar.open(error, 'Close', {duration: 2000});
        });
      }
    });
  }

  addUser(){
    let user={
      id: this.userList.length+1,
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_number: null,
      avator_url: "null",
      gender: null,
      email: "",
      degree: null,
      is_active: true,
      tenant: this.tenantId,
      is_staff: false,
      role_id: [2],
      is_upgrade_mail_sent: false,
      edited:false,
      about:""
    }
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '70vw',
      height:'90vh',
      disableClose: true,
      data: { userData: user }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.loadUsers();
      }else if(result.event == 'Update'){
        this.loadUsers();
      } else if(result.event == 'Cancel'){
        this.loadUsers();
      }
    });
    return dialogRef.afterClosed(); 
  }
  closeManageuser(){
    this.dialogRef.close();
  }

}
