import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from '../../environments/environment';
import {LoginService} from '../service/login.service'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public header: any;
  public authToken: any;
  public users: any;
  public userDetails: any;
  private userSubject = new BehaviorSubject<string>("default message");
  userList = this.userSubject.asObservable();

  //public userSubject: BehaviorSubject<any>("anyData");
  // public userList=this.userSubject.asObservable();

  constructor(public http: HttpClient, public loginService:LoginService)  {
  let LSData=JSON.parse(localStorage.getItem('userDetails'));
  this.authToken=LSData?.authToken;
  this.header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json'});
 }
 getHeader(){
  let LSData=JSON.parse(localStorage.getItem('userDetails'));
  this.authToken=LSData.authToken;
  this.header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/json'});
 }
  public nextUserList() {
    this.userSubject.next(this.users);
}
  public GetTenantList() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/tenants/';
    return this.http.get(apiURL, {headers : this.header});
  }
  public GetTenantData(id) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/tenants/'+id;
    return this.http.get(apiURL, {headers : this.header});
  }
  public GetUserList() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_all_users_except_patient/';
    let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    return this.http.get(apiURL,{headers : this.header});
    // return this.http.get(apiURL,{headers : header});
  }
  public GetSpecificTenantUserList(id) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_all_users_except_patient_by_tenant/?tenant='+id;
    let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    return this.http.get(apiURL,{headers : this.header});
  }
  public GetTenantUserList() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_all_users_except_patient_by_tenant/';
    let header = new HttpHeaders({'accept': 'application/json','Content-Type': 'application/json'})
    return this.http.get(apiURL,{headers : this.header});
  }
  public GetDoctorFees(){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_provider/';
    return this.http.get(apiURL, {headers : this.header});
  }
  public GetSpecificDoctor(id){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_provider/'+id;
    return this.http.get(apiURL, {headers : this.header});
  }
  public EditDoctorFees(params,id){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/provider_price_update/'+id;
    return this.http.patch(apiURL,params, {headers : this.header});
  }
  public changeFeeStatus(params){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_price/'+params.id;
    return this.http.patch(apiURL,params, {headers : this.header});
  }
  public GetRefundDetails(){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_refund';
    return this.http.get(apiURL, {headers : this.header});
  }
  public EditRefundDetails(params,id){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/appointment_refund/'+id;
    return this.http.patch(apiURL,params, {headers : this.header});
  }
  
  public GetPatientList() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/';
    return this.http.get(apiURL, {headers : this.header});
  }
  public getCountryList() {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/countries/';
    return this.http.get(apiURL, {headers : this.header});
  }
  public getStateList(country) {
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/get_state_names/'+country+'/';
    return this.http.get(apiURL, {headers : this.header});
  }
  public editTenentList(params:any,id){
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken});
    const apiURL = environment.baseUrl + 'api/v1/user/tenants/'+id;
    return this.http.patch(apiURL,params, {headers : header});
  }
  public addTenant(params:any){    
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken});
    const apiURL = environment.baseUrl + 'api/v1/user/tenants/';
    return this.http.post(apiURL,params, {headers : header});
  }
  public editUserForm(params:any,id){
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken});
    const apiURL = environment.baseUrl + 'api/v1/user/users/'+id;
    return this.http.patch(apiURL,params, {headers : header});
  }
  // public editUser(params:any,id){
  //   let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/json'});
  //   const apiURL = environment.baseUrl + 'api/v1/user/add_multitenant_users/'+id;
  //   return this.http.patch(apiURL,params, {headers : header});
  // }
  public editUser(params:any,id){
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/json'});
    const apiURL = environment.baseUrl + 'api/v1/user/update_users/'+id+'/';
    return this.http.patch(apiURL,params, {headers : header});
  }
  public addUserForm(params:any){
    console.log(params)
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken});
    const apiURL = environment.baseUrl + 'api/v1/user/users/';
    // const apiURL = environment.baseUrl + 'api/v1/user/add_multitenant_users/';
    return this.http.post(apiURL,params, {headers : header});
  }
  public addUser(params:any){
    console.log(params)
    // let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken});
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/json'})
    const apiURL = environment.baseUrl + 'api/v1/user/add_multitenant_users/';
    return this.http.post(apiURL,params, {headers : header});
  }
  public addLogo(params:any,id){
    //console.log(params.get('avator_url'));
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/user_logo_upload/'+id;
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'multipart/form-data'});
    return this.http.patch(apiURL,params, {headers : header});
  }
  public addTenantLogo(params:any,id){
    console.log(params.get('logo_url'));
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/tenant_logo_upload/'+id;
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'multipart/form-data'});
    return this.http.patch(apiURL,params, {headers : header});
  }
  public getPatientActivity(id){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/appointments/patients/history/'+id;
    return this.http.get(apiURL, {headers : this.header});
  }
  
  public getAboutDoctorData(docId){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_details/' + docId
    return this.http.get(apiURL, {headers : this.header});
  }
  public saveAboutDoctorData(params){
    this.getHeader();
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_details/';
    return this.http.post(apiURL,params, {headers : this.header});
  }
  public updateAboutDoctorData(params, id) {
    let header = new HttpHeaders({'Authorization': 'Bearer ' + this.authToken,'accept': 'application/json','Content-Type': 'application/json'});
    const apiURL = environment.baseUrl + 'api/v1/user/doctor_details/'+id;
    return this.http.patch(apiURL,params, {headers : header});
  }

}
