import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
  CdkDragStart,
} from '@angular/cdk/drag-drop';
import { E } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { start } from 'repl';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { DashboardPopupComponent } from 'src/app/shared_components/dashboard-popup/dashboard-popup.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  parentData: any;
  fromDate: any;
  toDate: any;
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  isAdmin: boolean = false;
  fDate: any;
  tDate: any;
  fDate1: any;
  tDate1: any;
  fDate2: any;
  tDate2: any;
  fDate3: any;
  tDate3: any;
  fDate4: any;
  tDate4: any;
  fDate5: any;
  tDate5: any;
  fDate6: any;
  tDate6: any;
  fDate7: any;
  tDate7: any;
  fDate8: any;
  tDate8: any;
  dateRange: any;
  dateRange1: any;
  dateRange2: any;
  dateRange3: any;
  dateRange4: any;
  dateRange5: any;
  dateRange6: any;
  dateRange7: any;
  dateRange8: any;
  dateRange9: any;
  datepickerValue: any;
  dragId: string = '';
  dropId: string = '';
  chartList: any[] = [
    {
      id: 1,
      chartName: 'Appointment stats',
      datePickerId: 'picker9',
      chartType: 'med-speed1',
      fullScreen: 'dateRange9',
      fdate: null,
      tdate: null,
    },
    {
      id: 2,
      chartName: 'Appointment stats',
      datePickerId: 'picker1',
      chartType: 'med-speed',
      fullScreen: 'dateRange1',
      fdate: null,
      tdate: null,
    },
    {
      id: 3,
      chartName: 'Day-wise appointent count',
      datePickerId: 'picker2',
      chartType: 'med-bar',
      fullScreen: 'dateRange2',
      fdate: null,
      tdate: null,
    },
    {
      id: 4,
      chartName: 'Scheduled appointment gender comparison',
      datePickerId: 'picker3',
      chartType: 'med-line',
      fullScreen: 'dateRange3',
      fdate: null,
      tdate: null,
    },
    {
      id: 5,
      chartName: 'Top Causes',
      datePickerId: 'picker4',
      chartType: 'med-multi',
      fullScreen: 'dateRange4',
      fdate: null,
      tdate: null,
    },
    {
      id: 6,
      chartName: 'Appointment status',
      datePickerId: 'picker5',
      chartType: 'med-pie',
      fullScreen: 'dateRange5',
      fdate: null,
      tdate: null,
    },
    {
      id: 7,
      chartName: 'Patient age category',
      datePickerId: 'picker6',
      chartType: 'med-vertical',
      fullScreen: 'dateRange6',
      fdate: null,
      tdate: null,
    },
    {
      id: 8,
      chartName: 'Payment Status',
      datePickerId: 'picker7',
      chartType: 'med-pie1',
      fullScreen: 'dateRange7',
      fdate: null,
      tdate: null,
    },
    {
      id: 9,
      chartName: 'Revenue Status',
      datePickerId: 'picker8',
      chartType: 'med-vertical1',
      fullScreen: 'dateRange8',
      fdate: null,
      tdate: null,
    },
  ];
  dropIndex = [
    { key: 0, value: 'med-speed1' },
    { key: 1, value: 'med-speed' },
    { key: 2, value: 'med-bar' },
    { key: 3, value: 'med-line' },
    { key: 4, value: 'med-multi' },
    { key: 5, value: 'med-pie' },
  ];
  range = new FormGroup({
    start: new FormControl(Date),
    end: new FormControl(Date),
  });
  showFullscreen: boolean = false;
  constructor(
    public adminService: AdminService,
    public appService: AppointmentService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private zone: NgZone,
    public dialogRef: MatDialogRef<DashboardPopupComponent>
  ) {}
  ngOnInit(): void {
    var d = new Date();
    let prevMonth = d.getMonth() + 1;
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    this.toDate = prevMonth + '-' + prevDay + '-' + prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev = dPrev.getMonth() + 1;
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    this.fromDate = monthPrev + '-' + dayPrev + '-' + yearPrev;
    // this.loadPreiviousAppointmentList();
    this.isAdmin = this.userData.userAccess.is_staff;
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 7));
    let start = this.datepipe.transform(priorDate, 'dd-MM-yyyy');
    let end = this.datepipe.transform(today, 'dd-MM-yyyy');
    console.log(end, start);
    this.chartList.forEach((ele) => {
      ele.fdate = priorDate;
      ele.tdate = today;
    });
    this.fDate = priorDate;
    this.tDate = today;
    this.fDate1 = priorDate;
    this.tDate1 = today;
    this.fDate2 = priorDate;
    this.tDate2 = today;
    this.fDate3 = priorDate;
    this.tDate3 = today;
    this.fDate4 = priorDate;
    this.tDate4 = today;
    this.fDate5 = priorDate;
    this.tDate5 = today;
    this.fDate6 = priorDate;
    this.tDate6 = today;
    this.fDate7 = priorDate;
    this.tDate7 = today;
    this.fDate8 = priorDate;
    this.tDate8 = today;
    // this.range.get('start').setValue(this.fDate)
    // this.range.get('end').setValue(this.tDate)
    console.log(priorDate, this.fDate, this.tDate);
    console.log(today);
    console.log(this.tDate);
    // this.fDate=new Date(this.fromDate)
    // this.tDate=new Date(this.toDate)
  }

  loadPreiviousAppointmentList() {
    debugger;
    let appParams = {
      appointment_start_date: this.fromDate,
      appointment_end_date: this.toDate,
    };
    this.appService.getAppointmentList(appParams).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          this.parentData = response;
        }
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  dateRangeChange(dateRangeStart: any, dateRangeEnd: any, id: any) {
    console.log(dateRangeStart, dateRangeEnd, id);
    this.fromDate = new Date(dateRangeStart);
    // this.fromDate=(fromdate.getMonth()+1)+"-"+fromdate.getDate()+"-"+fromdate.getFullYear();
    this.toDate = new Date(dateRangeEnd);
    console.log(this.fromDate, this.toDate);
    // this.toDate=(todate.getMonth()+1)+"-"+todate.getDate()+"-"+todate.getFullYear();
    if (this.fromDate !== 'NaN-NaN-NaN' && this.toDate !== 'NaN-NaN-NaN') {
      let data = {
        Start_date: this.datepipe.transform(dateRangeStart, 'MM-dd-yyyy'),
        End_date: this.datepipe.transform(dateRangeEnd, 'MM-dd-yyyy'),
      };
      console.log(data);
      if (!!dateRangeStart && !!dateRangeEnd) {
        debugger;
        // this.dateRange = data;
        if (id == 'dateRange1') {
          this.dateRange1 = data;
        }
        if (id == 'dateRange2') {
          this.dateRange2 = data;
        }
        if (id == 'dateRange3') {
          this.dateRange3 = data;
        }
        if (id == 'dateRange4') {
          this.dateRange4 = data;
        }
        if (id == 'dateRange5') {
          this.dateRange5 = data;
        }
        if (id == 'dateRange6') {
          this.dateRange6 = data;
        }
        if (id == 'dateRange7') {
          this.dateRange7 = data;
        }
        if (id == 'dateRange8') {
          this.dateRange8 = data;
        }
        if (id == 'dateRange9') {
          this.dateRange9 = data;
        }
      }
      dateRangeStart = '';
      dateRangeEnd = '';
    }
  }
  interchangeNodes(
    parent: HTMLElement,
    node1: HTMLElement,
    node2: HTMLElement
  ): void {
    // Ensure all nodes are child nodes of the parent
    if (!parent.contains(node1) || !parent.contains(node2)) {
      console.error('Nodes are not children of the given parent element.');
      return;
    }

    // Clone the nodes
    const clonedNode1 = node1.cloneNode(true) as HTMLElement;
    const clonedNode2 = node2.cloneNode(true) as HTMLElement;

    // Replace node1 with clonedNode2
    parent.replaceChild(clonedNode2, node1);

    // Replace node2 with clonedNode1
    parent.replaceChild(clonedNode1, node2);
  }
  onDragStarted(event: CdkDragStart, draggedChartId: string) {
    debugger;
    console.log('Drag started for chart with ID:', draggedChartId);
    this.dragId = draggedChartId;
  }
  // onDrop(e: any) {
  //   const parentElement = document.getElementById('parent');
  //   const node1 = document.getElementById('node1');
  //   const node2 = document.getElementById('node2');
  //   this.interchangeNodes(parentElement, node1, node2);
  // }
  onDrop(event: CdkDragDrop<string[]>) {
    const draggedIndex = event.previousIndex;
    const targetIndex = event.currentIndex;

    // Swap the positions of the two charts in the array
    const temp = this.chartList[draggedIndex];
    this.chartList[draggedIndex] = this.chartList[targetIndex];
    this.chartList[targetIndex] = temp;
  }
  openFullscreen(data) {
    debugger;
    console.log(data);
    this.datepickerValue = '';
    this.showFullscreen = true;
    if (!!this.dateRange1 && data == 'dateRange1') {
      this.datepickerValue = this.dateRange1;
    }
    if (!!this.dateRange2 && data == 'dateRange2') {
      this.datepickerValue = this.dateRange2;
    }
    if (!!this.dateRange3 && data == 'dateRange3') {
      this.datepickerValue = this.dateRange3;
    }
    if (!!this.dateRange4 && data == 'dateRange4') {
      this.datepickerValue = this.dateRange4;
    }
    if (!!this.dateRange5 && data == 'dateRange5') {
      this.datepickerValue = this.dateRange5;
    }
    if (!!this.dateRange6 && data == 'dateRange6') {
      this.datepickerValue = this.dateRange6;
    }
    if (!!this.dateRange7 && data == 'dateRange7') {
      this.datepickerValue = this.dateRange7;
    }
    if (!!this.dateRange8 && data == 'dateRange8') {
      this.datepickerValue = this.dateRange8;
    }
    if (!!this.dateRange9 && data == 'dateRange9') {
      this.datepickerValue = this.dateRange9;
    }
    let chartSize;
    if (data == 'dateRange9') {
      chartSize = '100vw';
    }
    const dialogRef = this.dialog.open(DashboardPopupComponent, {
      width: !!chartSize ? chartSize : '100%',
      height: '80vh',
      disableClose: true,
      panelClass: ['custom-overlay-class', 'custom-dialog-container'],
      data: {
        dateRange: data,
        datePick: !!this.datepickerValue ? this.datepickerValue : null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.prescriptionData = result.prescriptionData;
      }
    });
    return dialogRef.afterClosed();
  }
}
