import { Component, OnInit, Input, Optional, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
})
export class PieChartComponent implements OnInit {
  public appointmentData: any = [];
  public appData: any = [];
  public year: number;
  fromDate: any;
  toDate: any;
  fDate: any;
  tDate: any;
  initFromDate: any;
  initToDate: any;
  public userList: any;
  public initialData: any = [];
  public userData = JSON.parse(localStorage.getItem('userDetails'));
  public tableData: any = [];
  tenantId: any = this.userData.userAccess.tenant;
  patientAppmt: any = [];
  doctorAppmt: any = [];
  single: any[];
  view: any[] = [630, 400];
  maximize: boolean = false;
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#FFBF00', '#1DA372', '#2292FF', '#FF7000', '#D72323'],
    // domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA',,'#D72323']
  };
  @Input() parentData: any;
  public graph;
  @Input() dateRange: any;
  @Input() chartPopup: any;
  chartWidth: number = 1300;
  constructor(
    public adminService: AdminService,
    public appService: AppointmentService,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<PieChartComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    public datepipe: DatePipe
  ) {}
  ngOnInit(): void {
    var d = new Date();
    let prevMonth = d.getMonth() + 1;
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    let end = prevMonth + '-' + prevDay + '-' + prevYear;
    this.initToDate = prevMonth + '-' + prevDay + '-' + prevYear;
    var dPrev = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let monthPrev = dPrev.getMonth() + 1;
    let dayPrev = dPrev.getDate();
    let yearPrev = dPrev.getFullYear();
    let start = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.initFromDate = monthPrev + '-' + dayPrev + '-' + yearPrev;
    this.fromDate = this.datepipe.transform(start, 'MM-dd-yyyy');
    this.toDate = this.datepipe.transform(end, 'MM-dd-yyyy');

    if (!this.dateRange) {
      this.loadChart(null);
    }
    this.loadInitData(this.parentData);
    if (this.data?.openVia == 'fullScreen') {
      this.maximize = true;
      this.view = this.data.view;
      this.loadInitData(this.data.parentData);
    }
  }
  ngOnChanges() {
    // this.loadInitData(this.parentData);
    if (!!this.dateRange.Start_date && !!this.dateRange.End_date) {
      this.loadChart(this.dateRange);
    }
  }
  loadPreiviousAppointmentList() {
    let appParams = {
      appointment_start_date: this.fromDate,
      appointment_end_date: this.toDate,
    };
    this.fDate = new Date(this.fromDate);
    this.tDate = new Date(this.toDate);
    this.appService.getAppointmentList(appParams).subscribe(
      (response) => {
        if (response) {
          //console.log(response)
          let appData: any = response;
          if (appData.length > 0) {
            this.appData = appData;
            this.appData.map((x) => {
              var d = new Date(x.appointment_date);
              x.age =
                this.year - parseInt(x.patient.date_of_birth.split('-')[2]);
              // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
              // if(x.appointment_status=='UP'){
              //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
              // }
              // if(x.appointment_status=='CI'){
              //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
              // }
            });
            if (this.appData?.length > 0) {
              this.appData.map((x) => {
                this.patientAppmt.push({
                  ...x,
                  ...x.patient,
                  appmt_id: x.id,
                  complaint_name: x.questionnaire_info[0]?.complaint_id[0]
                    ?.complaint_name
                    ? x.questionnaire_info[0]?.complaint_id[0]?.complaint_name
                    : 'Not filled',
                });
              });
            }
            this.single = [
              { name: 'Upcoming', value: 20 },
              {
                name: 'Completed',
                value: this.appData.filter((x) => x.appointment_status == 'SE')
                  .length,
              },
              {
                name: 'Checked in',
                value: this.appData.filter((x) => x.appointment_status == 'CI')
                  .length,
              },
              {
                name: 'Missed',
                value: this.appData.filter((x) => x.appointment_status == 'MI')
                  .length,
              },
              { name: 'Cancelled', value: 10 },
              // {"name": "Upcoming","value": this.appData.filter(x=>x.appointment_status=='UP').length},
              // {"name": "Cancelled","value": this.appData.filter(x=>x.appointment_status=='CA').length}
            ];
            //console.log(this.single)
            //console.log(this.appData)
            this.initialData = JSON.stringify(this.tableData);
            // this.dataSource = new MatTableDataSource(this.appData);
            //console.log(this.appointmentData);
          } else {
            this.appointmentData = [];
          }
        }
      },
      (error) => {
        //console.log(error)
      }
    );
  }
  loadInitData(appData) {
    this.fDate = new Date(this.initFromDate);
    this.tDate = new Date(this.initToDate);
    if (appData?.length > 0) {
      this.appData = appData;
      this.appData.map((x) => {
        var d = new Date(x.appointment_date);
        x.age = this.year - parseInt(x.patient.date_of_birth.split('-')[2]);
        // x.appointment_time=parseInt(x.appointment_time.split(':')[0])>12?parseInt(x.appointment_time.split(':')[0])-12+':'+x.appointment_time.split(':')[1]+' PM':x.appointment_time+' AM';
        // if(x.appointment_status=='UP'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'UP';
        // }
        // if(x.appointment_status=='CI'){
        //   x.appointment_status=new Date(x.appointment_date)<new Date?'MI':'CI';
        // }
      });
      if (this.appData?.length > 0) {
        this.appData.map((x) => {
          this.patientAppmt.push({
            ...x,
            ...x.patient,
            appmt_id: x.id,
            complaint_name: x.questionnaire_info[0]?.complaint_id[0]
              ?.complaint_name
              ? x.questionnaire_info[0]?.complaint_id[0]?.complaint_name
              : 'Not filled',
          });
        });
      }
      this.single = [
        { name: 'Upcoming', value: 20 },
        {
          name: 'Completed',
          value: this.appData.filter((x) => x.appointment_status == 'SE')
            .length,
        },
        {
          name: 'Checked in',
          value: this.appData.filter((x) => x.appointment_status == 'CI')
            .length,
        },
        {
          name: 'Missed',
          value: this.appData.filter((x) => x.appointment_status == 'MI')
            .length,
        },
        { name: 'Cancelled', value: 10 },
        // {"name": "Upcoming","value": this.appData.filter(x=>x.appointment_status=='UP').length},
        // {"name": "Cancelled","value": this.appData.filter(x=>x.appointment_status=='CA').length}
      ];
      //console.log(this.single)
      //console.log(this.appData)
      this.initialData = JSON.stringify(this.tableData);
      // this.dataSource = new MatTableDataSource(this.appData);
      //console.log(this.appointmentData);
    } else {
      this.appointmentData = [];
    }
  }
  dateRangeChange(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    var fromdate = new Date(dateRangeStart.value);
    this.fromDate =
      fromdate.getMonth() +
      1 +
      '-' +
      fromdate.getDate() +
      '-' +
      fromdate.getFullYear();
    var todate = new Date(dateRangeEnd.value);
    this.toDate =
      todate.getMonth() +
      1 +
      '-' +
      todate.getDate() +
      '-' +
      todate.getFullYear();
    if (this.fromDate !== 'NaN-NaN-NaN' && this.toDate !== 'NaN-NaN-NaN')
      this.loadPreiviousAppointmentList();
  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  openFullscreen() {
    this.maximize = true;
    const dialogRef = this.dialog.open(PieChartComponent, {
      width: '900px',
      height: '800px',
      disableClose: true,
      data: {
        openVia: 'fullScreen',
        parentData: this.parentData,
        view: [800, 500],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
      this.view = [630, 400];
      this.maximize = false;
    });
    return dialogRef.afterClosed();
  }
  fullScreenExit() {
    this.view = [630, 400];
    this.maximize = false;
    // this.dialog.closeAll();
    this.dialogRef.close();
  }
  downloadChart() {
    document.getElementById('med-pie-header').style.display = 'none';
    html2canvas(document.getElementById('med-pie'), { useCORS: true }).then(
      (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        // var doc = new jsPDF('p', 'mm','a4');
        var doc = new jsPDF();
        var x = 1;
        var position = 0;
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.text('Medosys ' + x + ' / ' + doc.getNumberOfPages(), 150, 285);
          x++;
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save('medosys_pie_chart.pdf');
      }
    );
    document.getElementById('med-pie-header').style.display = 'block';
  }
  clearDate(event) {
    this.loadInitData(this.parentData);
  }
  loadChart(param) {
    if (!!param) {
      let appParams = {
        start_date: param.Start_date,
        end_date: param.End_date,
      };
      this.appService
        .getPieChartStatus(appParams)
        .subscribe((response: any) => {
          if (response) {
            console.log(response);
            let ch = (response.layout.height = 550);
            let cw = (response.layout.width = 773);
            if (response.data.length == 0) {
              response.layout.width = 773;
              response.layout.height = 550;
            }
            if (!!this.chartPopup) {
              let res = (response.layout.width = this.chartWidth);
              console.log(res);
            }
          }
          this.graph = response;
        });
    } else {
      let appParams = {
        start_date: this.fromDate,
        end_date: this.toDate,
      };

      this.fDate = new Date(this.fromDate);
      this.tDate = new Date(this.toDate);
      console.log(appParams);
      this.appService
        .getPieChartStatus(appParams)
        .subscribe((response: any) => {
          if (response) {
            console.log(response);
            let ch = (response.layout.height = 550);
            let cw = (response.layout.width = 773);
            if (response.data.length == 0) {
              response.layout.width = 773;
              response.layout.height = 550;
            }
            if (!!this.chartPopup) {
              let res = (response.layout.width = this.chartWidth);
              console.log(res);
            }
          }
          this.graph = response;
        });
    }
  }
}
