<div class="inline-container">
  <div class="closeIcon" (click)="closeAppmtList()">X</div>
  <div *ngIf="appList">
    <div>
      <label style="font-size: 1.2em; margin: 20px"
        ><b>All Appointments</b></label
      >
      <input
        class="search"
        type="search"
        placeholder="search by doctor name"
        [(ngModel)]="searchParam"
        (ngModelChange)="searchUser($event)"
      />
    </div>
    <div class="row" style="max-width: 100% !important">
      <div *ngFor="let appmt of appointmentList">
        <div class="card">
          <table style="width: 100%">
            <tr>
              <td style="width: 25%">
                <img
                  [src]="
                    appmt.patient.avator_url
                      ? appmt.patient.avator_url
                      : '../../../assets/images/avator.JPG'
                  "
                  alt="userName"
                  style="width: 60px; height: 50px; border-radius: 50%"
                />
              </td>
              <td
                *ngIf="appmt.patient.gender == 'M'"
                class="name"
                style="width: 55%"
              >
                <label style="font-weight: bold; font-size: 1.2em"
                  >Mr. {{ appmt.patient.first_name }}
                  {{ appmt.patient.last_name }}</label
                >
              </td>
              <td
                *ngIf="appmt.patient.gender == 'F'"
                class="name"
                style="width: 55%"
              >
                <label style="font-weight: bold; font-size: 1.2em"
                  >Mrs. {{ appmt.patient.first_name }}
                  {{ appmt.patient.last_name }}</label
                >
              </td>

              <td style="width: 20%">
                <mat-icon
                  style="color: black; top: 0px"
                  *ngIf="appmt.appointment_from == 'W'"
                  class="systemIcon ml-2"
                  title="Created from Web"
                  >computer</mat-icon
                >
                <mat-icon
                  style="color: black; top: 0px"
                  *ngIf="appmt.appointment_from == 'M'"
                  class="systemIcon ml-2"
                  title="Created from Mobile-App"
                  >mobile_screen_share</mat-icon
                >
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <div style="width: 100%; padding-top: 5px">
                  <div style="width: 50%; display: inline-block; float: left">
                    <mat-icon style="font-size: 1.5em">person_add</mat-icon>
                    <label
                      style="color: black; font-size: 0.9em; font-weight: 500"
                      >Dr. {{ appmt.doctor.first_name }}</label
                    >
                  </div>
                  <div
                    style="
                      width: 50%;
                      display: inline-block;
                      float: right;
                      text-align: right;
                    "
                  >
                    <span
                      ><mat-icon style="font-size: 1.2em">today</mat-icon></span
                    ><label
                      style="color: black; font-size: 0.9em; font-weight: 500"
                      >{{
                        appmt.appointment_date.split(" ")[0]
                          | date : "mediumDate"
                      }}
                      &nbsp; {{ appmt.appointment_time }}</label
                    >
                  </div>
                </div>
              </td>
              <!-- <td><mat-icon style="font-size:1.2em;">access_alarm</mat-icon><label  style="color:black;font-size:0.9em;font-weight: 500;margin-top:15px;"></label></td> -->
            </tr>
            <tr>
              <td colspan="2" style="padding-bottom: 10px">
                <button
                  style="float: left"
                  [ngStyle]="{
                    background:
                      appmt.payment_status !== 'SU' &&
                      appmt.payment_status !== 'IN'
                        ? 'rgb(248, 103, 103)'
                        : ''
                  }"
                  class="viewBtn"
                  (click)="selectPayment(appmt)"
                >
                  {{
                    appmt.payment_status == "IN"
                      ? "Payment: Paid"
                      : appmt.payment_status == "SU"
                      ? "Payment: Paid"
                      : "Payment: Unpaid"
                  }}
                </button>
              </td>
              <td
                *ngIf="appmt.appointment_status == 'SE'"
                style="padding-left: 40px; padding-bottom: 10px"
              >
                <button class="viewBtn" (click)="selectAppointment(appmt)">
                  View
                </button>
              </td>
              <td
                *ngIf="appmt.appointment_status != 'SE'"
                style="padding-left: 40px; padding-bottom: 10px"
              >
                <button class="viewBtn" (click)="selectAppointment(appmt)">
                  Action
                </button>
              </td>
            </tr>
            <tr style="border-top: 1px solid black; padding-top: 15px">
              <td *ngIf="appmt.appointment_status == 'UP'">
                <label
                  style="
                    color: #0984a8;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid #0984a8;
                    border-radius: 5px;
                  "
                  >Upcoming</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'CI'" class="checked-in">
                <label
                  style="
                    border: 2px solid rgb(10, 10, 94);
                    color: rgb(10, 10, 94);
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border-radius: 5px;
                  "
                  >Checked-In</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'SE'">
                <label
                  style="
                    color: green;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid green;
                    border-radius: 5px;
                  "
                  >Completed</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'CA'">
                <label
                  style="
                    color: red;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid red;
                    border-radius: 5px;
                  "
                  >Cancel</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == 'MI'">
                <label
                  style="
                    color: red;
                    font-size: 0.9em;
                    font-weight: 500;
                    padding: 4px;
                    margin-top: 25px;
                    border: 2px solid red;
                    border-radius: 5px;
                  "
                  >Missed</label
                >
              </td>
              <td *ngIf="appmt.appointment_status == null"></td>
              <td colspan="2">
                <b>Payment Status :</b>
                {{
                  appmt.payment_status == "IN"
                    ? "Paid"
                    : appmt.payment_status == "SU"
                    ? "Paid"
                    : "Unpaid"
                }}, <b>Appointment Status :</b>
                {{
                  appmt.appointment_status == "UP"
                    ? "Appointment is booked"
                    : appmt.appointment_status == "CI"
                    ? "Questionnaire is completed / skipped, Appointment is confirmed"
                    : appmt.appointment_status == "SE"
                    ? "Doctor consultation is completed, and notes are saved"
                    : appmt.appointment_status == "CA"
                    ? "Cancelled"
                    : appmt.appointment_status == "MI"
                    ? "Patient missed the appointment / appointment not yet completed"
                    : "Not Started"
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <div *ngFor="let appmt of appointmentList" class="col-md-4">
            <div class="card">
            <table>
                <tr>
                    <td><img src="../../../assets/images/avator.JPG" alt="userName" style="width:60px;height:50px;border-radius:50%;"></td>
                    <td><label style="font-weight: bold;font-size:1.2em;padding-top:10px;">Dr. {{appmt.doctor.first_name}}</label></td>
                </tr>
                <tr>
                    <td><mat-icon style="font-size:1.2em;margin-top:20px;padding-left: 10px;">today</mat-icon><label  style="color:black;font-size:0.9em;font-weight: 500;padding:10px">{{appmt.appointment_date.split(' ')[0]}}</label></td>
                    <td><mat-icon style="font-size:1.2em;margin-top:10px;padding-left: 20px;">access_alarm</mat-icon><label  style="color:black;font-size:0.9em;font-weight: 500;float:right;padding:10px">{{appmt.appointment_time}}</label></td>
                </tr>
                <tr>
                    <td *ngIf="appmt.appointment_status=='UP'"><label style="color:#0984A8;font-size:0.9em;font-weight: 500;padding:10px">Upcoming</label></td>
                    <td *ngIf="appmt.appointment_status=='CI'"><label style="color:rgb(14, 14, 92);font-size:0.9em;font-weight: 500;padding:10px">Checked-In</label></td>
                    <td *ngIf="appmt.appointment_status=='SE'"><label style="color:green;font-size:0.9em;font-weight: 500;padding:10px">Seen</label></td>
                    <td *ngIf="appmt.appointment_status=='CA'"><label style="color:red;font-size:0.9em;font-weight: 500;padding:10px">Cancel</label></td>
                    <td><button class="viewBtn" (click)="viewAppointment(appmt)">View Details</button></td></tr>
            </table> 
            </div> 
        </div> -->
      <div
        *ngIf="appointmentList.length == 0"
        style="
          text-align: center;
          padding-inline: 20%;
          padding-block: 10%;
          color: red;
          font-size: 1.2em;
          font-weight: 500;
        "
      >
        No appointments available for this patient
      </div>
    </div>
  </div>
  <div class="patientDetails" *ngIf="!appList">
    <div class="content headerCont">
      <h1><strong>Edit Patient Details</strong></h1>
    </div>
    <br />
    <!-- <mat-label class="example-container"><span class="material-icons">
      open_in_browser
      </span>NRIC / FIN / PASSPORT</mat-label>
      <br>
      <mat-form-field class="example-container" appearance="outline" [style.width.px]=150>
        
        <mat-select [(ngModel)]="MRNData.idType">
          <mat-option *ngFor="let id of IDType" [value]="id.value">
            {{id.viewValue}}
          </mat-option>
        
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-container"  appearance="outline" [style.width.px]=272>
        <input matInput [placeholder]="MRNData.idType=='1'?'Enter medical record number from NRIC/FIN':'Enter the passport number'" 
        [(ngModel)]="MRNData.mrn" (change)="mrnValidation()" style="text-transform: uppercase;">
      </mat-form-field>
      <br>
     <label *ngIf="validMRN=='true' && MRNData.mrn !==null" class="error">* Please Enter valid {{MRNData.idType=='1'?'NRIC':'Passport'}} number</label>
      <br>
    
    <mat-label class="example-container"><span class="material-icons">
      person
      </span>Name</mat-label>
    <br>
    <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
      <input matInput placeholder="first" [(ngModel)]="MRNData.first_name">
    </mat-form-field>
    &nbsp;
    <mat-form-field  class="example-container"appearance="outline" [style.width.px]=133>
      <input matInput placeholder="Middle" [(ngModel)]="MRNData.middle_name">
    </mat-form-field>
    &nbsp;
    <mat-form-field  class="example-container"appearance="outline" [style.width.px]=133>
      <input matInput placeholder="Last" [(ngModel)]="MRNData.last_name">
    </mat-form-field>
    
    <br>
    
      <mat-label class="example-container"><span class="material-icons">
        calendar_today
        </span>DOB</mat-label>
        <mat-label class="example-container" style="margin-left: 20%;"><span class="material-icons">
            female
        </span>Gender</mat-label>
      <br>
      <mat-form-field appearance="outline">
        <input  matInput #ref [matDatepicker]="picker" [value]="dob" (dateChange)="dateFormat(ref.value)">
        <mat-datepicker-toggle matSuffix [for]="picker" style="color:#0984A8;font-size:1em"></mat-datepicker-toggle>
        <mat-datepicker #ref #picker></mat-datepicker>
      </mat-form-field>
      &nbsp;&nbsp;&nbsp;
      <mat-form-field class="example-container" appearance="outline" [style.width.px]=200>
        
        <mat-select [(ngModel)]="MRNData.gender">
          <mat-option *ngFor="let gender of genders" [value]="gender.value">
            {{gender.viewValue}}
          </mat-option>
        
        </mat-select>
       
    </mat-form-field>
    <br>
    
      <mat-label class="lbl2"><span class="material-icons">
        phone
        </span>Phone</mat-label>
        <mat-label class="example-container" style="margin-left: 20%;"><span class="material-icons">
            mail
            </span>Email</mat-label>
    <br>
    <mat-form-field class="example-full-width" [style.width.px]=80 appearance="outline">
      <input type="text"
             placeholder="Country"
             aria-label="Number"
             matInput
             [formControl]="myControl"
             [(ngModel)]="MRNData.phone_number_prefix"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getPosts($event.option.value)'>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.dial_code">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>&nbsp;
  <mat-form-field class="example-container"  appearance="outline" [style.width.px]=120>
    <span></span>
    <span><input matInput placeholder="Phone Number" [(ngModel)]="MRNData.phone_number" ></span>
  </mat-form-field>&nbsp;&nbsp;&nbsp;
    <mat-form-field class="example-container" appearance="outline" [style.width.px]=200>
        <input matInput placeholder="Enter your email id" type="email" [(ngModel)]="MRNData.email">
      </mat-form-field>
      <div class="additionalFields">
        <table>
          <tr>
            <td>
              <label>Marital Status</label>
              <br>
              <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
              <mat-select [(ngModel)]="MRNData.marital_status">
                <mat-option *ngFor="let mStatus of mStatus" [value]="mStatus.value">
                  {{mStatus.viewValue}}
                </mat-option>
              </mat-select>
              </mat-form-field>
            </td>
            <td>
              <label style="padding-left:15px">Children</label>
              <br>
              <div class="radioButtonPad">
              <mat-radio-group  aria-label="Select an option" [(ngModel)]="MRNData.children">
                <mat-radio-button value="Y">Yes</mat-radio-button>
                <mat-radio-button style="padding-left:15px" value="N">No</mat-radio-button>
              </mat-radio-group>
            </div>
            </td>
            <td>
              <label>Blood Group</label>
              <br>
              <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
              <mat-select [(ngModel)]="MRNData.blood_group">
                <mat-option *ngFor="let bGroup of bGroup" [value]="bGroup.value">
                  {{bGroup.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <label>Weight (kg)</label>
              <br>
              <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
                <input matInput placeholder="Weight" [(ngModel)]="MRNData.weight">
              </mat-form-field>
            </td>
            <td>
              <label style="padding-left:15px">Height (m)</label>
              <br>
              <mat-form-field class="example-container" style="padding-left:15px" appearance="outline" [style.width.px]=133>
                <input matInput placeholder="Height" [(ngModel)]="MRNData.height">
              </mat-form-field>
            </td>
            <td>
              <label>BMI (kg/m2)</label>
              <br>
              <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
                <input matInput placeholder="BMI" [(ngModel)]="MRNData.bmi">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <label>BF Percentage %</label>
              <br>
              <mat-form-field class="example-container" appearance="outline" [style.width.px]=133>
                <input matInput placeholder="BF %" [(ngModel)]="MRNData.bf_percentage">
              </mat-form-field>
            </td>
            <td>
              <label style="padding-left:15px">Smoker</label>
              <br>
              <div class="radioButtonPad">
              <mat-radio-group aria-label="Select an option"  [(ngModel)]="MRNData.smoker">
                <mat-radio-button value="Y">Yes</mat-radio-button>
                <mat-radio-button style="padding-left:15px" value="N">No</mat-radio-button>
              </mat-radio-group>
            </div>
            </td>
            <td>
              <label style="padding-left:15px">Allergies</label>
              <br>
              <div class="radioButtonPad">
              <mat-radio-group aria-label="Select an option"  [(ngModel)]="MRNData.allergies">
                <mat-radio-button value="1">Yes</mat-radio-button>
                <mat-radio-button style="padding-left:15px" value="2">No</mat-radio-button>
              </mat-radio-group>
            </div>
            </td>
          </tr>
          <tr>
        </table>
      </div> -->

    <table>
      <tr>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> person </span>First Name</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput [(ngModel)]="MRNData.first_name" />
          </mat-form-field>
        </td>

        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> person </span>Second Name</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput [(ngModel)]="MRNData.middle_name" />
          </mat-form-field>
        </td>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> person </span>Last Name</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput [(ngModel)]="MRNData.last_name" />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> open_in_browser </span>NRIC / FIN /
            PASSPORT</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <mat-select [(ngModel)]="MRNData.idType">
              <mat-option *ngFor="let id of IDType" [value]="id.value">
                {{ id.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <br />
          <mat-form-field
            *ngIf="edited || headerText !== 'Patient Not Found'"
            class="example-container"
            appearance="outline"
          >
            <span *ngIf="!!MRNData.mrn && MRNData.mrn !== 'string'">
              <input
                matInput
                placeholder="Enter medical record No"
                (change)="mrnValidation($event)"
                [(ngModel)]="MRNData.mrn"
                style="text-transform: uppercase"
            /></span>
            <span
              *ngIf="
                !!MRNData.passport_number &&
                MRNData.passport_number !== 'string'
              "
            >
              <input
                matInput
                placeholder="Enter medical record No"
                (change)="mrnValidation($event)"
                [(ngModel)]="MRNData.passport_number"
                style="text-transform: uppercase"
            /></span> </mat-form-field
          ><br />
          <label *ngIf="validMRN == true && MRNData.mrn" class="error"
            >* Please Enter valid
            {{ MRNData.idType == "1" ? "NRIC" : "Passport" }} number</label
          >
        </td>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> calendar_today </span>DOB</mat-label
          ><br />
          <mat-form-field appearance="outline" [style.width.px]="200">
            <input
              matInput
              #ref
              [matDatepicker]="picker"
              [value]="dob"
              (dateChange)="dateFormat(ref.value)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              style="color: #0984a8; font-size: 1em"
            ></mat-datepicker-toggle>
            <mat-datepicker #ref #picker></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> female </span>Gender</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <mat-select [(ngModel)]="MRNData.gender">
              <mat-option *ngFor="let gender of genders" [value]="gender.value">
                {{ gender.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-label class="lbl2"
            ><span class="material-icons"> phone </span>Phone</mat-label
          ><br />
          <mat-form-field
            class="example-full-width"
            appearance="outline"
            [(ngModel)]="MRNData.phone_number"
            [style.width.px]="80"
          >
            <input
              type="text"
              placeholder="Country"
              aria-label="Number"
              matInput
              [(ngModel)]="MRNData.phone_number_prefix"
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="getPosts($event.option.value)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.dial_code"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          &nbsp;
          <mat-form-field
            class="example-container"
            appearance="outline"
            [style.width.px]="133"
          >
            <span
              ><input
                matInput
                placeholder="Phone Number"
                [(ngModel)]="MRNData.phone_number"
            /></span>
          </mat-form-field>
        </td>
        <td>
          <mat-label class="example-container"
            ><span class="material-icons"> mail </span>Email</mat-label
          >
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input
              matInput
              placeholder="Enter your email id"
              type="email"
              [(ngModel)]="MRNData.email"
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <label>Marital Status</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <mat-select [(ngModel)]="MRNData.marital_status">
              <mat-option
                *ngFor="let mStatus of mStatus"
                [value]="mStatus.value"
              >
                {{ mStatus.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <label style="margin-left: 15px">Children</label>
          <br />
          <div class="radioButtonPad">
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="MRNData.children"
            >
              <mat-radio-button value="Y">Yes</mat-radio-button>
              <mat-radio-button style="padding-left: 15px" value="N"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </td>
        <td>
          <label>Blood Group</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <mat-select [(ngModel)]="MRNData.blood_group">
              <mat-option *ngFor="let bGroup of bGroup" [value]="bGroup.value">
                {{ bGroup.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <label>Weight (kg)</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput placeholder="Weight" [(ngModel)]="MRNData.weight" />
          </mat-form-field>
        </td>
        <td>
          <label>Height (m)</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput placeholder="Height" [(ngModel)]="MRNData.height" />
          </mat-form-field>
        </td>
        <td>
          <label>BMI (kg/m2)</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input matInput placeholder="BMI" [(ngModel)]="MRNData.bmi" />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <label>BF Percentage %</label>
          <br />
          <mat-form-field class="example-container" appearance="outline">
            <input
              matInput
              placeholder="BF %"
              [(ngModel)]="MRNData.bf_percentage"
            />
          </mat-form-field>
        </td>
        <td>
          <label style="margin-left: 15px">Smoker</label>
          <br />
          <div class="radioButtonPad">
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="MRNData.smoker"
            >
              <mat-radio-button value="Y">Yes</mat-radio-button>
              <mat-radio-button style="padding-left: 15px" value="N"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </td>
        <td>
          <label style="margin-left: 15px">Allergies</label>
          <br />
          <div class="radioButtonPad">
            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="MRNData.allergies"
            >
              <mat-radio-button value="Y">Yes</mat-radio-button>
              <mat-radio-button style="padding-left: 15px" value="N"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <mat-checkbox
            class="example-margin"
            [(ngModel)]="MRNData.is_active"
            [checked]="MRNData.is_active"
            >Active
          </mat-checkbox>
        </td>
      </tr>
      <tr></tr>
    </table>

    <br />
    <div class="content">
      <button
        class="btn mt-4"
        style="float: left; margin-left: 33%"
        (click)="updateMRN()"
      >
        Update
      </button>
    </div>
  </div>
</div>
