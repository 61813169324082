<div class="container-fluid" style="height:65vh">
    <!-- <div class="row p-2">
      <button mat-raised-button color="primary" class="m-2" [disabled]="isCallStarted$ | async" (click)="showModal(false)">Start Call</button>
      <button mat-raised-button class="m-2" [disabled]="isCallStarted$ | async" (click)="showModal(true)">Join Call</button>
      <button mat-raised-button color="warn" class="m-2" [disabled]="!(isCallStarted$ | async)" (click)="endCall()">End Call</button>
    </div>
    <div class="row">
      <div class="col text-center">
        <div id="video-wrapper">
          <video #remoteVideo id="remote-video" poster="./assets/images/video-placeholder.jpg" autoplay playsinline controls></video>
          <video #localVideo id="local-video" autoplay playsinline [muted]=true controls></video>
          
        </div>
      </div>
    </div> -->
    <dyte-meeting show-setup-screen="true" mode='fill' #myid></dyte-meeting>
  </div>