import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NotesComponent } from './components/notes/notes.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { LeftPanelComponent } from './components/left-panel/left-panel.component';
import { UserAppointmentComponent } from './components/user-appointment/user-appointment.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClarityModule } from '@clr/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular-legacy';
import { DyteComponentsModule } from '@dytesdk/angular-ui-kit';
//import {TranscribeStreamingClient,StartMedicalStreamTranscriptionCommand,Specialty, Type} from "@aws-sdk/client-transcribe-streaming";

import { UserListComponent } from './components/user-list/user-list.component';
import { ConfirmationDialogComponent } from './shared_components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './shared_components/alert-dialog/alert-dialog.component';
import { CommonHeaderComponent } from './shared_components/common-header/common-header.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { AddTenantComponent } from './components/add-tenant/add-tenant.component';
import { CommonModalDialogService } from './service/common-modal-dialog.service';
import { MatNativeDateModule } from '@angular/material/core';
import { AppointmentLandingComponent } from './components/appointment-landing/appointment-landing.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { VideoComponent } from './components/video/video.component';

import { CallInfoDialogComponents } from './components/video/dialog/callinfo-dialog.component';
import { CallInfoDialogComponent } from './components/mobile-video/dialog/callinfo-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AppHttpInterceptor } from './http.interceptor';
import { PatientRecordsComponent } from './components/patient-records/patient-records.component';
import { PatientAppointmentListComponent } from './components/patient-appointment-list/patient-appointment-list.component';
import { AppointmentDetailComponent } from './components/appointment-detail/appointment-detail.component';
import { FullScreenComponent } from './shared_components/full-screen/full-screen.component';
import { MobileVideoComponent } from './components/mobile-video/mobile-video.component';
import { ReportComponent } from './components/report/report.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BarChartComponent } from './components/dashboard/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/dashboard/pie-chart/pie-chart.component';
import { MultiChartComponent } from './components/dashboard/multi-chart/multi-chart.component';
import { LineChartComponent } from './components/dashboard/line-chart/line-chart.component';
import { SpeedChartComponent } from './components/dashboard/speed-chart/speed-chart.component';
import { MultiVerticalComponent } from './components/dashboard/multi-vertical/multi-vertical.component';
import { DoctorManagementComponent } from './components/doctor-management/doctor-management.component';
import { SetFeesComponent } from './shared_components/set-fees/set-fees.component';
import { RefundRequestsComponent } from './components/refund-requests/refund-requests.component';
import { AppointmentPaymentComponent } from './shared_components/appointment-payment/appointment-payment.component';
import { RefundResponseComponent } from './shared_components/refund-response/refund-response.component';
import { NricMaskPipe } from './pipes/nric-mask.pipe';
import { PaymentChartComponent } from './components/dashboard/payment-chart/payment-chart.component';
import { RevenueChartComponent } from './components/dashboard/revenue-chart/revenue-chart.component';
import { DoctorDetailComponent } from './components/doctor-detail/doctor-detail.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import { DatePipe } from '@angular/common';
import { Time24to12Format } from './pipes/time24to12.pipe';
import { TimeslotComponent } from './components/timeslot/timeslot.component';
import { GaugeChartComponent } from './components/dashboard/gauge-chart/gauge-chart.component';
import { PlotlyModule } from 'angular-plotly.js';
import { SetGstComponent } from './shared_components/set-gst/set-gst.component';
import { DashboardPopupComponent } from './shared_components/dashboard-popup/dashboard-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppointmentComponent,
    ForgetPasswordComponent,
    AdminPageComponent,
    LeftPanelComponent,
    UserAppointmentComponent,
    UserListComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    CommonHeaderComponent,
    AddUserComponent,
    AddTenantComponent,
    AppointmentLandingComponent,
    NotesComponent,
    QuestionnaireComponent,
    NotesListComponent,
    ManageUserComponent,
    VideoComponent,
    CallInfoDialogComponents,
    CallInfoDialogComponent,
    PatientRecordsComponent,
    PatientAppointmentListComponent,
    AppointmentDetailComponent,
    FullScreenComponent,
    MobileVideoComponent,
    ReportComponent,
    DashboardComponent,
    BarChartComponent,
    PieChartComponent,
    MultiChartComponent,
    LineChartComponent,
    SpeedChartComponent,
    MultiVerticalComponent,
    DoctorManagementComponent,
    SetFeesComponent,
    RefundRequestsComponent,
    AppointmentPaymentComponent,
    // SetGstComponent,
    RefundResponseComponent,
    NricMaskPipe,
    PaymentChartComponent,
    RevenueChartComponent,
    DoctorDetailComponent,
    Time24to12Format,
    TimeslotComponent,
    GaugeChartComponent,
    SetGstComponent,
    DashboardPopupComponent,
    // TranscribeStreamingClient,
    // StartMedicalStreamTranscriptionCommand
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatIconModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatStepperModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ClipboardModule,
    MatSlideToggleModule,
    ClarityModule,
    NgxChartsModule,
    ChartsModule,
    DyteComponentsModule,
    PlotlyModule,
    DragDropModule,
    MatDialogModule,
    AgGridModule.withComponents([ReportComponent]),
  ],
  exports: [MatFormFieldModule, MatInputModule, MatSortModule, RouterModule],
  entryComponents: [
    AppointmentDetailComponent,
    ManageUserComponent,
    AdminPageComponent,
    AddUserComponent,
    AddTenantComponent,
    ConfirmationDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    CommonModalDialogService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
