<div class="container-fluid">
  <app-common-header></app-common-header>
  <div class="row notes">
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 leftDiv">
      <div style="float: right; padding: 20px; font-weight: 500">
        <span style="display: inline-block; padding: 10px; padding-top: 0px"
          >Audio</span
        >
        <mat-slide-toggle
          color="accent"
          [(checked)]="videoEnable"
          (change)="onSwitchChange($event)"
          >Video</mat-slide-toggle
        >
      </div>
      <div *ngIf="videoEnable" class="videoSection">
        <!-- <app-video [selectedAppointment]="selectedAppointment" (blobPass)="blopReceived($event)"></app-video> -->
        <app-video
          [selectedAppointment]="selectedAppointment"
          (transcriptPass)="transcriptReceive($event)"
          (dyteClose)="pageRefresh($event)"
        ></app-video>
      </div>
      <div *ngIf="loading">
        <mat-spinner
          diameter="80"
          style="
            color: grey;
            opacity: 0.5;
            position: fixed;
            top: 50%;
            left: 50%;
          "
        ></mat-spinner>
      </div>
      <div
        *ngIf="!videoEnable"
        class="recordingSection"
        style="border-radius: 20px"
      >
        <div>
          <dyte-grid #mic class="dyte-el"></dyte-grid>
          <div class="controlbar">
            <!-- Your own components -->
            <!-- <button #mic (click)="onMicToggle()">Toggle Mic</button>        -->
            <button
              class="record_btn"
              type="button"
              #mic
              (click)="onMicToggle()"
            >
              <mat-icon>play_circle_filled</mat-icon>
              <span
                style="vertical-align: top; padding: 4px; display: inline-block"
                >Record</span
              >
            </button>
            <button
              class="Stop_btn"
              [ngClass]="{ disableCursor: enableStop == true }"
              type="button"
              (click)="onMicStop()"
            >
              <mat-icon>stop</mat-icon>
              <span
                style="vertical-align: top; padding: 4px; display: inline-block"
                >Stop</span
              >
            </button>
          </div>
        </div>
        <!-- <div *ngIf="(voiceActiveSectionDisabled && !audioAvail); else voicesection;">
                    <button class="record_btn" type="button" (click)="startVoiceRecognition()">
                      <mat-icon>play_circle_filled</mat-icon> <span style="vertical-align:top;padding:4px;display: inline-block;">Record</span></button>
                    <button class="Stop_btn opacity" type="button">
                        <mat-icon>stop</mat-icon> <span style="vertical-align:top;padding:4px;display: inline-block;">Stop</span></button>
                    
                    </div>
                  <ng-template #voicesection>
                    <ng-container *ngIf="!voiceActiveSectionError; else failure">
                      <ng-container *ngIf="voiceText">
                          <span>{{voiceText}}</span>
                      </ng-container>
                      
                    </ng-container>
                    <ng-template #failure>
                      
                    </ng-template>
                    <div>
                      <button class="record_btn opacity" type="button">
                        <mat-icon>play_circle_filled</mat-icon> <span style="vertical-align:top;padding:4px;display: inline-block;">Record</span></button>
                      
                      <button class="Stop_btn" *ngIf="!audioAvail" type="button" (click)="closeVoiceRecognition()">
                        <mat-icon>stop</mat-icon> <span style="vertical-align:top;padding:4px;display: inline-block;">Stop</span></button>
                    </div>
                  </ng-template>
                      <div class="boxContainer" *ngIf="!voiceText && voiceActiveSectionListening">
                        <div class="box box1"></div>
                        <div class="box box2"></div>
                        <div class="box box3"></div>
                        <div class="box box4"></div>
                        <div class="box box5"></div>
                      </div>
                      <div style="margin-top:2vh;margin-left:10vw;">
                        <audio controls controlsList="nodownload" *ngIf="url && !audioShow && !audioLoader">
                          <source [src]="sanitize(url)" type="audio/wav">
                          </audio>
                          <audio controls controlsList="nodownload" *ngIf="audioShow && !audioLoader">
                            <source [src]="audioURL" type="audio/wav">
                            </audio>
                            <mat-spinner *ngIf="audioLoader" diameter="80" style="color:grey;opacity:0.5;top:35%;left:40%"></mat-spinner>
                      </div> -->
      </div>
      <div class="transcriptSection" style="border-radius: 20px">
        <div class="transTop" style="border-radius: 20px">
          <div class="transHeader">Transcription</div>
          <section class="example-section" *ngIf="!scriptLoader"></section>
          <section class="example-section" *ngIf="scriptLoader">
            <mat-progress-bar
              class="example-margin"
              color="primary"
              mode="indeterminate"
              [value]="scriptLoaderValue"
            >
            </mat-progress-bar>
            <div
              style="
                position: absolute;
                padding: 10px;
                color: white;
                font-weight: 500;
              "
            >
              Medical Transcription is Loading &nbsp;&nbsp;
              {{ scriptLoaderValue }}%
            </div>
          </section>
          <div class="search"><input type="text" placeholder="search" /></div>
        </div>
        <div class="transBottom">
          <mat-spinner
            *ngIf="transcriptLoader"
            diameter="80"
            style="color: grey; opacity: 0.5; top: 35%; left: 40%"
          ></mat-spinner>
          <!-- <div *ngIf="scriptLoader" style="margin:10%;margin-top:15%;">
            <div style="color:green;font-size:1.2em;text-align:center;font-weight:500;">
              Medical Transcription is Loading
            </div>
          <section class="example-section">
            <mat-progress-bar
                class="example-margin"
                color="primary"
                mode=indeterminate
                [value]="scriptLoaderValue">
            </mat-progress-bar>
                  <div style="position: absolute; padding: 10px; color: white;left:40%;font-weight:500;font-size:1.8em">{{scriptLoaderValue}}% </div>
          </section>
        </div> -->
          <div *ngFor="let convo of voiceData" style="margin: 20px">
            <div *ngIf="convo.spk_label == 'spk_0'">
              <div
                style="
                  border-radius: 20px 20px 20px 0px;
                  background: #e1f4fd;
                  max-width: 50%;
                  padding: 20px;
                "
              >
                <p
                  style="
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #2f2f2f;
                  "
                >
                  {{ convo.voiceNote }}
                </p>
              </div>
            </div>
            <div
              *ngIf="convo.spk_label == 'spk_1'"
              style="justify-content: flex-end; display: flex"
            >
              <div
                style="
                  border-radius: 20px 20px 0px 20px;
                  background: #ace1fb;
                  max-width: 50%;
                  padding: 20px;
                "
              >
                <p
                  style="
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #2f2f2f;
                  "
                >
                  {{ convo.voiceNote }}
                </p>
              </div>
            </div>
          </div>
          <div *ngFor="let voice of transcriptArray; let i = index">
            <div *ngIf="voice.peerId === peerId" class="speaker-container">
              <div style="width: 20vw; float: left">
                <div class="speaker1">{{ voice.text }}</div>
                <div>
                  <span
                    style="font-size: 0.8em; color: grey; margin-left: 10px"
                    >{{ voice.startTimeISO }}</span
                  ><span
                    ><img
                      src="../../../assets/images/avator.JPG"
                      alt="userName"
                      style="
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        float: right;
                      "
                  /></span>
                </div>
              </div>
            </div>
            <div *ngIf="voice.peerId !== peerId" class="speaker-container">
              <div style="width: 20vw; float: right">
                <div class="speaker2">{{ voice.text }}</div>
                <div>
                  <span
                    ><img
                      src="../../../assets/images/avator.JPG"
                      alt="userName"
                      style="width: 20px; height: 20px; border-radius: 50%"
                  /></span>
                  <span
                    style="
                      font-size: 0.8em;
                      color: grey;
                      float: right;
                      margin-right: 10px;
                    "
                    >{{ voice.startTimeISO }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 rightDiv"
      id="notesContainer"
    >
      <div class="appontmentDetails">
        <div class="detailHeader" id="detailHeader" style="border-radius: 20px">
          <div
            style="
              width: 10%;
              float: left;
              padding-top: 8px;
              padding-left: 10px;
            "
          >
            <img
              [src]="
                selectedAppointment?.patient.avator_url
                  ? selectedAppointment?.patient.avator_url
                  : '../../../assets/images/avator.JPG'
              "
              alt="userName"
              style="width: 80px; height: 73px; border-radius: 50%"
            />
          </div>
          <div class="detailList text-left">
            <li style="font-weight: 500; font-size: 1.3em">
              {{
                selectedAppointment?.patient
                  ? selectedAppointment?.patient.first_name
                  : ""
              }}
              {{
                selectedAppointment?.patient
                  ? selectedAppointment?.patient.last_name
                  : ""
              }}
            </li>
            <li style="padding-top: 3px; font-size: 0.9em; font-weight: 400">
              NRIC :
              {{
                selectedAppointment?.patient
                  ? (selectedAppointment?.patient.mrn | nricMask)
                  : ""
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DOB :
              {{
                selectedAppointment?.patient
                  ? selectedAppointment?.patient.date_of_birth
                  : ""
              }}
            </li>
            <li style="padding-top: 3px; font-size: 0.9em; font-weight: 400">
              GENDER :
              {{
                selectedAppointment?.patient
                  ? selectedAppointment?.patient.gender == "M"
                    ? "Male"
                    : "Female"
                  : ""
              }}
              &nbsp;&nbsp;&nbsp;&nbsp;PHONE:
              {{ selectedAppointment?.patient?.phone_number }}
            </li>
          </div>
          <div class="viewFull">
            <!-- <div style="padding-top:15px;font-size:0.9em;cursor:pointer">VIEW FULL PROFILE</div> -->
          </div>
        </div>
        <div
          class="detailContent mt-3 rec"
          id="detailContent"
          style="border-radius: 20px"
        >
          <mat-tab-group
            class="ml-3"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            (selectedTabChange)="onTabChanged($event)"
          >
            <!-- <mat-tab label="HPI & ROS">
                  <ul class="tab1Content">
                    <li><span class="HPI">ENT</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Lungs</span><span class="ROS">Positive for shortness of breath and wheezing</span></li>
                    <li><span class="HPI">Endocrine</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Hematologic</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Digestive</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Skin</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Genitourinary</span><span class="ROS">Positive for disuria</span></li>
                    <li><span class="HPI">Cardiac</span><span class="ROS">Positive for chest pain</span></li>
                    <li><span class="HPI">Neurologic</span><span class="ROS">Negative</span></li>
                    <li><span class="HPI">Eyes</span><span class="ROS">Negative</span></li>
                  </ul>
                </mat-tab> -->
            <mat-tab label="Medical Terms" style="margin-left: 0px !important">
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  height: 500px;
                  width: 100%;
                "
              >
                <div
                  class="col-sm-4 px-0 mb-3"
                  *ngFor="let mcdp of mcdpData; let mcdpKey = index"
                >
                  <div class="card-mcdp-category h-100">
                    <div style="display: flex; justify-content: space-between">
                      <h3 style="font-weight: 700; margin-bottom: 12px">
                        {{ mcdp.mcdp_category }}
                      </h3>
                    </div>
                    <div
                      style="display: flex; flex-wrap: wrap"
                      *ngFor="let item of mcdp | keyvalue; let cat"
                    >
                      <div
                        *ngIf="item.value != mcdp.mcdp_category"
                        class="chipAlign"
                      >
                        <div
                          *ngIf="item.value.length == 0 && !!isEditable"
                          class="no-record-label"
                        >
                          No Record
                        </div>
                        <mat-form-field>
                          <mat-chip-list #chipList>
                            <mat-chip
                              *ngFor="
                                let diseaseType of item.value;
                                let disKey = index
                              "
                              (removed)="
                                removeChip(diseaseType, item.key, mcdpKey)
                              "
                            >
                              {{ diseaseType }}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                          </mat-chip-list>
                        </mat-form-field>
                        <input
                          *ngIf="!isEditable"
                          matInput
                          placeholder="enter here"
                          [matChipInputFor]="chipList"
                          [disabled]="isEditable"
                          (matChipInputTokenEnd)="
                            addChip($event, item.key, mcdpKey)
                          "
                          style="
                            width: 95%;
                            border-bottom: 1px solid #e9e9e9;
                            padding: 5px;
                          "
                        />
                      </div>
                    </div>
                    <div *ngIf="isEditable == true; else noEntries">
                      <!-- <div  *ngIf="mcdp.key != mcdp.mcdp_category">
                            <mat-form-field>
                              <mat-chip-list #chipList>
                                <mat-chip *ngFor="let diseaseType of mcdp.value; let disKey = index" (removed)="removeChip(chip)">
                                  {{ diseaseType }}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                              </mat-chip-list>
                              <input
                              matInput
                              placeholder="Add chip"
                              [matChipInputFor]="chipList"
                              (matChipInputTokenEnd)="addChip($event)"
                            />
                          </mat-form-field> 
                          </div> -->
                      <!-- <input matInput placeholder="enter" type="text" [(ngModel)]="mcdp.id" (change)="getmcdpData($event)"/> -->
                      <!-- <mat-form-field>
                            <mat-chip-list #chipList>
                              <mat-chip *ngFor="let chip of chips" (removed)="removeChip(chip)">
                                {{ chip }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                          
                            <input
                              matInput
                              placeholder="Add chip"
                              [matChipInputFor]="chipList"
                              (matChipInputTokenEnd)="addChip($event)"
                            />
                          </mat-form-field> -->
                      <!-- </div> -->
                      <!-- <div style="display: flex; flex-wrap: wrap;"  *ngFor="let item of mcdp | keyvalue; let cat"> -->
                      <!-- <div  *ngIf="item.value != mcdp.mcdp_category"> -->
                      <!-- <div *ngIf="item.value.length > 0; else noEntries"  style="display: flex; flex-wrap: wrap;">
                              <div style="display: flex;" *ngFor="let diseaseType of item.value; let disKey = index"> -->
                      <!-- <div class="mcdp-data-desease"> -->
                      <!-- <p style="margin-bottom: 0;max-width: 280px;">
                                    {{diseaseType}}
                                  </p> -->

                      <!-- <mat-icon style="font-size: 16px;cursor: pointer;height: 16px;width: 12px;" (click)="onDelete(item, mcdpKey, disKey)">cancel</mat-icon>
                                </div> -->
                      <!-- </div> -->
                      <!-- </div> -->
                      <!-- <ng-template #noEntries style="margin-right: 10px;">Nil</ng-template> -->
                      <!-- <span *ngIf="!transcrptionData.s3_transcripted_file_url">
                              <input class="add-new-disease" matInput placeholder="Add new"  (keyup.enter)="addDisease($event,mcdp, item)" type="text" />
                            </span> -->
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <!-- <mat-tab label="A&T">
                  <div>

                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Summary</mat-label>
                      <textarea matInput placeholder="Ex. take this medicine 2 times a day..."></textarea>
                    </mat-form-field>
                  </div>
                </mat-tab> -->
            <!-- <mat-tab label="Historical">
              <div class="med-tab">
                <ul class="tab1Content">
                  <label
                    *ngIf="hisoryFileURL == null"
                    class="label2"
                    style="color: orangered"
                    >No documents/files uploaded</label
                  >
                  <iframe
                    [src]="transform()"
                    frameBorder="0"
                    height="500"
                    width="100%"
                  ></iframe>
                </ul>
              </div>
            </mat-tab> -->
            <mat-tab label="Prescriptions & Letters">
              <div class="med-tab">
                <div class="innerTab">
                  <mat-tab-group
                    [selectedIndex]="innerTabIndex"
                    mat-align-tabs="start"
                    (selectedTabChange)="onTabPresChanged($event)"
                  >
                    <mat-tab label="Prescription">
                      <div class="detailHeader" id="med-pre-header">
                        <div
                          style="width: 10%; float: left; padding-block: 5px"
                        >
                          <img
                            src="../../../assets/images/avator.JPG"
                            alt="userName"
                            style="
                              width: 100px;
                              height: 100px;
                              border-radius: 50%;
                            "
                          />
                        </div>
                        <div class="detailList text-left">
                          <li
                            style="font-weight: 500; font-size: 1.2em"
                            id="name_"
                          >
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.first_name
                                : ""
                            }}
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.last_name
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            NRIC :
                            {{
                              selectedAppointment?.patient
                                ? (selectedAppointment?.patient.mrn | nricMask)
                                : ""
                            }}
                            &nbsp;&nbsp; GENDER:
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.gender == "M"
                                  ? "Male"
                                  : "Female"
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            DOB :
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.date_of_birth
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            PHONE:
                            {{ selectedAppointment?.patient?.phone_number }}
                          </li>
                        </div>
                      </div>
                      <div>
                        <div
                          id="element-to-hide"
                          data-html2canvas-ignore="true"
                          class="fullscreen"
                          id="fullscreen"
                          (click)="openFullscreen('prescription')"
                        >
                          <mat-icon>fullscreen</mat-icon>
                        </div>
                        <div id="header-content" style="display: flex">
                          <div style="padding: 37px; display: inline-block">
                            <img
                              src="../../../assets/images/logo.JPG"
                              alt="Logo"
                              width="50px"
                              height="60px"
                            />
                          </div>
                          <label
                            class="mt-2 ml-0"
                            style="
                              display: inline-block;
                              width: 70%;
                              font-size: 1.4em;
                              padding-top: 39px;
                            "
                            ><b>Medosys</b></label
                          >

                          <br />
                          <div
                            class="col-sm-6 text-right"
                            style="padding-top: 35px"
                          >
                            <div class="doctorDetails">
                              <div>
                                <label
                                  >Dr.
                                  {{ selectedAppointment?.doctor.first_name }}
                                  {{
                                    selectedAppointment?.doctor.last_name
                                      ? selectedAppointment?.doctor.last_name
                                      : ""
                                  }}</label
                                >
                              </div>
                              <div><label>MCR No: M6992G</label></div>
                              <div><label>10, Anson Road</label></div>
                              <div><label>Singapore 100001</label></div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="med-pre"
                          [ngClass]="{ editCursor: isDisplay == true }"
                        >
                          <div>
                            <div class="patientDetails">
                              <div>
                                <label
                                  >Patient Name:
                                  <span style="color: black; margin-left: 20px"
                                    >{{
                                      selectedAppointment?.patient.first_name
                                    }}
                                    {{
                                      selectedAppointment?.patient.last_name
                                        ? selectedAppointment?.patient.last_name
                                        : ""
                                    }}</span
                                  ></label
                                >
                              </div>
                              <div>
                                <label
                                  >Gender:
                                  <span
                                    style="color: black; margin-left: 20px"
                                    >{{
                                      selectedAppointment?.patient.gender == "M"
                                        ? "Male"
                                        : "Female"
                                    }}</span
                                  ></label
                                >
                                <div style="width: 100%">
                                  <label class="d-flex" style="width: 100%">
                                    <div class="mr-3">Advice:</div>
                                    <textarea
                                      id="txAreaInp"
                                      class="advice"
                                      type="text"
                                      placeholder="Type the advice ...."
                                      [(ngModel)]="prescriptionData.advice"
                                    ></textarea>
                                    <span
                                      style="color: black; width: 80%"
                                      id="txAreaPar"
                                      class="d-none"
                                    >
                                      {{ prescriptionData.advice }}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="search ml-none">
                              <input
                                type="text"
                                placeholder="Type Something...."
                              />
                            </div> -->
                            <label class="patientDetails">Prescription</label>
                            <div class="presol">
                              <ol style="width: 100%">
                                <li
                                  *ngFor="
                                    let data of prescriptionData.medicine_details;
                                    let id = index
                                  "
                                >
                                  <div class="medRight">
                                    <div class="medLeft">
                                      <input [(ngModel)]="data.med" />
                                    </div>
                                    <input
                                      type="checkbox"
                                      (change)="presTimeChange('mor', id)"
                                      [checked]="data.time.mor"
                                      id="morning"
                                      name="morning"
                                      value="morning"
                                    />
                                    <label for="morning"> Mor</label>
                                    <input
                                      type="checkbox"
                                      (change)="presTimeChange('an', id)"
                                      [checked]="data.time.an"
                                      id="AN"
                                      name="AN"
                                      value="AN"
                                    />
                                    <label for="AN"> AN</label>
                                    <input
                                      type="checkbox"
                                      (change)="presTimeChange('night', id)"
                                      [checked]="data.time.night"
                                      id="Night"
                                      name="Night"
                                      value="Night"
                                    />
                                    <label for="Night"> Night</label>
                                    <div
                                      style="
                                        display: inline-block;
                                        margin-left: 30px;
                                      "
                                    >
                                      <mat-radio-group [(ngModel)]="data.food">
                                        <mat-radio-button
                                          disableRipple="true"
                                          value="bf"
                                          >BF</mat-radio-button
                                        >
                                        <mat-radio-button
                                          disableRipple="true"
                                          value="af"
                                          style="margin-left: 20px"
                                          >AF</mat-radio-button
                                        >
                                      </mat-radio-group>
                                    </div>
                                    <div
                                      class="medLast"
                                      style="
                                        display: inline-block;
                                        margin-left: 10px;
                                      "
                                    >
                                      <label><b>Days</b></label
                                      ><input [(ngModel)]="data.days" />
                                    </div>
                                    <div
                                      class="medDel"
                                      id="element-to-hide"
                                      data-html2canvas-ignore="true"
                                      (click)="deleteMedicine(id)"
                                    >
                                      <span
                                        ><mat-icon class="delIcon"
                                          >delete</mat-icon
                                        ></span
                                      >
                                    </div>
                                  </div>
                                </li>
                              </ol>
                              <div class="lowerDetail" id="lowerDetail">
                                <div>
                                  <label><b>Add:</b></label>
                                </div>
                                <div
                                  style="cursor: pointer"
                                  (click)="addMedicine()"
                                >
                                  <span
                                    ><mat-icon
                                      style="
                                        font-size: 0.9em;
                                        width: 20px;
                                        height: 15px;
                                        margin: 5px;
                                        border-radius: 50px;
                                        color: black;
                                        background: white;
                                      "
                                      >free_breakfast</mat-icon
                                    ></span
                                  ><label style="cursor: pointer"
                                    >Medicine</label
                                  >
                                </div>
                                <div>
                                  <span
                                    ><mat-icon
                                      style="
                                        font-size: 0.9em;
                                        width: 20px;
                                        height: 15px;
                                        margin: 5px;
                                        border-radius: 50px;
                                        color: black;
                                        background: white;
                                      "
                                      >notes</mat-icon
                                    ></span
                                  ><label style="cursor: pointer"
                                    >Diagnostic methods</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4" style="margin-inline: 15px">
                            <div class="col-6">
                              <div *ngIf="!!selectedAppointment">
                                <label
                                  >Dr.
                                  {{ selectedAppointment?.doctor.first_name }}
                                  {{
                                    selectedAppointment?.doctor.last_name
                                      ? selectedAppointment?.doctor.last_name
                                      : ""
                                  }}</label
                                >
                              </div>
                              <div><label>MCR No: M6992G</label></div>
                              <div><label>10, Anson Road</label></div>
                              <div><label>Singapore 100001</label></div>
                              <div><label>Telephone: 69874523</label></div>
                              <div><label>UEN: 202034863N</label></div>
                            </div>
                            <div class="col-6">
                              <div *ngIf="hideSeal">
                                <img
                                  [src]="
                                    tenantData?.clinic_stamp_url !== null
                                      ? tenantData?.clinic_stamp_url
                                      : '../../../assets/images/signature_doctor.jpg'
                                  "
                                  alt="seal"
                                  width="120px"
                                  height="65px"
                                  crossorigin
                                />
                              </div>

                              <div *ngIf="hideSign">
                                <img
                                  [src]="
                                    selectedAppointment?.doctor
                                      ?.doctor_signature_url !== null
                                      ? selectedAppointment?.doctor
                                          ?.doctor_signature_url
                                      : '../../../assets/images/seal.jpg'
                                  "
                                  alt="seal"
                                  width="180px"
                                  height="140px"
                                  crossorigin
                                />
                              </div>
                            </div>
                            <div class="col-sm-12 text-right" id="removeBtn">
                              <button class="remove-btn" (click)="removeSeal()">
                                Remove Seal
                              </button>
                              <button class="remove-btn" (click)="removeSign()">
                                Remove Sign
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Medical Certificate">
                      <div class="detailHeader" id="med-cert-header">
                        <div
                          style="width: 10%; float: left; padding-block: 5px"
                        >
                          <img
                            src="../../../assets/images/avator.JPG"
                            alt="userName"
                            style="
                              width: 100px;
                              height: 90px;
                              border-radius: 50%;
                            "
                          />
                        </div>
                        <div
                          class="detailList text-left"
                          style="padding-left: 20px"
                        >
                          <li style="font-weight: 500; font-size: 1.2em">
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.first_name
                                : ""
                            }}
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.last_name
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            NRIC :
                            {{
                              selectedAppointment?.patient
                                ? (selectedAppointment?.patient.mrn | nricMask)
                                : ""
                            }}
                            &nbsp;&nbsp; GENDER:
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.gender == "M"
                                  ? "Male"
                                  : "Female"
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            DOB :
                            {{
                              selectedAppointment?.patient
                                ? selectedAppointment?.patient.date_of_birth
                                : ""
                            }}
                          </li>
                          <li style="padding-top: 3px; font-size: 0.9em">
                            PHONE:
                            {{ selectedAppointment?.patient.phone_number }}
                          </li>
                        </div>
                      </div>
                      <div>
                        <div
                          id="element-to-hide"
                          data-html2canvas-ignore="true"
                          class="fullscreen"
                          id="fullscreen"
                          (click)="openFullscreen('medCert')"
                        >
                          <mat-icon>fullscreen</mat-icon>
                        </div>

                        <div id="header-content" style="display: flex">
                          <div
                            style="
                              padding: 37px;
                              padding-left: 13px;
                              display: inline-block;
                            "
                          >
                            <img
                              src="../../../assets/images/logo.JPG"
                              alt="Logo"
                              width="50px"
                              height="60px"
                            />
                          </div>
                          <label
                            class="mt-2 ml-0"
                            style="
                              display: inline-block;
                              width: 70%;
                              font-size: 1.4em;
                              padding-top: 39px;
                            "
                            ><b>Medosys</b></label
                          >

                          <br />
                          <div
                            class="col-sm-6 text-right"
                            style="padding-top: 35px"
                          >
                            <div class="doctorDetails">
                              <div>
                                <label
                                  >Dr.
                                  {{ selectedAppointment?.doctor.first_name }}
                                  {{
                                    selectedAppointment?.doctor.last_name
                                      ? selectedAppointment?.doctor.last_name
                                      : ""
                                  }}</label
                                >
                              </div>
                            </div>
                            <div><label>MCR No: M6992G</label></div>
                            <div><label>10, Anson Road</label></div>
                            <div><label>Singapore 100001</label></div>
                          </div>
                        </div>
                        <div
                          id="med-cer"
                          [ngClass]="{ editCursor: isDisplay == true }"
                        >
                          <ul class="medcert">
                            <li>
                              Date of consultation :
                              <input
                                type="date"
                                id="BirthDate"
                                name="BirthDate"
                                [ngModel]="
                                  selectedAppointment?.appointment_date
                                    | date : 'yyyy-MM-dd'
                                "
                                (ngModelChange)="
                                  selectedAppointment?.patient.modified_date =
                                    $event
                                "
                                [value]="
                                  selectedAppointment?.patient.modified_date
                                    | date : 'yyyy-MM-dd'
                                "
                                #BirthDate="ngModel"
                              />
                              <!-- <input type="date" [value]="new Date(selectedAppointment?.patient.modified_date)|date:'dd/MM/yyyy'" style="text-align:center;border:none;border-bottom: 1px solid black;margin-left:20px;"/> -->
                            </li>
                            <li>
                              Patient Name :
                              <input
                                [value]="
                                  selectedAppointment?.patient.first_name
                                "
                                type="text"
                                class="withoutBorder"
                                placeholder="Type patient's Name.."
                              />
                            </li>
                            <li>
                              NRIC/FIN/PASSPORT :
                              <input
                                [value]="selectedAppointment?.patient.mrn"
                                type="text"
                                class="withoutBorder"
                                placeholder="Type patient's NRIC/FIN/PASSPORT number.."
                              />
                            </li>
                            <li>
                              Medical certificate ID :
                              <input
                                [value]="selectedAppointment?.id"
                                type="text"
                                class="withoutBorder"
                                placeholder="Enter medical certificate ID"
                              />
                            </li>
                          </ul>
                          <div class="nextDiv">
                            <p>
                              This is to certify that the above named person is
                              unfit for Office/Work/School for a period of
                              <input
                                [(ngModel)]="medicalCertData.days"
                                oninput="this.value = Math.abs(this.value)"
                                min="0"
                                type="number"
                                style="
                                  text-align: center;
                                  width: 40px;
                                  border: none;
                                  border-bottom: 1px solid black;
                                "
                              />
                              days from

                              <input
                                type="date"
                                id="fromDate"
                                name="fromDate"
                                [ngModel]="
                                  medicalCertData.from_date
                                    | date : 'yyyy-MM-dd'
                                "
                                (ngModelChange)="fromDateChange($event)"
                                [value]="
                                  medicalCertData.from_date
                                    | date : 'yyyy-MM-dd'
                                "
                                #fromDate="ngModel"
                              />
                              to
                              <input
                                type="date"
                                id="toDate"
                                name="toDate"
                                [ngModel]="
                                  medicalCertData.to_date | date : 'yyyy-MM-dd'
                                "
                                (ngModelChange)="toDateChange($event)"
                                [value]="
                                  medicalCertData.to_date | date : 'yyyy-MM-dd'
                                "
                                #toDate="ngModel"
                              />
                              <!-- <input type="date"  [value]="medicalCertData.from_date|date:'dd2/MM/yyyy'" style="border:none;border-bottom:1px solid black;"/> 
                     <input type="date" [value]="medicalCertData.to_date|date:'dd/MM/yyyy'" style="border:none;border-bottom:1px solid black;"/>  </p>  -->
                            </p>
                          </div>
                          <!-- </div> -->
                          <div class="nextDiv">
                            <p>
                              Doctor Name:
                              <input
                                [value]="selectedAppointment?.doctor.first_name"
                                style="
                                  text-align: center;
                                  font-weight: bold;
                                  border: none;
                                  border-bottom: 1px solid black;
                                "
                                type="text"
                                placeholder="Type Doctor's name.."
                              />
                              (MCR No:<input
                                style="
                                  text-align: center;
                                  font-weight: bold;
                                  border: none;
                                  border-bottom: 1px solid black;
                                "
                                type="text"
                                placeholder="Enter MCR number.."
                                [(ngModel)]="medicalCertData.mcr_number"
                              />)
                            </p>
                            <p>
                              This certificate is electronically generated. No
                              signature is required
                            </p>
                          </div>
                          <div class="nextDiv">
                            <p>
                              This Medical certificate is not valid for absence
                              from court.
                            </p>
                          </div>
                          <div
                            class="row"
                            style="
                              margin-inline: 15px;
                              text-align: center;
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                            "
                          >
                            <div class="col-6" *ngIf="hideSeal">
                              <img
                                [src]="
                                  tenantData?.clinic_stamp_url !== null
                                    ? tenantData?.clinic_stamp_url
                                    : '../../../assets/images/signature_doctor.jpg'
                                "
                                alt="seal"
                                width="120px"
                                height="65px"
                                crossorigin
                              />
                            </div>
                            <div class="col-6" *ngIf="hideSign">
                              <img
                                [src]="
                                  selectedAppointment?.doctor
                                    ?.doctor_signature_url !== null
                                    ? selectedAppointment?.doctor
                                        ?.doctor_signature_url
                                    : '../../../assets/images/seal.jpg'
                                "
                                alt="seal"
                                width="180px"
                                height="140px"
                                crossorigin
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 text-right" id="removeCerBtn">
                            <button class="remove-btn" (click)="removeSeal()">
                              Remove Seal
                            </button>
                            <button class="remove-btn" (click)="removeSign()">
                              Remove Sign
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Consultation Summary"></mat-tab>
            <mat-tab label="Clinical Notes & Codes"></mat-tab>
            <mat-tab label="Other Medical Records">
              <div class="med-tab">
                <ul class="tab1Content">
                  <label
                    *ngIf="hisoryFileURL == null"
                    class="label2"
                    style="color: orangered"
                    >No documents/files uploaded</label
                  >
                  <iframe
                    [src]="transform()"
                    frameBorder="0"
                    height="500"
                    width="100%"
                  ></iframe>
                </ul>
              </div>

            </mat-tab>
            <mat-tab label="Past Consultation Records"></mat-tab>
            <mat-tab label="Medical Images Analysis"></mat-tab>
            <mat-tab label="Wearable Health Data"></mat-tab>
            <mat-tab disabled></mat-tab>
            <mat-tab disabled class="mr-0">
              <ng-template mat-tab-label>
                <span
                  class="shadow"
                  [ngClass]="{ editCursor: isDisplay == true }"
                  style="border: 4px solid #20e159e5; border-radius: 5px"
                  ><mat-icon
                    style="font-size: 1.5em; cursor: pointer; color: green"
                    matTooltip="Edit"
                    (click)="updateNote()"
                    >edit</mat-icon
                  ></span
                >
                <button
                  mat-icon-button
                  matTooltip="Download"
                  (click)="printDocument()"
                  id="hideprint2"
                >
                  <mat-icon>print</mat-icon>
                </button>
              </ng-template>
            </mat-tab>
            <!-- <mat-tab disabled> </mat-tab> -->
            <!-- <mat-tab disabled class="mr-0">
              <ng-template mat-tab-label class="mat-tab-last-child">
                <span
                  class="shadow"
                  style="border: 4px solid #20e159e5; border-radius: 5px"
                  ><mat-icon
                    style="font-size: 1.5em; cursor: pointer; color: green"
                    matTooltip="edit"
                    (click)="updateNote()"
                    >edit</mat-icon
                  ></span
                >
                <button
                  mat-icon-button
                  (click)="printDocument()"
                  id="hideprint2"
                >
                  <mat-icon>print</mat-icon>
                </button>
              </ng-template>
            </mat-tab> -->
          </mat-tab-group>
        </div>
        <div class="row container my-3" id="hideprint">
          <div class="mx-auto my-3">
            <button
              *ngIf="!isDisplay"
              class="btn1 mx-2"
              (click)="cancelNotes()"
            >
              Cancel</button
            >&nbsp;

            <button
              class="btn1 mx-2"
              *ngIf="!!isShow && !isDisplay"
              (click)="saveNotes()"
            >
              Save</button
            >&nbsp;
            <button
              class="btn mx-2"
              *ngIf="!!isShow && !isDisplay"
              (click)="doneAllNotes('done')"
            >
              Done
            </button>

            <button
              *ngIf="!isDisplay"
              class="btn mx-2"
              (click)="doneAllNotes('complete')"
            >
              Compelete
            </button>
            <button
              *ngIf="!!isDisplay"
              class="btn mx-2"
              (click)="moveDashboard()"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
