 <!-- <div id="med-bar-header"><div class="drpicker">
    <mat-form-field appearance="fill">
      <mat-label><b>Date Range</b></mat-label>
      <mat-date-range-input [rangePicker]="picker1">
        <input matStartDate placeholder="Start date" #dateRangeStart [(ngModel)]="fDate">
        <input matEndDate placeholder="End date" #dateRangeEnd  [(ngModel)]="tDate" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
      </mat-date-range-input>
      <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-date-range-picker #picker1></mat-date-range-picker>
    </mat-form-field>
  </div>
  </div> -->
 <!-- <div class="card-header-right">
    <div *ngIf="!maximize" class="fullscreen" id="fullscreen" (click)="openFullscreen()"><mat-icon>fullscreen</mat-icon></div>
    <div *ngIf="maximize" class="fullscreen" (click)="fullScreenExit()"><mat-icon>fullscreen_exit</mat-icon></div>
    <div class="fullscreen" id="fullscreen" (click)="downloadChart()"><mat-icon>cloud_download</mat-icon></div>
  </div>
  </div> -->
  <!-- animations="true"
  schemeType="ordinal" -->
  <!-- <ngx-charts-bar-vertical-stacked
    [view]="view"
    [barPadding]="padding"
    id="bar-chart1"
    class="bar-chart1"
    [scheme]="colorScheme"
    [results]="multi"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    legendPosition="below"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)">
  </ngx-charts-bar-vertical-stacked> -->
  <!-- <div class="p-3" style="padding-top: 5rem !important;"> -->
    <plotly-plot [data]="graph.data" [layout]="graph.layout" [config]="graph.config"></plotly-plot>      
  <!-- </div> -->