import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.css'],
})
export class DashboardPopupComponent {
  dateRange: any;
  dateRange1: any;
  dateRange2: any;
  dateRange3: any;
  dateRange4: any;
  dateRange5: any;
  dateRange6: any;
  dateRange7: any;
  dateRange8: any;
  datepick: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DashboardPopupComponent>
  ) {
    if (!!data) {
      console.log(data.dateRange);
      if (data.dateRange == 'dateRange9') {
        this.dateRange = data.dateRange;
      }
      if (data.dateRange == 'dateRange1') {
        this.dateRange1 = data.dateRange;
      }
      if (data.dateRange == 'dateRange2') {
        this.dateRange2 = data.dateRange;
      }
      if (data.dateRange == 'dateRange3') {
        this.dateRange3 = data.dateRange;
      }
      if (data.dateRange == 'dateRange4') {
        this.dateRange4 = data.dateRange;
      }
      if (data.dateRange == 'dateRange5') {
        this.dateRange5 = data.dateRange;
      }
      if (data.dateRange == 'dateRange6') {
        this.dateRange6 = data.dateRange;
      }
      if (data.dateRange == 'dateRange7') {
        this.dateRange7 = data.dateRange;
      }
      if (data.dateRange == 'dateRange8') {
        this.dateRange8 = data.dateRange;
      }
    }
    console.log(data.datePick);
    if (data.datePick !== null) {
      console.log(data.datePick);
      this.datepick = data.datePick;
    }
  }
  fullScreenExit() {
    debugger;
    this.dialogRef.close();
  }
}
