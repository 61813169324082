import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  providers: any = [];
  selectedProvider:number=0;
  selectedSpeciality:string="";
  doctorSelectFlag:boolean=false;
  speciality:any=[];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<AlertDialogComponent>,private snackBar: MatSnackBar) {
    if (data) {
      this.providers = data.providerList;
      this.selectedProvider=data.selectedProvider;
      if(this.selectedProvider!==0){
        this.speciality=this.providers.find(x=>x.id==this.selectedProvider).speciality_name;
      }
    }
    //this.dialogRef.updateSize('300vw','300vw')
   }

  ngOnInit(): void {
  }
  closeDialog(){
      this.dialogRef.close({event:'Cancel'});
  }
saveDialog(){
  if(this.selectedSpeciality!==""){
    this.dialogRef.close({event:'OK',data:this.selectedSpeciality});
  } else {
    this.snackBar.open("Select any speciality to proceed", 'Close', {duration: 4000});
  }
}
}
