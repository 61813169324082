import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-refund-response',
  templateUrl: './refund-response.component.html',
  styleUrls: ['./refund-response.component.css']
})
export class RefundResponseComponent {
  public refundData:any;
  public speciality:any=[];
  constructor(public dialogRef:MatDialogRef<RefundResponseComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    this.refundData=this.data.data;
    
  }
  saveDialog(){
    this.refundData.Is_declined=true;
    this.dialogRef.close({event:'Save',data:this.refundData});
  }
  closeDialog(){
    this.dialogRef.close({event:'Cancel', data: {}});
  }
}
