import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { VideoService } from '../../service/video.service';
import {
  CallInfoDialogComponents,
  DialogData,
} from './dialog/callinfo-dialog.component';
import * as RecordRTC from 'recordrtc';
import * as S3 from 'aws-sdk/clients/s3';
import { AppointmentService } from 'src/app/service/appointment.service';
import { DyteMeeting } from '@dytesdk/angular-ui-kit';
import DyteClient from '@dytesdk/web-core';
import {
  activateTranscriptions,
  deactivateTranscriptions,
  addTranscriptionsListerner,
  removeTranscriptionsListener,
} from '@dytesdk/symbl-transcription';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
})
export class VideoComponent implements OnInit {
  title = 'MyProject';
  @Input() selectedAppointment: any;
  vidAuthToken: any;
  vidEntry: boolean = false;
  video_recording_id: any;
  @Output() transcriptPass = new EventEmitter<any>();
  @ViewChild('myid') meetingComponent: DyteMeeting;
  @Output() dyteClose = new EventEmitter<any>();
  dyteMeeting: DyteClient;
  uiConfigOptions = {
    dimensions: {
      mode: 'fillParent',
    },
    logo: '../../../assets/images/logo.JPG',
    logoUrl: '../../../assets/images/logo.JPG',
  };
  constructor(
    public dialog: MatDialog,
    private callService: VideoService,
    private appService: AppointmentService
  ) {}
  async ngAfterViewInit() {
    //console.log(this.selectedAppointment)
    let params = {
      title:
        'Doctor Meeting with ' + this.selectedAppointment.patient.first_name,
      doctor_name: this.selectedAppointment.doctor.first_name,
      appointment_id: this.selectedAppointment.id,
      phone_number: this.selectedAppointment.patient.phone_number,
      preset_name: 'Medosys_preset',
    };

    console.log(params);
    this.appService.getVideoAuth(params).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          const res: any = response;
          this.vidAuthToken = res.data.participant_response.data.token;
          this.video_recording_id = res.data.video_recording_id;
          this.callVideo();
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  async callVideo() {
    let transcriptToken;
    const meeting = await DyteClient.init({
      authToken: this.vidAuthToken,
      // authToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJZCI6ImRkNzkzN2E3LTFjMmUtNGE2Ny1hZTBlLTYyMmQwODA5OTBhZCIsIm1lZXRpbmdJZCI6ImJiYmU2YzMwLTlkYzYtNGY3Ny1iYWU0LTk5NzI2ZDc0Njk1NiIsInBhcnRpY2lwYW50SWQiOiJhYWE1NjMzMy1iNTM4LTQxNDYtYTY5NC04ZmYwOWJkMmI5YTciLCJwcmVzZXRJZCI6Ijk3YjBhNTdjLTA1NTItNDM0Yy04YmVkLTgwZjcyMjdhNDkyYSIsImlhdCI6MTY3NzgzODE5NCwiZXhwIjoxNjg2NDc4MTk0fQ.UvxF5YH1E6YvGtQRbLfs-0jkrLupM0OmU8Y-ndgwK9xKdqFU0Ndvi4l1y_3yu8vBTH-dxdZK-oNkhd4_A0PQHzGdaAGibC4iOVQqvuRpLO6a8bo2b4azc4zcqCFH6mqZNLXbOjC8cwa4PYFkq5l-bOIgSD-0_RRDeBk6tDMlPWWc8KlYb1Dd1qWDc3Mqp1DQ126fBQuw-3xIbFI8kLRdKb4ZgXkmSCnEjT12jXASQZ0V3d-vp-cwjv8VgLGN_o2qpdogQpZs0h697nppxObl4RwYChHkjQuMQ_67s2sddidIIIczosWgwwDoDkzWLeOshwOadicJ8YvWxrDnMA6hPA',
    });
    meeting.joinRoom();
    this.dyteMeeting = meeting;
    if (this.meetingComponent) this.meetingComponent.meeting = meeting;
    let transcriptParams = {
      type: 'application',
      appId: '57615579366a334a5649794d654c48323353766764694d36706775646b686653',
      appSecret:
        '667766325243305a7037396d34707445417278354536485465634f35796f734366416e4c4e743546394f313961764c4c454e514a526d4e57554c6c355a636c42',
    };
    this.appService.getTranscriptAuth(transcriptParams).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          const res: any = response;
          transcriptToken = res.accessToken;

          activateTranscriptions({
            meeting: meeting, // From DyteClient.init
            symblAccessToken: transcriptToken,
          });
          addTranscriptionsListerner({
            meeting: meeting,
            noOfTranscriptionsToCache: 200,
            transcriptionsCallback: (allFormattedTranscriptions) => {
              //console.log(allFormattedTranscriptions);
              if (allFormattedTranscriptions?.length > 0) {
                this.transcriptPass.emit(allFormattedTranscriptions);
              }
            },
          });
        }
      },
      (error) => {
        //console.log(error);
      }
    );
    meeting.self.on('roomLeft', () => {
      //console.log("meeting ends")
      this.endCall();
    });
  }

  endCall() {
    let params = {
      recording_id: this.video_recording_id,
    };
    this.appService.stopRecording(params).subscribe(
      (response) => {
        if (response) {
          //console.log(response)
          const res: any = response;
        }
        this.dyteClose.emit(true);
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  // public isCallStarted$: Observable<boolean>;
  // private peerId: string;
  // record;
  // url;
  // @Output()
  // blobPass = new EventEmitter<string>();
  // @Input() selectedAppointment: any;
  // file:any;
  // @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  // @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;
  // @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
  // constructor(public dialog: MatDialog, private callService: VideoService,
  //   private appService: AppointmentService) {
  //   this.isCallStarted$ = this.callService.isCallStarted$;
  //   this.peerId = this.callService.initPeer();
  // }

  ngOnInit(): void {
    //   this.callService.localStream$
    //     .pipe(filter(res => !!res))
    //     .subscribe(stream => {
    //       this.localVideo.nativeElement.srcObject = stream;
    //       this.successCallback(stream);
    //     })
    //   this.callService.remoteStream$
    //     .pipe(filter(res => !!res))
    //     .subscribe(stream => {
    //       this.remoteVideo.nativeElement.srcObject = stream
    //       this.successCallback(stream);
    //     });
  }

  // ngOnDestroy(): void {
  //   this.callService.destroyPeer();
  // }
  // public successCallback(stream) {
  //   var options = {
  //   mimeType: "audio/wav",
  //   numberOfAudioChannels: 1,
  //   sampleRate: 50000,
  //   };
  //   //Start Actuall Recording
  //   var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
  //   this.record = new StereoAudioRecorder(stream, options);
  //   this.record.record();
  //   }
  // public showModal(joinCall: boolean): void {
  //   if(this.selectedAppointment.video_call_url!==null){
  //     let url=this.selectedAppointment.video_call_url.replace('https://','');
  //     url=url.replace('.com','');
  //     this.selectedAppointment.video_call_url=url;
  //   }
  //   let dialogData: DialogData = joinCall ? ({ peerId: this.selectedAppointment.video_call_url, joinCall: true }) : ({ peerId: this.peerId, joinCall: false });
  //   const dialogRef = this.dialog.open(CallInfoDialogComponents, {
  //     width: '250px',
  //     data: dialogData
  //   });
  //   if(!joinCall){
  //     //alert(this.peerId)
  //     let appmt_data={
  //             "appointment_date":this.selectedAppointment.appointment_date,
  //             "doctor_id":this.selectedAppointment.doctor_id,
  //             "patient_id":this.selectedAppointment.patient_id,
  //             "questionnaire_url":this.selectedAppointment.questionnaire_url,
  //             "booking_date":this.selectedAppointment.booking_date,
  //             "appointment_status":this.selectedAppointment.appointment_status,
  //             "questionnaire_status":this.selectedAppointment.questionnaire_status,
  //             "notes_status":this.selectedAppointment.notes_status,
  //             "video_call_url":"https://"+this.peerId+".com"
  //           }
  //           var inputParam = Object.keys(appmt_data).map(key => key + '=' + encodeURIComponent(appmt_data[key])).join('&');
  //           //console.log(inputParam)
  //           this.appService.editAppointment(inputParam,this.selectedAppointment.id).subscribe((response) => {
  //             if (response) {
  //               //console.log(response)
  //               this.selectedAppointment=response;
  //               }
  //           }, (error) => {
  //             //console.log(error)
  //             if(error.status=404){
  //               //console.log(error.statusText);
  //             }
  //           });
  //   }
  //   dialogRef.afterClosed()
  //     .pipe(
  //       switchMap(peerId =>
  //         joinCall ? of(this.callService.establishMediaCall(peerId)) : of(this.callService.enableCallAnswer(peerId))
  //       ),
  //     )
  //     .subscribe(_  => { });
  // }

  // public endCall() {
  //   this.callService.closeMediaCall();
  //   this.record.stop(this.processRecording.bind(this));
  //   //this.record.stop();
  //   //this.appService.video.subscribe(data => this.selectedAppointment = data);
  // }

  // //processRecording(blob) {

  //   // this.url = URL.createObjectURL(blob);
  //   // //console.log("blob", blob);
  //   // //console.log("url", this.url);

  //   // var d = new Date();
  //   // let month =(d.getMonth() + 1);
  //   // let day = d.getDate();
  //   // let year = d.getFullYear();
  //   // let hours = d.getHours();
  //   // let minutes = d.getMinutes();
  //   // let secs = d.getSeconds();
  //   // let pickedDate = year+"_"+month+"_"+day+"_"+hours+"_"+minutes+"_"+secs;
  //   // let filename = pickedDate+'.wav';
  //   // this.file = new File([blob],filename,{ type:"audio/wav" });
  //   // this.uploadFile(this.file);
  //   //}
  //   processRecording(blob) {
  //     this.url = URL.createObjectURL(blob);
  //     //console.log("blob", blob);
  //     //console.log("url", this.url);

  //     var d = new Date();
  //     let month =(d.getMonth() + 1);
  //     let day = d.getDate();
  //     let year = d.getFullYear();
  //     let hours = d.getHours();
  //     let minutes = d.getMinutes();
  //     let secs = d.getSeconds();
  //     let pickedDate = year+"_"+month+"_"+day+"_"+hours+"_"+minutes+"_"+secs;
  //     let filename = this.selectedAppointment.id.toString()+"_"+pickedDate+'.wav';
  //     this.file = new File([blob],filename,{ type:"audio/wav" });
  //     this.blobPass.emit(this.file)
  //     }
  //   uploadFile(file) {
  //     const contentType = file.type;
  //     const bucket = new S3(
  //           {
  //               accessKeyId: 'AKIA5LEOLHXEHTEOM5IL',
  //               secretAccessKey: '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',
  //               region: 'ap-southeast-1'
  //           }
  //       );
  //       const params = {
  //           Bucket: 'medosys-dev-audio-file',
  //           Key: file.name,
  //           Body: file,
  //           ACL: 'public-read',
  //           ContentType: contentType
  //       };
  //       bucket.upload(params, (err, data) => {
  //         if (err) {
  //           //console.log(err, 'there was an error uploading your file');
  //         } else {
  //           //console.log(data.Key+ ' uploaded successfully');
  //         }
  //         return true;
  //       });
  // }
}
