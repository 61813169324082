import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen.component.html',
  styleUrls: ['./full-screen.component.css']
})

export class FullScreenComponent implements OnInit {
  // @Input() mcdpData: any;
  selectedAppointment:any;
  prescriptionData:any;
  public tenantData = {
    clinic_stamp_url: null
  };
  public medicalCertData = {
    mcr_number: ''
  };

  public mcdpCateogories: any = {};
  public mcdpData: any = {};
  public selectedMcdpDataIndex: number;
  public screenType = ''
  public screen = ''
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<FullScreenComponent>) {
    if (data) {
      this.screenType = data.modal
      this.tenantData = data.tetenantData;
      this.medicalCertData = data.medicalCertData;
      this.screen = data.screen;
      this.selectedAppointment = data.selectedAppointment;
      this.prescriptionData = data.prescriptionData;
      this.mcdpCateogories = data.mcdpCateogories
      this.mcdpData = data.mcdpData
      this.selectedMcdpDataIndex = data.selectedMcdpDataIndex; 
    }
   }

  ngOnInit(): void {
  }
  addMedicine(){
    this.prescriptionData.medicine_details.push({
      med:"",
      time:{mor:false,an:false,night:false},
      food:"",
      days:""
    })
  }
  fullScreenExit() {
    this.dialogRef.close({prescriptionData:this.prescriptionData, mcdpData: this.mcdpData });
  }
  deleteDiseaseOfCategory(disease, disKey) {
    this.mcdpData[disease.key] = this.mcdpData[disease.key].filter((dis, index) => index != disKey);
  }
  addDisease() {
    this.mcdpData[this.mcdpCateogories[this.mcdpData.mcdp_category]].push('')
  }
  onChangeValue(value, i) {
    this.mcdpData[this.mcdpCateogories[this.mcdpData.mcdp_category]][i] = value
 }
}
