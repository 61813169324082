<div *ngIf="dateRange">
  <div class="container-fluid">
    <div (click)="fullScreenExit()">
      <mat-icon style="font-weight: bold; float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-gauge-chart [chartPopup]="'gaugePopup'"></app-gauge-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-gauge-chart
            [dateRange]="datepick"
            [chartPopup]="'gaugePopup'"
          ></app-gauge-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange1">
  <div class="container-fluid">
    <div (click)="fullScreenExit()">
      <mat-icon style="font-weight: bold; float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-speed-chart [chartPopup]="'speedPopup'"></app-speed-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-speed-chart
            [dateRange]="datepick"
            [chartPopup]="'speedPopup'"
          ></app-speed-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange2">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-bar-chart [chartPopup]="'barPopup'"></app-bar-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-bar-chart
            [dateRange]="datepick"
            [chartPopup]="'barPopup'"
          ></app-bar-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange3">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-line-chart [chartPopup]="'linePopup'"></app-line-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-line-chart
            [dateRange]="datepick"
            [chartPopup]="'linePopup'"
          ></app-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange4">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-multi-chart [chartPopup]="'multiPopup'"></app-multi-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-multi-chart
            [dateRange]="datepick"
            [chartPopup]="'multiPopup'"
          ></app-multi-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange5">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-pie-chart [chartPopup]="'piePopup'"></app-pie-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-pie-chart
            [dateRange]="datepick"
            [chartPopup]="'piePopup'"
          ></app-pie-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange6">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-multi-vertical
            [chartPopup]="'multiverticalPopup'"
          ></app-multi-vertical>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-multi-vertical
            [dateRange]="datepick"
            [chartPopup]="'multiverticalPopup'"
          ></app-multi-vertical>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange7">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-payment-chart [chartPopup]="'paymentPopup'"></app-payment-chart>
        </div>
      </div>
    </div>

    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-payment-chart
            [dateRange]="datepick"
            [chartPopup]="'paymentPopup'"
          ></app-payment-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dateRange8">
  <div class="container-fluid">
    <div class="fullscreen" (click)="fullScreenExit()">
      <mat-icon style="float: right">close</mat-icon>
    </div>
    <div *ngIf="!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-revenue-chart [chartPopup]="'revenuePopup'"></app-revenue-chart>
        </div>
      </div>
    </div>
    <div *ngIf="!!datepick">
      <div class="row">
        <div class="mx-auto pt-5">
          <app-revenue-chart
            [dateRange]="datepick"
            [chartPopup]="'revenuePopup'"
          ></app-revenue-chart>
        </div>
      </div>
    </div>
  </div>
</div>
