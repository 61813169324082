import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService } from 'src/app/service/appointment.service';
interface Degree{
  degree : string,
  degree_completed_year: string,
  college : string,
  university: string,
  degree_completed_country: string,
  fmge_status: string
}
interface Qualify{
  degrees : Degree[] ,
  about : string | null,
  user_id : number | null
}
@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['./doctor-detail.component.css']
})

export class DoctorDetailComponent {
  public doctorDetail={
degree:"",degree_completed_year:"",university:"",college:"",degree_completed_country:"",fmge_status:"",

  }
  public doctorDetails;
  degree:any;
  isShow:boolean=false;
  id:any;
  about:any;
  degreeId:any;
  QualDegree : Degree = {
    degree : "",
    degree_completed_year : "",
    college: "",
    university : "",
    degree_completed_country : "",
    fmge_status : ""
  };
  requestData : Qualify = {
    degrees : [],
    about : null,
    user_id : null
  };
  constructor( private dialog: MatDialog,public appService:AppointmentService,public dialogRef:MatDialogRef<DoctorDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private snackBar: MatSnackBar){
    if(!!data){
      
      this.doctorDetails = data.degree;
      this.id = data.doctor_id;   
      this.degreeId=data.degree_id
      console.log(this.doctorDetails)
    }
  }

  ngOnInit():void{ 
    console.log(this.doctorDetails[0])
    if(this.doctorDetails.length==0){
      this.isShow=true
    }else{
      this.isShow=false
    }   
   if(!!this.doctorDetails[0]){   
    this.requestData.user_id = this.doctorDetails[0].user_id;
    this.requestData.about = this.doctorDetails[0].about;
    this.requestData.degrees = this.doctorDetails[0].degrees.length > 0 ? this.doctorDetails[0].degrees : null;
    console.log(this.requestData,this.QualDegree)
  }
  if(!!this.degreeId){
    var detail = this.doctorDetails[0].degrees.filter(a=> a.id == this.degreeId);
    this.QualDegree=detail[0]
    console.log(this.QualDegree,this.QualDegree.degree)

  }
    // this.QualDegree.degree=x[0].degree
    // this.QualDegree.degree_completed_year=x[0].degree_completed_year
    // this.QualDegree.degree_completed_country=x[0].degree_completed_country
    // this.QualDegree.fmge_status=x[0].fmge_status
    // this.QualDegree.university=x[0].university
    // console.log(this.QualDegree)
   
   
  }
  saveDetail(_id){
    
    if(this.QualDegree.degree=="" || this.QualDegree.degree_completed_country=="" || this.QualDegree.degree_completed_year=="" || this.QualDegree.university==""){
    this.snackBar.open("Enter all the required fields", 'Close', {duration: 2000});
    }else{
     if(!this.degreeId && this.doctorDetails.length==0){
      console.log(this.QualDegree)
      let data={
       "degree":this.QualDegree.degree,
       "degree_completed_country":this.QualDegree.degree_completed_country,
       "degree_completed_year":this.QualDegree.degree_completed_year,
       "college":this.QualDegree.college,
       "university":this.QualDegree.university,
       "fmge_status":this.QualDegree.fmge_status
      }
      // this.requestData.degrees[0].degree=this.QualDegree.degree
      // this.requestData.degrees[0].degree_completed_country=this.QualDegree.degree_completed_country
      // this.requestData.degrees[0].degree_completed_year=this.QualDegree.degree_completed_year
      // this.requestData.degrees[0].university=this.QualDegree.university
      // this.requestData.degrees[0].fmge_status=this.QualDegree.fmge_status
      this.requestData.degrees.push(data);
      this.requestData.about=this.about;
      this.requestData.user_id=this.id
      console.log(this.requestData)
      this.appService.saveDoctordetail(this.requestData).subscribe((response) => {
            if (response) {
              this.doctorDetails= response;
      
      
              console.log(this.doctorDetails)
            }
            
          })
          this.dialogRef.close(true);
    }else if(!this.degreeId && this.doctorDetails.length>0){
      this.requestData.degrees.push(this.QualDegree);
      this.requestData.about=this.doctorDetails[0].about;
      this.requestData.user_id=this.id
      console.log(this.requestData)
      this.appService.updateDoctordetail(this.requestData,this.doctorDetails[0].id).subscribe((response) => {
              if (response) {
                this.doctorDetails = response;
                console.log(response)
              }
            
            })
            this.dialogRef.close(true);
    }
    else{
      
      var index = this.doctorDetails[0].degrees.findIndex(a=> a.id == this.degreeId);
      this.requestData.degrees[index].degree = this.QualDegree.degree;
      this.requestData.degrees[index].degree_completed_country = this.QualDegree.degree_completed_country;
      this.requestData.degrees[index].degree_completed_year = this.QualDegree.degree_completed_year;
      this.requestData.degrees[index].fmge_status = this.QualDegree.fmge_status;
      this.requestData.degrees[index].university = this.QualDegree.university;
      this.appService.updateDoctordetail(this.requestData,this.doctorDetails[0].id).subscribe((response) => {
              if (response) {
                this.doctorDetails = response;
                console.log(response)
              }
            
            })
            this.dialogRef.close(true);
    }   
  }
 
  }
  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
}

}
