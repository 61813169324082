import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public showError:boolean = false;
  public showResult:boolean = false;
  public userMail:string = "";
  public inputParams:any={};
  public responseObject:any;
  public result:string="";
  pwdresult:string=""
  showChange:boolean=false;
  showchangepwdError:boolean=false;
  oldPassword:string;
  newPassword:string;
  showchangepwdResult:boolean = false;
  constructor(private loginServive:LoginService,private router: Router) { }

  ngOnInit(): void {
  }
public proceedForgetPassword(){
  if(this.userMail!="" && this.userMail.includes('@')){
    this.showError=false;
    this.showResult=false;
    this.inputParams={email: this.userMail}
    this.loginServive.forgetPassword(this.inputParams).subscribe((response) => {
      if (response) {
        this.responseObject=response;
        this.showResult=true;
        this.userMail="";
        this.result=this.responseObject.data.message;
        console.log(response);
        this.showChange=true;
        }
    }, (error) => {
      this.showError=true;
    this.showResult=false;

    });
  } else {
    this.showError=true;
    this.showResult=false;
  }
}
closeDialog(){
  this.router.navigate(['/login']);
}
// public changePassword(){
//   if(this.oldPassword!="" && this.newPassword!=""){
//     this.showchangepwdError=false;
//     this.showchangepwdResult=false;
//     this.inputParams={old_password: this.oldPassword,new_password: this.newPassword}
//     this.loginServive.changePwd(this.inputParams).subscribe((response) => {
//       if (response) {
//         this.responseObject=response;
//         this.showchangepwdResult=true;
//         this.oldPassword="";
//         this.newPassword="";
//         this.pwdresult=this.responseObject.data.message;
//         console.log(response);
//         this.showChange=true;
//         }
//     }, (error) => {
//       this.pwdresult=error.message;
//       this.showchangepwdError=false;
//       this.showchangepwdResult=true;
//     });
//   } else {
//     this.showchangepwdError=true;
//     this.showchangepwdResult=false;
//   }
// }
}
