import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public showError:boolean = false;
  public userMail:string = "";
  public password:string = "";
  public inputParams:any={};
  public authToken:string="";
  public responseObject:any;
  public rememberMe:boolean=false;
  public access:any=[];
  showpwd:boolean=false;

  constructor(private loginService:LoginService,private router: Router,private snackBar: MatSnackBar) { }
  
  ngOnInit(): void {
    let userData=JSON.parse(localStorage.getItem('userData'));
    if(userData && userData.remember){
      this.userMail=userData.email;
      this.password=userData.password;
      this.rememberMe=userData.remember;
    } else {
      this.rememberMe=false;
    } 
  }
  public login(){
    if(this.userMail!="" && this.userMail.includes('@') && this.password!=""){
      this.showError=false;
      this.inputParams={email: this.userMail,password: this.password}

      // let email=encodeURIComponent("email");
      // let email_value=encodeURIComponent(this.userMail);
      // let password=encodeURIComponent("password");
      // let password_value=encodeURIComponent(this.password);
      // let inputParams:any=[];
  
      // inputParams.push(email+"="+email_value);
      // inputParams.push(password +"="+ password_value)

      this.loginService.GetAuthToken(this.inputParams).subscribe((response) => {
        if (response) {
          console.log(response)
          localStorage.setItem('userDetails', '');
          localStorage.setItem('userData', '');
          this.responseObject=response;
          this.authToken = this.responseObject.data.token;
          this.loginService.authToken=this.responseObject.token;
          this.loginService.userDetails=this.responseObject.data.user;
          this.loginService.email=this.userMail;
          this.loginService.password=this.password;
          this.access=this.responseObject.data.access;
          localStorage.setItem('userDetails', JSON.stringify({email: this.userMail,password: this.password,remember:this.rememberMe,authToken:this.authToken,userAccess:this.responseObject.data.user,access:this.access,tenant_details:this.responseObject.data.tenant_details}));
          if(this.rememberMe){
            localStorage.setItem('userData', JSON.stringify({email: this.userMail,password: this.password,remember:this.rememberMe}));
          } else {
            localStorage.setItem('userData', JSON.stringify({email: "",password: "",remember:false}));
          }
          this.router.navigate(['/appointment'],{ replaceUrl: true });
          }
          // setTimeout(()=>{
          //   alert('Do you want to check this')
          // },10000);
      }, (error) => {
        console.log(error);
        if (error.error.message){
          this.snackBar.open(JSON.stringify(error.error.message), 'Close', {duration: 2000});
        } else {
          this.snackBar.open("Error While Logging Please contact Administrator", 'Close', {duration: 2000});
        }
      });
    } else {
      this.showError=true;
    }
  }
  
  showPassword(){
    this.showpwd=!this.showpwd
  }
  public onRememberMe(){
    this.rememberMe=!this.rememberMe;
  }
  public forgetPassword(){
    this.router.navigate(['/forgetPassword']);
  }
}
