<div>
    <div *ngIf="data.headerText === 'accept'"><h3><b>Accept Reason</b></h3></div>
    <div *ngIf="data.headerText === 'reject'"><h3><b>Reject Reason</b></h3></div>
    <div class="row">
        <div class="col-6">
            <mat-form-field appearance="outline" [style.width.px]=500>
            <textarea  *ngIf="data.headerText === 'accept'"  matInput type="textarea" placeholder="Enter the Accept reason here..." 
            [(ngModel)]="refundData.doctor_description" rows="10" cols="70" >
            </textarea>
            <textarea  *ngIf="data.headerText === 'reject'"  matInput type="textarea" placeholder="Enter the Reject reason here..." 
            [(ngModel)]="refundData.doctor_description" rows="10" cols="70" >
            </textarea>
            
        </mat-form-field>
        </div>
    </div>
    <div class="row pt-4">
    <div class="col-8" style="text-align:center;margin-left:10%">
        <button class="btn1" mat-stroked-button color='default' (click)="closeDialog()">Cancel</button>&nbsp;
        <button class="btn" mat-stroked-button color='primary' (click)="saveDialog()">Save</button>
    </div>
    </div>
</div>
