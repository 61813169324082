import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentService } from 'src/app/service/appointment.service';
import { ConfirmationDialogComponent } from 'src/app/shared_components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.css']
})
export class LeftPanelComponent implements OnInit {
  public panelOpenState:boolean;
  public panelDefault:boolean=true;
  public userData=JSON.parse(localStorage.getItem('userDetails'));
  public userName:string="";
  public providers:any=[];
  originalProviders:any=[];
  redirect:boolean=false;
  access:any;
  appCount:number;
  fromDate:any;
  toDate:any;
  patientData:any;
  patientList:any;
  appData:any;
  subdomain:any=[];
  constructor(public appService:AppointmentService,private snackBar: MatSnackBar,private dialog: MatDialog) { }

  ngOnInit(): void {
    //console.log(this.userData)
    this.access=this.userData.access;
    this.userName=this.userData.userAccess.first_name+" "+
    (this.userData.userAccess.middle_name!==null ? this.userData.userAccess.middle_name:"")+" "+
    (this.userData.userAccess.last_name!==null ? this.userData.userAccess.last_name:"");
    let domain = window.location.hostname;
    this.subdomain = domain.split(".");
    this.onGetProviders();
    var d = new Date();
    let prevMonth =(d.getMonth() + 1);
    let prevDay = d.getDate();
    let prevYear = d.getFullYear();
    this.toDate=prevMonth+"-"+prevDay+"-"+prevYear;
    d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
    let month =(d.getMonth() + 1);
    let day = d.getDate();
    let year = d.getFullYear();
    this.fromDate=month+"-"+day+"-"+year;
    // this.loadAppointmentList();
    this.appService.appointmentLength.subscribe(data => this.appCount=data);
    this.appService.popupFlag.subscribe(data => this.redirect = data);
    this.appService.patientFlag.subscribe(data =>{
      this.patientData = data;
      this.patientList=data.userData;
      //console.log(this.patientData)
    });
  }
  loadAppointmentList(){
    let appParams={
      appointment_start_date:this.fromDate,
      appointment_end_date:this.toDate
    }
    this.appService.getAppointmentList(appParams).subscribe((response) => {
      if (response) {
        //console.log(response)
        let appData:any=response;
        if(appData.length>0){
          this.appCount=appData.length;
        } else {
          this.appCount=0;
        }
      }
    }, (error) => {
      //console.log(error)
        //this.snackBar.open(JSON.stringify(error.error), 'Close', {duration: 2000});
    });
  }
  onGetProviders(){
    this.appService.getProviders().subscribe((response) => {
      if (response) {
        //console.log(response)
        this.originalProviders=response;
        this.providers=response;

        this.providers.map(element=>{
          element.checked=false;
        })
        this.providers=this.providers.sort((a, b) => Number(b.online) - Number(a.online));
        }
    }, (error) => {
      //console.log(error)
      if(error.status=404){
        this.snackBar.open(error.statusText, 'Close', {duration: 2000});
      }
    });
  }
  searchDoctor(event){
    this.providers=this.originalProviders.filter(x=>x.first_name.includes(event.target.value));
  }
  openPopup(pageName){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: 'Are you sure to redirect to '+pageName+' page?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if(pageName=='doctorSelect'){
          let doctorList=[];
          doctorList=this.providers.filter(x=>x.checked);
          //console.log(doctorList)
          this.appService.getDoctorList(doctorList);
          this.appService.refundClick(true);
          this.appService.notesClick(true);
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.questionnaireClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.dashboardClick(false);
          this.appService.doctorClick(true);
        } else if(pageName=='Landing'){
          this.appService.refundClick(true);
          this.appService.doctorClick(true);
          this.appService.notesClick(true);
          this.appService.patientClick(true);
          this.appService.changeMessage(false);
          this.appService.questionnaireClick(true);
          this.appService.dashboardClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
        } else if(pageName=='Notes List'){
          this.appService.refundClick(true);
          this.appService.doctorClick(true);
          this.appService.notesClick(false);
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.questionnaireClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.dashboardClick(true);
        } else if(pageName=='Patient records'){
          this.appService.refundClick(true);
          this.appService.doctorClick(true);
          this.appService.patientClick(false);
          this.appService.changeMessage(true);
          this.appService.notesClick(true);
          this.appService.questionnaireClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.dashboardClick(true);
        }else if(pageName=='Doctor managment'){
          this.appService.refundClick(true);
          this.appService.doctorClick(false);
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.notesClick(true);
          this.appService.questionnaireClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.dashboardClick(true);
        } else if(pageName=='Report') {
          this.appService.refundClick(true);
          this.appService.doctorClick(true);
          this.appService.reportClick({flag:false,selectedreport:'Patient Appointment'});
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.notesClick(true);
          this.appService.questionnaireClick(true);
          this.appService.dashboardClick(true);
        } else if(pageName=='Refund Request') {
          this.appService.refundClick(false);
          this.appService.doctorClick(true);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.notesClick(true);
          this.appService.questionnaireClick(true);
          this.appService.dashboardClick(true);
        } else if(pageName=='Dashboard') {
          this.appService.refundClick(true);
          this.appService.doctorClick(true);
          this.appService.dashboardClick(false);
          this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
          this.appService.patientClick(true);
          this.appService.changeMessage(true);
          this.appService.notesClick(true);
          this.appService.questionnaireClick(true);
        }
      } else {
        this.redirect=true;
      }
    });
  }
  doctorSelect(){
    if(this.redirect){
      this.openPopup('doctorSelect');
    } else {
      let doctorList=[];
    doctorList=this.providers.filter(x=>x.checked);
    //console.log(doctorList)
    this.appService.getDoctorList(doctorList);
    this.appService.notesClick(true);
    this.appService.patientClick(true);
    this.appService.changeMessage(true);
    this.appService.questionnaireClick(true);
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
    this.appService.dashboardClick(false);
    this.appService.doctorClick(true);
    this.appService.refundClick(true);
    }
    this.redirect=false;
  }
  newAppointment(){
    if(this.redirect){
    this.openPopup('Landing');
    } else {
      this.appService.refundClick(true);
      this.appService.notesClick(true);
      this.appService.patientClick(true);
      this.appService.changeMessage(false);
      this.appService.questionnaireClick(true);
      this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
      this.appService.dashboardClick(true);
      this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
  notesListClick(){
    if(this.redirect){
    this.openPopup('Notes List');
    } else{
      this.appService.refundClick(true);
      this.appService.notesClick(false);
      this.appService.patientClick(true);
      this.appService.changeMessage(true);
      this.appService.questionnaireClick(true); 
      this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'}); 
      this.appService.dashboardClick(true); 
      this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
  patientRecordClick(){
    if(this.redirect){
    this.openPopup('Patient records');
    } else {
    this.appService.refundClick(true);
    this.appService.patientClick(false);
    this.appService.changeMessage(true);
    this.appService.notesClick(true);
    this.appService.questionnaireClick(true);
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
    this.appService.dashboardClick(true);
    this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
  doctorMgmtClick(){
    if(this.redirect){
    this.openPopup('Doctor managment');
    } else {
    this.appService.refundClick(true);
    this.appService.patientClick(true);
    this.appService.changeMessage(true);
    this.appService.notesClick(true);
    this.appService.questionnaireClick(true);
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
    this.appService.dashboardClick(true);
    this.appService.doctorClick(false);
    }
    this.redirect=false;
  }
  reportClick(selectedReport){
    if(this.redirect){
    this.openPopup('Report');
    } else {
    this.appService.refundClick(true);
    this.appService.reportClick({flag:false,selectedreport:selectedReport});
    this.appService.patientClick(true);
    this.appService.changeMessage(true);
    this.appService.notesClick(true);
    this.appService.questionnaireClick(true);
    this.appService.dashboardClick(true);
    this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
  requestRefundClick(){
    if(this.redirect){
    this.openPopup('Refund Request');
    } else {
    this.appService.refundClick(false);
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
    this.appService.patientClick(true);
    this.appService.changeMessage(true);
    this.appService.notesClick(true);
    this.appService.questionnaireClick(true);
    this.appService.dashboardClick(true);
    this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
  dashboardClick(){
    if(this.redirect){
    this.openPopup('Dashbord');
    } else {
    this.appService.refundClick(true);
    this.appService.dashboardClick(false);
    this.appService.reportClick({flag:true,selectedreport:'Patient Appointment'});
    this.appService.patientClick(true);
    this.appService.changeMessage(true);
    this.appService.notesClick(true);
    this.appService.questionnaireClick(true);
    this.appService.doctorClick(true);
    }
    this.redirect=false;
  }
}
