<div class="container-fluid">
  <div class="row justify-content-end">
    <div class="col-sm-7" style="height: 7vh">
      <div class="d-flex justify-content-between h-100">
        <div class="headerTitle p-0 align-self-center">Medosys</div>
        <div class="d-flex">
          <!-- <div class="userIcon align-self-center mx-3" (click)="homeClicked()">
            <span>
              <mat-icon style="color:white;">home</mat-icon>
            </span> -->
          <!-- <div class="userIcon" style="margin-left: 3vw;"><span><mat-icon style="color:white">account_circle</mat-icon></span>
                <span class="userName">{{userName}}</span -->
          <!-- </div> -->
          <!-- <div class="userIcon align-self-center mx-3" ><span><mat-icon style="color:white">add_box</mat-icon></span></div> -->
          <!-- <div class="mx-3 align-self-center">
            <span *ngIf="clinicList.length > 0" style="font-size:0.9em;color:white;width: 5vw;">{{ selectedClinic }}</span>
              <mat-select [(ngModel)]="selectedClinic" class="login-email ng-reflect" style="font-size:0.9em;color:white;display: inline-block;" (selectionChange)="onClinicChange($event)">
                <mat-option class="not-empty-select" *ngFor="let select of clinicList" [value]="select.subdomain_prefix" ><span [href]="select.url" target="blank"><b>{{select.subdomain_prefix}}</b></span></mat-option>
              </mat-select>
              
          </div> -->
          <div class="arrow align-self-center pt-2" style="width: 5vw">
            <mat-select
              [(ngModel)]="selectedClinic"
              style="font-size: 2em"
              class="login-email"
              style="font-size: 0.9em; color: white"
              (selectionChange)="onClinicChange($event)"
            >
              <mat-option
                class="not-empty-select"
                *ngFor="let select of clinicList"
                [value]="select.subdomain_prefix"
                ><span [href]="select.url" target="blank"
                  ><b>{{ select.subdomain_prefix }}</b></span
                ></mat-option
              >
            </mat-select>
          </div>
          <div class="userName ml-3 align-self-center">
            <button
              class="header-user-detail d-flex w-100"
              mat-icon-button
              (click)="select.open()"
            >
              <div class="header-user-name d-flex">
                <img
                  [src]="
                    userData.userAccess?.avator_url
                      ? userData.userAccess?.avator_url
                      : '../../../assets/images/avator.JPG'
                  "
                  style="width: 35px; height: 35px; border-radius: 50%"
                />
                <span class="userName w-100" style="text-transform: capitalize"
                  >{{ userName }}
                </span>
                <span
                  style="background-color: rgb(75, 240, 97)"
                  class="online w-100"
                ></span>
              </div>
              <mat-select
                #select
                class="langSelect"
                panelClass="testClass"
                disableOptionCentering
              >
                <mat-option (click)="logout()" value="en">Logout</mat-option>
                <mat-option (click)="editProfile()" value="en"
                  >Edit Profile</mat-option
                >
                <mat-option
                  *ngIf="access?.Manage_users?.read"
                  (click)="manageUser()"
                  value="en"
                  >Manage User</mat-option
                >
                <mat-option
                  *ngIf="access?.Manage_users?.read && isAdmin"
                  (click)="manageTenant()"
                  value="en"
                  >Manage Tenant</mat-option
                >
              </mat-select>
            </button>
          </div>
          <div class="align-self-center">
            <button mat-icon-button (click)="select1.open()">
              <span
                ><mat-icon
                  class="mt-1 pt-1 mr-5 ml-2"
                  style="
                    color: white;
                    width: 30px;
                    height: 25px;
                    border-radius: 50%;
                  "
                  >settings</mat-icon
                ></span
              >
              <!-- <img [src]="userData.userAccess?.avator_url ? userData.userAccess?.avator_url : '../../../assets/images/avator.JPG'" /> -->
              <mat-select
                #select1
                class="langSelect"
                panelClass="testClass"
                disableOptionCentering
              >
                <mat-option (click)="paymentSettings()" value="en"
                  >Payment Settings</mat-option
                >
                <mat-option value="en" (click)="timeDialog()"
                  >Timeslot Settings</mat-option
                >
                <!-- <mat-option value="en">Zone Settings</mat-option> -->
              </mat-select>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
