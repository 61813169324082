<div>
  <!-- <div><h3><b>Set Default Doctor Fee (Dr.{{doctorData.first_name}})</b></h3></div> -->
  <div class="row" *ngFor="let special of speciality">
    <div
      class="col-6 pt-4"
      style="font-weight: 500; font-size: 1em; color: rgb(46, 134, 134)"
    >
      {{ special.name }}:
    </div>
    <div class="col-6">
      <mat-form-field appearance="outline" [style.width.px]="100">
        <input
          matInput
          placeholder="Doctor Charge"
          oninput="this.value = Math.abs(this.value > 0 && this.value)"
          min="1"
          type="number"
          (ngModelChange)="checkFee($event)"
          [(ngModel)]="special.charge"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="text-right">
    <label
      style="font-weight: 500; font-size: 1em; color: red"
      *ngIf="!!showError"
      >Fee should be greater than 0</label
    >
  </div>
  <div class="row pt-4">
    <div class="col-8" style="text-align: center; margin-left: 10%">
      <button
        class="btn1"
        mat-stroked-button
        color="default"
        (click)="closeDialog()"
      >
        Cancel</button
      >&nbsp;
      <button
        class="btn"
        mat-stroked-button
        color="primary"
        (click)="saveDialog()"
      >
        Save
      </button>
    </div>
  </div>
</div>
